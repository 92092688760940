/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import Parser from 'html-react-parser'

import * as actionCreators from '../../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../../src/utils'
import { FillableComponent } from '../../../../../../../../src/components/fillable_component'

const classNames = require('classnames')

export class MyProductInfoBoxComponent extends FillableComponent {
  renderBody = () => {
    const { specialization, environment, data } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { product_info } = data
    const { title, subtitle, description, indications } = product_info

    return (
      <div className={classNames('')}>
        <div className={classNames('fw-bold fs-5')}>{title}</div>
        <div className={classNames('fst-italic fc-5 border-bottom')}>
          {subtitle}
        </div>
        <div className={classNames('my-3')}>{Parser(description, {})}</div>
        <div className={classNames('')}>
          <div
            className={classNames(
              'fst-uppercase fc-1 fs-7 fw-bold border-top pt-2'
            )}
          >
            {texts[locale].food_supplement_indications}
          </div>
          <div>{indications}</div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(MyProductInfoBoxComponent)
