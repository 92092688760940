import { saveSessionToServer } from '../../../../../../../src/services/sessionHelper'
import { cloneObj } from '../../../../../../../src/utils'

export async function handleAddQty(sender, pID, value) {
  const { props } = sender
  const { session, updateSession } = props

  const session_copy = cloneObj(session)

  const { session_id, session_data } = session_copy
  const { cart } = session_data
  const { products } = cart

  const curr_product = products[pID]

  const { quantity } = curr_product
  const updated_qty = Math.max(quantity + value, 0)
  curr_product.quantity = updated_qty

  const returned_data = await saveSessionToServer(session_id, session_data)

  session_copy.session_data = returned_data
  await updateSession(session_copy)
}
