/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import JujoLoading from '../../../../../../../src/components/loading'

import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'
import * as apiService from '../../../../../../../src/services/apiService'

const classNames = require('classnames')

export class MyMicronutrients extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      unities: [
        { label: 'Mg', slug: 'my_unity_milligrams' },
        { label: 'UI', slug: 'my_unity_unity' },
      ],
      micronutrients: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    await this.updateData()
  }

  componentDidUpdate = async prevProps => {
    const { holisticId: prevHolisticId } = prevProps
    const { holisticId } = this.props
    if (prevHolisticId !== holisticId) {
      await this.updateData()
    }
  }

  updateData = async () => {
    const data = await this.getServerInterpretationMicronutrients()
    const { micronutrients } = data
    this.setState({ micronutrients })
  }

  getServerInterpretationMicronutrients = async () => {
    const { environment } = this.props
    const { locale } = environment
    const parsed_url = `my/dna-result/get-interpretation-micronutrients-for-frontend?locale=${locale}`
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsed_url}`,
      {}
    )
    const { data, status } = response
    return status === 200 || status === 201 ? data : false
  }

  render() {
    const { unities, micronutrients } = this.state
    const { specialization, environment, genericData } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { myResults } = genericData
    const { swab } = myResults
    const { product_id } = swab || false
    const results_font_color = product_id === 4 ? 'fc-11' : 'fc-1'
    return (
      <>
        {!micronutrients && <JujoLoading />}
        {micronutrients && (
          <div
            className={classNames(
              'd-flex flex-column align-items-start justify-content-center mb-4 pt-4 w-100 fs-7 fw-bold'
            )}
          >
            <div
              className={classNames(
                ' border-bottom border-2 border-dark my-2 pb-2'
              )}
            >
              {texts[locale].my_result_legend}
            </div>

            {/* unities */}
            {unities.map(unity => (
              <div>{`${unity.label} = ${texts[locale][unity.slug]}`}</div>
            ))}

            {/* micronutrients */}
            <div
              className={classNames(
                'd-flex flex-column align-items-start justify-content-center border-top border-2 border-color-0 my-4 pt-4 w-100'
              )}
            >
              <div style={{ width: '230px' }}>
                <div className={classNames('row mb-2', results_font_color)}>
                  <div className={classNames('col-6')}>
                    {texts[locale].my_micronutrient}
                  </div>
                  <div className={classNames('col-6')}>
                    <div
                      className={classNames('text-center')}
                      style={{ width: '154px' }}
                    >
                      {texts[locale].quantity}
                    </div>
                  </div>
                </div>
                {micronutrients &&
                  micronutrients.map(micronutrient => (
                    <div className={classNames('row fw-normal')}>
                      <div className={classNames('col-6')}>
                        {texts[locale][`my_unities_${micronutrient.name}`]}
                      </div>
                      <div className={classNames('col-6 d-flex flex-row ')}>
                        <div
                          className={classNames('text-end')}
                          style={{ width: '100px' }}
                        >
                          {micronutrient.value}
                        </div>
                        <div className={classNames('ms-2')}>
                          {micronutrient.unity}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MyMicronutrients)
