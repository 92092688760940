export function formatted_swab_code(code) {
  let formatted_code = `${code}`
  formatted_code =
    formatted_code === undefined
      ? ''
      : `${formatted_code.slice(0, 3)} - ${formatted_code.slice(-3)}`
  return formatted_code
}

export function getProductIdBySlug(product_list, slug) {
  const keys = Object.keys(product_list)
  for (let i = 0; i < keys.length; i += 1) {
    const product = product_list[keys[i]]
    if (product.slug === slug) {
      return product.id
    }
  }
  return 0
}

export function accessTheFastCheckout() {
  if (typeof window !== 'undefined') {
    window.location.href = '/my-account/fast-checkout/'
  }
}
