export async function showLoginForm(sender) {
  const { props } = sender
  const { authentication, updateAuthentication } = props
  const updatedAuth = { ...authentication }
  updatedAuth.force_login = true
  await updateAuthentication(updatedAuth)
}

export function gotoSignUpPage() {
  if (typeof window !== 'undefined') {
    window.location.href = '/sign-up'
  }
}

export function goToPrivateSection(sender) {
  const { props } = sender
  const { authentication } = props
  const { user } = authentication
  const { type } = user

  const link = type === 'user' ? '/my-account/' : '/admin/'
  if (typeof window !== 'undefined') {
    window.location.href = link
  }
}

export function isUserLoggedIn(sender) {
  const { props } = sender
  const { authentication } = props
  const { user } = authentication

  return Object.keys(user).length > 0
}
