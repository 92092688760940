import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'

import { FillableComponent } from '../../../../../../../src/components/fillable_component'
import JujoEditableComponent from '../../../../../../../src/components/editable_component'

const classNames = require('classnames')

export class MandatoryPopupBody extends FillableComponent {
  renderCloseButton = () => <></>

  renderBody = () => {
    const { environment, specialization, authentication } = this.props
    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations

    const { user } = authentication
    const { name } = user

    const html = []

    html.push(
      <div key="mandatory-popup-body" className={classNames('')}>
        <div className={classNames('d-flex fs-4')}>
          <div className={classNames('fc-1')}>{texts[locale].hello}</div>
          <div className={classNames('ms-2 fw-bold')}>{name}</div>
        </div>
        <div className={classNames('fs-7 fc-1 fst-italic mb-5')}>
          {texts[locale].my_profile_badge_description}
        </div>
        <JujoEditableComponent
          c_def={{
            comp: 'editable_component',
            entity: {
              source: 'static',
              defValue: 'mandatory_profile_props',
            },
            renderer: {
              viewPath: 'my_profile_group/mandatory_profile_popup_fields',
            },
          }}
        />
      </div>
    )

    return html
  }
}

export default connect(mapStateToProps, actionCreators)(MandatoryPopupBody)
