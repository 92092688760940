/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'

import KitColoredBox from '../../../common_group/kitColoredBox'

const classNames = require('classnames')

export class VitaeDietNotYetAvailable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { specialization, environment } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div className={classNames('w-100 d-flex flex-column')}>
        <KitColoredBox
          title={`${texts[locale].diet} VitaeDNA`}
          customClass="bg-diet"
        >
          <div
            className={classNames('d-flex flex-column')}
            dangerouslySetInnerHTML={{
              __html: texts[locale].my_diet_informative,
            }}
          />
        </KitColoredBox>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeDietNotYetAvailable)
