import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'
import Questions from '../commons/expandable_questions/questions'
import JujoButtonComponent from '../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaeFaqShellComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index_expanded: -1,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleButtonClick = index => {
    const { index_expanded } = this.state
    const index_updated = index_expanded === index ? -1 : index
    this.setState({ index_expanded: index_updated })
  }

  goTo = url => {
    if (typeof window !== 'undefined') {
      window.open(url, '_self')
    }
  }

  drawContent = () => {
    const { index_expanded } = this.state
    const { environment, specialization, data } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const html = []
    data.map((item, index) => {
      const {
        title,
        subtitle,
        capitalized_title,
        capitalized_subtitle,
        force_to_show_all,
        faq_button,
        questions,
      } = item || {}
      const number_of_questions_to_show =
        (questions.length > 5 && index === index_expanded) ||
        force_to_show_all === true
          ? questions.length
          : 5
      const show_button = questions.length > 5 && !force_to_show_all
      const button_label =
        index === index_expanded
          ? `- ${texts[locale].see_less}`
          : `+ ${texts[locale].see_more}`
      const data_for_questions = {
        questions,
        number_of_questions_to_show,
      }
      const faq_url = `${process.env.site_url + locale}/faq`
      html.push(
        <div
          key={index}
          className={classNames('p-2 p-md-5 mt-md-3 mb-5')}
          style={{
            borderRadius: '20px',
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
            backgroundColor: '#fff',
          }}
        >
          <div
            className={classNames(
              'fs-4 fc-1 text-center',
              capitalized_title === true ? 'fst-uppercase' : '',
              subtitle ? 'mb-2' : 'mb-5'
            )}
          >
            {title}
          </div>
          {subtitle && (
            <div
              className={classNames(
                'fs-5 fc-1 text-center mb-5',
                capitalized_subtitle === true ? 'fst-uppercase' : ''
              )}
            >
              {subtitle}
            </div>
          )}

          <Questions data={data_for_questions} />
          {show_button && (
            <div
              className={classNames(
                'd-flex align-items-center justify-content-center mt-5'
              )}
            >
              <JujoButtonComponent
                bstyle={3}
                bwidth="160px"
                blabel={button_label}
                handleClick={() => this.handleButtonClick(index)}
              />
            </div>
          )}
          {faq_button && (
            <>
              <div className={classNames('fs-3 fc-1 mt-5')}>
                {`${texts[locale].more_questions}?`}
              </div>
              <div
                className={classNames(
                  'my-4 d-flex align-items-center justify-content-center'
                )}
              >
                <JujoButtonComponent
                  bstyle={1}
                  bclasses="fc-white kit_health-bg-gradient fs-6 fw-600"
                  bwidth="228px"
                  bheight="39px"
                  blabel={texts[locale].go_to_faq_section}
                  bdata={faq_url}
                  handleClick={() => this.goTo(faq_url)}
                />
              </div>
            </>
          )}
        </div>
      )
      return item
    })
    return html
  }

  render() {
    return <>{this.drawContent()}</>
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeFaqShellComponent)
