import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../utils'
import * as actionCreators from '../../store/actions'

export class JujoButtonComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const {
      bstyle,
      bclasses,
      blabel,
      bwidth,
      bheight,
      bdata,
      handleClick,
      bicon,
      biconWidth,
      biconHeight,
      bFontClass,
      bDisabled,
    } = this.props
    let btn_class = ''
    switch (bstyle) {
      case 0:
        btn_class = 'rounded-50 py-2 mx-2 my-1 my-md-0 text-center'
        break
      case 1:
        btn_class =
          'bg-2 bg2-gradient-270deg fc-white rounded-50 py-2 mx-2 my-1 my-md-0 text-center'
        break
      case 2:
        btn_class =
          'fc-4 bshadow-c4 rounded-50 py-2 mx-2 my-1 my-md-0 text-center'
        break
      case 3:
        btn_class =
          'bg-1 bg1-gradient-270deg fc-white rounded-50 py-2 mx-2 my-1 my-md-0 text-center'
        break
      case 4:
        btn_class =
          'border fs-8 rounded-50 px-1 py-0 my-1 my-md-0 text-center lh-1 d-flex align-items-center justify-content-center '
        break
      case 5:
        btn_class =
          'border fs-8 rounded-50 px-1 py-0 my-1 my-md-0 text-center lh-1 d-flex align-items-center justify-content-center fc-white '
        break
      case 6:
        btn_class =
          'bg-2 bg2-gradient-270deg fc-white rounded py-2 my-1 my-md-0 text-center fs-8 fw-600 d-flex align-items-center justify-content-center'
        break
      case 7:
        btn_class =
          'bg-1 bg1-gradient-270deg fc-white rounded py-2 mx-2 my-1 my-md-0 text-center'
        break
      case 8:
        btn_class =
          'bg-white fc-1 fs-7 rounded-5 py-2 mx-0 mx-md-2 my-2 text-center'
        break
      case 9:
        btn_class =
          'bg-1 bg1-gradient-270deg fc-white rounded-50 py-1 mx-2 my-1 my-md-0 text-center'
        break
      case 10:
        btn_class =
          'bg-danger bg1-gradient-270deg fc-white rounded-50 py-2 mx-2 my-1 my-md-0 text-center'
        break
      default:
        btn_class = 'rounded-50 py-2 mx-2 my-1 my-md-0 text-center'
    }

    return (
      <div
        className={classNames(btn_class, bclasses)}
        style={{
          width: bwidth || '150px',
          height: bheight || '40px',
          opacity: bDisabled ? '0.3' : '1',
        }}
        role="button"
        tabIndex={0}
        onClick={() => {
          if (!bDisabled) {
            handleClick(bdata)
          }
        }}
        onKeyPress={() => {
          if (!bDisabled) {
            handleClick(bdata)
          }
        }}
      >
        <div
          className={classNames(
            'd-flex align-items-center justify-content-center flex-row'
          )}
        >
          {bicon && bicon !== '' && (
            <div
              className={classNames(`mx-1 ${bicon || ''}`)}
              style={{
                width: biconWidth || '19px',
                height: biconHeight || '19px',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}
            />
          )}
          {blabel && (
            <div
              className={classNames(`${bFontClass || ''} mx-1`)}
              style={{
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                MsUserSelect: 'none',
                UserSelect: 'none',
              }}
            >
              {blabel}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoButtonComponent)
