import apiCallMap from '../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../src/enums/apiRequestTypesMap'

import * as apiService from '../../../../../src/services/apiService'

import { showMessageBox } from '../../components/common_group/message_box'

import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../src/services/servicesHelper'
import { cloneObj, processLogout } from '../../../../../src/utils'

export async function reloadAddressesOnCreationCompleted() {
  if (typeof window !== 'undefined') {
    window.location.reload()
  }
}

export async function exitMandatoryFieldsPopup(sender) {
  processLogout(sender)
}

export async function updateMandatoryFieldsData(sender) {
  const { state, props } = sender
  const { environment, authentication } = props
  const { localDataSource } = state

  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'address',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'update-mandatory-fields-data',
    pDefValue: 'update-mandatory-fields-data',
  })

  const parsedEp = parseEndpoint(requestData)
  const dataToSend = cloneObj(localDataSource)

  const response = await apiService.httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    dataToSend
  )

  const { status } = response
  if (status === 200 || status === 201) {
    if (typeof window !== 'undefined') {
      window.location.reload()
    }
  } else {
    showMessageBox(sender, response, 'error')
  }
}
