const meals = [
  'breakfast',
  'snak_1',
  'lunch',
  'snak_2',
  'dinner',
  'snak_after_dinner',
]

const macronutrients = ['carbohydrates', 'proteins', 'fats', 'fruits', 'mix']

export { meals, macronutrients }
