import { reloadSession } from '../../../../../../src/services/sessionHelper'
import { retrieveComponent, scrollPageOnTop } from '../../../../../../src/utils'

export async function performActionOnDataUpdatedSuccessfully(sender) {
  const { props } = sender
  const { msbox_instance_id } = props

  await reloadSession(sender)
  retrieveComponent(sender, msbox_instance_id)
  scrollPageOnTop()
}

export async function closeMessageBoxAndScrollOnTop(sender) {
  const { props } = sender
  const { msbox_instance_id } = props

  retrieveComponent(sender, msbox_instance_id)
  scrollPageOnTop()
}
