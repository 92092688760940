import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaePromoDiscount extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { environment, specialization, session } = this.props
    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations
    const { session_data } = session
    const { discount_data } = session_data
    const { discount_type, discount_value, ambassador_name } = discount_data
    const discount_value_rounded = Math.round(discount_value)
    const discount_to_show =
      discount_type === 'percentage'
        ? `${discount_value_rounded} %`
        : `${discount_value_rounded} €`

    if (Number.isNaN(discount_value_rounded) || discount_value_rounded === 0) {
      return <></>
    }

    return (
      <div
        className={classNames(
          'border border-2 border-white rounded-5 py-3 px-5 dflex-flex-column align-items-center justify-content-center mt-3'
        )}
      >
        <div className={classNames('fs-2 fw-bold')}>
          {`${texts[locale].discount} ${discount_to_show}`}
        </div>
        <div className={classNames('fs-7 my-2')}>
          {`offerto da ${ambassador_name}`}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaePromoDiscount)
