/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

import * as vitae_utils from '../../../utils'
import JujoButtonComponent from '../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaeMyResultsTitleAndDownloadButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleDownloadButtonClick = () => {
    const { downloadUrl } = this.props
    window.open(downloadUrl)
  }

  render() {
    const {
      title_black_part,
      title_colored_part,
      title_class,
      result_date,
      swab_id,
    } = this.props
    const { specialization, environment } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    return (
      <>
        <div
          className={classNames(
            'px-0 py-1 d-flex flex-column flex-md-row align-items-center justify-content-md-between'
          )}
        >
          <div
            className={classNames(
              'px-0 py-1 d-flex align-items-center justify-content-between'
            )}
          >
            <div className={classNames('px-0 py-1 fs-6 fw-bold')}>
              {title_black_part}
            </div>
            <div
              className={classNames(
                `px-0 py-1 fs-6 fw-bold mx-1 text-uppercase ${title_class}`
              )}
            >
              {title_colored_part}
            </div>
          </div>
          <div>
            <JujoButtonComponent
              bstyle={3}
              bwidth="230px"
              blabel={texts[locale].my_download_complete_pdf}
              bicon="download-icon"
              bFontClass="fw-bold"
              bdata=""
              handleClick={this.handleDownloadButtonClick}
            />
          </div>
        </div>
        <div className={classNames('d-flex justify-content-start')}>
          <div className={classNames('fs-7 me-1')}>{texts[locale].date}</div>
          <div className={classNames('fs-7 fw-bold me-1')}>{result_date}</div>
          <div className={classNames('fs-7 ms-4 me-1')}>
            {texts[locale].code}
          </div>
          <div className={classNames('fs-7 fw-bold me-1')}>
            {vitae_utils.formatted_swab_code(swab_id)}
          </div>
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeMyResultsTitleAndDownloadButton)
