import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import {
  createMSBDef,
  getEmptyMessageBox,
} from '../../../../../../../src/components/message_box/helper'

import * as actionCreators from '../../../../../../../src/store/actions'
import {
  injectComponent,
  mapStateToProps,
  retrieveComponent,
} from '../../../../../../../src/utils'
import * as apiService from '../../../../../../../src/services/apiService'

export class VitaeAddressLineComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  editRecord = () => {
    const { enterEditMode, record } = this.props
    enterEditMode(record)
  }

  previewPrivateData = user_id => {
    const { environment, authentication } = this.props
    const { access_token } = authentication
    const { locale } = environment
    const url = `${process.env.apiServerUrl}pdf/vitae/customer-private-data?locale=${locale}&user_id=${user_id}&tk=${access_token}`
    window.open(url)
  }

  previewDiet = user_id => {
    const { environment, authentication } = this.props
    const { access_token } = authentication
    const { locale } = environment
    const url = `${process.env.apiServerUrl}pdf/vitae/diet?locale=${locale}&user_id=${user_id}&tk=${access_token}`
    window.open(url)
  }

  previewHealth = user_id => {
    const { environment, authentication } = this.props
    const { access_token } = authentication
    const { locale } = environment
    const url = `${process.env.apiServerUrl}pdf/vitae/health?locale=${locale}&user_id=${user_id}&tk=${access_token}`
    window.open(url)
  }

  sendEmail = user_email => {
    window.open(`mailto:${user_email}`)
  }

  completeDietaPublish = async params => {
    const { sender, service_id, msbox_instance_id } = params
    const dataToSend = { service_id }
    await apiService.httpPost(
      `${process.env.apiUrl}my/service/publish`,
      dataToSend,
      true
    )
    window.location.href = '/my-account/diets/'
    retrieveComponent(sender, msbox_instance_id)
  }

  completeUnlockDiet = async params => {
    const { sender, service_id, msbox_instance_id } = params
    const dataToSend = { service_id }
    await apiService.httpPost(
      `${process.env.apiUrl}my/service/unlock`,
      dataToSend,
      true
    )
    window.location.href = '/my-account/diets/'
    retrieveComponent(sender, msbox_instance_id)
  }

  closeMessageBox = params => {
    const { sender, msbox_instance_id } = params
    retrieveComponent(sender, msbox_instance_id)
  }

  publishDiet = id => {
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const messageBox = getEmptyMessageBox()
    messageBox.icon = 'question-icon-color'
    messageBox.title = texts[locale].publish_diet_title
    messageBox.message = texts[locale].publish_diet_text

    const no_btn = createMSBDef(
      texts[locale].cancel,
      this.closeMessageBox,
      {
        sender: this,
        msbox_instance_id: messageBox.id,
      },
      false
    )
    messageBox.buttons.push(no_btn)

    const ok_btn = createMSBDef(
      texts[locale].yes,
      this.completeDietaPublish,
      {
        sender: this,
        msbox_instance_id: messageBox.id,
        service_id: id,
      },
      true
    )
    messageBox.buttons.push(ok_btn)

    const component_definition = {
      specialized: false,
      path: 'message_box',
      data: {
        messageBox,
      },
    }

    injectComponent(this, messageBox.id, component_definition)
  }

  unlockDiet = id => {
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const messageBox = getEmptyMessageBox()
    messageBox.icon = 'question-icon-color'
    messageBox.title = texts[locale].unlock_diet_title
    messageBox.message = texts[locale].unlock_diet_text

    const no_btn = createMSBDef(
      texts[locale].cancel,
      this.closeMessageBox,
      {
        ...this.props,
        msbox_instance_id: messageBox.id,
      },
      false
    )
    messageBox.buttons.push(no_btn)

    const ok_btn = createMSBDef(
      texts[locale].yes,
      this.completeUnlockDiet,
      {
        sender: this,
        msbox_instance_id: messageBox.id,
        service_id: id,
      },
      true
    )
    messageBox.buttons.push(ok_btn)

    const component_definition = {
      specialized: false,
      path: 'message_box',
      data: {
        messageBox,
      },
    }

    injectComponent(this, messageBox.id, component_definition)
  }

  renderButton = (action, icon, parameter, title, extraClass) => {
    const html = (
      <div
        className={classNames(`${icon} mx-2 ${extraClass}`)}
        style={{
          height: '18px',
          width: '18px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
        }}
        title={title}
        role="button"
        tabIndex={0}
        label="edit button"
        onClick={() => {
          this[action](parameter) // this.previewHealth(data)
        }}
        onKeyPress={() => {
          this[action](parameter) // this.previewHealth(data)
        }}
      />
    )
    return html
  }

  render() {
    const { record } = this.props
    const {
      id,
      status,
      in_modification,
      user_id,
      user_email,
      swab_id_formatted,
      user_name,
      ready_for_specialist_at_formatted_day,
    } = record

    const edit_record_style = in_modification ? 'theme-svg-7' : ''
    const private_data_class =
      status === 'waiting_clinical_profile' ? 'theme-svg-8' : ''
    const row_style = status !== 'available' ? 'bshadow-c7-2px' : ''
    const show_publish_button =
      status === 'waiting_specialist' && in_modification === 1

    return (
      <div
        className={classNames(
          'bg-3 my-1 px-3 py-4 fs-7 ffamily-secondary d-flex flex-column flex-md-row justify-content-between align-items-center',
          row_style
        )}
      >
        <div
          className={classNames('col-12 col-md-7 text-center text-md-start')}
        >
          <span className={classNames('fw-bold me-3')}>
            {swab_id_formatted}
          </span>
          <span className={classNames('fc-gray')}>
            {`${
              ready_for_specialist_at_formatted_day !== null
                ? ready_for_specialist_at_formatted_day
                : ''
            }`}
          </span>
          <span className={classNames('fw-bold ms-3')}>{user_name}</span>
        </div>
        <div
          className={classNames(
            'col-12 col-md-5 d-flex d-flex justify-content-center justify-content-md-end mt-3 mt-md-0'
          )}
        >
          {show_publish_button === true && (
            <div className={classNames('d-flex')}>
              {this.renderButton(
                'publishDiet',
                'plane',
                id,
                'Pubblica questa dieta',
                'theme-svg-7'
              )}
            </div>
          )}
          {status === 'available' && (
            <div className={classNames('d-flex')}>
              {this.renderButton(
                'unlockDiet',
                'unlock',
                id,
                'Riapri questa dieta',
                'theme-svg-7'
              )}
            </div>
          )}
          <div className={classNames('d-flex ms-0 ms-md-3')}>
            {this.renderButton(
              'sendEmail',
              'envelope-icon',
              user_email,
              'Contatta questo utente tramite email'
            )}
            {this.renderButton(
              'previewHealth',
              'dna-icon',
              user_id,
              'Visualizza PDF Salute'
            )}
            {this.renderButton(
              'previewDiet',
              'eye-icon',
              user_id,
              'Anteprima dieta'
            )}
            {this.renderButton(
              'previewPrivateData',
              'private_data-icon',
              user_id,
              'Anteprima scheda anamnesi',
              private_data_class
            )}
            {this.renderButton(
              'editRecord',
              'edit-icon-2',
              0,
              'Edita questa dieta',
              edit_record_style
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeAddressLineComponent)
