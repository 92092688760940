import loadable from '@loadable/component'
import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import { mapStateToProps } from '../../utils'

const JujoLoading = loadable(() => import('../loading'))

export class JujoFlowManager extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      flow_state: '',
      state_transitional_data: {},
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { config } = this.props
    const { initial_state, initial_data } = config

    this.setState({
      flow_state: initial_state,
      state_transitional_data: initial_data || {},
      loading: false,
    })
  }

  updateFlowState = async new_state => {
    this.setState({ flow_state: new_state })
  }

  updateFlowData = async data => {
    this.setState({ state_transitional_data: data })
  }

  renderStateComponents = () => {
    const { state_transitional_data, flow_state } = this.state

    const { config } = this.props
    const { steps, injections } = config

    const { components } = steps[flow_state]

    const html = []

    components.forEach(comp => {
      const { specialized, key, path } = comp

      let DynamicComponent = null
      if (specialized) {
        DynamicComponent =
          require(`../../../vitae_specializations/src/${process.env.client}/${path}`).default
      } else {
        DynamicComponent = require(`../../${path}`).default
      }

      if (DynamicComponent) {
        html.push(
          <DynamicComponent
            key={key}
            transitionaData={state_transitional_data}
            updateFlowState={this.updateFlowState}
            updateFlowData={this.updateFlowData}
            injections={injections}
          />
        )
      }
    })

    return html
  }

  render() {
    const { loading } = this.state

    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('')}>{this.renderStateComponents()}</div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoFlowManager)
