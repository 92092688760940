import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class TitleLineAndText extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { title, text, parentClasses } = this.props
    return (
      <div
        className={classNames(`px-0 py-1 d-flex flex-column ${parentClasses}`)}
      >
        <div
          className={classNames(
            'fw-bold border-bottom border-2 border-dark my-2 pb-2'
          )}
        >
          {title}
        </div>
        <div
          className={classNames('mt-1 lh-sm text-justify')}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(TitleLineAndText)
