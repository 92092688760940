import React from 'react'
import { connect } from 'react-redux'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'
import * as actionCreators from '../../../../../../src/store/actions'
import {
  cloneObj,
  mapStateToProps,
  scrollPageOnTop,
} from '../../../../../../src/utils'
import workflowStepsMap from '../enums/workflow_steps_map'
import { getSessionClientData } from '../order_workflow/helper'

const classNames = require('classnames')

export class VitaeOrderWorkflowIndicatorComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  setWorkflowStep = async step_idx => {
    const { session, updateSession } = this.props

    const session_copy = cloneObj(session)

    const { session_id, session_data } = session_copy

    const client_data = getSessionClientData(session_copy)
    const { order_workflow } = client_data
    const { current_step, registration_enabled } = order_workflow

    if (step_idx >= current_step) return
    if (step_idx === workflowStepsMap.registration && !registration_enabled)
      return

    session_data.cart.client_data.order_workflow.current_step = step_idx

    const returned_data = await saveSessionToServer(session_id, session_data)

    session_copy.session_data = returned_data
    await updateSession(session_copy)
    scrollPageOnTop()
  }

  render() {
    const { specialization, environment, session } = this.props

    const client_data = getSessionClientData(session)
    const { order_workflow } = client_data

    const { current_step } = order_workflow

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div
        className={classNames(
          'd-flex justify-content-between ffamily-secondary fs-9 fs-md-8 position-relative'
        )}
      >
        <div
          className={classNames('position-absolute bg-1')}
          style={{
            height: '3px',
            width: 'calc(100% - 80px)',
            top: '15px',
            zIndex: '-1',
            left: '40px',
          }}
        />
        {Object.keys(workflowStepsMap).map((name, idx) => (
          <div
            key={name}
            style={{ width: '80px' }}
            role="button"
            tabIndex={0}
            onClick={async () => {
              await this.setWorkflowStep(idx)
            }}
            onKeyPress={async () => {
              await this.setWorkflowStep(idx)
            }}
          >
            {idx === current_step && (
              <div
                className={classNames(
                  'border border-2 rounded-percentage-100 border-color-1 d-flex justify-content-center align-items-center mx-auto bg-white'
                )}
                style={{ width: '30px', height: '30px' }}
              >
                <div
                  className={classNames('bg-1 rounded-percentage-100')}
                  style={{ width: '20px', height: '20px' }}
                />
              </div>
            )}
            {idx > current_step && (
              <div
                className={classNames(
                  'border border-2 rounded-percentage-100 border-color-4 d-flex justify-content-center align-items-center mx-auto bg-white'
                )}
                style={{ width: '30px', height: '30px' }}
              />
            )}
            {idx < current_step && (
              <div
                className={classNames('confirm-icon-color')}
                style={{
                  width: '30px',
                  height: '30px',
                  marginLeft: '25px',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            )}
            <div
              className={classNames(
                'd-flex justify-content-center mt-1',
                current_step ? 'fc-1 fw-bold' : 'fc-4'
              )}
              style={{ opacity: current_step ? 1 : 0.8 }}
            >
              {texts[locale][name] || name}
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeOrderWorkflowIndicatorComponent)
