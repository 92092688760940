import apiCallMap from '../../../../../../../src/enums/apiCallMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../../src/services/servicesHelper'
import { saveSessionToServer } from '../../../../../../../src/services/sessionHelper'
import * as apiService from '../../../../../../../src/services/apiService'

import { cloneObj, scrollPageOnTop } from '../../../../../../../src/utils'
import addressTypesMap from '../../enums/address_types_map'
import workflowStepsMap from '../../enums/workflow_steps_map'
import { getSessionClientData } from '../../order_workflow/helper'

export function getAddressObject(sender) {
  const { session } = sender.props
  const { session_data } = session
  const { cart } = session_data
  const { user_data } = cart

  const client_data = getSessionClientData(session)
  const { order_workflow } = client_data
  const { current_step } = order_workflow
  const address_obj =
    current_step === workflowStepsMap.shipment
      ? user_data.shipping
      : user_data.billing

  return address_obj
}

export function getAddressType(sender) {
  const { session } = sender.props
  const client_data = getSessionClientData(session)
  const { order_workflow } = client_data
  const { current_step } = order_workflow
  const address_type =
    current_step === workflowStepsMap.shipment
      ? addressTypesMap.shipping
      : addressTypesMap.billing

  return address_type
}

export async function updateAddressTypeSelected(sender, at) {
  const { session, updateSession } = sender.props
  const session_copy = cloneObj(session)

  const { session_id, session_data } = session_copy
  const { cart } = session_data
  const { user_data } = cart

  const client_data = getSessionClientData(session_copy)
  const { order_workflow } = client_data
  const { current_step } = order_workflow

  if (current_step === workflowStepsMap.shipment) {
    user_data.shipping.address_type = at
  } else {
    user_data.billing.address_type = at
  }

  const returned_data = await saveSessionToServer(session_id, session_data)

  session_copy.session_data = returned_data
  await updateSession(session_copy)
}

export async function updateExistingAddressId(sender, id) {
  const { session, updateSession } = sender.props
  const session_copy = cloneObj(session)

  const { session_id, session_data } = session_copy
  const { cart } = session_data
  const { user_data } = cart

  const client_data = getSessionClientData(session_copy)
  const { order_workflow } = client_data
  const { current_step } = order_workflow

  if (current_step === workflowStepsMap.shipment) {
    user_data.shipping.existing.id = id
  } else {
    user_data.billing.existing.id = id
  }

  const returned_data = await saveSessionToServer(session_id, session_data)

  session_copy.session_data = returned_data
  await updateSession(session_copy)
}

export async function getSelectedExistingAddressId(sender) {
  const { session } = sender.props
  const { session_data } = session
  const { cart } = session_data
  const { user_data } = cart

  const client_data = getSessionClientData(session)
  const { order_workflow } = client_data
  const { current_step } = order_workflow

  let id = 0
  if (current_step === workflowStepsMap.shipment) {
    id = user_data.shipping.existing.id
  } else {
    id = user_data.billing.existing.id
  }

  return id
}

export function calculateAddressCreationRenderMode(sender) {
  const { data } = sender.state
  const renderMode =
    Object.keys(data[0] || {}).length <= 0
      ? sender.renderModeMap.add
      : sender.renderModeMap.edit

  return renderMode
}

export async function validateAddressAndChangeWorkflowStep(sender) {
  const { state, props } = sender
  const { environment, authentication, session, updateSession } = props
  const { entityDefinition, localDataSource } = state
  const { apis } = entityDefinition
  const { verifyData } = apis
  const { apiCall, requestType, placeholderMapping, relatedEntity } = verifyData

  const requestData = baseRequestObject(
    apiCallMap[apiCall],
    relatedEntity,
    requestType,
    environment,
    authentication
  )

  requestData.placeholderMapping = placeholderMapping

  const parsedEp = parseEndpoint(requestData)
  const dataToSend = { ...localDataSource }

  const response = await apiService.httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    dataToSend
  )

  const { status } = response
  if (status === 200 || status === 201) {
    const updated_session = cloneObj(session)

    const { session_id, session_data } = updated_session
    const { cart } = session_data
    const { user_data } = cart

    const client_data = getSessionClientData(updated_session)
    const { order_workflow } = client_data
    const { current_step } = order_workflow

    if (current_step === workflowStepsMap.shipment) {
      user_data.shipping.new = dataToSend
      client_data.order_workflow.current_step = workflowStepsMap.billing
    }
    if (current_step === workflowStepsMap.billing) {
      user_data.billing.new = dataToSend
      client_data.order_workflow.current_step = workflowStepsMap.payment
    }

    const returned_data = await saveSessionToServer(session_id, session_data)

    updated_session.session_data = returned_data
    await updateSession(updated_session)
    scrollPageOnTop()
  } else {
    sender.manageResponse(response)
  }
}
