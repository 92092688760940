const admin_profiles = require('./json/admin/profiles.json')
const admin_profiles_customers = require('./json/admin/admin_profiles_customers.json')
const admin_profiles_stores = require('./json/admin/admin_profiles_stores.json')
const admin_profiles_promoters = require('./json/admin/admin_profiles_promoters.json')
const admin_profiles_key_accounts = require('./json/admin/admin_profiles_key_accounts.json')
const admin_profiles_specialists = require('./json/admin/admin_profiles_specialists.json')
const admin_profiles_laboratories = require('./json/admin/admin_profiles_laboratories.json')
const campaigns = require('./json/admin/campaigns.json')
const dna = require('./json/admin/dna.json')
const fees = require('./json/admin/fees.json')
const food_supplements = require('./json/admin/food_supplements.json')
const admin_index = require('./json/admin/index.json')
const landings = require('./json/admin/landings.json')
const orders = require('./json/admin/orders.json')
const payments = require('./json/admin/payments.json')
const plates = require('./json/admin/plates.json')
const products = require('./json/admin/products.json')
const services = require('./json/admin/services.json')
const settings = require('./json/admin/settings.json')
const swabs = require('./json/admin/swabs.json')
const shippings = require('./json/admin/shippings.json')
const draw_ui_jujo_block = require('./json/admin/draw_ui_jujo_block.json')
const draw_ui_jujo_content_category = require('./json/admin/draw_ui_jujo_content_category.json')
const draw_ui_jujo_content_type = require('./json/admin/draw_ui_jujo_content_type.json')
const draw_ui_jujo_content = require('./json/admin/draw_ui_jujo_content.json')
const draw_ui_jujo_element = require('./json/admin/draw_ui_jujo_element.json')
const draw_ui_jujo_layout = require('./json/admin/draw_ui_jujo_layout.json')
const draw_ui_jujo_media = require('./json/admin/draw_ui_jujo_media.json')
const draw_ui_jujo_note = require('./json/admin/draw_ui_jujo_note.json')
const draw_ui_jujo_page = require('./json/admin/draw_ui_jujo_page.json')
const draw_ui_jujo_translation = require('./json/admin/draw_ui_jujo_translation.json')

const my_account_index = require('./json/my_account/index.json')
const my_account_clinical_profile = require('./json/my_account/clinical_profile.json')
const my_account_orders_status = require('./json/my_account/orders_status.json')
const my_account_orders_kit = require('./json/my_account/orders_kit.json')
const my_account_orders_diet = require('./json/my_account/orders_diet.json')
const my_account_orders_training_card = require('./json/my_account/orders_training_card.json')
const my_account_orders_associate_products = require('./json/my_account/orders_associate_products.json')
const my_account_run_test = require('./json/my_account/run_test.json')
const my_account_courier_service_new = require('./json/my_account/courier_service_new.json')
const my_account_courier_service_status = require('./json/my_account/courier_service_status.json')
const my_account_results_process_in_progress = require('./json/my_account/results_process_in_progress.json')
const my_account_results_dna = require('./json/my_account/results_dna.json')
const my_account_results_diets = require('./json/my_account/results_diets.json')
const my_account_results_training_cards = require('./json/my_account/results_training_cards.json')
const my_account_store_customers = require('./json/my_account/store_customers.json')
const my_account_store_reports = require('./json/my_account/store_reports.json')
const my_account_customers = require('./json/my_account/customers.json')
const my_account_stores = require('./json/my_account/stores.json')
const my_account_promoter_reports = require('./json/my_account/promoter_reports.json')
const my_account_promoter_stores = require('./json/my_account/promoter_stores.json')
const my_account_video_accademy = require('./json/my_account/video_accademy.json')
const my_account_diets = require('./json/my_account/diets.json')
const my_account_hintime_match_users = require('./json/my_account/hintime_match_users.json')
const my_account_key_account_swabs = require('./json/my_account/key_account_swabs.json')
const my_account_laboratory_plates = require('./json/my_account/laboratory_plates.json')
const my_account_fast_checkout = require('./json/my_account/fast_checkout.json')
const my_account_documents = require('./json/my_account/documents.json')

const reset_password = require('./json/reset_password.json')
const thank_you = require('./json/front/thank_you.json')

const admin = {
  campaigns,
  dna,
  fees,
  food_supplements,
  index: admin_index,
  landings,
  orders,
  payments,
  plates,
  products,
  profiles: {
    all: admin_profiles,
    customers: admin_profiles_customers,
    stores: admin_profiles_stores,
    promoters: admin_profiles_promoters,
    key_accounts: admin_profiles_key_accounts,
    specialists: admin_profiles_specialists,
    laboratories: admin_profiles_laboratories,
  },
  services,
  settings,
  shippings,
  swabs,
  draw_ui: {
    blocks: draw_ui_jujo_block,
    content_categories: draw_ui_jujo_content_category,
    content_types: draw_ui_jujo_content_type,
    contents: draw_ui_jujo_content,
    elements: draw_ui_jujo_element,
    layouts: draw_ui_jujo_layout,
    media: draw_ui_jujo_media,
    notes: draw_ui_jujo_note,
    pages: draw_ui_jujo_page,
    translations: draw_ui_jujo_translation,
  },
}

const my_account = {
  index: my_account_index,
  clinical_profile: my_account_clinical_profile,
  orders: {
    status: my_account_orders_status,
    kit: my_account_orders_kit,
    diet: my_account_orders_diet,
    training_card: my_account_orders_training_card,
    associate_products: my_account_orders_associate_products,
  },
  run_test: my_account_run_test,
  courier_service: {
    new: my_account_courier_service_new,
    status: my_account_courier_service_status,
  },
  my_results: {
    process_in_progress: my_account_results_process_in_progress,
    dna: my_account_results_dna,
    diets: my_account_results_diets,
    training_cards: my_account_results_training_cards,
  },
  customers: my_account_customers,
  stores: my_account_stores,
  store_reports: my_account_store_reports,
  store_customers: my_account_store_customers,
  promoter_reports: my_account_promoter_reports,
  promoter_stores: my_account_promoter_stores,
  video_accademy: my_account_video_accademy,
  diets: my_account_diets,
  hintime_match_users: my_account_hintime_match_users,
  key_account_swabs: my_account_key_account_swabs,
  laboratory_plates: my_account_laboratory_plates,
  fast_checkout: my_account_fast_checkout,
  documents: my_account_documents,
}

export { admin, my_account, thank_you, reset_password }
