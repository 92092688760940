import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'
import VitaeQuantitySelectorComponent from '../quantity_selector'

const classNames = require('classnames')

export class VitaeAdditionalProductsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderAdditionalProducts = () => {
    const { session } = this.props

    const { session_data } = session
    const { cart, product_data } = session_data
    const { products, additional_services } = cart

    const services_keys = Object.keys(additional_services)

    const html = []

    for (let i = 0; i !== services_keys.length; i += 1) {
      const sID = services_keys[i]
      const service = additional_services[sID]
      const service_def = product_data[sID]
      const service_instance = products[sID]
      const { quantity, max_quantity } = service_instance

      const { final_price } = service
      const { title_translated, attributes, cover_picture } = service_def
      let serviceColor =
        attributes && attributes.filter(item => item.key === 'color')
      serviceColor =
        serviceColor && serviceColor.length > 0 ? serviceColor[0] : serviceColor
      html.push(
        <div key={sID} className={classNames('py-3 border-top')}>
          <div
            className={classNames(
              'd-flex flex-column flex-md-row justify-content-between align-items-center'
            )}
          >
            <div className={classNames('d-flex flex-row align-items-center')}>
              <div
                style={{
                  backgroundImage: `url(${cover_picture})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  width: '80px',
                  height: '80px',
                }}
              />
              <div
                className={classNames('fs-6 fst-italic fw-bold ms-4')}
                style={{ color: serviceColor.value }}
              >
                {title_translated}
              </div>
            </div>
            <div
              className={classNames('d-flex flex-column align-items-center')}
            >
              <VitaeQuantitySelectorComponent
                quantity={quantity}
                max_quantity={max_quantity}
                pID={sID}
              />
              <div
                className={classNames(
                  'fw-bold d-flex justify-content-end pt-1 fs-6'
                )}
              >{`€ ${final_price}`}</div>
            </div>
          </div>
        </div>
      )
    }

    return html
  }

  render() {
    const { environment, specialization, session } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { session_data } = session
    const { cart } = session_data
    const { additional_services } = cart
    return (
      <>
        {Object.keys(additional_services).length > 0 && (
          <div className={classNames('bg-3 p-3 my-3')}>
            <div className={classNames('fw-bold text-center my-2')}>
              {texts[locale].add_to_your_order}
            </div>
            {this.renderAdditionalProducts()}
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeAdditionalProductsComponent)
