import apiCallMap from '../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../src/enums/apiRequestTypesMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../src/services/servicesHelper'
import * as apiService from '../../../../../src/services/apiService'
import { manageResponse } from '../common'

export async function saveCustomerPrivateData(sender) {
  const { state, props } = sender

  const { handleOnComplete } = props
  const { environment, authentication } = props
  const { localDataSource } = state

  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'customer-private-data',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'updateCard',
    pDefValue: 'updateCard',
  })

  const parsedEp = parseEndpoint(requestData)
  const response = await apiService.httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    localDataSource,
    true
  )

  const { status } = response
  if (status === 200 || status === 201) {
    handleOnComplete()
  } else {
    manageResponse(sender, response)
  }
}
