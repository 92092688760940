import React from 'react'
import { connect } from 'react-redux'
import './styles.scss'

import classNames from 'classnames'
import * as actionCreators from '../../../../../src/store/actions'
import { GenericField } from '../../../../../src/components/popup/fields/_parts/genericField'
import { mapStateToProps } from '../../../../../src/utils'

export class OrderFulfillmentField extends GenericField {
  handleExpandCollapse = idx => {
    const { fieldDynamicProps } = this.state
    const updatedDynamicProps = { ...fieldDynamicProps }
    const currentValue =
      updatedDynamicProps && updatedDynamicProps.expanded
        ? updatedDynamicProps.expanded[idx]
        : false

    if (!updatedDynamicProps.expanded) {
      updatedDynamicProps.expanded = []
    }
    updatedDynamicProps.expanded[idx] = !currentValue

    this.setState({ fieldDynamicProps: updatedDynamicProps })
  }

  handleProductChanged = (e, prodIdx) => {
    const { fieldValue } = this.state
    const updatedField = { ...fieldValue }

    updatedField[prodIdx].product_id = e.target.value

    this.saveOnRedux(updatedField)
  }

  handleQuantityChanged = (e, prodIdx, itemIdx) => {
    const { fieldValue } = this.state
    const updatedField = { ...fieldValue }

    updatedField[prodIdx].warehouse_items[itemIdx].quantity = e.target.value

    this.saveOnRedux(updatedField)
  }

  renderField = () => {
    const { fieldValue, fieldDynamicProps } = this.state

    const html = []
    for (let i = 0; i !== fieldValue.length; i += 1) {
      const prod = fieldValue[i]
      const { name, warehouse_items } = prod

      const isExpanded = !!(
        fieldDynamicProps &&
        fieldDynamicProps.expanded &&
        fieldDynamicProps.expanded[i]
      )

      html.push(
        <div key={prod.id} className={classNames('my-1')}>
          <div
            className={classNames(
              'p-2 bg-1 rounded-5 border-0 expand-collapse-accordion-icon',
              isExpanded ? '' : 'collapsed'
            )}
            style={{ color: '#fff' }}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleExpandCollapse(i)
            }}
            onKeyPress={() => {
              this.handleExpandCollapse(i)
            }}
          >
            {name}
          </div>
          <div
            key={`${prod.id}_items`}
            className={classNames('container-fluid')}
            style={{
              maxHeight: isExpanded ? '500px' : '0px',
              overflow: 'hidden',
              transition: 'max-height 0.5s ease',
            }}
          >
            {warehouse_items.map((item, idx) => {
              const { quantity, category, values } = item
              return (
                <div
                  key={`${prod.id}_${category}`}
                  className={classNames('row py-1')}
                >
                  <div
                    className={classNames(
                      'col-12 col-md m-1 p-1 rounded-4 border bg-disabled'
                    )}
                  >
                    {category}
                  </div>
                  <div
                    className={classNames(
                      'col-12 col-md m-1 p-1 rounded-4 border'
                    )}
                  >
                    <select
                      defaultValue={item.id}
                      onChange={e => {
                        this.handleProductChanged(e, i)
                      }}
                    >
                      {values.map(cat => (
                        <option key={cat.id} value={cat.id}>
                          {cat.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    className={classNames(
                      'col-12 col-md-2 m-1 p-1 rounded-4 border'
                    )}
                  >
                    <input
                      type="number"
                      defaultValue={quantity}
                      onBlur={e => {
                        this.handleQuantityChanged(e, i, idx)
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    }
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(OrderFulfillmentField)
