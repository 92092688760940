/* eslint-disable react/no-danger */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import * as apiService from '../../services/apiService'
import JujoLoading from '../loading'
import apiCallMap from '../../enums/apiCallMap'
import { baseRequestObject, parseEndpoint } from '../../services/servicesHelper'
import apiRequestTypesMap from '../../enums/apiRequestTypesMap'
import {
  cloneObj,
  lockBodyScroll,
  mapStateToProps,
  unlockBodyScroll,
} from '../../utils'

const classNames = require('classnames')

export class InfoBoxComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      html: '',
      data: {},
    }
  }

  componentWillUnmount() {
    unlockBodyScroll()
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { infoBox } = this.props
    const { visible, subject_id } = infoBox
    if (visible) {
      lockBodyScroll()

      const endPoint = this.composeRequest()
      const result = await apiService.httpPost(
        `${process.env.apiUrl}${endPoint}`,
        { subject_id },
        true
      )

      if (result) {
        const { status, data } = result
        if (status === 200) {
          this.setState({
            initialized: true,
            html: data.html || '',
            data: data.data || {},
          })
        }
      }
    }
  }

  renderDynamicComponent = () => {
    const { data } = this.state
    const { infoBox } = this.props
    const { customizations } = infoBox

    const { specialized, render_component } = customizations

    const html = []
    let DynamicComponent = null

    if (specialized) {
      DynamicComponent =
        require(`../../../vitae_specializations/src/${process.env.client}/components/${render_component}/index`).default
    } else {
      DynamicComponent = require(`../${render_component}`).default
    }

    html.push(<DynamicComponent key="dynamic_component" data={data} />)
    return html
  }

  composeRequest = () => {
    const { environment, authentication, infoBox } = this.props
    const { relatedEntity, actName } = infoBox

    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      relatedEntity,
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: actName,
      pDefValue: actName,
    })

    const parsedEp = parseEndpoint(requestData)
    return parsedEp
  }

  renderButtons = () => {
    const { specialization, environment, resetInfoBox } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const buttons = [
      {
        text: texts[locale].close,
        handleFunction: resetInfoBox,
      },
    ]

    const html = []

    for (let i = 0; i !== buttons.length; i += 1) {
      const btn = buttons[i]
      const { text, handleFunction, stylePrimary } = btn

      html.push(
        <div
          key={`btn_${i}`}
          className={classNames(
            'text-center p-1 fs-7 rounded-50 mx-2 px-2 py-1',
            stylePrimary
              ? 'bg-1 bg1-gradient-270deg fc-white'
              : 'bg-white bshadow-c1 fc-1'
          )}
          style={{ minWidth: '100px' }}
          role="button"
          tabIndex={0}
          onClick={async () => {
            await handleFunction()
          }}
          onKeyPress={async () => {
            await handleFunction()
          }}
        >
          {text}
        </div>
      )
    }

    return html
  }

  handleClose = () => {
    const { resetInfoBox } = this.props
    resetInfoBox()
  }

  getCustomizations = () => {
    const { infoBox } = this.props
    const { customizations } = infoBox

    const parsed_customizations = cloneObj(customizations || {})
    const { show_close_button, working_area_classes, width, height } =
      parsed_customizations

    parsed_customizations.show_close_button =
      show_close_button === undefined ? true : show_close_button
    parsed_customizations.working_area_classes = working_area_classes || ''
    parsed_customizations.width = width || ''
    parsed_customizations.height = height || ''

    return parsed_customizations
  }

  render() {
    const { initialized, html, data } = this.state
    const { show_close_button, working_area_classes, width, height } =
      this.getCustomizations()

    return (
      <>
        {initialized === false && <JujoLoading />}
        {initialized === true && (
          <>
            <div
              className={classNames(
                'position-fixed top-0 start-0 bg-black-opacity-2 zindex-1'
              )}
              style={{
                height: '100vh',
                width: '100vw',
                backgroundBlendMode: 'multiply',
                mixBlendMode: 'multiply',
              }}
            />

            <div
              className={classNames(
                'bg-3 position-fixed top-50 start-50 translate-middle zindex-2 rounded-30 shadow'
              )}
              style={{
                width: width && width.length > 0 ? width : 'min(99%, 600px)',
                height:
                  height && height.length > 0 ? height : 'min(99%, 700px)',
              }}
            >
              <div className={classNames('d-flex flex-column p-4')}>
                <div
                  className={classNames(
                    'close-icon theme-svg-4 d-flex align-self-end'
                  )}
                  style={{
                    height: '14px',
                    width: '14px',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: '2',
                  }}
                  role="button"
                  tabIndex={0}
                  label="close"
                  onClick={() => {
                    this.handleClose()
                  }}
                  onKeyPress={() => {
                    this.handleClose()
                  }}
                />
                <div
                  className={classNames(
                    working_area_classes && working_area_classes.length > 0
                      ? working_area_classes
                      : 'd-flex flex-column align-items-center justify-content-between w-100 h-100 px-4 py-5 position-absolute top-50 start-50 translate-middle'
                  )}
                >
                  {html.length > 0 && (
                    <div
                      className={classNames('w-100 overflow-auto')}
                      dangerouslySetInnerHTML={{
                        __html: html,
                      }}
                    />
                  )}
                  {Object.keys(data).length > 0 &&
                    this.renderDynamicComponent()}
                  {show_close_button && (
                    <div
                      className={classNames(
                        'd-flex justify-content-center mt-3'
                      )}
                    >
                      {this.renderButtons()}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(InfoBoxComponent)
