import React from 'react'
import { connect } from 'react-redux'

import './styles.scss'

import classNames from 'classnames'
import * as actionCreators from '../../../../../src/store/actions'

import VitaeMyAccountHead from '../common/header'
import VitaeFooter from '../common/footer'
import { JujoLeraLayout } from '../../../../../src/components/page_layout/layouts/lera'
import PageComponent from '../../../../../src/components/page_layout/layouts/_parts/page'
import { mapStateToProps } from '../../../../../src/utils'
import MandatoryProfilePopupFieldsComponent from '../../components/my_profile_group/mandatory_profile_popup_fields'

export class VitaeMyAccountLayout extends JujoLeraLayout {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderHead = () => <VitaeMyAccountHead />

  renderPageBody = () => {
    const html = []
    html.push(
      <div
        key="body"
        className={classNames('p-3 p-md-5 me-md-5')}
        style={{ marginTop: '56px', maxWidth: '800px' }}
      >
        <MandatoryProfilePopupFieldsComponent />
        <PageComponent />
      </div>
    )
    return html
  }

  renderFooter = () => <VitaeFooter />
}

export default connect(mapStateToProps, actionCreators)(VitaeMyAccountLayout)
