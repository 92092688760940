/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { injectComponent, mapStateToProps } from '../../../../../../src/utils'

export class MandatoryProfilePopupFieldsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.checkMandatoryFields()
  }

  componentDidUpdate = async prevProps => {
    const { session: prevSession } = prevProps
    const { session_data: prevSession_data } = prevSession
    const { mandatory_profile_fields: prev_mandatory_profile_fields } =
      prevSession_data
    const { session } = this.props
    const { session_data } = session
    const { mandatory_profile_fields } = session_data

    const { incomplete: prevIncomplete } = prev_mandatory_profile_fields
    const { incomplete } = mandatory_profile_fields
    if (incomplete !== prevIncomplete) {
      this.checkMandatoryFields()
    }
  }

  checkMandatoryFields = () => {
    const { session } = this.props
    const { session_data } = session
    const { mandatory_profile_fields } = session_data

    const { incomplete } = mandatory_profile_fields

    if (incomplete) {
      const comp_id = `mandatory_fields_popup_componente_id`
      const component_definition = {
        specialized: true,
        path: 'my_profile_group/mandatory_profile_popup_fields/mandatory_popup_body',
        data: {
          sender: this,
        },
      }
      injectComponent(this, comp_id, component_definition)
    }
  }

  render() {
    return <></>
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(MandatoryProfilePopupFieldsComponent)
