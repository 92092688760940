/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'
import * as apiService from '../../../../../../../src/services/apiService'

import JujoLoading from '../../../../../../../src/components/loading'
import { cloneObj, mapStateToProps } from '../../../../../../../src/utils'
import JujoButtonComponent from '../../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class MyResultsTabMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      menuItemSelected: 0,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    await this.updateData()
  }

  componentDidUpdate = async () => {
    await this.updateData()
  }

  updateData = async () => {
    const { genericData, updateGenericData, authentication } = this.props
    const { myResults } = genericData
    const { swab } = myResults
    const { menu } = this.state
    const { user } = authentication

    if (!menu && myResults) {
      const { panel } = swab || false
      if (panel) {
        const url = `public/action/post/result-category-tab/list?locale=${user.lang}`
        const response = await apiService.httpPost(
          `${process.env.apiUrl}${url}`,
          { panel }
        )
        if (response.status === 200) {
          const returned_category_tabs = response.data.data || false
          const updatedGenericData = { ...genericData }
          updatedGenericData.myResults.resultCategoryTabs =
            returned_category_tabs

          await updateGenericData(updatedGenericData)
          this.setState({
            menu: returned_category_tabs,
          })
        }
      }
    }
  }

  handleMyButtonClick = async menuItemSelected => {
    const { genericData, updateGenericData } = this.props
    this.setState({ menuItemSelected })

    const genericDataCopy = cloneObj(genericData)
    genericDataCopy.myResults.menuItemSelected = menuItemSelected
    await updateGenericData(genericDataCopy)
  }

  render() {
    const { menu, menuItemSelected } = this.state
    const { genericData } = this.props
    const { myResults } = genericData
    const { swab } = myResults
    const { product_id } = swab || false
    const results_font_color = product_id === 4 ? 'fc-11' : 'fc-1'
    const results_border_color =
      product_id === 4 ? 'border-color-11' : 'border-color-1'
    const results_background_color = product_id === 4 ? 'bg-fitness' : 'bg-1'

    return (
      <div className={classNames('py-4')}>
        <div
          className={classNames(
            'd-flex align_items-center justify-content-between flex-row flex-wrap'
          )}
        >
          {!menu && <JujoLoading />}
          {menu &&
            menu.map((menuItem, menuItemIndex) => (
              <div key={menuItem.id} className={classNames('pb-2')}>
                <JujoButtonComponent
                  bstyle={menuItemSelected === menuItemIndex ? 5 : 4}
                  bwidth="130px"
                  bheight="34px"
                  blabel={menuItem.title_translated}
                  bicon=""
                  bclasses={`${
                    menuItemSelected === menuItemIndex
                      ? results_background_color
                      : results_font_color
                  } ${results_border_color} `}
                  bdata={menuItemIndex}
                  handleClick={this.handleMyButtonClick}
                />
              </div>
            ))}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MyResultsTabMenu)
