import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'

const classNames = require('classnames')

export class VitaePersonalAreaBtnComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handlePersonalAreaClicked = () => {
    window.location.href = '/my-account'
  }

  render() {
    const { environment, specialization } = this.props
    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations

    return (
      <div
        className={classNames(
          'd-flex flex-column justify-content-center align-items-center bg-3 h-auto h-md-80px'
        )}
        role="button"
        tabIndex={0}
        label="lang-swithcer"
        onClick={() => {
          this.handlePersonalAreaClicked()
        }}
        onKeyPress={() => {
          this.handlePersonalAreaClicked()
        }}
      >
        <div
          className={classNames(
            'personal-account-icon theme-svg background-image'
          )}
          style={{ width: '40px', height: '40px' }}
        />
        <div className={classNames('fs-8 fc-1 text-center')}>
          {texts[locale].personal_area}
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaePersonalAreaBtnComponent)
