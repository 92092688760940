/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'

import * as apiService from '../../../../../../src/services/apiService'
import VitaePaymentMethodSelectorComponent from '../../common_group/payment_method_selector'
import VitaeAddressMethodSelectorComponent from '../../common_group/address_selector'
import paymentsTypesMap from '../../checkout_group/enums/payments_types_map'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import JujoLoading from '../../../../../../src/components/loading'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import VitaeFastCartContent from '../../common_group/fast_cart_content'
import { reloadSession } from '../../../../../../src/services/sessionHelper'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'

import { mapStateToProps, injectComponent } from '../../../../../../src/utils'
import JujoButtonComponent from '../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class FastCheckout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      waiting_paypal: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  checkCartAvailability = async () => {
    const { session } = this.props
    const { session_id } = session
    const response = await apiService.httpPost(
      `${process.env.apiUrl}public/action/post/session/check-cart-availability`,
      { session_id }
    )
    const { status } = response || { status: 400 }
    if (status === 200 || status === 201) {
      const { data } = response
      const { cart_modified } = data
      if (cart_modified.length > 0) {
        this.showCartModified(cart_modified)
        await reloadSession(this)
        return false
      }
    }
    return true
  }

  showCartModified = cart_modified => {
    const comp_id = `cart_modified_infobox`
    const component_definition = {
      specialized: true,
      path: 'fast_checkout_group/cart_modified_infobox',
      data: {
        sender: this,
        cart_modified,
      },
    }
    injectComponent(this, comp_id, component_definition)
  }

  componentDidMount = async () => {
    await this.checkCartAvailability()
  }

  handleBackButtonClicked = () => {
    const { session } = this.props
    const { session_data } = session
    const { cart } = session_data
    let { back_to_url } = cart
    back_to_url = back_to_url === undefined ? '/my-account' : back_to_url
    window.location.href = back_to_url
  }

  handlePayButtonClicked = async () => {
    const { session, environment, authentication } = this.props
    const { session_data, session_id } = session
    const { cart } = session_data
    const { selected_payment_method } = cart

    const can_continue = await this.checkCartAvailability()
    if (can_continue === false) {
      return
    }

    if (selected_payment_method === paymentsTypesMap.paypal) {
      await this.setState({ waiting_paypal: true })
      const requestData = baseRequestObject(
        apiCallMap.publicServerAction,
        paymentsTypesMap.paypal,
        apiRequestTypesMap.post,
        environment,
        authentication
      )

      requestData.placeholderMapping.push({
        pSource: 'static',
        pKey: '{action}',
        pValue: 'generate-payment-link',
        pDefValue: 'generate-payment-link',
      })

      const parsedEp = parseEndpoint(requestData)
      const response = await apiService.httpPost(
        `${process.env.apiUrl}${parsedEp}`,
        { session_id }
      )

      const { status } = response || { status: 400 }
      if (status === 200 || status === 201) {
        const { data } = response
        const { url } = data
        if (typeof window !== 'undefined') {
          window.location.href = url
        }
      }
    }
    if (selected_payment_method === paymentsTypesMap.bank_transfer) {
      if (typeof window !== 'undefined') {
        window.location.href = `/thank-you/?sessionId=${session_id}`
      }
    }
  }

  render() {
    const { waiting_paypal } = this.state
    const { session } = this.props
    const { session_data } = session
    const { shipping_data, fast_checkout } = session_data
    const { shipping_information } = shipping_data || {}
    const { address_required, purchase_enabled } = fast_checkout

    return (
      <div
        className={classNames(
          'container d-flex flex-column align-items-center mx-auto py-5'
        )}
      >
        <div className={classNames('w-100 w-md-60 d-flex flex-column')}>
          <div
            className={classNames(
              'p-3 w-100 d-flex align-items-center justify-content-center'
            )}
          >
            <JujoButtonComponent
              bstyle={3}
              bwidth="190px"
              blabel="Indietro"
              bicon=""
              bFontClass="fw-bold"
              bdata=""
              handleClick={this.handleBackButtonClicked}
            />
          </div>
          <VitaeFastCartContent customClass="mb-1" />
          {address_required && <VitaeAddressMethodSelectorComponent />}
          <VitaePaymentMethodSelectorComponent />
          <div
            className={classNames(
              'p-3 w-100 d-flex align-items-center justify-content-center'
            )}
          >
            {!waiting_paypal && (
              <div style={{ opacity: !purchase_enabled ? '0.3' : '1' }}>
                <JujoButtonComponent
                  bstyle={3}
                  bwidth="190px"
                  blabel="Acquista"
                  bicon=""
                  bFontClass="fw-bold"
                  bdata=""
                  handleClick={() => {
                    if (purchase_enabled) {
                      this.handlePayButtonClicked()
                    }
                  }}
                />
              </div>
            )}
            {waiting_paypal && <JujoLoading />}
          </div>
          {shipping_information !== '' && (
            <div
              className={classNames(
                'p-2 w-100 d-flex flex-row align-items-center'
              )}
            >
              <div
                className={classNames('truck-icon theme-svg')}
                style={{
                  width: '35px',
                  height: '35px',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  right: '10px',
                  top: '4px',
                }}
              />
              <div className={classNames('ms-2 fst-italic fc-1')}>
                {shipping_information}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(FastCheckout)
