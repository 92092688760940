/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../src/store/actions'
import JujoLoading from '../../../../../src/components/loading'
import { cloneObj, mapStateToProps } from '../../../../../src/utils'

import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../src/services/servicesHelper'
import apiCallMap from '../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../src/enums/apiRequestTypesMap'
import * as apiService from '../../../../../src/services/apiService'
import { showMessageBox } from '../common_group/message_box'
import { JujoButtonComponent } from '../../../../../src/components/jujo_button'

const classNames = require('classnames')

const empty_fields = {
  swab_id: {
    type: 'text',
    alias: 'swab_code',
    value: '',
  },
  gender: {
    type: 'sex',
    value: '',
  },
  email: {
    type: 'email',
    value: '',
  },
  email_confirmation: {
    type: 'email',
    value: '',
  },
  first_name: {
    type: 'text',
    value: '',
  },
  last_name: {
    type: 'text',
    value: '',
  },
  biopy: {
    type: 'biopy',
    value: '',
  },
  training_history: {
    type: 'training_history',
    value: '',
  },
  training_place: {
    type: 'training_place',
    value: '',
  },
  age: {
    type: 'text',
    value: '',
  },
}

export class HintimeMatchUsers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: true,
      fields: cloneObj(empty_fields),
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  renderFields = fields => {
    const { specialization, environment } = this.props

    const keys = Object.keys(fields)

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const html = []
    for (let i = 0; i !== keys.length; i += 1) {
      const k = keys[i]
      const f = fields[k]
      const { type, alias, value } = f
      if (k === 'biopy') {
        html.push(
          <div
            key={`${k}divider`}
            className={classNames('mt-4 fs-7 fst-italic')}
          >
            <div className={classNames('fc-1')}>
              {texts[locale].fill_next_fields_only_on_sport}
            </div>
            <hr className={classNames('mt-0')} />
          </div>
        )
      }
      html.push(
        <div key={k} className={classNames('my-3 col-md-6')}>
          <div className={classNames('ffamily-secondary fs-9 fc-gray')}>
            {alias ? texts[locale][alias] || alias : texts[locale][k] || k}
          </div>
          <div
            className={classNames('p-1 border bg-white border-color-4 rounded')}
          >
            {type === 'sex' && (
              <select
                onChange={e => this.handleValueChanged(k, e.target.value)}
              >
                <option value=""> </option>
                <option value="m">{texts[locale].male}</option>
                <option value="f">{texts[locale].female}</option>
              </select>
            )}
            {type === 'biopy' && (
              <select
                onChange={e => this.handleValueChanged(k, e.target.value)}
              >
                <option value=""> </option>
                <option value="gynoid">{texts[locale].gynoid}</option>
                <option value="android">{texts[locale].android}</option>
              </select>
            )}
            {type === 'training_history' && (
              <select
                onChange={e => this.handleValueChanged(k, e.target.value)}
              >
                <option value=""> </option>
                <option value="i_dont_train">
                  {texts[locale].i_dont_train}
                </option>
                <option value="i_train_less_than_a_year">
                  {texts[locale].i_train_less_than_a_year}
                </option>
                <option value="i_train_more_than_a_year">
                  {texts[locale].i_train_more_than_a_year}
                </option>
              </select>
            )}
            {type === 'training_place' && (
              <select
                onChange={e => this.handleValueChanged(k, e.target.value)}
              >
                <option value=""> </option>
                <option value="home">{texts[locale].home}</option>
                <option value="gym">{texts[locale].gym}</option>
              </select>
            )}
            {(type === 'text' || type === 'email' || type === 'age') && (
              <input
                type={type}
                defaultValue={value}
                onChange={e => this.handleValueChanged(k, e.target.value)}
              />
            )}
          </div>
        </div>
      )
    }

    return html
  }

  handleValueChanged = (k, v) => {
    const { fields } = this.state
    const cloned_fields = cloneObj(fields)

    cloned_fields[k].value = v
    this.setState({ fields: cloned_fields })
  }

  matchClicked = async () => {
    const { fields } = this.state
    const { environment, authentication } = this.props
    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'swab',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'hintime-match-swab',
      pDefValue: 'hintime-match-swab',
    })

    const parsedEp = parseEndpoint(requestData)
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      {
        swab_id: fields.swab_id.value,
        first_name: fields.first_name.value,
        last_name: fields.last_name.value,
        email: fields.email.value,
        email_confirmation: fields.email_confirmation.value,
        gender: fields.gender.value,
        biopy: fields.biopy.value,
        training_history: fields.training_history.value,
        training_place: fields.training_place.value,
        age: fields.age.value,
      }
    )

    const { status } = response
    if (status === 200 || status === 201) {
      await this.setState({ loaded: false })
      await this.setState({
        loaded: true,
        fields: cloneObj(empty_fields),
      })
      showMessageBox(this, response, 'success')
    } else {
      showMessageBox(this, response, 'error')
    }
  }

  render() {
    const { specialization, environment } = this.props
    const { fields, loaded } = this.state
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    return (
      <>
        {!loaded && <JujoLoading />}
        {loaded && (
          <>
            <div className={classNames('row')}>{this.renderFields(fields)}</div>
            <div className={classNames('d-flex justify-content-center my-4')}>
              <JujoButtonComponent
                bstyle={3}
                bwidth="200px"
                blabel={texts[locale].match_user}
                handleClick={async () => {
                  await this.matchClicked()
                }}
              />
            </div>
          </>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(HintimeMatchUsers)
