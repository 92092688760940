import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import JujoLoading from '../../../../../../src/components/loading'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeWeeklyWorkoutsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({ initialized: true })
  }

  handleChange = (idx, subFieldName, value) => {
    const { dataSource, field, handleValueChanged } = this.props
    const { fieldDef } = field
    const { name } = fieldDef

    const currField = dataSource[name]
    const updatedField = [...currField]

    updatedField[idx][subFieldName] = value

    handleValueChanged(name, updatedField)
  }

  handleNewActivity = () => {
    const { dataSource, field, handleValueChanged } = this.props

    const { fieldDef } = field
    const { name, subFields } = fieldDef

    const currField = dataSource[name]
    const updatedField = [...currField]

    const newFieldObj = {}

    for (let i = 0; i !== subFields.length; i += 1) {
      const subF = subFields[i]
      newFieldObj[subF.name] = subF.defValue || ''
    }

    updatedField.push(newFieldObj)
    handleValueChanged(name, updatedField)
  }

  handleDeleteActivity = async idx => {
    this.setState({ initialized: false })

    const { dataSource, field, handleValueChanged } = this.props
    const { fieldDef } = field
    const { name } = fieldDef

    const currField = dataSource[name]
    const updatedField = [...currField]
    updatedField.splice(idx, 1)
    await handleValueChanged(name, updatedField)

    this.setState({ initialized: true })
  }

  drawRepeatedElement = (element, idx) => {
    const { field, specialization, environment } = this.props
    const { fieldDef, required } = field
    const { subFields } = fieldDef

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const html = []

    for (let i = 0; i !== subFields.length; i += 1) {
      const subF = subFields[i]
      const { name, alias, description } = subF

      let sfTitle = alias
        ? texts[locale][alias] || alias
        : texts[locale][name] || name
      sfTitle = required ? `* ${sfTitle}` : sfTitle
      const sfValue = element[name] || ''

      html.push(
        <div
          key={`${name}_${idx}`}
          className={classNames(
            'col-12 col-md-6 d-flex flex-column justify-content-between'
          )}
        >
          <div className={classNames('d-flex align-items-center')}>
            {i === 0 && (
              <div
                className={classNames(
                  'bg-white rounded-percentage-100 border d-flex justify-content-center align-items-center fw-bold fs-8 me-2'
                )}
                style={{ width: '25px', height: '25px' }}
              >
                {idx + 1}
              </div>
            )}
            <div className={classNames('fw-bold fs-7')}>{sfTitle}</div>
          </div>
          {description && (
            <div className={classNames('fs-8 fst-italic')}>
              {texts[locale][description] || description}
            </div>
          )}
          <input
            className={classNames(
              'mt-2 bg-white border border-color-4 rounded'
            )}
            type="text"
            defaultValue={sfValue}
            onChange={e => {
              this.handleChange(idx, name, e.target.value)
            }}
          />
        </div>
      )
    }

    return html
  }

  drawRepeater = () => {
    const { dataSource, field, specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { fieldDef } = field
    const { name } = fieldDef

    const fieldToRender = dataSource[name] || []

    const html = []

    html.push(
      <div key={name} className={classNames('px-4 py-3 mt-4')}>
        {fieldToRender.map((element, idx) => (
          <div
            key={`subField_${idx}`}
            className={classNames('row g-4', idx > 0 ? 'mt-3' : '')}
          >
            {this.drawRepeatedElement(element, idx)}
            <div className={classNames('d-flex justify-content-center ')}>
              <div
                className={classNames(
                  'd-inline-flex bg-white rounded-50 border border-color-black fw-bold fs-8 align-items-center px-2 py-1'
                )}
                role="button"
                tabIndex={0}
                onClick={async () => {
                  await this.handleDeleteActivity(idx)
                }}
                onKeyPress={async () => {
                  await this.handleDeleteActivity(idx)
                }}
              >
                <div
                  className={classNames('trash-icon-2')}
                  style={{
                    height: '18px',
                    width: '18px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                  }}
                />
                <div className={classNames('ms-1')}>
                  {texts[locale].delete_physical_activity}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div
          className={classNames(
            'd-flex justify-content-center align-items-center mt-4 mb-2'
          )}
        >
          <div
            className={classNames(
              'd-inline-flex bg-white rounded-50 border border-color-black fw-bold fs-8 align-items-center pe-3'
            )}
            role="button"
            tabIndex={0}
            onClick={() => this.handleNewActivity()}
            onKeyPress={() => this.handleNewActivity()}
          >
            <div
              className={classNames(
                'bg-white rounded-percentage-100 d-flex justify-content-center align-items-center fw-bold me-2'
              )}
              style={{
                width: '25px',
                height: '25px',
                boxShadow: '0px 0px 0 1px #000',
              }}
            >
              +
            </div>
            <div>{texts[locale].add_another_physical_activity}</div>
          </div>
        </div>
      </div>
    )

    return html
  }

  render() {
    const { initialized } = this.state
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <>
        {!initialized && <JujoLoading />}
        {initialized && (
          <div className={classNames('my-2')}>
            <div className={classNames('d-flex align-items-end mb-2')}>
              <div
                className={classNames('dumbbells-icon')}
                style={{
                  width: '36px',
                  height: '36px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                }}
              />
              <div className={classNames('ms-2')}>
                <div
                  className={classNames('fs-7 fw-bold')}
                  style={{ lineHeight: '14px' }}
                >
                  {texts[locale].weekly_workouts_instruction_1}
                </div>
                <div className={classNames('fs-8 fc-4 fst-italic')}>
                  {texts[locale].weekly_workouts_instruction_2}
                </div>
              </div>
            </div>
            <div className={classNames('bg-3')}>{this.drawRepeater()}</div>
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeWeeklyWorkoutsComponent)
