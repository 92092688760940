/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../src/store/actions'
import { mapStateToProps, cloneObj } from '../../../../../src/utils'

import FieldShell from '../common_group/field_shell'
import ECSelectFieldComponent from '../../../../../src/components/common/fields/selectField'
import ECDateFieldComponent from '../../../../../src/components/common/fields/dateField'
import { saveSessionToServer } from '../../../../../src/services/sessionHelper'
import JujoLoading from '../../../../../src/components/loading'

import { showMessageBox } from '../common_group/message_box'

import * as apiService from '../../../../../src/services/apiService'

import JujoButtonComponent from '../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaeCampaigns extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  handleValueChanged = async (name, value) => {
    const { session, updateSession } = this.props
    const session_updated = cloneObj(session)
    const { session_data, session_id } = session_updated
    const { campaigns } = session_data
    const { data } = campaigns || {}

    const data_updated = cloneObj(data)
    data_updated[name] = value

    session_data.campaigns.data = data_updated

    const returned_data = await saveSessionToServer(session_id, session_data)
    session_updated.session_data = returned_data
    await updateSession(session_updated)
  }

  handleReceivedKitChanged = async (data, value) => {
    const { session, updateSession } = this.props
    const session_updated = cloneObj(session)
    const { session_data, session_id } = session_updated
    const { received_kit } = data
    const received_kit_updated = received_kit === undefined ? [] : received_kit
    if (received_kit_updated.includes(value)) {
      received_kit_updated.splice(received_kit_updated.indexOf(value), 1)
    } else {
      received_kit_updated.push(value)
    }

    const data_updated = cloneObj(data)
    data_updated.received_kit = received_kit_updated
    session_data.campaigns.data = data_updated

    const returned_data = await saveSessionToServer(session_id, session_data)
    session_updated.session_data = returned_data
    await updateSession(session_updated)
  }

  drawActionCreatedAtWindow = (fields, data) => {
    const { createdAtWindowDateInField, createdAtWindowDateOutField } = fields
    const { created_at_window_date_in, created_at_window_date_out } = data
    const html = []
    html.push(
      <div
        key="drawActionCreatedAtWindow"
        className={classNames('d-flex flex-column')}
      >
        <div className={classNames('d-flex align-items-center fc-1 fs-5 mb-3')}>
          Finestra data registrazione
        </div>
        <div
          className={classNames(
            'd-flex align-items-center justify-content-between'
          )}
        >
          <div
            key="created_at_window_date_in"
            className={classNames('w-50 pe-2')}
          >
            <FieldShell fieldName="created_at_window_date_in" withBorder>
              <ECDateFieldComponent
                field={createdAtWindowDateInField}
                handleDateCompiled={this.handleValueChanged}
                initialValue={created_at_window_date_in}
              />
            </FieldShell>
          </div>
          <div
            key="created_at_window_date_out"
            className={classNames('w-50 ps-2')}
          >
            <FieldShell fieldName="created_at_window_date_out" withBorder>
              <ECDateFieldComponent
                field={createdAtWindowDateOutField}
                handleDateCompiled={this.handleValueChanged}
                initialValue={created_at_window_date_out}
              />
            </FieldShell>
          </div>
        </div>
      </div>
    )
    return html
  }

  drawActionBought = (fields, data) => {
    const { boughtField } = fields
    const { bought } = data
    const html = []
    html.push(
      <div key="drawActionBought" className={classNames('d-flex flex-column')}>
        <div className={classNames('d-flex align-items-center fc-1 fs-5 mb-3')}>
          Hanno acuistato kit
        </div>
        <div className={classNames('w-100')}>
          <FieldShell fieldName="bought" withBorder>
            <ECSelectFieldComponent
              field={boughtField}
              handleValueChanged={this.handleValueChanged}
              initialValue={bought}
            />
          </FieldShell>
        </div>
      </div>
    )
    return html
  }

  drawActionSex = (fields, data) => {
    const { sexField } = fields
    const { sex } = data
    const html = []
    html.push(
      <div key="drawActionSex" className={classNames('d-flex flex-column')}>
        <div className={classNames('d-flex align-items-center fc-1 fs-5 mb-3')}>
          Appartengono al sesso
        </div>
        <div className={classNames('w-100')}>
          <FieldShell fieldName="sex" withBorder>
            <ECSelectFieldComponent
              field={sexField}
              handleValueChanged={this.handleValueChanged}
              initialValue={sex}
            />
          </FieldShell>
        </div>
      </div>
    )
    return html
  }

  drawActionBirthMonth = (fields, data) => {
    const { monthField } = fields
    const { birth_month } = data
    const html = []
    html.push(
      <div key="drawActionSex" className={classNames('d-flex flex-column')}>
        <div className={classNames('d-flex align-items-center fc-1 fs-5 mb-3')}>
          Mese di nascita
        </div>
        <div className={classNames('w-100')}>
          <FieldShell fieldName="birth_month" withBorder>
            <ECSelectFieldComponent
              field={monthField}
              handleValueChanged={this.handleValueChanged}
              initialValue={birth_month}
            />
          </FieldShell>
        </div>
      </div>
    )
    return html
  }

  drawActionReceivedTestBefore = (fields, data) => {
    const { receivedTestBeforeField } = fields
    const { received_test_before } = data
    const html = []
    html.push(
      <div
        key="drawActionCreatedAtWindow"
        className={classNames('d-flex flex-column')}
      >
        <div className={classNames('d-flex align-items-center fc-1 fs-5 mb-3')}>
          Ricevuto il test prima del
        </div>
        <div
          className={classNames(
            'd-flex align-items-center justify-content-between'
          )}
        >
          <div className={classNames('w-50 pe-2')}>
            <FieldShell fieldName="received_test_before" withBorder>
              <ECDateFieldComponent
                field={receivedTestBeforeField}
                handleDateCompiled={this.handleValueChanged}
                initialValue={received_test_before}
              />
            </FieldShell>
          </div>
        </div>
      </div>
    )
    return html
  }

  drawActionReceivedKit = (fields, data) => {
    const { receivedKitField } = fields
    const { options } = receivedKitField

    const html = []
    html.push(
      <div key="drawActionSex" className={classNames('d-flex flex-column')}>
        <div className={classNames('d-flex align-items-center fc-1 fs-5 mb-3')}>
          Hanno ricevuto questi Kit
        </div>
        <div className={classNames('w-100 row mt-2')}>
          {options &&
            options.map(item => {
              const { label, value } = item
              const { received_kit } = data || {}
              const received_kit_updated =
                received_kit === undefined ? [] : received_kit
              const buttonStyle = received_kit_updated.includes(value) ? 3 : 2
              return (
                <div key={value} className={classNames('col-6 col-md-3')}>
                  <div className={classNames('w-100')}>
                    <FieldShell fieldName="received_kit">
                      <JujoButtonComponent
                        bstyle={buttonStyle}
                        bwidth="110px"
                        blabel={label}
                        handleClick={() =>
                          this.handleReceivedKitChanged(data, value)
                        }
                      />
                    </FieldShell>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    )
    return html
  }

  drawActionStoreType = (fields, data) => {
    const { storeTypeField } = fields
    const { store_type } = data
    const html = []
    html.push(
      <div key="drawActionSex" className={classNames('d-flex flex-column')}>
        <div className={classNames('d-flex align-items-center fc-1 fs-5 mb-3')}>
          Tipologia distributore
        </div>
        <div className={classNames('w-100')}>
          <FieldShell fieldName="store_type" withBorder>
            <ECSelectFieldComponent
              field={storeTypeField}
              handleValueChanged={this.handleValueChanged}
              initialValue={store_type}
            />
          </FieldShell>
        </div>
      </div>
    )
    return html
  }

  drawActionPlate = (fields, data) => {
    const { plateField } = fields
    const { plate } = data
    const html = []
    html.push(
      <div key="drawActionSex" className={classNames('d-flex flex-column')}>
        <div className={classNames('d-flex align-items-center fc-1 fs-5 mb-3')}>
          Ricevuto i risultati in piastra
        </div>
        <div className={classNames('w-100')}>
          <FieldShell fieldName="plate" withBorder>
            <ECSelectFieldComponent
              field={plateField}
              handleValueChanged={this.handleValueChanged}
              initialValue={parseInt(plate, 10)}
            />
          </FieldShell>
        </div>
      </div>
    )
    return html
  }

  drawAction = (fields, data, action) => {
    const html = []
    const v = action.toString().split('_')
    for (let i = 0; i < v.length; i += 1) {
      const s = v[i]
      v[i] = s.charAt(0).toUpperCase() + s.slice(1)
    }
    const method_name = `drawAction${v.join('')}`
    html.push(this[method_name](fields, data))

    return html
  }

  handleExportContacts = async () => {
    const { session } = this.props
    const { session_id } = session
    const data = { session_id }
    await this.setState({ loading: true })
    const parsedEp = 'action/post/campaign/export-contacts'
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      data
    )
    await this.setState({ loading: false })
    showMessageBox(this, response, 'error')
  }

  render() {
    const { loading } = this.state
    const { session } = this.props
    const { session_data } = session
    const { campaigns } = session_data
    const { data, fields, actions } = campaigns || {}
    const { subjectField } = fields || {}
    const { subjects, identified_subjects } = data || {}

    const export_button_disabled = identified_subjects === 0

    if (fields === undefined || loading === true) {
      return <JujoLoading />
    }

    return (
      <div
        className={classNames(
          'border rounded-1 py-5 px-4 border-color-1 d-flex flex-column align-items-center'
        )}
      >
        <div className={classNames('fc-1 fs-4 mb-4')}>Campagne</div>
        <div
          className={classNames('w-100 w-md-50 my-3 d-flex align-items-center')}
        >
          <div className={classNames('w-50  mx-3')}>
            <FieldShell fieldName="subject" withBorder>
              <ECSelectFieldComponent
                field={subjectField}
                handleValueChanged={this.handleValueChanged}
                initialValue={subjects}
              />
            </FieldShell>
          </div>
          <div className={classNames('w-50 d-flex flex-column mx-3')}>
            <div className={classNames('ffamily-secondary fs-9 fc-gray')}>
              soggetti identificati
            </div>
            <div className={classNames('fw-bold fs-5 fc-1')}>
              {identified_subjects}
            </div>
          </div>
        </div>
        {subjects && actions && actions.length > 0 && (
          <div className={classNames('w-100 my-3 row')}>
            {actions.map(action => (
              <div key={action} className={classNames('col-12 col-md-4 p-1')}>
                <div
                  className={classNames('border rounded-1 p-3 border-color-4')}
                >
                  {this.drawAction(fields, data, action)}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className={classNames('my-4')}>
          <JujoButtonComponent
            bstyle={3}
            bwidth="250px"
            blabel={`Esporta ${identified_subjects} contatti`}
            bDisabled={export_button_disabled}
            handleClick={() => {
              this.handleExportContacts()
            }}
          />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeCampaigns)
