/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import JujoLoading from '../../../../../../../src/components/loading'

import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'
import * as apiService from '../../../../../../../src/services/apiService'

const classNames = require('classnames')

export class MyMicronutrients extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      my_foods: false,
      foods: false,
      food_subcategories: false,
      food_categories: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    await this.updateData()
  }

  componentDidUpdate = async prevProps => {
    const { holisticId: prevHolisticId } = prevProps
    const { holisticId } = this.props
    if (prevHolisticId !== holisticId) {
      await this.updateData(prevProps)
    }
  }

  updateData = async () => {
    const data = await this.getServerInterpretationFoods()
    const { my_foods, foods, food_subcategories, food_categories } = data
    this.setState({ my_foods, foods, food_subcategories, food_categories })
  }

  getServerInterpretationFoods = async () => {
    const { environment } = this.props
    const { locale } = environment
    const parsed_url = `my/dna-result/get-interpretation-foods-for-frontend?locale=${locale}`
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsed_url}`,
      {}
    )
    const { data, status } = response
    return status === 200 || status === 201 ? data : false
  }

  getFoodFlag = food => {
    const { my_foods } = this.state || {}
    let flag = my_foods ? my_foods[food.id] : ''
    flag = flag === undefined ? '' : flag
    return flag.toLowerCase()
  }

  drawSingleFood = (food, locale, howMany) =>
    howMany > 0 &&
    this.getFoodFlag(food) &&
    this.getFoodFlag(food) !== '' && ( // Cambia con 1 se vuoi che non si vedano i cibi singoli sotto una categoria
      <div
        key={food.id}
        className={classNames(
          'd-flex flex-row align-items-center justify-content-start'
        )}
      >
        <div
          className={classNames(`food-circle-${this.getFoodFlag(food)} me-1`)}
        />
        <div className={classNames('fw-normal fs-8')}>{food.name[locale]}</div>
      </div>
    )

  getFoodTrance = (foods, trance, food_subcategories) => {
    const itemsPerTrance = Math.ceil(food_subcategories.length / 4)
    return foods.slice(
      trance * itemsPerTrance,
      trance * itemsPerTrance + itemsPerTrance
    )
  }

  drawFlexFoods = (foods, food_subcategories, category, locale, trance) =>
    this.getFoodTrance(
      foods.filter(
        item =>
          item.subcategory_id ===
          food_subcategories.filter(
            cItem => cItem.category_id === category.id
          )[0].id
      ),
      trance,
      food_subcategories
    ).map(food => this.drawSingleFood(food, locale, 999))

  subcategoryFoods = (foods, subcategory) => {
    const f = foods.filter(item => item.subcategory_id === subcategory.id)
    return f
  }

  drawSubcategoryName = (subcategory, foods, locale) => {
    const subFoods = this.subcategoryFoods(foods, subcategory)
    const showCircle = subFoods.length === 0 // Cambia con 1 se vuoi far apparire il pallino alle subcategories
    return (
      <div
        className={classNames(
          'd-flex flex-row align-items-center justify-content-start'
        )}
      >
        {showCircle && <div className={classNames(`food-circle-v me-1`)} />}
        <div className={classNames('text-uppercase text-capitalize')}>
          {subcategory.name[locale]}
        </div>
      </div>
    )
  }

  render() {
    const { my_foods, foods, food_subcategories, food_categories } = this.state
    const { specialization, environment, genericData } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { myResults } = genericData
    const { swab } = myResults
    const { product_id } = swab || false
    const food_icon_color = product_id === 4 ? 'green' : 'light-blue'

    return (
      <>
        {!my_foods && <JujoLoading />}
        {my_foods && (
          <div
            className={classNames(
              'd-flex flex-column align-items-start justify-content-center mb-4 pt-4 w-100 fs-7 fw-bold'
            )}
          >
            <div
              className={classNames(
                'd-flex flex-column align-items-start justify-content-center mb-4 pt-4 w-100 fs-7 fw-bold'
              )}
            >
              <div
                className={classNames(
                  ' border-bottom border-2 border-dark my-2 pb-2 w-100'
                )}
              >
                {texts[locale].my_result_legend}
              </div>
              <div className={classNames('row mb-2 pt-1 w-100 fs-7 fw-bold')}>
                <div
                  className={classNames(
                    'col-12 col-md-4 d-flex flex-row align-items-center'
                  )}
                >
                  <div className={classNames('food-circle-v')} />
                  <div className={classNames('ms-1')}>
                    {texts[locale].my_recommended}
                  </div>
                </div>
                <div
                  className={classNames(
                    'col-12 col-md-4 d-flex flex-row align-items-center'
                  )}
                >
                  <div className={classNames('food-circle-g')} />
                  <div className={classNames('ms-1')}>
                    {texts[locale].my_to_limit}
                  </div>
                </div>
                <div
                  className={classNames(
                    'col-12 col-md-4 d-flex flex-row align-items-center'
                  )}
                >
                  <div className={classNames('food-circle-r')} />
                  <div className={classNames('ms-1')}>
                    {texts[locale].my_not_recommended}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                'd-flex flex-column align-items-start justify-content-center  w-100'
              )}
            >
              {/* Categories */}
              {food_categories &&
                food_categories.map(category => (
                  <div
                    key={category.id}
                    className={classNames(
                      'd-flex flex-column align-items-start border-top border-1 border-color-0 my-3 pt-4 w-100'
                    )}
                  >
                    {/* Categories title */}
                    <div
                      className={classNames(
                        'd-flex flex-row align-items-center pb-4'
                      )}
                    >
                      {/* Categories title icon */}
                      <div
                        className={classNames(
                          `food-${category.id}-${food_icon_color}-icon me-4`
                        )}
                        style={{
                          width: '30px',
                          height: '30px',
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                        }}
                      />
                      {/* Categories title text */}
                      <div className={classNames('fw-bold text-uppercase')}>
                        {category.name[locale]}
                      </div>
                    </div>

                    {/* Subcategories */}
                    {category.ui_type === 'grid' && (
                      <div className={classNames('row w-100')}>
                        {food_subcategories &&
                          food_subcategories
                            .filter(item => item.category_id === category.id)
                            .map(subcategory => (
                              <div
                                key={subcategory.id}
                                className={classNames(
                                  'col-6 col-sm-4 col-md-3 d-flex flex-column align-items-start pb-2'
                                )}
                              >
                                {/* Subcategories title */}
                                {this.drawSubcategoryName(
                                  subcategory,
                                  foods,
                                  locale
                                )}

                                {/* Foods */}
                                {this.subcategoryFoods(foods, subcategory).map(
                                  food =>
                                    this.drawSingleFood(
                                      food,
                                      locale,
                                      this.subcategoryFoods(foods, subcategory)
                                        .length
                                    )
                                )}
                              </div>
                            ))}
                      </div>
                    )}
                    {category.ui_type === 'flex' && (
                      <div className={classNames('row w-100')}>
                        <div
                          className={classNames(
                            'col-6 col-sm-4 col-md-3 d-flex flex-column align-items-start pb-2'
                          )}
                        >
                          {/* Foods trance 1 */}
                          {this.drawFlexFoods(
                            foods,
                            food_subcategories,
                            category,
                            locale,
                            0
                          )}
                        </div>
                        <div
                          className={classNames(
                            'col-6 col-sm-4 col-md-3 d-flex flex-column align-items-start pb-2'
                          )}
                        >
                          {/* Foods trance 2 */}
                          {this.drawFlexFoods(
                            foods,
                            food_subcategories,
                            category,
                            locale,
                            1
                          )}
                        </div>
                        <div
                          className={classNames(
                            'col-6 col-sm-4 col-md-3 d-flex flex-column align-items-start pb-2'
                          )}
                        >
                          {/* Foods trance 3 */}
                          {this.drawFlexFoods(
                            foods,
                            food_subcategories,
                            category,
                            locale,
                            2
                          )}
                        </div>
                        <div
                          className={classNames(
                            'col-6 col-sm-4 col-md-3 d-flex flex-column align-items-start pb-2'
                          )}
                        >
                          {/* Foods trance 4 */}
                          {this.drawFlexFoods(
                            foods,
                            food_subcategories,
                            category,
                            locale,
                            3
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MyMicronutrients)
