/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import JujoLoading from '../../../../../../../src/components/loading'

import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'
import MyDetailsPopupComponent from './my_details_popup'
import MyGradientBarComponent from './my_gradient_bar'
import MyDrawFoodSupplements from './my_draw_food_supplements'
import * as apiService from '../../../../../../../src/services/apiService'
import JujoButtonComponent from '../../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class MyDrawInterpretations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      ui_elements: false,
      dont_use_this_holistic: false,
      details_popup_visible: false,
      food_supplement_suggested: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    await this.updateData()
  }

  componentDidUpdate = async prevProps => {
    const { holisticId: prevHolisticId } = prevProps
    const { holisticId } = this.props
    if (prevHolisticId !== holisticId) {
      await this.updateData()
    }
  }

  getServerInterpretation = async holistic_id => {
    const { environment, authentication } = this.props
    // eslint-disable-next-line no-unused-vars
    const { locale } = environment
    const { user } = authentication
    const parsed_url = `my/dna-result/get-interpretation-for-frontend?locale=${user.lang}`
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsed_url}`,
      { holistic_id }
    )
    const { data, status } = response
    return status === 200 || status === 201 ? data : false
  }

  updateData = async () => {
    const { dont_use_this_holistic } = this.state
    const { environment } = this.props
    const { locale } = environment
    let { holisticId } = this.props
    holisticId *= 1
    const data = await this.getServerInterpretation(holisticId)
    if (!dont_use_this_holistic && holisticId > 0 && data) {
      const { interpretation, holistic, food_supplement_suggested } = data || {}
      const ui_elements = {}
      ui_elements.interpretation = interpretation
      if (interpretation) {
        ui_elements.interpretation.tips[locale].replaceAll('[br]', '<br />')
        ui_elements.face_icon = false
        ui_elements.arrow_position = false
        ui_elements.interpretation_text_color = false

        switch (ui_elements.interpretation.flag) {
          case 'R':
            ui_elements.face_icon = 'face-red-sad'
            ui_elements.arrow_position = 0
            break
          case 'O':
            ui_elements.face_icon = 'face-orange-happy'
            ui_elements.arrow_position = 45
            break
          case 'Y':
            ui_elements.face_icon = 'face-orange-happy'
            ui_elements.arrow_position = 95
            break
          case 'G':
            ui_elements.face_icon = 'face-green-happy'
            ui_elements.arrow_position = 188
            break
          default:
            ui_elements.face_icon = ''
            ui_elements.arrow_position = 500
            break
        }
        ui_elements.interpretation_text_color =
          ui_elements.interpretation.flag === 'R'
            ? 'fs-7 fc-8 text-justify'
            : 'fs-7 text-justify'
        await this.setState({
          holistic,
          ui_elements,
          food_supplement_suggested,
        })
      }
      await this.setState({
        loaded: true,
      })
    } else {
      this.setState({ dont_use_this_holistic: true })
    }
  }

  handleDetailsButtonClick = () => {
    this.setState({ details_popup_visible: true })
  }

  handlCloseDetailsPopup = () => {
    this.setState({ details_popup_visible: false })
  }

  render() {
    const {
      holistic,
      ui_elements,
      dont_use_this_holistic,
      details_popup_visible,
      food_supplement_suggested,
      loaded,
    } = this.state
    const { specialization, environment, genericData, authentication } =
      this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const { user } = authentication
    const holistic_title_translated =
      holistic && holistic.title ? holistic.title[user.lang] : ''
    const {
      interpretation,
      face_icon,
      arrow_position,
      interpretation_text_color,
    } = ui_elements
    const { tips } = interpretation || {}
    const tips_translated = tips ? tips[user.lang] : ''
    const { myResults } = genericData || {}
    const { swab } = myResults || {}
    const { panel, product_id } = swab || ''
    const holisticIconClass =
      panel === 'panel_swabs_short' ? 'light-blue' : 'green'

    const results_font_color = product_id === 4 ? 'fc-11' : 'fc-1'

    return (
      <>
        {loaded === false && <JujoLoading />}
        {loaded === true &&
          !dont_use_this_holistic &&
          interpretation &&
          ui_elements && (
            <div
              className={classNames(
                'd-flex flex-column align-items-start justify-content-center border-top border-2 border-color-0 my-4 pt-4 w-100'
              )}
            >
              {/* Icon + title and gradient bar */}
              <div
                className={classNames(
                  'd-flex flex-column flex-md-row justify-content-start justify-content-md-between flex-wrap w-100'
                )}
              >
                {/* Icon + title */}
                <div
                  className={classNames('d-flex flex-row align-items-center')}
                >
                  <div
                    className={classNames(
                      `holistic-${holistic.id}-${holisticIconClass}-icon me-3`
                    )}
                    style={{
                      width: '30px',
                      height: '30px',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                  <div className={classNames('fs-7 fw-bold')}>
                    {`${holistic_title_translated}`}
                  </div>
                </div>

                {/* Gradient Bar */}
                {holistic.gradient_bar &&
                  holistic.gradient_bar.show === true && (
                    <MyGradientBarComponent
                      arrow_position={arrow_position}
                      face_icon={face_icon}
                      holistic={holistic}
                    />
                  )}

                {/* Not Gradient Bar but only Face */}
                {(!holistic.gradient_bar ||
                  (holistic.gradient_bar &&
                    holistic.gradient_bar.show === false)) &&
                  face_icon && (
                    <div
                      className={classNames(
                        'd-flex flex-row align-items-center justify-content-center'
                      )}
                      style={{ width: '208px' }}
                    >
                      <div
                        className={classNames(face_icon)}
                        style={{ width: '27px', height: '27px' }}
                      />
                    </div>
                  )}
              </div>

              {/* Interpretation */}
              <div
                className={classNames(
                  'd-flex flex-row align-items-start justify-content-start my-2 w-100 w-md-60 lh-sm'
                )}
              >
                <div
                  className={classNames(
                    'fs-7 fw-bold me-2',
                    results_font_color
                  )}
                >
                  {`${texts[user.lang].my_interpretation}: `}
                </div>
                <div className={classNames(interpretation_text_color)}>
                  {interpretation && interpretation.interpretation[user.lang]}
                </div>
              </div>

              {/* Tip + Details */}
              <div
                className={classNames(
                  'd-flex flex-column flex-md-row align-items-start justify-content-start justify-content-md-between w-100 lh-sm'
                )}
              >
                <div
                  className={classNames(
                    'd-flex flex-row align-items-start justify-content-start w-100 w-md-60'
                  )}
                  // style={{ width: '508px' }}
                >
                  {tips_translated && tips_translated !== '' && (
                    <>
                      <div
                        className={classNames(
                          'fs-7 fw-bold me-2',
                          results_font_color
                        )}
                      >
                        {`${texts[locale].my_tip}: `}
                      </div>
                      <div
                        className={classNames('fs-7 text-justify')}
                        style={{
                          // width: 'min(100%, 375px)',
                          whiteSpace: 'pre-wrap',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: tips_translated.replaceAll(
                            '[BULLET]',
                            '&bull;'
                          ),
                        }}
                      />
                    </>
                  )}
                </div>
                <div
                  className={classNames(
                    'w-100 w-md-unset d-flex justify-content-center justify-content-md-end'
                  )}
                >
                  <div
                    className={classNames(
                      'd-flex flex-row align-items-center justify-content-center mt-2 mt-md-0'
                    )}
                    style={{ width: '204px' }}
                  >
                    <JujoButtonComponent
                      bstyle={3}
                      bwidth="100px"
                      bheight="35px"
                      blabel={texts[locale].my_details}
                      bFontClass="fw-bold"
                      bdata=""
                      handleClick={this.handleDetailsButtonClick}
                    />
                  </div>
                </div>
              </div>
              {food_supplement_suggested &&
                food_supplement_suggested.length > 0 && (
                  <MyDrawFoodSupplements
                    food_supplement_suggested={food_supplement_suggested}
                  />
                )}
              {details_popup_visible && (
                <MyDetailsPopupComponent
                  handlCloseDetailsPopup={this.handlCloseDetailsPopup}
                  holistic={holistic}
                  ui_elements={ui_elements}
                  holistic_icon_class={holisticIconClass}
                />
              )}
            </div>
          )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MyDrawInterpretations)
