import React from 'react'
import { connect } from 'react-redux'
import JujoEditableComponent from '../../../../../../src/components/editable_component'
import * as actionCreators from '../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeUserRegistrationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleLoginClicked = () => {
    const { authentication, updateAuthentication } = this.props
    const updated_auth = cloneObj(authentication)
    updated_auth.force_login = true
    updateAuthentication(updated_auth)
  }

  render() {
    const { specialization, environment, authentication } = this.props
    const { user } = authentication

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div className={classNames('')}>
        {Object.keys(user).length === 0 && (
          <div>
            <span>{texts[locale].already_registerd}</span>
            <span
              className={classNames('fw-bold ms-1')}
              style={{ textDecoration: 'underline' }}
              role="button"
              tabIndex={0}
              onClick={() => {
                this.handleLoginClicked()
              }}
              onKeyPress={() => {
                this.handleLoginClicked()
              }}
            >
              {texts[locale].login}
            </span>
          </div>
        )}
        <JujoEditableComponent
          c_def={{
            comp: 'editable_component',
            entity: {
              source: 'static',
              defValue: 'customer',
            },
            renderer: {
              viewPath: 'checkout_group/user_registration',
            },
            reloadOnPropChanged: ['session.session_data.cart.user_data.user'],
          }}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeUserRegistrationComponent)
