/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'
import { FillableComponent } from '../../../../../../src/components/fillable_component'

const classNames = require('classnames')

export class CartModifiedInfobox extends FillableComponent {
  renderBody = () => {
    const { specialization, environment, data } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { cart_modified } = data

    return (
      <div className={classNames('')}>
        <div className={classNames('fw-bold fs-5')}>
          {texts[locale].cart_modified_notice}
        </div>
        <div className={classNames('fst-italic fc-5 border-bottom')}>
          {texts[locale].cart_modified_notice_description}
        </div>
        <div className={classNames('my-3')}>
          <div className={classNames('row')}>
            <div className={classNames('col-4 ')}>{texts[locale].product}</div>
            <div className={classNames('col-4 text-center fc-8')}>
              {texts[locale].selected_quantities}
            </div>
            <div className={classNames('col-4 text-center ')}>
              {texts[locale].available_quantities}
            </div>
          </div>
          {cart_modified &&
            cart_modified.map(item => (
              <div className={classNames('row')}>
                <div className={classNames('col-4 fw-bold')}>
                  {item.product_title_translated}
                </div>
                <div className={classNames('col-4 text-center fw-bold fc-8 ')}>
                  {item.quantity_selected}
                </div>
                <div className={classNames('col-4 text-center fw-bold')}>
                  {item.quantity_founded}
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(CartModifiedInfobox)
