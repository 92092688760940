/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import Video from './video'

import './styles.scss'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeVideoAccademyGallery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const { session } = this.props
    const { session_data } = session
    const { video_accademy } = session_data
    const { gallery, selected_video } = video_accademy

    return (
      <div
        className={classNames(
          'd-flex flex-row flex-wrap justify-content-between'
        )}
      >
        {gallery &&
          gallery.map((item, index) => {
            if (index !== selected_video) {
              return (
                <div key={index} className={classNames('gallery_video')}>
                  <Video data={item} />
                </div>
              )
            }
          })}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeVideoAccademyGallery)
