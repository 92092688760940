import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaePrivateDataViewComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    return (
      <div className={classNames('row bg-3 p-3')}>
        This view has not been implemented
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaePrivateDataViewComponent)
