import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../../src/services/servicesHelper'
import { saveSessionToServer } from '../../../../../../../src/services/sessionHelper'

import apiCallMap from '../../../../../../../src/enums/apiCallMap'
import * as apiService from '../../../../../../../src/services/apiService'
import workflowStepsMap from '../../enums/workflow_steps_map'
import { cloneObj, scrollPageOnTop } from '../../../../../../../src/utils'

export async function validateUserDataAndChangeWorkflowStep(sender) {
  const { state, props } = sender
  const { environment, authentication, session, updateSession } = props
  const { entityDefinition, localDataSource } = state
  const { apis } = entityDefinition
  const { verifyData } = apis
  const { apiCall, requestType, placeholderMapping, relatedEntity } = verifyData

  const requestData = baseRequestObject(
    apiCallMap[apiCall],
    relatedEntity,
    requestType,
    environment,
    authentication
  )

  requestData.placeholderMapping = placeholderMapping

  const parsedEp = parseEndpoint(requestData)
  const dataToSend = { ...localDataSource }

  const response = await apiService.httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    dataToSend
  )

  const { status } = response
  if (status === 200 || status === 201) {
    const updated_session = cloneObj(session)

    const { session_id, session_data } = updated_session
    const { cart } = session_data
    const { client_data, user_data } = cart

    user_data.user.new = dataToSend
    client_data.order_workflow.current_step = workflowStepsMap.shipment

    const returned_data = await saveSessionToServer(session_id, session_data)

    updated_session.session_data = returned_data
    await updateSession(updated_session)
    scrollPageOnTop()
  } else {
    sender.manageResponse(response)
  }
}

export function calculateUserRegistrationRenderMode(sender) {
  const { data } = sender.state
  const renderMode =
    Object.keys(data[0] || {}).length <= 0
      ? sender.renderModeMap.add
      : sender.renderModeMap.edit

  return renderMode
}
