import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeFastCartContent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { customClass, session, specialization, environment } = this.props
    const { session_data } = session || {}
    const { cart, product_data } = session_data || {}
    const { products, totals } = cart
    const {
      total_price,
      total_products_gross_discounted,
      shipping_cost_gross,
      of_which_vat,
    } = totals

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const product_keys = Object.keys(products)
    return (
      <div className={classNames(`w-100 d-flex flex-column ${customClass}`)}>
        <div className="fw-bold border-bottom border-2 border-color-black pt-3 pb-2">
          {texts[locale].order_summary}
        </div>
        <div className={classNames('border-top bg-3 p-3 fs-7')}>
          {product_keys &&
            product_keys.map(product_key => {
              if (products[product_key].quantity > 0) {
                return (
                  <div key={product_key} className={classNames('row')}>
                    <div className={classNames('col-6 fw-bold')}>
                      {product_data[product_key].title_translated}
                    </div>
                    <div className={classNames('col-2 fw-bold text-end')}>
                      {products[product_key].quantity}
                    </div>
                    <div className={classNames('col-4 fw-bold text-end')}>
                      {`€ ${products[product_key].total_gross_price}`}
                    </div>
                  </div>
                )
              }
              return false
            })}
        </div>
        <div className="fw-bold p-3 border-top border-bottom border-color-4 fs-7">
          <div className="d-flex justify-content-between py-1">
            <div>{texts[locale].total_products}</div>
            <div>{`€ ${total_products_gross_discounted}`}</div>
          </div>
          <div className="d-flex justify-content-between py-1">
            <div>{texts[locale].shipping_costs}</div>
            <div>{`€ ${shipping_cost_gross}`}</div>
          </div>
        </div>
        <div className={classNames('p-2 d-flex flex-column')}>
          <div
            className={classNames(
              'd-flex justify-content-between py-1 fs-5 fw-bold'
            )}
          >
            <div className={classNames('fst-uppercase')}>
              {texts[locale].total}
            </div>
            <div>{`€ ${total_price}`}</div>
          </div>
          <div
            className={classNames('d-flex justify-content-between py-1 fs-7')}
          >
            <div>{texts[locale].of_which_vat}</div>
            <div>{`€ ${of_which_vat}`}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeFastCartContent)
