/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import runTestWorkflowStepsMap from '../../common_group/enums/run_test_workflow_steps_map'
import VitaeWorkflowIndicatorComponent from '../../common_group/workflow_indicator'
import VitaeSwabUserDataComponent from '../swab_user_data'
import VitaeSensitiveDataComponent from '../sensitive_data'
import VitaePaymentStepComponent from '../payment_step'
import JujoLoading from '../../../../../../src/components/loading'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeRunTestWizardComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const { session, specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { session_data } = session
    const { run_test } = session_data
    const { workflow_step } = run_test || {}

    const current_step_slug = Object.keys(runTestWorkflowStepsMap).find(
      k => runTestWorkflowStepsMap[k] === workflow_step
    )

    return (
      <>
        {!run_test && <JujoLoading />}
        {run_test && (
          <div className={classNames('')}>
            <div
              className={classNames(
                'd-flex flex-column flex-md-row fc-white bg-1 p-3 rounded-top-20 align-items-center'
              )}
            >
              <div className={classNames('fw-bold fs-5 me-md-4')}>
                {texts[locale].self_analysis_dna_kit}
              </div>
              <div className={classNames('fst-italic')}>
                {texts[locale].run_the_new_test_wizard}
              </div>
            </div>
            <div className={classNames('pt-4 bg-3 rounded-bottom-20')}>
              <VitaeWorkflowIndicatorComponent
                iconPrefix="run-test-status"
                navigateBackEnabled
                stepsTrackerPath="run_test/workflow_step"
                workflowStepsMap={runTestWorkflowStepsMap}
                currentStepSlug={current_step_slug}
              />
              <div className={classNames('p-4')}>
                {workflow_step ===
                  runTestWorkflowStepsMap.swab_and_user_identification && (
                  <VitaeSwabUserDataComponent />
                )}
                {workflow_step ===
                  runTestWorkflowStepsMap.sensitive_data_compilation && (
                  <VitaeSensitiveDataComponent />
                )}
                {workflow_step === runTestWorkflowStepsMap.payment && (
                  <VitaePaymentStepComponent />
                )}
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeRunTestWizardComponent)
