import React from 'react'
import { connect } from 'react-redux'
import apiCallMap from '../../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../../src/enums/apiRequestTypesMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../../src/services/servicesHelper'
import * as apiService from '../../../../../../../src/services/apiService'

import * as actionCreators from '../../../../../../../src/store/actions'
import {
  cloneObj,
  injectComponent,
  mapStateToProps,
  retrieveComponent,
} from '../../../../../../../src/utils'
import {
  createMSBDef,
  getEmptyMessageBox,
} from '../../../../../../../src/components/message_box/helper'
import JujoButtonComponent from '../../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaeAssociableComponent extends React.Component {
  constructor(props) {
    super(props)
    this.assignablePerson = {
      me: 'me',
      another_person: 'another_person',
    }
    this.state = {
      associated_to: '',
      fields: {
        first_name: { f_type: 'text', f_value: '' },
        last_name: { f_type: 'text', f_value: '' },
        email: { f_type: 'email', f_value: '' },
        email_confirmation: { f_type: 'email_confirmation', f_value: '' },
      },
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderRadioButton = (label, value) => {
    const { associated_to } = this.state
    const html = []

    const selected = value === associated_to

    html.push(
      <div
        key={value}
        className={classNames('d-flex align-items-center my-2 fs-7')}
        role="button"
        tabIndex={0}
        onClick={async () => {
          this.setState({ associated_to: value })
        }}
        onKeyPress={async () => {
          this.setState({ associated_to: value })
        }}
      >
        <div
          className={classNames(
            'd-flex justify-content-center align-items-center bg-white border rounded-percentage-100 me-2'
          )}
          style={{ width: '14px', height: '14px' }}
        >
          {selected && (
            <div
              className={classNames('bg-1 rounded-percentage-100')}
              style={{ width: '10px', height: '10px' }}
            />
          )}
        </div>
        <div>{label}</div>
      </div>
    )

    return html
  }

  handleFieldValueChanged = (key, value) => {
    const { fields } = this.state
    const cloned_fields = cloneObj(fields)
    cloned_fields[key].f_value = value
    this.setState({ fields: cloned_fields })
  }

  handleAssociateProduct = async () => {
    const { associated_to, fields } = this.state
    const { environment, authentication, product, session } = this.props
    const { user } = authentication

    let email = ''
    let email_confirmation = ''
    let first_name = ''
    let last_name = ''
    if (associated_to === this.assignablePerson.me) {
      email = user.email
      email_confirmation = user.email
    } else {
      email = fields.email.f_value
      email_confirmation = fields.email_confirmation.f_value
      first_name = fields.first_name.f_value
      last_name = fields.last_name.f_value
    }

    const requestData = baseRequestObject(
      apiCallMap.actionMy,
      'product',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'associate',
      pDefValue: 'associate',
    })

    const parsedEp = parseEndpoint(requestData)
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      {
        instance_id: product.id,
        email,
        email_confirmation,
        first_name,
        last_name,
        session_id: session.session_id,
        category: product.category,
      }
    )

    await this.manageResponse(response)
  }

  closeMessageBox = params => {
    const { msbox_instance_id } = params
    retrieveComponent(this, msbox_instance_id)
  }

  closeMessageBoxAndUpdateSession = async params => {
    const { session, updateSession } = this.props
    const { data, msbox_instance_id } = params
    const { session_updated } = data

    const session_copy = cloneObj(session)
    session_copy.session_data = session_updated

    await updateSession(session_copy)
    retrieveComponent(this, msbox_instance_id)
  }

  manageResponse = async response => {
    const { specialization, environment } = this.props
    const { status, data } = response

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const messageBox = getEmptyMessageBox()

    if (status === 200 || status === 201) {
      messageBox.icon = 'confirm-icon-color'
      messageBox.message = data.message

      const continue_btn = createMSBDef(
        texts[locale].continue_browsing,
        this.closeMessageBoxAndUpdateSession,
        {
          data,
          msbox_instance_id: messageBox.id,
        },
        true
      )
      messageBox.buttons.push(continue_btn)
    } else {
      messageBox.icon = 'warning-icon-color'
      messageBox.title = texts[locale].warning

      let { message } = data

      if (data.fields) {
        message += '<div class="fs-7">'
        const kList = Object.keys(data.fields)
        for (let i = 0; i !== kList.length; i += 1) {
          const k = kList[i]
          const KMsgList = data.fields[k]
          const msg = KMsgList[0]
          message += `<div>${msg}</div>`
        }
        message += '</div>'
      }

      messageBox.message = message

      const ok_btn = createMSBDef(
        texts[locale].ok,
        this.closeMessageBox,
        {
          msbox_instance_id: messageBox.id,
        },
        true
      )
      messageBox.buttons.push(ok_btn)
    }

    const component_definition = {
      specialized: false,
      path: 'message_box',
      data: {
        messageBox,
      },
    }

    injectComponent(this, messageBox.id, component_definition)
  }

  render() {
    const { associated_to, fields } = this.state
    const { specialization, environment, session, product } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { session_data } = session
    const { product_data } = session_data

    const { id, product_id, created_at_formatted, swab_id_formatted } = product
    const p_def = product_data[product_id]

    const { cover_picture, title_translated, category, attributes } = p_def
    let color = ''
    for (let j = 0; j !== attributes.length; j += 1) {
      const attr = attributes[j]
      if (attr.key === 'color') {
        color = attr.value
        break
      }
    }

    return (
      <div key={id} className={classNames('bg-3 p-3 fc-5 mb-3')}>
        <div
          className={classNames(
            'd-flex flex-column flex-md-row justify-content-between align-items-center'
          )}
        >
          <div
            className={classNames(
              'd-flex flex-column flex-md-row align-items-center'
            )}
          >
            <div
              style={{
                backgroundImage: `url(${cover_picture})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                width: '100px',
                height: '100px',
              }}
            />
            <div
              className={classNames(
                'd-flex flex-column mx-3 text-center text-md-start'
              )}
            >
              <div>
                {category === 'kit' && (
                  <span className={classNames('me-1')}>VitæDNA</span>
                )}
                <span
                  className={classNames('fw-bold fst-uppercase')}
                  style={{ color }}
                >
                  {title_translated}
                </span>
              </div>
              <div>
                <span
                  className={classNames('fs-8')}
                >{`${texts[locale].purchase_date}: `}</span>
                <span className={classNames('fw-bold fc-black')}>
                  {created_at_formatted}
                </span>
              </div>
            </div>
          </div>
          {category === 'kit' && (
            <div
              className={classNames(
                'border p-2 text-center text-md-end mt-3 mt-md-0'
              )}
            >
              <div className={classNames('fs-8')}>
                {texts[locale].swab_code}
              </div>
              <div className={classNames('fw-bold')}>{swab_id_formatted}</div>
            </div>
          )}
        </div>
        <div
          className={classNames(
            'd-flex flex-column flex-md-row fw-bold fc-black align-items-center mt-3'
          )}
        >
          <div className={classNames('me-0 me-md-4')}>
            {this.renderRadioButton(
              texts[locale].this_product_is_for_me,
              this.assignablePerson.me
            )}
          </div>
          <div>
            {this.renderRadioButton(
              texts[locale].this_product_is_for_another_person,
              this.assignablePerson.another_person
            )}
          </div>
        </div>
        {associated_to === this.assignablePerson.another_person && (
          <div className={classNames('row')}>
            {Object.keys(fields).map(f_key => {
              const { f_type, f_value } = fields[f_key]
              return (
                <div key={f_key} className={classNames('mt-2 col-6 col-md-6')}>
                  <div className={classNames('ffamily-secondary fs-9 fc-gray')}>
                    {texts[locale][f_key]}
                  </div>
                  <div
                    className={classNames(
                      'p-1 border border-color-4 rounded bg-white'
                    )}
                  >
                    <input
                      type={f_type}
                      defaultValue={f_value}
                      onChange={e => {
                        this.handleFieldValueChanged(f_key, e.target.value)
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        )}
        <div className={classNames('d-flex justify-content-center my-3')}>
          <JujoButtonComponent
            bstyle={3}
            bDisabled={associated_to === ''}
            bwidth="200px"
            blabel={texts[locale].associate_product}
            handleClick={async () => {
              await this.handleAssociateProduct()
            }}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeAssociableComponent)
