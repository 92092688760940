import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'
import Question from './question'

export class VitaeQuestions extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { data } = this.props
    const { questions, number_of_questions_to_show } = data || {}
    const questions_to_show = questions.slice(0, number_of_questions_to_show)

    return (
      <>
        {questions_to_show.map((obj, index) => (
          <Question key={index} obj={obj} />
        ))}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeQuestions)
