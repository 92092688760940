import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'
import JujoButtonComponent from '../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaeBuyKit extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleBuyNow = () => {
    const { environment } = this.props
    const { locale } = environment
    const path = locale === 'it' ? 'it/acquista/' : 'en/buy/'
    const url = `${process.env.site_url}${path}`
    if (typeof window !== 'undefined') {
      window.open(url, '_blank')
    }
  }

  render() {
    const { data, session, environment, specialization } = this.props
    const { session_data } = session
    const { browser_data, product_data } = session_data
    const { products } = browser_data || {}
    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations
    const { slug } = data || {}
    const product = products.filter(item => item.slug === slug)[0]
    const { cover_picture, id, title, attributes } = product
    const product_price = product_data[id].final_price
    const kit_color = attributes.filter(attr => attr.key === 'color')[0].value
    return (
      <div
        key={`${slug}_content`}
        className={classNames('d-flex flex-column align-items-center w-100')}
        style={{ width: 'min(380px, 100%)' }}
      >
        <div
          className={classNames('background-image background-image')}
          style={{
            backgroundImage: `url(${cover_picture})`,
            width: '300px',
            height: '180px',
          }}
        />
        <div
          className={classNames('fs-4 fc-5 mt-3 text-center ffamily-numbers')}
        >
          <div className={classNames('mt-2 text-center')}>
            <span>VitæDNA</span>
            <span
              className={classNames('fst-uppercase ms-1 fw-bold')}
              style={{ color: kit_color }}
            >
              {title[locale]}
            </span>
          </div>
        </div>
        <div className={classNames('fs-3 fc-5 text-center ffamily-numbers')}>
          {`${product_price} €`}
        </div>
        <div className={classNames('my-3')}>
          <JujoButtonComponent
            bstyle={3}
            bwidth="200px"
            blabel={texts[locale].buy_now}
            handleClick={() => {
              this.handleBuyNow()
            }}
          />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeBuyKit)
