export async function printSwabLabels(sender) {
  const { props } = sender
  const { gridComponent, authentication, environment } = props
  const { access_token } = authentication
  const { locale } = environment
  const { selectedRows } = gridComponent.state
  const { idList } = selectedRows
  const sIdList = idList.join(',')
  if (sIdList !== '') {
    const url = `${process.env.apiServerUrl}pdf/vitae/swabs?locale=${locale}&shipping_subject=order&subject_ids=${sIdList}&tk=${access_token}`
    window.open(url)
  }
}
export async function printWaybillLabels(sender) {
  const { props } = sender
  const { gridComponent, authentication, environment } = props
  const { access_token } = authentication
  const { locale } = environment
  const { selectedRows } = gridComponent.state
  const { idList } = selectedRows
  const sIdList = idList.join(',')
  if (sIdList !== '') {
    const url = `${process.env.apiServerUrl}pdf/vitae/waybill?locale=${locale}&shipping_subject=order&subject_ids=${sIdList}&tk=${access_token}`
    window.open(url)
  }
}
