import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeDietTipsFieldComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleDeleteTip = async idx => {
    const { dataSource, field, handleValueChanged } = this.props
    const { name } = field

    const currField = dataSource[name]
    const updatedField = cloneObj(currField)
    updatedField.splice(idx, 1)
    await handleValueChanged(name, updatedField)
  }

  handleChange = (idx, subFieldName, value) => {
    const { dataSource, field, handleValueChanged } = this.props
    const { name } = field

    const currField = dataSource[name]
    const updatedField = cloneObj(currField)

    updatedField[idx][subFieldName] = value

    handleValueChanged(name, updatedField)
  }

  handleNewActivity = () => {
    const { dataSource, field, handleValueChanged } = this.props

    const { name } = field

    const currField = dataSource[name]
    const updatedField = cloneObj(currField)

    const newFieldObj = {
      title: '',
      body: '',
    }

    updatedField.push(newFieldObj)
    handleValueChanged(name, updatedField)
  }

  drawRepeatedElement = (element, idx) => {
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const html = []

    const { title, body } = element

    html.push(
      <div
        key={`tip_${idx}`}
        className={classNames('col-12 d-flex flex-column')}
      >
        <div className={classNames('d-flex align-items-center')}>
          <div className={classNames('fw-bold fs-7 mb-2')}>
            {`${texts[locale].tip} ${idx + 1}`}
          </div>
        </div>

        <div className={classNames('mt-2 ffamily-secondary fs-9 fc-gray')}>
          {texts[locale].title}
        </div>
        <input
          className={classNames('mt-1 bg-white border border-color-4 rounded')}
          type="text"
          defaultValue={title}
          onChange={e => {
            this.handleChange(idx, 'title', e.target.value)
          }}
        />
        <div className={classNames('mt-2 ffamily-secondary fs-9 fc-gray')}>
          {texts[locale].description}
        </div>
        <textarea
          className={classNames('mt-1 bg-white border border-color-4 rounded')}
          defaultValue={body}
          onBlur={e => {
            this.handleChange(idx, 'body', e.target.value)
          }}
        />
      </div>
    )

    return html
  }

  drawRepeater = () => {
    const { dataSource, field, specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { name } = field

    const fieldToRender = dataSource[name] || []

    const html = []

    html.push(
      <div key={name} className={classNames('px-4 py-3')}>
        {fieldToRender.map((element, idx) => (
          <div
            key={`subField_${idx}`}
            className={classNames('row g-4', idx > 0 ? 'mt-3' : '')}
          >
            {this.drawRepeatedElement(element, idx)}
            <div className={classNames('d-flex justify-content-center ')}>
              <div
                className={classNames(
                  'd-inline-flex bg-white rounded-50 border border-color-black fw-bold fs-8 align-items-center px-2 py-1'
                )}
                role="button"
                tabIndex={0}
                onClick={async () => {
                  await this.handleDeleteTip(idx)
                }}
                onKeyPress={async () => {
                  await this.handleDeleteTip(idx)
                }}
              >
                <div
                  className={classNames('trash-icon-2')}
                  style={{
                    height: '18px',
                    width: '18px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                  }}
                />
                <div className={classNames('ms-1')}>
                  {texts[locale].delete_tip}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div
          className={classNames(
            'd-flex justify-content-center align-items-center mt-4 mb-2'
          )}
        >
          <div
            className={classNames(
              'd-inline-flex bg-white rounded-50 border border-color-black fw-bold fs-8 align-items-center pe-3'
            )}
            role="button"
            tabIndex={0}
            onClick={() => this.handleNewActivity()}
            onKeyPress={() => this.handleNewActivity()}
          >
            <div
              className={classNames(
                'bg-white rounded-percentage-100 d-flex justify-content-center align-items-center fw-bold me-2'
              )}
              style={{
                width: '25px',
                height: '25px',
                boxShadow: '0px 0px 0 1px #000',
              }}
            >
              +
            </div>
            <div>{texts[locale].add_another_tip}</div>
          </div>
        </div>
      </div>
    )

    return html
  }

  render() {
    return (
      <div className={classNames('')}>
        <div className={classNames('bg-3')}>{this.drawRepeater()}</div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeDietTipsFieldComponent)
