import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeTestComparisonGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index_expanded: -1,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleButtonClick = index => {
    const { index_expanded } = this.state
    const index_updated = index_expanded === index ? -1 : index
    this.setState({ index_expanded: index_updated })
  }

  goTo = url => {
    if (typeof window !== 'undefined') {
      window.open(url, '_self')
    }
  }

  drawContent = () => {
    const { environment, specialization, data } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const { title, list } = data

    const html = []
    html.push(
      <div
        key="comparison_table"
        className={classNames('w-100 d-flex flex-column')}
      >
        <div className={classNames('w-100 d-flex')}>
          <div
            className={classNames(
              'w-md-20 d-none d-md-flex justify-content-center align-items-center bg-12 p-2 '
            )}
          >
            {title}
          </div>
          <div
            className={classNames(
              'w-23 w-md-20 py-3 d-flex justify-content-center align-items-center bg-health fc-white p-2 fs-7 fw-bold fst-uppercase text-center'
            )}
          >
            <div className={classNames('fs-9 fs-md-6')}>
              {texts[locale].health}
            </div>
          </div>
          <div
            className={classNames(
              'w-31 w-md-20 py-3 d-flex justify-content-center align-items-center bg-diet fc-white p-2 fs-7 fw-bold fst-uppercase text-center'
            )}
          >
            <div className={classNames('fs-9 fs-md-6')}>
              {texts[locale].slimming}
            </div>
          </div>
          <div
            className={classNames(
              'w-23 w-md-20 py-3 d-flex justify-content-center align-items-center bg-sport fc-white p-2 fs-7 fw-bold fst-uppercase text-center'
            )}
          >
            <div className={classNames('fs-9 fs-md-6')}>SPORT</div>
          </div>
          <div
            className={classNames(
              'w-23 w-md-20 py-3 d-flex justify-content-center align-items-center bg-fitness fc-white p-2 fs-7 fw-bold fst-uppercase text-center'
            )}
          >
            <div className={classNames('fs-9 fs-md-6')}>FITNESS</div>
          </div>
        </div>
        {list.map((item, index) => {
          const { feature, title_color_theme, flags, flag_colors_theme } =
            item || {}
          const v = flags.split('')
          const v_theme = flag_colors_theme.split(',')
          return (
            <div key={index} className={classNames('w-100 row mx-0')}>
              <div
                className={classNames(
                  `w-100 w-md-20  d-flex justify-content-center justify-content-md-start align-items-center px-3 py-2 bg-light-gray fs-7 fw-600 fc-${title_color_theme}`
                )}
              >
                {feature}
              </div>
              {v.map((flag, flag_index) => {
                let bg_color = ''
                switch (flag_index) {
                  case 0:
                    bg_color = 'bg-health-opacity-10'
                    break
                  case 1:
                    bg_color = 'bg-diet-opacity-10'
                    break
                  case 2:
                    bg_color = 'bg-sport-opacity-10'
                    break
                  case 3:
                    bg_color = 'bg-fitness-opacity-10'
                    break
                  default:
                    bg_color = ''
                    break
                }

                return (
                  <div
                    key={`${index}_${flag_index}`}
                    className={classNames(
                      flag_index === 1 ? 'w-31' : 'w-23',
                      'w-md-20 d-flex justify-content-center align-items-center p-2',
                      bg_color
                    )}
                  >
                    {flag === '1' && (
                      <div
                        className={classNames(
                          `background-image check-icon theme-svg-${v_theme[flag_index]}`
                        )}
                        style={{ width: 20, height: 20 }}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    )
    return html
  }

  render() {
    return <>{this.drawContent()}</>
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeTestComparisonGroup)
