/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import * as apiService from '../../../../../../src/services/apiService'
import JujoLoading from '../../../../../../src/components/loading'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeCourierStatus extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      data: [],
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const url = 'my/shipping?sort=-id&locale=it&page=1&per_page=1'
    const response = await apiService.httpGet(`${process.env.apiUrl}${url}`)
    if (response.status === 200) {
      this.setState({ data: response.data.data, initialized: true })
    }
  }

  render() {
    const { data, initialized } = this.state
    const { specialization, environment } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    return (
      <div className={classNames('pt-4')}>
        {!initialized && <JujoLoading />}
        {initialized && data.length === 0 && (
          <div className={classNames('pt-4 fst-italic fw-bold fc-1')}>
            {texts[locale].no_shippings_yet}
          </div>
        )}
        {initialized &&
          data.map(item => (
            <div key={item.id} className={classNames('row mb-4')}>
              <div
                className={classNames(
                  'px-0 py-1 d-flex justify-content-between align-items-center border-bottom border-2 border-dark'
                )}
              >
                <div className={classNames('fs-6 fw-bold')}>
                  {texts[locale].my_shipment_number} {item.id} -{' '}
                  {item.updated_at_formatted_day}
                </div>
                <div
                  className={classNames('fs-6 text-md-right')}
                  style={{ color: item.status_color }}
                >
                  {item.status_translated}
                </div>
              </div>
              <div className={classNames('row mt-3 fs-8 fc-4')}>
                <div className={classNames('col-md-6 px-0')}>
                  {item.info_title}
                </div>
                <div className={classNames('col-md-6 px-0')}>
                  {item.shipping_direction === 'to_home'
                    ? texts[locale].sender_address
                    : texts[locale].recipient_address}
                </div>
              </div>
              <div className={classNames('row mt-1 mb-3 fs-8 fw-bold')}>
                <div className={classNames('col-md-6 px-0 d-flex flex-column')}>
                  {item.info_description.map((element, index) => (
                    <div key={index}>{element}</div>
                  ))}
                </div>
                <div className={classNames('col-md-6 px-0')}>
                  {item.shipping_direction === 'to_home'
                    ? item.sender_address
                    : item.recipient_address}
                </div>
              </div>
              <div className={classNames('row mt-2 fs-8 fc-4')}>
                <div className={classNames('col-md-6 px-0')}>
                  {texts[locale].courier_service}
                </div>
                <div className={classNames('col-md-6 px-0')}>
                  {texts[locale].waybill}
                </div>
              </div>
              <div className={classNames('row mt-1 mb-3 fs-8 fw-bold')}>
                <div className={classNames('col-md-6 px-0')}>
                  {item.shipping_service_name}
                </div>
                <div className={classNames('col-md-6 px-0')}>
                  {item.waybill}
                </div>
              </div>
            </div>
          ))}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeCourierStatus)
