import {
  createMSBDef,
  getEmptyMessageBox,
} from '../../../../../../src/components/message_box/helper'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import * as apiService from '../../../../../../src/services/apiService'

import { injectComponent, retrieveComponent } from '../../../../../../src/utils'

function closeMessageBox(params) {
  const { sender, msbox_instance_id } = params
  retrieveComponent(sender, msbox_instance_id)
}

function closeMessageBoxAndNavigateToNewPage(params) {
  const { sender, msbox_instance_id, callback_url } = params
  const { emptyForm } = sender
  emptyForm()
  retrieveComponent(sender, msbox_instance_id)
  if (typeof window !== 'undefined') {
    window.location = callback_url
  }
}

function renderMessage(sender, message, icon, callback_function, callback_url) {
  const { props } = sender
  const { specialization, environment } = props

  const { translations } = specialization
  const { texts } = translations
  const { locale } = environment

  const messageBox = getEmptyMessageBox()
  messageBox.icon = icon
  messageBox.title = texts[locale].warning
  messageBox.message = message

  const ok_btn = createMSBDef(
    texts[locale].ok,
    callback_function,
    {
      sender,
      msbox_instance_id: messageBox.id,
      callback_url,
    },
    true
  )
  messageBox.buttons.push(ok_btn)

  const component_definition = {
    specialized: false,
    path: 'message_box',
    data: {
      messageBox,
    },
  }

  injectComponent(sender, messageBox.id, component_definition)
}

function verifyRequiredFields(sender) {
  const { props, state } = sender
  const { data, specialization, environment } = props
  const { fields } = data

  const { translations } = specialization
  const { texts } = translations
  const { locale } = environment

  const { dataSource } = state

  const missing_fields = []
  for (let i = 0; i !== fields.length; i += 1) {
    const f = fields[i]
    const { name, required } = f

    if (required && JSON.parse(required) === true) {
      if (
        dataSource[name] === undefined ||
        dataSource[name] === '' ||
        dataSource[name] === false
      ) {
        missing_fields.push(name)
      }
    }
  }

  if (missing_fields.length > 0) {
    let message = '<div class="fs-7">'
    for (let i = 0; i !== missing_fields.length; i += 1) {
      const field = missing_fields[i]
      const msg = `${texts[locale].the_field} ${
        texts[locale][field] || field
      }  ${texts[locale].is_required}`
      message += `<div style="text-transform: lowercase;">${msg}</div>`
    }
    message += '</div>'

    renderMessage(sender, message, 'warning-icon-color', closeMessageBox)
  }

  const validated = missing_fields.length === 0
  return validated
}

function composeSendMailRequest(sender, action) {
  const { props, state } = sender
  const { dataSource } = state

  const { environment, authentication } = props

  const requestData = baseRequestObject(
    apiCallMap.publicServerAction,
    'jujo_form',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: action,
    pDefValue: action,
  })

  const parsedEp = parseEndpoint(requestData)

  return {
    parsedEp,
    data: dataSource,
  }
}

function composeSubmitSignUpRequest(sender) {
  const { props, state } = sender
  const { dataSource } = state

  const { environment, authentication } = props

  const requestData = baseRequestObject(
    apiCallMap.publicServerAction,
    'user',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'sign-up',
    pDefValue: 'sign-up',
  })

  const parsedEp = parseEndpoint(requestData)

  return {
    parsedEp,
    data: dataSource,
  }
}

function manageResponse(sender, response) {
  const { props } = sender
  const { environment } = props
  const { status, data } = response
  const { message } = data
  const { locale } = environment

  if (status === 200) {
    renderMessage(
      sender,
      message,
      'confirm-icon-color',
      closeMessageBoxAndNavigateToNewPage,
      `/${locale}`
    )
  } else {
    let error_message = message

    if (data.fields) {
      error_message += '<div class="fs-7">'
      const kList = Object.keys(data.fields)
      for (let i = 0; i !== kList.length; i += 1) {
        const k = kList[i]
        const KMsgList = data.fields[k]
        const msg = KMsgList[0]
        error_message += `<div>${msg}</div>`
      }
      error_message += '</div>'
    }

    renderMessage(sender, error_message, 'warning-icon-color', closeMessageBox)
  }
}

export async function becomeDistributorSendMail(sender) {
  const validated = verifyRequiredFields(sender)

  if (!validated) return

  const reqObj = composeSendMailRequest(sender, 'become-distributor-send-mail')
  const { parsedEp, data } = reqObj
  console.log('reqObj', reqObj)
  const response = await apiService.httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    data,
    false
  )

  manageResponse(sender, response)
}

export async function submitSignUpForm(sender) {
  const reqObj = composeSubmitSignUpRequest(sender)
  const { parsedEp, data } = reqObj

  const response = await apiService.httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    data,
    false
  )

  manageResponse(sender, response)
}
