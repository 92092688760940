import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import workflowStepsMap from '../enums/workflow_steps_map'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'
import {
  cloneObj,
  mapStateToProps,
  scrollPageOnTop,
} from '../../../../../../src/utils'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import * as apiService from '../../../../../../src/services/apiService'

import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import paymentsTypesMap from '../enums/payments_types_map'

import VitaeSelectedProductsComponent from './_parts/selected_products'
import VitaeDiscountCodeComponent from './_parts/discount_code'
import VitaeSecurePaymentsComponent from './_parts/secure_payments'
import VitaeCompactAddressSelectorComponent from '../compact_address_selector'
import VitaeCompactPaymentMethodComponent from '../compact_payment_method'
import { getNextWorkflowStep } from './helper'
import workflowAccessPointMap from '../enums/workflow_access_point_map'
import JujoLoading from '../../../../../../src/components/loading'

import { getSessionClientData } from '../order_workflow/helper'
import JujoButtonComponent from '../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaeOrderSummaryComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pay_button_clicked: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  handlePayButtonClicked = async () => {
    this.setState({ pay_button_clicked: true })

    const { session, environment, authentication } = this.props
    const { session_data, session_id } = session
    const { cart } = session_data
    const { selected_payment_method } = cart

    if (selected_payment_method === paymentsTypesMap.paypal) {
      const requestData = baseRequestObject(
        apiCallMap.publicServerAction,
        paymentsTypesMap.paypal,
        apiRequestTypesMap.post,
        environment,
        authentication
      )

      requestData.placeholderMapping.push({
        pSource: 'static',
        pKey: '{action}',
        pValue: 'generate-payment-link',
        pDefValue: 'generate-payment-link',
      })

      const parsedEp = parseEndpoint(requestData)
      const response = await apiService.httpPost(
        `${process.env.apiUrl}${parsedEp}`,
        { session_id }
      )

      const { status } = response
      if (status === 200 || status === 201) {
        const { data } = response
        const { url } = data
        if (typeof window !== 'undefined') {
          window.location.href = url
        }
      }
    }
    if (selected_payment_method === paymentsTypesMap.bank_transfer) {
      if (typeof window !== 'undefined') {
        window.location.href = `/thank-you/?sessionId=${session_id}`
      }
    }
  }

  createOrderOnServer = async () => {
    const { session, environment, authentication } = this.props
    const { session_id } = session

    const requestData = baseRequestObject(
      apiCallMap.publicServerAction,
      'order',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'create',
      pDefValue: 'create',
    })

    const parsedEp = parseEndpoint(requestData)

    await apiService.httpPost(`${process.env.apiUrl}${parsedEp}`, {
      session_id,
    })
  }

  handleButtonClick = async () => {
    const { session, updateSession } = this.props

    const updated_session = cloneObj(session)

    const { session_id, session_data } = updated_session
    const { cart } = session_data
    const { totals } = cart

    const client_data = getSessionClientData(updated_session)
    const { order_workflow } = client_data
    const { current_step } = order_workflow
    const { total_quantity } = totals
    if (current_step === workflowStepsMap.shopping_cart && total_quantity > 0) {
      const next_step = getNextWorkflowStep(this)
      client_data.order_workflow.current_step = next_step
      const returned_data = await saveSessionToServer(session_id, session_data)

      updated_session.session_data = returned_data
      await updateSession(updated_session)
      scrollPageOnTop()
    }
    if (current_step === workflowStepsMap.payment) {
      this.handlePayButtonClicked()
    }
  }

  render() {
    const { pay_button_clicked } = this.state
    const { specialization, environment, session, authentication } = this.props

    const { user } = authentication
    const { role } = user

    const { session_data } = session
    const { cart } = session_data
    const { totals, user_data, selected_payment_method } = cart
    const { shipping } = user_data

    const client_data = getSessionClientData(session)
    const { order_workflow } = client_data
    const { current_step } = order_workflow
    const {
      total_quantity,
      total_products_gross_discounted,
      shipping_cost_gross,
      total_price,
      of_which_vat,
    } = totals

    const { translations } = specialization
    const { texts } = translations
    const { locale, path } = environment

    const blabel =
      current_step === workflowStepsMap.shopping_cart
        ? texts[locale].continue_with_purchase
        : texts[locale].pay.toUpperCase()

    let compact_workflow_mode = false
    let bDisabled = true

    if (current_step === workflowStepsMap.shopping_cart) {
      bDisabled = false
    }

    //  ██████╗ █████╗ ██████╗ ████████╗
    // ██╔════╝██╔══██╗██╔══██╗╚══██╔══╝
    // ██║     ███████║██████╔╝   ██║
    // ██║     ██╔══██║██╔══██╗   ██║
    // ╚██████╗██║  ██║██║  ██║   ██║
    //  ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝

    if (
      path.startsWith(workflowAccessPointMap.it_acquista) ||
      path.startsWith(workflowAccessPointMap.en_buy)
    ) {
      if (
        current_step === workflowStepsMap.payment &&
        selected_payment_method !== null
      ) {
        bDisabled = false
      }
    }

    // ███╗   ███╗██╗   ██╗     █████╗  ██████╗ ██████╗ ██████╗ ██╗   ██╗███╗   ██╗████████╗
    // ████╗ ████║╚██╗ ██╔╝    ██╔══██╗██╔════╝██╔════╝██╔═══██╗██║   ██║████╗  ██║╚══██╔══╝
    // ██╔████╔██║ ╚████╔╝     ███████║██║     ██║     ██║   ██║██║   ██║██╔██╗ ██║   ██║
    // ██║╚██╔╝██║  ╚██╔╝      ██╔══██║██║     ██║     ██║   ██║██║   ██║██║╚██╗██║   ██║
    // ██║ ╚═╝ ██║   ██║       ██║  ██║╚██████╗╚██████╗╚██████╔╝╚██████╔╝██║ ╚████║   ██║
    // ╚═╝     ╚═╝   ╚═╝       ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

    if (path.startsWith(workflowAccessPointMap.my_account_orders_new)) {
      if (current_step > workflowStepsMap.registration) {
        compact_workflow_mode = true
      }

      if (
        current_step > workflowStepsMap.registration &&
        shipping.existing.id > 0 &&
        selected_payment_method !== null
      ) {
        bDisabled = false
      }
    }

    return (
      <div className={classNames('bg-3 p-3')}>
        <div
          className={classNames(
            'fw-bold border-bottom border-2 border-color-black pt-3 pb-2'
          )}
        >
          {texts[locale].order_summary}
        </div>
        {total_quantity === 0 && (
          <div
            className={classNames(
              'd-flex justify-content-center border-bottom border-color-4 fw-bold fc-1 fst-italic py-4 fs-7'
            )}
          >
            {texts[locale].choose_genetic_test_now}
          </div>
        )}
        {total_quantity > 0 && <VitaeSelectedProductsComponent />}
        <div className={classNames('fw-bold p-2 border-bottom border-color-4')}>
          <div className={classNames('d-flex justify-content-between py-1')}>
            <div>{texts[locale].total_products}</div>
            <div>{`€ ${total_products_gross_discounted}`}</div>
          </div>
          <div className={classNames('d-flex justify-content-between py-1')}>
            <div>{texts[locale].shipping_costs}</div>
            <div>{`€ ${shipping_cost_gross}`}</div>
          </div>
        </div>
        <div className={classNames('p-2 d-flex flex-column')}>
          <div
            className={classNames(
              'd-flex justify-content-between py-1 fs-5 fw-bold'
            )}
          >
            <div className={classNames('fst-uppercase')}>
              {texts[locale].total}
            </div>
            <div>{`€ ${total_price}`}</div>
          </div>
          <div
            className={classNames('d-flex justify-content-between py-1 fs-7')}
          >
            <div>{texts[locale].of_which_vat}</div>
            <div>{`€ ${of_which_vat}`}</div>
          </div>
        </div>
        {(role === 'customer' || role === undefined) && (
          <VitaeDiscountCodeComponent />
        )}
        {compact_workflow_mode && (
          <div>
            <VitaeCompactAddressSelectorComponent />
            <VitaeCompactPaymentMethodComponent />
          </div>
        )}
        <div className={classNames('d-flex justify-content-center py-4')}>
          {pay_button_clicked === false && (
            <JujoButtonComponent
              bstyle={3}
              bwidth="210px"
              blabel={blabel}
              bDisabled={bDisabled}
              handleClick={this.handleButtonClick}
            />
          )}
          {pay_button_clicked === true && <JujoLoading />}
        </div>
        <VitaeSecurePaymentsComponent />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeOrderSummaryComponent)
