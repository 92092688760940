/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import Parser from 'html-react-parser'

import * as actionCreators from '../../../../../../src/store/actions'

import * as apiService from '../../../../../../src/services/apiService'
import FieldShell from '../../common_group/field_shell'
import ECDateFieldComponent from '../../../../../../src/components/common/fields/dateField'
import ECQuantitySelectorFieldComponent from '../../../../../../src/components/common/fields/quantitySelectorField'
import ECTextAreaFieldComponent from '../../../../../../src/components/common/fields/textAreaField'
import ECTextFieldComponent from '../../../../../../src/components/common/fields/textField'
import ECSelectFieldComponent from '../../../../../../src/components/common/fields/selectField'
import {
  createMSBDef,
  getEmptyMessageBox,
} from '../../../../../../src/components/message_box/helper'
import VitaePaymentMethodSelectorComponent from '../../common_group/payment_method_selector'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'

import TitleLineAndText from '../../common_group/title_line_and_text'
import {
  cloneObj,
  injectComponent,
  mapStateToProps,
  retrieveComponent,
  dateIsWeekend,
} from '../../../../../../src/utils'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import paymentsTypesMap from '../../checkout_group/enums/payments_types_map'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import VitaeCartContent from '../../common_group/cart_content'
import JujoLoading from '../../../../../../src/components/loading'

import { showMessageBox } from '../../common_group/message_box'
import JujoButtonComponent from '../../../../../../src/components/jujo_button'

const classNames = require('classnames')

const emptyFields = {
  waybill: {
    name: 'waybill',
    type: 'select',
    value: '',
  },
  preferably_at: {
    name: 'preferably_at',
    type: 'date',
    value: '',
  },
  swab_count: {
    name: 'swab_count',
    type: 'quantity_selector',
    value: 1,
    max_quantity: 10,
  },
  notes: {
    name: 'notes',
    type: 'test_area',
    value: '',
  },
}

export class VitaeCourierNew extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      customer_swab_check: false,
      customer_swab_id: '',
      waiting_paypal: false,
      waybills: [],
      had_waybills_already_used: false,
      fields: cloneObj(emptyFields),
      swab_matched: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  loadMyWaybills = async () => {
    const { customer_swab_id, fields } = this.state
    const data = { customer_swab_id }
    let url = 'my/shipping/available-waybills'
    let response = await apiService.httpPost(
      `${process.env.apiUrl}${url}`,
      data
    )
    if (response.status === 200) {
      let singleSelectedValue = ''
      const waybills = []
      response.data.data.map(shipping => {
        const obj = {
          label: shipping.waybill,
          value: shipping.waybill,
          sender_address: shipping.sender_address,
          sender_address_id: shipping.sender_address_id,
        }
        waybills.push(obj)
        singleSelectedValue =
          response.data.data.length === 1 ? shipping.waybill : ''
      })
      await this.setState({ waybills: [] })
      await this.setState(
        {
          waybills,
          initialized: true,
          fields: {
            ...fields,
            waybill: { value: singleSelectedValue },
          },
        },
        async () => {
          await this.updateSessionData()
        }
      )
    }

    url = 'my/shipping/had-waybills-already-used'
    response = await apiService.httpPost(`${process.env.apiUrl}${url}`, data)
    if (response.status === 200) {
      const { had_waybills_already_used } = response.data || false
      await this.setState({ had_waybills_already_used })
    }
  }

  checkMyMatchedSwab = async () => {
    const url = 'my/swab/have-matched-swab'
    const response = await apiService.httpGet(`${process.env.apiUrl}${url}`)
    if (response.status === 200) {
      const { swab_matched } = response.data
      await this.setState({ swab_matched })
    }
  }

  componentDidMount = async () => {
    await this.loadMyWaybills()
    await this.checkMyMatchedSwab()
  }

  handleValueChanged = async (fieldName, value) => {
    const { fields } = this.state
    const newFields = cloneObj(fields)
    newFields[fieldName].value = value
    await this.setState({ fields: newFields })
    await this.updateSessionData()
  }

  handleSwabChanged = (fieldName, value) => {
    this.setState({ customer_swab_id: value })
  }

  handleVerifySwab = async () => {
    const { specialization, environment } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { customer_swab_id } = this.state
    const data = {
      swab_id: customer_swab_id,
    }
    const url = 'my/shipping/verify-customer-swab'
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${url}`,
      data
    )
    if (response.status === 200) {
      this.setState({ customer_swab_check: true }, () => {
        this.loadMyWaybills()
      })
    } else {
      const messageBox = getEmptyMessageBox()
      messageBox.icon = 'warning-icon-color'
      messageBox.title = texts[locale].warning
      const ok_btn = createMSBDef(
        texts[locale].ok,
        this.closeMessageBox,
        {
          sender: this,
          msbox_instance_id: messageBox.id,
        },
        true
      )
      messageBox.message = texts[locale].courier_wrong_swab
      messageBox.buttons.push(ok_btn)
      const component_definition = {
        specialized: false,
        path: 'message_box',
        data: { messageBox },
      }
      injectComponent(this, messageBox.id, component_definition)
    }
  }

  updateSessionData = async () => {
    const { fields, waybills, customer_swab_id } = this.state
    const { waybill, preferably_at, swab_count, notes } = fields
    const { session, updateSession } = this.props
    const updated_session = cloneObj(session)
    updated_session.session_data.courier_service.waybill = waybill.value || ''
    updated_session.session_data.courier_service.preferably_at =
      preferably_at.value || ''
    updated_session.session_data.courier_service.swab_count =
      swab_count.value || 1
    updated_session.session_data.courier_service.notes = notes.value || ''
    updated_session.session_data.courier_service.customer_swab_id =
      customer_swab_id || ''

    const waybillSelected = waybills.filter(
      item => item.value === waybill.value
    )[0]
    if (waybillSelected) {
      updated_session.session_data.courier_service.sender_address_id =
        waybillSelected.sender_address_id
    }
    const { session_id, session_data } = updated_session
    updated_session.session_data = await saveSessionToServer(
      session_id,
      session_data
    )
    await updateSession(updated_session)
  }

  closeMessageBox = params => {
    const { sender, msbox_instance_id } = params
    retrieveComponent(sender, msbox_instance_id)

    if (typeof window !== 'undefined') {
      window.location.href = '/my-account/courier-service/status/'
    }
  }

  checkIfPreferablyAtIsWeekEnd = () => {
    const { session, environment, specialization } = this.props
    const { session_data } = session
    const { courier_service } = session_data
    const { preferably_at } = courier_service
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    if (preferably_at !== '') {
      if (dateIsWeekend(preferably_at)) {
        const messageObj = {
          data: { message: texts[locale].preferably_at_no_week_end_allowed },
        }
        showMessageBox(this, messageObj, 'error')
        return true
      }
    }
    return false
  }

  handleCallCourierButtonClick = async () => {
    const { specialization, environment, session } = this.props
    const { session_id } = session
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const data = { session_id }

    await this.updateSessionData()

    if (this.checkIfPreferablyAtIsWeekEnd()) {
      return
    }

    const url = 'my/shipping/call-courier'
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${url}`,
      data
    )
    const messageBox = getEmptyMessageBox()
    messageBox.title = texts[locale].shipping

    if (response.status === 200) {
      messageBox.icon = 'confirm-icon-color'
      messageBox.buttons.push(
        createMSBDef(
          'Ok',
          this.closeMessageBox,
          {
            sender: this,
            msbox_instance_id: messageBox.id,
          },
          true
        )
      )
      messageBox.title = texts[locale].shipping_done_successfully_title
      messageBox.message = texts[locale].shipping_done_successfully_text
      this.setState({ fields: cloneObj(emptyFields) })
    } else {
      messageBox.icon = 'warning-icon-color'
      messageBox.message = `${texts[locale].shipping_failed}<br />${response.data.message}`
      messageBox.buttons.push(
        createMSBDef(
          'Ok',
          this.closeMessageBox,
          {
            sender: this,
            msbox_instance_id: messageBox.id,
          },
          true
        )
      )
    }

    const component_definition = {
      specialized: false,
      path: 'message_box',
      data: {
        messageBox,
      },
    }

    injectComponent(this, messageBox.id, component_definition)
  }

  handlePayButtonClicked = async () => {
    const { session, environment, authentication } = this.props
    const { session_data, session_id } = session
    const { courier_service } = session_data
    const { selected_payment_method } = courier_service

    await this.updateSessionData()

    if (this.checkIfPreferablyAtIsWeekEnd()) {
      return
    }

    if (selected_payment_method === paymentsTypesMap.paypal) {
      await this.setState({ waiting_paypal: true })
      const requestData = baseRequestObject(
        apiCallMap.publicServerAction,
        paymentsTypesMap.paypal,
        apiRequestTypesMap.post,
        environment,
        authentication
      )

      requestData.placeholderMapping.push({
        pSource: 'static',
        pKey: '{action}',
        pValue: 'generate-payment-link',
        pDefValue: 'generate-payment-link',
      })

      const parsedEp = parseEndpoint(requestData)
      const response = await apiService.httpPost(
        `${process.env.apiUrl}${parsedEp}`,
        { session_id }
      )

      const { status } = response || { status: 400 }
      if (status === 200 || status === 201) {
        const { data } = response
        const { url } = data
        if (typeof window !== 'undefined') {
          window.location.href = url
        }
      }
    }
    if (selected_payment_method === paymentsTypesMap.bank_transfer) {
      if (typeof window !== 'undefined') {
        window.location.href = `/thank-you/?sessionId=${session_id}`
      }
    }
  }

  render() {
    const {
      fields,
      waybills,
      initialized,
      waiting_paypal,
      customer_swab_check,
      swab_matched,
      had_waybills_already_used,
    } = this.state
    const { specialization, environment, session, authentication } = this.props

    const { user } = authentication
    const { role } = user

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const { preferably_at, swab_count, notes, waybill } = fields
    const waybills_available = waybills.length >= 1
    const waybillField = {
      name: 'waybill',
      options: waybills,
    }
    const { session_data } = session
    const { courier_service } = session_data
    const { selected_payment_method, pay_courier } = courier_service || {}
    let waybillSelected = waybills.filter(item => item.value === waybill.value)
    waybillSelected =
      waybillSelected && waybillSelected.length > 0 ? waybillSelected[0] : false
    const { sender_address, value: waybillSelectedValue } =
      waybillSelected || {}
    let callCourierDescription = texts[locale].call_courier_description
    callCourierDescription = callCourierDescription
      .replace('[waybill]', `<strong>${waybillSelectedValue}</strong>`)
      .replace('[swabs]', `<strong>${swab_count.value}</strong>`)

    const my_new_shipment_with_waybill_description =
      texts[locale].my_new_shipment_with_waybill_description_1 +
      (customer_swab_check === true || role !== 'customer'
        ? `<br />${texts[locale].my_new_shipment_with_waybill_description_2}`
        : '')

    return (
      <>
        {!initialized && <JujoLoading />}
        {initialized && swab_matched === 'no' && (
          <div className={classNames('pt-4 d-flex flex-column fc-8')}>
            {texts[locale].swab_match_is_needed}
          </div>
        )}
        {initialized && swab_matched === 'yes' && (
          <div className={classNames('pt-4 d-flex flex-column')}>
            {/* Nuova spedizione con lettera di vettura */}
            <div>
              <TitleLineAndText
                title={texts[locale].my_new_shipment_with_waybill}
                text={my_new_shipment_with_waybill_description}
              />
              <div
                className={classNames(
                  'mt-3 row m-0 d-flex flex-row align-items-center'
                )}
              >
                {initialized &&
                  !waybills_available &&
                  (role !== 'customer' ||
                    (role === 'customer' && !had_waybills_already_used)) && (
                    <div
                      className={classNames(
                        'my-3 fc-8 fst-italic fw-bold col-12 p-0'
                      )}
                    >
                      {`${texts[locale].my_no_waybills_available}!`}
                    </div>
                  )}
                {initialized &&
                  !waybills_available &&
                  role === 'customer' &&
                  had_waybills_already_used && (
                    <>
                      <div className={classNames('my-3 fw-bold col-12 p-0')}>
                        {Parser(texts[locale].my_courier_already_call_courier)}
                      </div>
                      <div className={classNames('mt-3 col-12 p-0')}>
                        {Parser(texts[locale].my_courier_didnt_pass)}
                      </div>
                      {/* <div className={classNames('fw-bold col-12 p-0')}>
                        <a
                          href="https://www.sda.it/wps/portal/Servizi_online/gestione-ritiri/Prenotazione%20Ritiri?locale=it"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://www.sda.it/wps/portal/Servizi_online/gestione-ritiri/Prenotazione%20Ritiri?locale=it
                        </a>
                      </div>
                      <div
                        className={classNames(
                          'mt-4 fs-8 col-12 p-3 border border-1 border-dark rounded-1'
                        )}
                      >
                        {Parser(texts[locale].my_courier_instructions)}
                      </div> */}
                    </>
                  )}

                {initialized &&
                  waybills_available &&
                  customer_swab_check === false &&
                  role === 'customer' && (
                    <>
                      <div
                        className={classNames('row align-items-center gy-3')}
                      >
                        <div
                          className={classNames('col-12 col-md-6')}
                          style={{
                            backgroundImage: `url(/${process.env.client}/run-test/kit.png`,
                            height: '150px',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                          }}
                        />
                        <div className={classNames('col-12 col-md-6')}>
                          <div className={classNames('fw-bold')}>
                            {texts[locale].courier_insert_swab_title}
                          </div>
                          <div
                            className={classNames('fst-italic fs-7')}
                            dangerouslySetInnerHTML={{
                              __html:
                                texts[locale].courier_insert_swab_description,
                            }}
                          />
                          <div
                            className={classNames(
                              'mt-3 d-flex flex-row align-items-end justify-content-between'
                            )}
                          >
                            <div className={classNames('me-3')}>
                              <FieldShell fieldName="swab_code" withBorder>
                                <ECTextFieldComponent
                                  initialValue=""
                                  field={notes}
                                  handleValueChanged={this.handleSwabChanged}
                                />
                              </FieldShell>
                            </div>
                            <div>
                              <JujoButtonComponent
                                bstyle={4}
                                bwidth="120px"
                                bheight="37px"
                                blabel={texts[locale].verify_swab}
                                bFontClass="fw-bold fst-uppercase"
                                bclasses="fc-1 border-color-1"
                                handleClick={this.handleVerifySwab}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {initialized &&
                  waybills_available &&
                  (customer_swab_check === true || role !== 'customer') && (
                    <>
                      <div
                        key="waybills"
                        className={classNames('my-3 col-6 p-0 pe-5')}
                      >
                        <FieldShell fieldName="waybill" withBorder>
                          <ECSelectFieldComponent
                            field={waybillField}
                            handleValueChanged={this.handleValueChanged}
                            initialValue={waybill.value}
                          />
                        </FieldShell>
                      </div>
                      <div
                        key={preferably_at.name}
                        className={classNames('my-3 col-6 p-0 pe-5 lh-1')}
                      >
                        <span
                          className={classNames(
                            'fc-1 fs-8 fw-bold fst-italic me-1'
                          )}
                        >
                          {texts[locale].nb}:
                        </span>
                        <span
                          className={classNames('fs-8 fw-bold  fst-italic')}
                        >
                          {texts[locale].integrates_swabs_into_envelope}
                        </span>
                      </div>
                    </>
                  )}
              </div>
              {sender_address && (
                <div
                  className={classNames(
                    'border rounded-4 border-2 border-color-1 p-3'
                  )}
                >
                  <div className={classNames('fc-1 fst-italic fs-8')}>
                    {texts[locale].my_waybill_has_this_sender_address}
                  </div>
                  <div className={classNames('fw-bold fst-italic fs-7')}>
                    {sender_address}
                  </div>
                </div>
              )}
            </div>
            {/* Informazioni di spedizione */}
            {waybills_available &&
              waybillSelected &&
              (customer_swab_check === true || role !== 'customer') &&
              waybillSelected.value !== '' && (
                <div className={classNames('my-4')}>
                  <TitleLineAndText
                    title={texts[locale].my_new_shipment_informations}
                    text={
                      texts[locale].my_new_shipment_informations_description
                    }
                    parentClasses="mt-1"
                  />
                  <div
                    className={classNames(
                      'mt-3 row m-0 d-flex flex-row align-items-center'
                    )}
                  >
                    <div
                      key={preferably_at.name}
                      className={classNames('my-3 col-6 p-0 pe-5')}
                    >
                      <FieldShell fieldName="preferably_at" withBorder>
                        <ECDateFieldComponent
                          initialValue={preferably_at.value}
                          field={preferably_at}
                          handleValueChanged={this.handleValueChanged}
                        />
                      </FieldShell>
                    </div>
                    <div
                      key={swab_count.name}
                      className={classNames('my-3 col-6 p-0')}
                    >
                      <FieldShell fieldName="swab_count_to_send">
                        <ECQuantitySelectorFieldComponent
                          field={swab_count}
                          initialValue={swab_count.value}
                          handleValueChanged={this.handleValueChanged}
                        />
                      </FieldShell>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      'row m-0 d-flex flex-row align-items-center'
                    )}
                  >
                    <div
                      key={swab_count.name}
                      className={classNames('my-3 col-12 p-0')}
                    >
                      <FieldShell fieldName="courier_notes" withBorder>
                        <ECTextAreaFieldComponent
                          initialValue={notes.value}
                          field={notes}
                          handleValueChanged={this.handleValueChanged}
                        />
                      </FieldShell>
                    </div>
                  </div>
                </div>
              )}
            {waybillSelected &&
              waybillSelected.value !== '' &&
              (customer_swab_check === true || role !== 'customer') &&
              !pay_courier && (
                <>
                  <TitleLineAndText
                    title={texts[locale].call_courier}
                    text={callCourierDescription}
                    parentClasses="mt-3"
                  />
                  <div
                    className={classNames(
                      'w-100 d-flex flex-column align-items-center mt-4'
                    )}
                  >
                    <JujoButtonComponent
                      bstyle={3}
                      bwidth="210px"
                      blabel={texts[locale].call_courier}
                      bicon="truck-icon"
                      bFontClass="fw-bold"
                      bdata=""
                      handleClick={this.handleCallCourierButtonClick}
                    />
                  </div>
                </>
              )}
            {waybillSelected && waybillSelected.value !== '' && pay_courier && (
              <>
                <VitaePaymentMethodSelectorComponent />
                <div
                  className={classNames(
                    'w-100 d-flex flex-column align-items-center'
                  )}
                >
                  <VitaeCartContent customClass="mb-4" />
                  {!waiting_paypal && (
                    <JujoButtonComponent
                      bstyle={3}
                      bwidth="280px"
                      blabel={texts[locale].call_courier}
                      bicon="truck-icon"
                      bFontClass="fw-bold"
                      bDisabled={
                        selected_payment_method === null ||
                        selected_payment_method === ''
                      }
                      handleClick={this.handlePayButtonClicked}
                    />
                  )}
                  {waiting_paypal && <JujoLoading />}
                </div>
              </>
            )}
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeCourierNew)
