/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'
import MyGradientBarComponent from './my_gradient_bar'

const classNames = require('classnames')

export class MyDetailsPopupComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const {
      handlCloseDetailsPopup,
      holistic,
      ui_elements,
      holistic_icon_class,
      specialization,
      environment,
      genericData,
    } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const {
      interpretation,
      face_icon,
      arrow_position,
      interpretation_text_color,
    } = ui_elements
    const { holistic_id } = interpretation
    const { title, description, genes, deepening } = holistic
    const holistic_title_translated = title ? title[locale] : ''

    const holistic_description_translated = description
      ? description[locale]
      : ''
    const holistic_genes_translated = genes ? genes[locale] : ''
    const holistic_deepening_translated = deepening ? deepening[locale] : ''
    const { array_polymorphisms_names, array_polymorphisms_values } =
      interpretation

    const { myResults } = genericData
    const { swab } = myResults
    const { product_id } = swab || false
    const results_font_color = product_id === 4 ? 'fc-11' : 'fc-1'

    if (!array_polymorphisms_names || array_polymorphisms_names.lengh === 0) {
      return <></>
    }

    return (
      <>
        <div
          className={classNames(
            'position-fixed top-0 start-0 bg-black-opacity-2 zindex-1'
          )}
          style={{
            height: '100vh',
            width: '100vw',
            backgroundBlendMode: 'multiply',
            mixBlendMode: 'multiply',
          }}
        />
        <div
          className={classNames(
            'bg-3 position-fixed top-50 start-50 translate-middle zindex-1 rounded-30 shadow'
          )}
          style={{ width: 'min(90%, 800px)', height: 'min(90%, 800px)' }}
        >
          <div className={classNames('d-flex flex-column p-3 h-100')}>
            <div
              className={classNames(
                'close-icon theme-svg-4 d-flex mb-2 align-self-end'
              )}
              style={{
                height: '35px',
                width: '35px',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
              role="button"
              tabIndex={0}
              label="close"
              onClick={() => {
                handlCloseDetailsPopup()
              }}
              onKeyPress={() => {
                handlCloseDetailsPopup()
              }}
            />
            <div
              className={classNames(
                'd-flex flex-column overflow-auto px-1 px-md-3'
              )}
            >
              <div className={classNames('d-flex align-items-center')}>
                <div
                  className={classNames(
                    `holistic-${holistic_id}-${holistic_icon_class}-icon me-3`
                  )}
                  style={{
                    width: '40px',
                    height: '40px',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
                <div className={classNames('fw-bold fs-5', results_font_color)}>
                  {holistic_title_translated}
                </div>
              </div>
              {holistic_description_translated &&
                holistic_description_translated !== null &&
                holistic_description_translated !== 'null' &&
                holistic_description_translated !== '' && (
                  <>
                    <div
                      className={classNames(
                        'fs-7 fw-bold border-bottom border-2 border-dark mt-4 mb-2 pb-2'
                      )}
                    >
                      {texts[locale].description}
                    </div>

                    <div
                      className={classNames('fs-7 mb-2')}
                      style={{ whiteSpace: 'pre-wrap' }}
                      dangerouslySetInnerHTML={{
                        __html: holistic_description_translated,
                      }}
                    />
                  </>
                )}
              {holistic_genes_translated &&
                holistic_genes_translated !== null &&
                holistic_genes_translated !== 'null' &&
                holistic_genes_translated !== '' && (
                  <div
                    className={classNames(
                      'd-flex mt-3 pt-4 border-2 border-top'
                    )}
                  >
                    <div
                      className={classNames(`gene-${holistic_icon_class}-icon`)}
                      style={{
                        width: '40px',
                        height: '40px',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                    <div className={classNames('ms-3')}>
                      <div
                        className={classNames('fw-bold', results_font_color)}
                      >
                        {`${texts[locale].gene} :`}
                      </div>
                      <div
                        className={classNames('fs-7')}
                        dangerouslySetInnerHTML={{
                          __html: holistic_genes_translated,
                        }}
                      />
                    </div>
                  </div>
                )}

              <div
                className={classNames('d-flex flex-column flex-md-row mt-5')}
              >
                <div className={classNames('d-flex flex-column me-md-5')}>
                  <div className={classNames('fw-bold', results_font_color)}>
                    {`${texts[locale].polymorphism} :`}
                  </div>
                  <div className={classNames('fs-7')}>
                    {array_polymorphisms_names.map(polymorphism => (
                      <div key={polymorphism}>{polymorphism}</div>
                    ))}
                  </div>
                </div>
                <div className={classNames('d-flex flex-column my-3 my-md-0')}>
                  <div className={classNames('fw-bold', results_font_color)}>
                    {`${texts[locale].genotype} :`}
                  </div>
                  <div className={classNames('fs-7')}>
                    {array_polymorphisms_values.map((value, index) => (
                      <div key={`genotype_${index}`}>{value}</div>
                    ))}
                  </div>
                </div>
                {/* Gradient Bar */}
                <div
                  className={classNames(
                    'ms-md-auto align-self-center align-self-md-end'
                  )}
                >
                  {holistic.gradient_bar &&
                    holistic.gradient_bar.show === true && (
                      <MyGradientBarComponent
                        arrow_position={arrow_position}
                        face_icon={face_icon}
                        holistic={holistic}
                      />
                    )}
                  {!holistic.gradient_bar && face_icon && (
                    <div
                      className={classNames(face_icon)}
                      style={{
                        width: '27px',
                        height: '27px',
                        marginRight: '80px',
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={classNames('d-flex flex-column me-md-5 mt-4')}>
                <div className={classNames('fw-bold', results_font_color)}>
                  {`${texts[locale].interpretation} :`}
                </div>
                <div className={classNames('fs-7', interpretation_text_color)}>
                  {interpretation.interpretation[locale]}
                </div>
              </div>
              <div className={classNames('d-flex flex-column me-md-5 mt-4')}>
                <div className={classNames('fw-bold', results_font_color)}>
                  {`${texts[locale].my_tip} :`}
                </div>
                <div
                  className={classNames('fs-7')}
                  style={{ whiteSpace: 'pre-wrap' }}
                  dangerouslySetInnerHTML={{
                    __html: interpretation.tips[locale].replaceAll(
                      '[BULLET]',
                      '&bull;'
                    ),
                  }}
                />
              </div>
              {holistic_deepening_translated &&
                holistic_deepening_translated !== null &&
                holistic_deepening_translated !== 'null' &&
                holistic_deepening_translated !== '' && (
                  <div
                    className={classNames('d-flex flex-column me-md-5 mt-4')}
                  >
                    <div className={classNames('fw-bold', results_font_color)}>
                      {`${texts[locale].deepening} :`}
                    </div>
                    <div className={classNames('fs-7')}>
                      <div
                        className={classNames('fs-7')}
                        style={{ whiteSpace: 'pre-wrap' }}
                        dangerouslySetInnerHTML={{
                          __html: holistic_deepening_translated,
                        }}
                      />
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MyDetailsPopupComponent)
