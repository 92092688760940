import lsEntryMap from '../../../enums/lsEntryMap'
import { lsGet, lsSet } from '../../../services/localStorage'
import initialState from '../../reducers/authentication/initialState'

export function reloadOrInitAuthenticationIntoRedux() {
  return async dispatch => {
    const authentication = lsGet(lsEntryMap.authentication) || initialState

    authentication.force_login = false

    dispatch({
      type: 'UPDATE_AUTHENTICATION',
      authentication,
    })
  }
}

export function resetAuthentication() {
  return async dispatch => {
    const authentication = initialState
    lsSet(lsEntryMap.authentication, authentication)

    dispatch({
      type: 'UPDATE_AUTHENTICATION',
      authentication,
    })
  }
}

export function updateAuthentication(authentication) {
  return async dispatch => {
    lsSet(lsEntryMap.authentication, authentication)

    dispatch({
      type: 'UPDATE_AUTHENTICATION',
      authentication,
    })
  }
}
