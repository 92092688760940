import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps, processLogout } from '../../../../../../src/utils'
import VitaeCartIcon from '../cart_icon'

const classNames = require('classnames')

export class VitaeMyAccountHead extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  //M@t window.location.href = 'https://www.vitaedna.com/'
  //M@t 
  //M@t 
  handleGoBackToSiteClick = () => {
    if (typeof window !== 'undefined') {
      window.location.href = 'https://www.vitaedna.com/'
    }
  }

  render() {
    const { authentication, specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { user } = authentication
    const { name } = user

    return (
      <div
        className={classNames(
          'shadow zindex-2 g-column-1-1 position-fixed bg-white'
        )}
        style={{ width: '100%' }}
      >
        <iframe
          title="googletagmanager"
          src="https://www.googletagmanager.com/ns.html?id=GTM-M5G4BGT"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
        <div
          className={classNames(
            'container d-flex flex-row p-2 justify-content-between align-items-center'
          )}
        >
          <div
            className={classNames('logo d-flex')}
            label="logo"
            style={{
              height: '40px',
              width: '110px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
          />

          <div className={classNames('d-flex align-items-center')}>
            {name && (
              <div className={classNames('d-flex align-items-center')}>
                <div
                  className={classNames(
                    'ffamily-secondary fc-gray d-none d-md-flex fst-uppercase'
                  )}
                  style={{ lineHeight: 'normal' }}
                >
                  {name}
                </div>
                <div
                  className={classNames('logout-icon-2 gray-svg ms-1')}
                  style={{
                    width: '20px',
                    height: '20px',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                  label="logout"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    processLogout(this)
                  }}
                  onKeyPress={() => {
                    processLogout(this)
                  }}
                />
              </div>
            )}
            <VitaeCartIcon />
            <div
              className={classNames(
                'bg-1 bg1-gradient-270deg fc-white p-2 text-center rounded-50 ms-4'
              )}
              style={{ width: '130px' }}
              role="button"
              tabIndex={0}
              onClick={() => {
                this.handleGoBackToSiteClick()
              }}
              onKeyPress={() => {
                this.handleGoBackToSiteClick()
              }}
            >
              {texts[locale].back_to_the_site}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeMyAccountHead)
