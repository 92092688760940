/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'
import VitaeCookiebotComponent from './_parts/cookiebot'

const classNames = require('classnames')

export class VitaeFooter extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const privacy_policy_url = `${process.env.site_url + locale}/${
      texts[locale].privacy_policy_url
    }`
    const cookie_policy_url = `${process.env.site_url + locale}/${
      texts[locale].cookie_policy_url
    }`

    return (
      <div
        className={classNames('bg-1 g-column-1-1')}
        style={{ width: '100%' }}
      >
        <div className={classNames('container my-4')}>
          <div
            className={classNames(
              'd-flex flex-column flex-md-row justify-content-between align-items-center'
            )}
          >
            <div
              className={classNames('logo-white')}
              label="logo"
              style={{
                height: '40px',
                width: '110px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
              }}
            />
            <div className={classNames('d-flex my-3 my-md-0')}>
              <div
                className={classNames('facebook-icon-2 white-svg')}
                label="logo"
                style={{
                  height: '20px',
                  width: '20px',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }}
              />
              <div
                className={classNames('instagram-icon white-svg ms-2')}
                label="logo"
                style={{
                  height: '20px',
                  width: '20px',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }}
              />
            </div>
          </div>
          <div
            className={classNames(
              'fc-white fs-8 mt-4 text-center text-md-start'
            )}
          >
            <div>{process.env.companyName} </div>
            <div>{`${texts[locale].vat_fc} ${process.env.vatNumber}`}</div>
            <div>
              {`${texts[locale].registered_office}: ${process.env.registeredOffice}`}
            </div>
            <div className={classNames('d-flex flex-row')}>
              <div style={{ width: 100 }}>
                <a
                  href={privacy_policy_url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  {texts[locale].privacy_policy}
                </a>
              </div>
              <div style={{ width: 100 }}>
                <a
                  href={cookie_policy_url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  {texts[locale].cookie_policy}
                </a>
              </div>
              <VitaeCookiebotComponent />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeFooter)
