/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'

const classNames = require('classnames')

export class MyGradientBarComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { arrow_position, face_icon, holistic, environment } = this.props
    const { locale } = environment

    return (
      <div
        className={classNames(
          'd-flex flex-column align-items-center justify-content-between'
        )}
        style={{ width: '234px' }}
      >
        <div
          className={classNames(
            'd-flex flex-row align-items-center justify-content-start w-100'
          )}
        >
          <div style={{ width: '30px' }} />
          <div
            className={classNames('gradient-arrow')}
            style={{
              width: '15px',
              height: '18px',
              position: 'relative',
              left: `${arrow_position}px`,
            }}
          />
        </div>
        <div
          className={classNames(
            'd-flex flex-row align-items-center justify-content-between w-100'
          )}
        >
          <div
            className={classNames(face_icon)}
            style={{ width: '27px', height: '27px' }}
          />
          <div
            className={classNames('gradient-bar')}
            style={{ width: '200px', height: '21px' }}
          />
        </div>
        <div
          className={classNames(
            'd-flex flex-row align-items-center justify-content-start w-100'
          )}
        >
          <div style={{ width: '39px' }} />
          <div
            className={classNames(
              'fs-9 d-flex flex-row align-items-center justify-content-between w-100'
            )}
          >
            <div>
              {holistic.gradient_bar &&
                holistic.gradient_bar.lower_label &&
                holistic.gradient_bar.lower_label[locale]}
            </div>
            <div>
              {holistic.gradient_bar &&
                holistic.gradient_bar.upper_label &&
                holistic.gradient_bar.upper_label[locale]}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MyGradientBarComponent)
