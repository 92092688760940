import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import * as actionCreators from '../../../../store/actions'
import { injectComponent, mapStateToProps, translate } from '../../../../utils'
import JujoLoading from '../../../loading'

import * as functionHandler from '../functionHandler'

const classNames = require('classnames')

export class GridActionComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      is_working: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  componentDidUpdate = async () => {}

  executeAction = async () => {
    this.setState({ is_working: true })
    const { action } = this.props

    const { handler, specialized, actionPath, actionName } = action

    if (specialized) {
      const specialized_actions =
        await require(`../../../../../vitae_specializations/src/${process.env.client}/${actionPath}`)

      await specialized_actions[actionName](this)
    } else {
      await functionHandler[handler](this)
    }

    this.setState({ is_working: false })
  }

  handleButtonClick = async () => {
    const { action } = this.props
    const { confirmation } = action

    if (confirmation === undefined) {
      this.executeAction()
    } else {
      const { message } = confirmation

      const comp_id = uuid()
      const component_definition = {
        specialized: false,
        path: 'common/confirmation_box',
        data: {
          message,
          executeAction: this.executeAction,
        },
      }
      injectComponent(this, comp_id, component_definition)
    }
  }

  render() {
    const { is_working } = this.state
    const { action } = this.props

    const { icon, label } = action

    return (
      <>
        {is_working === true && (
          <div
            className={classNames('shadow p-1 rounded bg-light fs-7')}
            style={{ width: '200px' }}
          >
            <JujoLoading />
          </div>
        )}
        {is_working === false && (
          <div
            className={classNames(
              'btn px-2 py-1 my-1 mx-1 d-flex justify-content-center align-items-center shadow-sm fs-7 w-100 w-md-unset'
            )}
            style={{ minWidth: '200px' }}
            role="button"
            tabIndex={0}
            onClick={async () => {
              await this.handleButtonClick()
            }}
            onKeyPress={async () => {
              await this.handleButtonClick()
            }}
          >
            <div
              className={classNames('theme-svg', icon)}
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '25px',
                height: '25px',
                backgroundSize: '25px',
              }}
              label={label}
            />
            <div className={classNames('ms-1')}>{translate(label)}</div>
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(GridActionComponent)
