import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'
import { handleAddQty } from './helper'

const classNames = require('classnames')

export class VitaeQuantitySelectorComponent extends React.Component {
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { quantity, max_quantity, pID } = this.props
    return (
      <div className={classNames('d-flex align-items-center')}>
        <div
          className={classNames('minus-circle-icon theme-svg')}
          style={{
            height: '22px',
            width: '22px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: quantity === 0 ? '0.3' : '1',
          }}
          label="decrease quantity"
          role="button"
          tabIndex={0}
          // eslint-disable-next-line no-loop-func
          onClick={async () => {
            if (quantity === 0) return
            await handleAddQty(this, pID, -1)
          }}
          // eslint-disable-next-line no-loop-func
          onKeyPress={async () => {
            if (quantity === 0) return
            await handleAddQty(this, pID, -1)
          }}
        />
        <div className={classNames('fw-bold fc-black mx-2 fs-5')}>
          {quantity}
        </div>
        <div
          className={classNames('plus-circle-icon theme-svg')}
          style={{
            height: '22px',
            width: '22px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: max_quantity === 0 ? '0.3' : '1',
          }}
          label="increase quantity"
          role="button"
          tabIndex={0}
          // eslint-disable-next-line no-loop-func
          onClick={async () => {
            if (max_quantity === 0) return
            await handleAddQty(this, pID, 1)
          }}
          // eslint-disable-next-line no-loop-func
          onKeyPress={async () => {
            if (max_quantity === 0) return
            await handleAddQty(this, pID, 1)
          }}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeQuantitySelectorComponent)
