/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import {
  retrieveEntityValue,
  verifyFieldConditions,
} from '../../../../../../src/components/editable_component/helper'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaePersonalDataViewComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: [],
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { c_def, specialization } = this.props
    const { config } = specialization

    const entity = retrieveEntityValue(this.props)

    const { entities } = config
    const entityDefinition = entities[entity][c_def.comp]

    const { fields } = entityDefinition
    const { view } = fields

    this.setState({ fields: view })
  }

  render() {
    const { fields } = this.state
    const { specialization, environment, data } = this.props

    const pData = data[0]
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div className={classNames('row bg-3 p-3')}>
        {fields?.map(field => {
          const { name, alias, conditions } = field
          const condition_satisfied = verifyFieldConditions(
            this,
            conditions,
            pData
          )
          if (condition_satisfied) {
            return (
              <div key={name} className={classNames('col-md-6 my-2')}>
                <div className={classNames('ffamily-secondary fs-9 fc-gray')}>
                  {texts[locale][alias] || texts[locale][name]}
                </div>
                <div className={classNames('fw-bold')}>{pData[name]}</div>
              </div>
            )
          }
        })}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaePersonalDataViewComponent)
