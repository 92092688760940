/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../../../../../src/utils'

import * as actionCreators from '../../../../../../src/store/actions'
import JujoGrid from '../../../../../../src/components/jujo_grid'

export class PaymentComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    return <JujoGrid entity="payment" />
  }
}

export default connect(mapStateToProps, actionCreators)(PaymentComponent)
