import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeEatingHabitsFieldsGroupComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const {
      specialization,
      environment,
      field,
      handleValueChanged,
      dataSource,
    } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { fields } = field
    return (
      <>
        <div className={classNames('my-2')}>
          <div className={classNames('d-flex align-items-end mb-2')}>
            <div
              className={classNames('fork-spoon-icon')}
              style={{
                width: '36px',
                height: '36px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
              }}
            />
            <div
              className={classNames('fs-7 fw-bold ms-2')}
              style={{ lineHeight: '14px' }}
            >
              <div>{texts[locale].eating_habits_instruction_1}</div>
              <div>{texts[locale].eating_habits_instruction_2}</div>
            </div>
          </div>
          <div className={classNames('fs-8 fc-4 fst-italic')}>
            {texts[locale].eating_habits_instruction_3}
          </div>
        </div>
        <div className={classNames('bg-3 row p-2 mt-4 mx-0')}>
          {fields.map((f, idx) => {
            const { name, description, required } = f
            let label_to_show = `${texts[locale].meal} ${idx + 1} -`
            label_to_show = required ? `* ${label_to_show}` : label_to_show
            return (
              <div key={name} className={classNames('col-12 col-md-6 my-2')}>
                <div className={classNames('d-flex fw-bold')}>
                  <div className={classNames('me-1')}>{label_to_show}</div>
                  <div className={classNames('fc-1')}>
                    {texts[locale][name] || name}
                  </div>
                </div>
                <div
                  className={classNames('fs-8 fc-4 fst-italic my-1')}
                  style={{ height: '40px' }}
                >
                  {texts[locale][description] || description}
                </div>
                <div>
                  <textarea
                    className={classNames(
                      'border border-color-4 rounded-3 p-2 bg-white'
                    )}
                    name={name}
                    rows={3}
                    placeholder={`${texts[locale].meal} ${idx + 1}`}
                    defaultValue={dataSource[name]}
                    onChange={e => {
                      const { value } = e.target
                      handleValueChanged(name, value)
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeEatingHabitsFieldsGroupComponent)
