import React from 'react'
import { connect } from 'react-redux'
import Parser from 'html-react-parser'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaePageHeadComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { specialization, environment, authentication, c_def, session } =
      this.props

    const { description, badge_description } = c_def

    const { translations } = specialization
    const { texts } = translations
    const { locale, path } = environment
    const { session_data } = session
    const { menu } = session_data

    const { user } = authentication
    const { name } = user

    let show_badge_description = false
    if (menu) {
      for (let i = 0; i < menu.length; i += 1) {
        if (menu[i].url === path && menu[i].badge_amount > 0) {
          show_badge_description = true
          break
        }
      }
    }

    return (
      <div className={classNames('')}>
        <div className={classNames('d-flex fs-4')}>
          <div className={classNames('fc-1')}>{texts[locale].hello}</div>
          <div className={classNames('ms-2 fw-bold')}>{name}</div>
        </div>
        <div className={classNames('fs-7 fc-1 fst-italic')}>
          {Parser(texts[locale][description] || description)}
        </div>
        {show_badge_description && badge_description && (
          <div className={classNames('fs-7 fc-8 mt-1')}>
            {Parser(texts[locale][badge_description] || badge_description)}
          </div>
        )}
        <div className={classNames('mb-3')} />
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaePageHeadComponent)
