import React from 'react'
import { connect } from 'react-redux'
import JujoEditableComponent from '../../../../../../src/components/editable_component'
import * as actionCreators from '../../../../../../src/store/actions'
import addressSelectionTypesMap from '../enums/address_selection_types_map'
import workflowStepsMap from '../enums/workflow_steps_map'

import { getAddressObject, updateAddressTypeSelected } from './helper'

import VitaeExistingAddressSelectorComponent from './_parts/existing_address_selector'
import {
  cloneObj,
  mapStateToProps,
  scrollPageOnTop,
} from '../../../../../../src/utils'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'

import { getSessionClientData } from '../order_workflow/helper'
import JujoButtonComponent from '../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaeAddressSelectorComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  handleAddressSelectorTypeClicked = async address_type => {
    await updateAddressTypeSelected(this, address_type)
  }

  renderAddressTypeSelector = (label, type, selected) => {
    const html = []

    html.push(
      <div
        key={label}
        className={classNames('d-flex align-items-center my-3')}
        role="button"
        tabIndex={0}
        onClick={() => {
          this.handleAddressSelectorTypeClicked(type)
        }}
        onKeyPress={() => {
          this.handleAddressSelectorTypeClicked(type)
        }}
      >
        <div
          className={classNames(
            'd-flex justify-content-center align-items-center bg-white border rounded-percentage-100 me-2'
          )}
          style={{ width: '16px', height: '16px' }}
        >
          {selected && (
            <div
              className={classNames('bg-1 rounded-percentage-100')}
              style={{ width: '10px', height: '10px' }}
            />
          )}
        </div>
        <div>{label}</div>
      </div>
    )

    return html
  }

  handleGoToPaymentStep = async () => {
    const { session, updateSession } = this.props

    const session_copy = cloneObj(session)

    const { session_id, session_data } = session_copy
    const { cart } = session_data
    const { client_data } = cart

    client_data.order_workflow.current_step = workflowStepsMap.payment

    const returned_data = await saveSessionToServer(session_id, session_data)

    session_copy.session_data = returned_data
    await updateSession(session_copy)
    scrollPageOnTop()
  }

  render() {
    const { specialization, environment, authentication, session } = this.props
    const { user } = authentication

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const address_obj = getAddressObject(this)
    const { address_type } = address_obj

    const client_data = getSessionClientData(session)
    const { order_workflow } = client_data
    const { current_step } = order_workflow

    const same_as_shipping_visible = current_step === workflowStepsMap.billing
    const existing_address_visible = Object.keys(user).length > 0
    const new_address_visible =
      Object.keys(user).length > 0 || current_step === workflowStepsMap.billing

    return (
      <div className={classNames('')}>
        <div
          className={classNames(
            'border-bottom border-2 border-color-black pb-3'
          )}
        >
          <div className={classNames('fw-bold')}>
            {current_step === workflowStepsMap.shipment
              ? texts[locale].shipping_data
              : texts[locale].billing_data}
          </div>
          <div>
            {current_step === workflowStepsMap.shipment
              ? texts[locale].where_do_we_ship
              : texts[locale].who_do_we_pay_the_invoice_to}
          </div>
        </div>
        <div
          className={classNames(
            'py-2',
            !same_as_shipping_visible &&
              !existing_address_visible &&
              !new_address_visible
              ? ''
              : 'border-bottom'
          )}
        >
          {same_as_shipping_visible &&
            this.renderAddressTypeSelector(
              texts[locale].same_as_shipping,
              addressSelectionTypesMap.same_as_shipping,
              address_type === addressSelectionTypesMap.same_as_shipping
            )}
          {existing_address_visible &&
            this.renderAddressTypeSelector(
              texts[locale].existing_address,
              addressSelectionTypesMap.existintg,
              address_type === addressSelectionTypesMap.existintg
            )}
          {new_address_visible &&
            this.renderAddressTypeSelector(
              texts[locale].new_address,
              addressSelectionTypesMap.new,
              address_type === addressSelectionTypesMap.new
            )}
        </div>
        {existing_address_visible &&
          address_type === addressSelectionTypesMap.existintg && (
            <VitaeExistingAddressSelectorComponent />
          )}

        {address_type === addressSelectionTypesMap.new && (
          <JujoEditableComponent
            c_def={{
              comp: 'editable_component',
              entity: {
                source: 'static',
                defValue: 'address',
              },
              renderer: {
                viewPath: 'checkout_group/address_selector',
              },
              reloadOnPropChanged: ['session.session_data.cart.user_data.user'],
            }}
          />
        )}
        {address_type === addressSelectionTypesMap.same_as_shipping && (
          <div className={classNames('d-flex justify-content-center my-4')}>
            <JujoButtonComponent
              bstyle={3}
              bwidth="200px"
              blabel={texts[locale].continue_with_purchase}
              handleClick={() => {
                this.handleGoToPaymentStep()
              }}
            />
          </div>
        )}
        <div
          className={classNames(
            'd-flex justify-content-center align-items-center mx-auto mt-4'
          )}
          style={{ width: 'min(100%, 350px)' }}
        >
          <div
            className={classNames('courier-sda')}
            style={{
              height: '64px',
              width: '100px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
            }}
          />
        </div>
      </div>
    )
  }
}
export default connect(
  mapStateToProps,
  actionCreators
)(VitaeAddressSelectorComponent)
