/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import requestTypesMap from '../../../enums/requestTypesMap'

import * as actionCreators from '../../../store/actions'
import { cloneObj, mapStateToProps } from '../../../utils'

import './styles.scss'

const classNames = require('classnames')

export class FieldsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      didMountCompleted: false,
      entityDefinition: {},
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { didMountCompleted, entityDefinition } = this.state
    if (
      (didMountCompleted === false && nextState.didMountCompleted === true) ||
      entityDefinition !== nextState.entityDefinition
    ) {
      return true
    }
    return false
  }

  componentDidMount = async () => {
    const { specialization, dynamicForm } = this.props

    const { entities } = specialization.config
    const { entity, type } = dynamicForm
    const sourceType =
      type === requestTypesMap.view ? requestTypesMap.update : type

    const config = entities[entity]
    const definition = config.form
    const fields = definition.fields[sourceType]

    const filteredFields = []
    for (let i = 0; i !== fields.length; i += 1) {
      const f = fields[i]
      if (f.isGroup) {
        const { fields: subFields } = f
        for (let j = 0; j !== subFields.length; j += 1) {
          const sf = subFields[j]
          filteredFields.push(sf)
        }
      } else filteredFields.push(f)
    }

    await this.addFieldsInformationToDynamicForm(filteredFields)
    this.setState({ entityDefinition: definition })
  }

  componentDidUpdate = async (prevProp, prevState) => {
    const { entityDefinition } = this.state
    if (prevState.entityDefinition !== entityDefinition) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ didMountCompleted: true })
    }
  }

  addFieldsInformationToDynamicForm = async fields => {
    const { dynamicForm, updateDynamicFormIntoRedux } = this.props

    const updatedDForm = { ...dynamicForm }
    const { computedKeys, conditionalKeys, resetKeys, updateFieldsDefs } =
      updatedDForm

    const dComputedKeys = [...computedKeys]
    const dConditionalKeys = [...conditionalKeys]
    const dResetKeys = [...resetKeys]
    const dUpdateFieldsDefs = { ...updateFieldsDefs }

    for (let i = 0; i !== fields.length; i += 1) {
      const field = fields[i]
      const { name, conditions, resetOn, computedSettings } = field

      if (computedSettings) {
        for (let j = 0; j !== computedSettings.fields.length; j += 1) {
          const cField = computedSettings.fields[j]
          if (!dUpdateFieldsDefs[cField]) {
            dUpdateFieldsDefs[cField] = []
          }
          dUpdateFieldsDefs[cField].push(name)

          if (dComputedKeys.indexOf(cField) < 0) {
            dComputedKeys.push(cField)
          }
        }
      }
      if (conditions) {
        for (let j = 0; j !== conditions.length; j += 1) {
          const cond = conditions[j]
          const { relatedField } = cond

          if (!dUpdateFieldsDefs[relatedField]) {
            dUpdateFieldsDefs[relatedField] = []
          }
          if (!dUpdateFieldsDefs[relatedField].includes(name)) {
            dUpdateFieldsDefs[relatedField].push(name)
          }

          if (dConditionalKeys.indexOf(relatedField) < 0) {
            dConditionalKeys.push(relatedField)
          }
        }
      }
      if (resetOn) {
        for (let j = 0; j !== resetOn.length; j += 1) {
          const rValue = resetOn[j]

          if (!dUpdateFieldsDefs[rValue]) {
            dUpdateFieldsDefs[rValue] = []
          }
          if (!dUpdateFieldsDefs[rValue].includes(name)) {
            dUpdateFieldsDefs[rValue].push(name)
          }

          if (!dResetKeys.includes(rValue)) {
            dResetKeys.push(rValue)
          }
        }
      }
    }

    updatedDForm.computedKeys = dComputedKeys
    updatedDForm.conditionalKeys = dConditionalKeys
    updatedDForm.resetKeys = dResetKeys
    updatedDForm.updateFieldsDefs = dUpdateFieldsDefs
    await updateDynamicFormIntoRedux(updatedDForm)
  }

  deleteFieldsInformationFromDynamicForm = async fieldList => {
    const { dynamicForm, updateDynamicFormIntoRedux } = this.props
    const updatedDForm = { ...dynamicForm }
    const {
      fields,
      computedKeys,
      conditionalKeys,
      resetKeys,
      updateFieldsDefs,
    } = updatedDForm

    const dFields = { ...fields }
    const dComputedKeys = [...computedKeys]
    const dConditionalKeys = [...conditionalKeys]
    const dResetKeys = [...resetKeys]
    const dUpdateFieldsDefs = { ...updateFieldsDefs }

    for (let i = 0; i !== fieldList.length; i += 1) {
      const field = fieldList[i]
      const { name } = field

      delete dFields[name]

      if (dComputedKeys.includes(name)) {
        const idx = dComputedKeys.indexOf(name)
        dComputedKeys.splice(idx, 1)
      }

      if (dConditionalKeys.includes(name)) {
        const idx = dConditionalKeys.indexOf(name)
        dConditionalKeys.splice(idx, 1)
      }

      if (dResetKeys.includes(name)) {
        const idx = dResetKeys.indexOf(name)
        dResetKeys.splice(idx, 1)
      }

      if (dUpdateFieldsDefs[name]) {
        delete dUpdateFieldsDefs[name]
      }
      const dUpdateFieldsDefKeys = Object.keys(dUpdateFieldsDefs)
      for (let j = 0; j !== dUpdateFieldsDefKeys.length; j += 1) {
        const fieldDefKey = dUpdateFieldsDefKeys[j]
        const dUpdateFieldsDefsArray = dUpdateFieldsDefs[fieldDefKey]
        if (dUpdateFieldsDefsArray.includes(name)) {
          const idx = dUpdateFieldsDefsArray.indexOf(name)
          dUpdateFieldsDefsArray.splice(idx, 1)
        }
      }
    }

    updatedDForm.fields = dFields
    updatedDForm.computedKeys = dComputedKeys
    updatedDForm.conditionalKeys = dConditionalKeys
    updatedDForm.resetKeys = dResetKeys
    updatedDForm.updateFieldsDefs = dUpdateFieldsDefs
    await updateDynamicFormIntoRedux(updatedDForm)
  }

  getDynamicFieldType = field => {
    const { fieldType, specializedComponent } = field

    const html = []
    if (fieldType) {
      let DynamicField = null
      if (specializedComponent) {
        DynamicField =
          require(`../../../../vitae_specializations/src/${process.env.client}/components/${fieldType}/index`).default
      } else {
        DynamicField = require(`./_parts/${fieldType}`).default
      }
      if (DynamicField) {
        html.push(
          <DynamicField
            key={uuid()}
            field={field}
            handleValueChanged={this.handleValueChanged}
            addFieldsInformationToDynamicForm={
              this.addFieldsInformationToDynamicForm
            }
            deleteFieldsInformationFromDynamicForm={
              this.deleteFieldsInformationFromDynamicForm
            }
          />
        )
      }
    }

    return html
  }

  handleValueChanged = async (key, value) => {
    if (value === undefined || value === null) {
      return
    }
    const { dynamicForm, updateDynamicFormIntoRedux } = this.props
    const dForm = cloneObj(dynamicForm)

    const { fields } = dForm

    fields[key] = value

    dForm.conditionalKeyUpdated =
      dForm.conditionalKeys.indexOf(key) >= 0 ? key : false
    dForm.resetKeyUpdated = dForm.resetKeys.indexOf(key) >= 0 ? key : false
    dForm.computedKeyUpdated =
      dForm.computedKeys.indexOf(key) >= 0 ? key : false
    dForm.fieldsToUpdate = dForm.updateFieldsDefs[key] || []

    await updateDynamicFormIntoRedux(dForm)
  }

  render() {
    const { didMountCompleted, entityDefinition } = this.state
    const { environment, dynamicForm, specialization } = this.props
    const { translations } = specialization
    const { texts } = translations

    const { locale } = environment

    const { type } = dynamicForm
    const sourceType =
      type === requestTypesMap.view ? requestTypesMap.update : type

    return (
      <div
        key={uuid()}
        className={classNames('container-fluid row mx-auto my-3 gy-3')}
      >
        {didMountCompleted === false && (
          <div className={classNames({ loading_text: true })}>
            {texts[locale].loading}
          </div>
        )}
        {didMountCompleted === true &&
          entityDefinition.fields[sourceType].map(f => {
            if (f.isGroup) {
              const groupHtml = []
              const { label, fields } = f
              for (let i = 0; i !== fields.length; i += 1) {
                const groupedField = fields[i]
                groupHtml.push(this.getDynamicFieldType(groupedField))
              }

              return (
                <div
                  key={uuid()}
                  className={classNames('border-bottom p-3 row')}
                >
                  <div className={classNames('fs-5 mb-2 fc-1 fw-bold')}>
                    {texts[locale][label] || label}
                  </div>
                  {groupHtml}
                </div>
              )
            }
            return this.getDynamicFieldType(f)
          })}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(FieldsComponent)
