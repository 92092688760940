import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeFoodSupplementsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const { specialization, environment, handleValueChanged, dataSource } =
      this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const fieldName = 'food_supplements_in_use'

    return (
      <div className={classNames('my-2')}>
        <div className={classNames('d-flex align-items-end mb-2')}>
          <div
            className={classNames('food-supplements-icon')}
            style={{
              width: '36px',
              height: '36px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
            }}
          />
          <div
            className={classNames('fs-7 fw-bold ms-2')}
            style={{ lineHeight: '14px' }}
          >
            <div>{texts[locale].food_supplements_in_use}</div>
          </div>
        </div>
        <textarea
          className={classNames('border border-color-4 rounded-3 p-2 bg-white')}
          name={fieldName}
          rows={3}
          placeholder={texts[locale].drug_list}
          defaultValue={dataSource[fieldName]}
          onChange={e => {
            const { value } = e.target
            handleValueChanged(fieldName, value)
          }}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeFoodSupplementsComponent)
