import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeDietMealsFieldComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  processOnBlur = async (field_key, k, content) => {
    const { dataSource, field, handleValueChanged } = this.props

    const { name } = field
    const field_value = dataSource[name]

    const updated_field = cloneObj(field_value)
    updated_field[field_key][k] = content

    await handleValueChanged(name, updated_field)
  }

  renderBlockSections = field_key => {
    const { specialization, environment, dataSource, field } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { name } = field

    const html = []

    // const keys = Object.keys(sections)
    const ordered_keys = ['carbohydrates', 'proteins', 'fats', 'fruits', 'mix']

    for (let i = 0; i !== ordered_keys.length; i += 1) {
      const k = ordered_keys[i]
      html.push(
        <div key={k} className={classNames('col-12 col-md-6 mt-4')}>
          <div className={classNames('fw-bold mb-2')}>
            {texts[locale][k] || k}
          </div>
          <div>
            <textarea
              rows={4}
              defaultValue={dataSource[name][field_key][k] || ''}
              onBlur={async e => {
                await this.processOnBlur(field_key, k, e.target.value)
              }}
            />
          </div>
        </div>
      )
    }

    return html
  }

  renderBlocks = () => {
    const { specialization, environment } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const html = []

    // const keys = Object.keys(f_obj)
    const ordered_keys = [
      'breakfast',
      'snak_1',
      'lunch',
      'snak_2',
      'dinner',
      'snak_after_dinner',
    ]

    for (let i = 0; i !== ordered_keys.length; i += 1) {
      const k = ordered_keys[i]

      html.push(
        <div key={k} className={classNames('p-3 bg-3')}>
          <div className={classNames('fc-1 fw-bold fs-5 mb-1')}>
            {texts[locale][k] || k}
          </div>
          <div className={classNames('row pb-4')}>
            {this.renderBlockSections(k)}
          </div>
        </div>
      )
    }
    return html
  }

  render() {
    return (
      <div className={classNames('')}>
        <div>{this.renderBlocks()}</div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeDietMealsFieldComponent)
