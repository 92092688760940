import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class LabelAndText extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { label, texts, size } = this.props
    const margin_bottom = size === 'short' ? '' : 'mb-1'
    return (
      <div
        className={classNames(
          `row m-0 ${margin_bottom} d-flex align-items-start`
        )}
      >
        <div className={classNames('col-md-5 p-0 fs-8 fc-4')}>{label}</div>
        <div
          className={classNames(
            `col-md-7 p-0 fs-8 fw-bold d-flex flex-column `
          )}
        >
          {texts && texts.map((text, index) => <div key={index}>{text}</div>)}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(LabelAndText)
