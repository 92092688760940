/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class KitColoredBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { children, title, customStyle, customClass } = this.props
    return (
      <>
        <div
          className={classNames(`w-100 p-3 fc-white ${customClass}`)}
          style={{
            borderRadius: '10px 10px 0px 0px',
            ...customStyle,
          }}
        >
          {title}
        </div>
        <div className={classNames('w-100 px-3 py-4 bg-3')}>{children}</div>
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(KitColoredBox)
