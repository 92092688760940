import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import {
  createMSBDef,
  getEmptyMessageBox,
} from '../../../../../../../src/components/message_box/helper'
import apiCallMap from '../../../../../../../src/enums/apiCallMap'
import * as apiService from '../../../../../../../src/services/apiService'

import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../../src/services/servicesHelper'

import * as actionCreators from '../../../../../../../src/store/actions'
import {
  injectComponent,
  mapStateToProps,
  retrieveComponent,
} from '../../../../../../../src/utils'

export class VitaeAddressLineComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  removeAddressClicked = () => {
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const messageBox = getEmptyMessageBox()

    messageBox.icon = 'warning-icon-color'
    messageBox.title = texts[locale].warning
    messageBox.message = texts[locale].remove_address_message

    const cancel_btn = createMSBDef(
      texts[locale].cancel,
      this.closeMessageBox,
      {
        msbox_instance_id: messageBox.id,
      },
      false
    )
    messageBox.buttons.push(cancel_btn)

    const confirm_btn = createMSBDef(
      texts[locale].confirm,
      this.processDeleteAddress,
      {
        msbox_instance_id: messageBox.id,
      },
      true
    )
    messageBox.buttons.push(confirm_btn)

    const component_definition = {
      specialized: false,
      path: 'message_box',
      data: {
        messageBox,
      },
    }

    injectComponent(this, messageBox.id, component_definition)
  }

  processDeleteAddress = async params => {
    const { reloadData } = this.props

    const reqObj = this.composeDeleteRequest()
    const { parsedEp } = reqObj

    const response = await apiService.httpDelete(
      `${process.env.apiUrl}${parsedEp}`
    )
    const { status } = response
    if (status === 200 || status === 201) {
      const { msbox_instance_id } = params
      retrieveComponent(this, msbox_instance_id)
      reloadData()
    }
  }

  composeDeleteRequest = () => {
    const { address, entity, entityDefinition, environment, authentication } =
      this.props

    const { apis } = entityDefinition

    const { deleteData } = apis

    const { apiCall, requestType, placeholderMapping } = deleteData

    const requestData = baseRequestObject(
      apiCallMap[apiCall],
      entity,
      requestType,
      environment,
      authentication
    )
    requestData.placeholderMapping = placeholderMapping || []
    requestData.dynamicFields = address
    const parsedEp = parseEndpoint(requestData)

    return {
      parsedEp,
      data: [],
    }
  }

  closeMessageBox = params => {
    const { msbox_instance_id } = params
    retrieveComponent(this, msbox_instance_id)
  }

  editAddress = () => {
    const { enterEditMode, address } = this.props
    enterEditMode(address)
  }

  render() {
    const { specialization, environment, address } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { address_type, full_address, country_translated, is_default } =
      address
    return (
      <div
        className={classNames(
          'bg-3 my-1 px-3 py-4 fs-7 ffamily-secondary d-flex justify-content-between align-items-center',
          is_default === 1 ? 'bshadow-c1-3px' : ''
        )}
      >
        <div>
          <span className={classNames('fc-gray')}>
            {`${texts[locale][address_type]} - `}
          </span>
          <span className={classNames('fw-bold')}>
            {`${full_address} ${country_translated}`}
          </span>
        </div>
        <div className={classNames('d-flex ms-5')}>
          <div
            className={classNames('trash-icon-2')}
            style={{
              height: '18px',
              width: '18px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
            }}
            role="button"
            tabIndex={0}
            label="delete button"
            onClick={() => {
              this.removeAddressClicked()
            }}
            onKeyPress={() => {
              this.removeAddressClicked()
            }}
          />
          <div
            className={classNames('edit-icon-2 ms-2')}
            style={{
              height: '18px',
              width: '18px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
            }}
            role="button"
            tabIndex={0}
            label="edit button"
            onClick={() => {
              this.editAddress()
            }}
            onKeyPress={() => {
              this.editAddress()
            }}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeAddressLineComponent)
