/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'

import * as dietEnums from '../../../common_group/enums/diet'
import VitaeMyResultsTitleAndDownloadButton from '../../_parts/my_results_title_and_download_button'
import MyDrawSinglFoodSupplement from '../../dna/_parts/my_draw_single_food_supplement'
import JujoButtonComponent from '../../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaeDrawDiet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => { }
  }

  mealHasData = (diet, meal) => {
    let flag = false
    dietEnums.macronutrients.map(macronutrient => {
      flag =
        flag ||
        (diet &&
          diet.meals &&
          diet.meals[meal] &&
          diet.meals[meal][macronutrient] &&
          diet.meals[meal][macronutrient] !== '')
    })
    return flag
  }

  drawSuggestedFoodSupplements = () => {
    const { session } = this.props
    const { session_data } = session
    const { suggested_food_supplements } = session_data
    const html = []
    if (suggested_food_supplements) {
      for (let i = 0; i < suggested_food_supplements?.length; i += 1) {
        html.push(
          <MyDrawSinglFoodSupplement
            key={`suggested_food_supplement_product${i}`}
            item={suggested_food_supplements[i]}
          />
        )
      }
    }

      return html
    }

    render() {
      const { specialization, environment, authentication, genericDataBox2 } =
        this.props
      const { translations } = specialization
      const { texts } = translations
      const { locale } = environment
      const { diet } = genericDataBox2 || false
      const { swab } = genericDataBox2 || false
      let counter = 0

      const { access_token: tk } = authentication || ''
      const downloadUrl = `${process.env.apiServerUrl}pdf/vitae/diet?locale=${locale}&tk=${tk}`
      const { analysed_at_formatted_day: result_date, id: swab_id } = swab || {}
      const informative = `${texts[locale].my_results_diets_informative}. <br /> ${texts[locale].my_diet_preface}`

      const old_pdf_diet_exists =
        swab.old_pdf_diet !== null && swab.old_pdf_diet !== ''

      const { key_account } = diet
      const hide_macronutrients = key_account === 'hintime'

      return (
        <div className={classNames('pt-4')}>
          <VitaeMyResultsTitleAndDownloadButton
            title_black_part={texts[locale].vitae}
            title_colored_part={texts[locale].diet}
            title_class="fc-6"
            downloadUrl={downloadUrl}
            result_date={result_date}
            swab_id={swab_id}
          />
          {swab && old_pdf_diet_exists && (
            <div
              className={classNames(
                'd-flex justify-content-start mt-3 pb-4 fs-9'
              )}
            >
              <div
                className={classNames('fs-7')}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: informative,
                }}
              />
            </div>
          )}
          {swab && !old_pdf_diet_exists && (
            <>
              {/* Diet */}
              <div
                className={classNames('w-100 p-3 mt-4 d-flex flex-column fc-6')}
                style={{
                  border: `2px solid`,
                  borderRadius: '10px',
                }}
              >
                <div className={classNames('fst-italic fs-7')}>
                  {texts[locale].my_diet_preface}
                </div>
                {/* Meals */}
                <div className={classNames('my-4')}>
                  {dietEnums &&
                    dietEnums.meals.map(meal => {
                      if (this.mealHasData(diet, meal)) {
                        return (
                          <div key={meal}>
                            <div
                              className={classNames(
                                'fw-bold border-bottom border-2 border-dark mt-4'
                              )}
                            >
                              {texts[locale][`my_meal_${meal}`]}
                            </div>
                            {/* Macronutrients */}
                            <div className={classNames('row m-0')}>
                              {dietEnums.macronutrients.map(
                                (macronutrient, idx) => {
                                  const macronutrient_has_text =
                                    diet.meals[meal][macronutrient] &&
                                    diet.meals[meal][macronutrient] !== ''
                                  if (macronutrient_has_text) {
                                    counter += 1
                                    return (
                                      <div
                                        key={macronutrient}
                                        className={classNames(
                                          `col-12 col-md-6 p-0 pt-2 pb-1 ${counter % 2 === 0
                                            ? 'ps-md-2'
                                            : 'pe-md-2'
                                          }`
                                        )}
                                      >
                                        {/* Macronutrient column */}
                                        <div
                                          className={classNames(
                                            `p-3 bg-diet-macronutrient-${idx} d-flex flex-column h-100`
                                          )}
                                        >
                                          {/* Macronutrient title */}
                                          {!hide_macronutrients && (
                                            <div
                                              className={classNames(
                                                'd-flex flex-row align-items-baseline mb-2'
                                              )}
                                            >
                                              <div
                                                className={classNames(
                                                  `diet-${macronutrient}-icon`
                                                )}
                                                style={{
                                                  width: '25px',
                                                  height: '25px',
                                                  backgroundSize: 'contain',
                                                  backgroundPosition: 'center',
                                                  backgroundRepeat: 'no-repeat',
                                                }}
                                              />
                                              <div
                                                className={classNames(
                                                  'fw-bold ms-2 fs-7'
                                                )}
                                              >
                                                {texts[locale][
                                                  `my_macronutrient_${macronutrient}`
                                                ].toUpperCase()}
                                              </div>
                                            </div>
                                          )}

                                          {/* Macronutrient text */}
                                          <div
                                            className={classNames(
                                              ' fst-italic fs-8'
                                            )}
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                diet.meals[meal][macronutrient],
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )
                                  }
                                }
                              )}
                            </div>
                          </div>
                        )
                      }
                    })}
                </div>
                {diet.notes && diet.notes !== '' && (
                  <div
                    className={classNames('w-100 p-3 mt-3 fs-8 fst-italic fc-6')}
                    style={{
                      border: `2px solid`,
                      borderRadius: '10px',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: diet.notes,
                    }}
                  />
                )}
              </div>
              <div
                className={classNames('w-100 p-3 mt-3 d-flex flex-column fc-6')}
                style={{
                  border: `2px solid`,
                  borderRadius: '10px',
                }}
              >
                {diet &&
                  diet.tips &&
                  diet.tips.map((tip, idx) => (
                    <div
                      key={idx}
                      className={classNames('w-100 d-flex flex-column mb-5')}
                    >
                      {/* tip title */}
                      <div
                        className={classNames('fw-bold pb-3 mb-3 fc-6 fc-6')}
                        style={{
                          borderBottom: `2px solid `,
                        }}
                      >
                        {tip.title}
                      </div>
                      <div
                        className={classNames('fs-7 fst-italic')}
                        dangerouslySetInnerHTML={{
                          __html: tip.body,
                        }}
                      />
                    </div>
                  ))}
                {diet && diet.suggested_food_supplements && (
                  <div
                    key="suggested_food_supplements"
                    className={classNames('w-100 d-flex flex-column mb-5')}
                  >
                    {/* tip title */}
                    <div
                      className={classNames('fw-bold pb-3 mb-3 fc-6 fc-6')}
                      style={{
                        borderBottom: `2px solid `,
                      }}
                    >
                      Integratori suggeriti
                    </div>
                    <div className={classNames('row')}>
                      {this.drawSuggestedFoodSupplements()}
                    </div>
                  </div>
                )}
                <div
                  className={classNames(
                    'd-flex flex-row align-items-start justify-content-between'
                  )}
                >
                  {/* Dietician sign */}
                  <div
                    className={classNames(
                      'd-flex flex-column align-items-center px-3'
                    )}
                  >
                    <div>
                      {`${diet.specialist_professional_qualification}: `}
                      <strong>{diet.specialist_company_name}</strong>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: diet.specialist_description,
                      }}
                    />
                    <div>
                      <img
                        src={diet.specialist_signature_url}
                        alt="dietician signature"
                        style={{ width: '190px' }}
                      />
                    </div>
                  </div>
                  {/* Download button */}
                  <div>
                    <JujoButtonComponent
                      bstyle={3}
                      bwidth="230px"
                      blabel={texts[locale].my_download_complete_pdf}
                      bicon="download-icon"
                      bFontClass="fw-bold"
                      bdata=""
                      handleClick={this.handleDownloadDietButtonClick}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )
    }
  }

export default connect(mapStateToProps, actionCreators)(VitaeDrawDiet)
