import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../src/utils'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'
import JujoLoading from '../../../../../../src/components/loading'

const classNames = require('classnames')

export class VitaeCompactPaymentMethodComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { initialized: true }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  handlePaymentMethodClicked = async pMethod => {
    const { session, updateSession } = this.props
    const session_copy = cloneObj(session)

    const { session_id, session_data } = session_copy
    const { cart } = session_data

    cart.selected_payment_method = pMethod

    const returned_data = await saveSessionToServer(session_id, session_data)

    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  render() {
    const { initialized } = this.state
    const { specialization, environment, session } = this.props

    const { session_data } = session
    const { cart, payments } = session_data

    const { selected_payment_method } = cart

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div className={classNames('')}>
        {initialized === false && <JujoLoading />}
        {initialized === true && (
          <div className={classNames('mt-2')}>
            <div
              className={classNames(
                'fw-bold border-bottom border-2 border-color-black pt-3 pb-2 fs-7'
              )}
            >
              {texts[locale].payment_method_you_prefer}
            </div>
            {Object.keys(payments).map(pKey => {
              const selected = pKey === selected_payment_method

              return (
                <div
                  key={pKey}
                  className={classNames('d-flex align-items-center my-2 fs-8')}
                  role="button"
                  tabIndex={0}
                  onClick={async () => {
                    await this.handlePaymentMethodClicked(pKey)
                  }}
                  onKeyPress={async () => {
                    await this.handlePaymentMethodClicked(pKey)
                  }}
                >
                  <div
                    className={classNames(
                      'd-flex justify-content-center align-items-center bg-white border rounded-percentage-100 me-2'
                    )}
                    style={{ width: '14px', height: '14px' }}
                  >
                    {selected && (
                      <div
                        className={classNames('bg-1 rounded-percentage-100')}
                        style={{ width: '10px', height: '10px' }}
                      />
                    )}
                  </div>
                  <div>{texts[locale][pKey] || pKey}</div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeCompactPaymentMethodComponent)
