import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'

import { retrieveEntityValue } from '../../../../../../src/components/editable_component/helper'
import VitaeDietLine from './_parts/diet_line'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeDiets extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { data, entityDefinition, reloadData, enterEditMode, enterAddMode } =
      this.props
    const entity = retrieveEntityValue(this.props)

    return (
      <div className={classNames('row')}>
        {data.map(diet => (
          <VitaeDietLine
            key={diet.id}
            entity={entity}
            entityDefinition={entityDefinition}
            record={diet}
            updateShowList={this.updateShowList}
            enterEditMode={enterEditMode}
            enterAddMode={enterAddMode}
            reloadData={reloadData}
          />
        ))}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeDiets)
