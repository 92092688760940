/* eslint-disable no-useless-escape */
const {
  getEnvData,
  createFolderIfNotExists,
  parseHtml,
  copyFile,
  readFile,
  writeFile,
  getApiData,
  processEnv,
} = require('./common')

async function setScriptLoaderRawText(script_loader, url) {
  const { homePageRelPath } = processEnv()

  const { childNodes } = script_loader
  const { _rawText } = childNodes[0]
  const updated_raw_text = _rawText
    .replace('window.pagePath="/"', `window.pagePath="${url}"`)
    .replace("window.pagePath = '/'", `window.pagePath = '${url}'`)
    .replace('window.pagePath = "/"', `window.pagePath = '${url}'`)
    .replace(`window.pagePath="${homePageRelPath}"`, `window.pagePath="${url}"`)
    .replace(
      `window.pagePath = '${homePageRelPath}'`,
      `window.pagePath = '${url}'`
    )

  // eslint-disable-next-line no-underscore-dangle
  childNodes[0]._rawText = updated_raw_text
}

async function createStaticPage(url, meta) {
  const { opsys, app_slash } = getEnvData()
  const { client, site_url } = processEnv()

  const parsed_page_rel_path =
    opsys === 'linux' ? url : url.replaceAll('/', '\\')

  const public_path = `${__dirname}${app_slash}..${app_slash}..${app_slash}public`
  const page_data_path = `${public_path}${app_slash}page-data`
  const file_path = `${public_path}${app_slash}index.html`
  const dest_path = `${public_path}${parsed_page_rel_path}`
  const dest_file_path = `${dest_path}index.html`
  const dest_page_data_path = `${page_data_path}${parsed_page_rel_path}`
  const dest_page_data_file = `${dest_page_data_path}page-data.json`

  createFolderIfNotExists(dest_path)
  createFolderIfNotExists(dest_page_data_path)

  await copyFile(file_path, dest_file_path)
  const data = await readFile(dest_file_path)

  const page_html = parseHtml(data)

  const page_head = page_html.querySelector('head')

  // Add Canonical
  const canonical_url = `${site_url.replace(/\/$/, '')}${url}`
  const canonical_tag = `<link rel="canonical" href="${canonical_url}" />`
  page_head.innerHTML += canonical_tag

  // Add Meta
  if (meta !== '') {
    page_head.innerHTML += meta
  }

  // Add company Logo
  const company_logo_obj = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: site_url,
    logo: `${site_url}${client}/logo.png`,
  }
  const company_logo_string = JSON.stringify(company_logo_obj)
  page_head.innerHTML += `<script type="application/ld+json">${company_logo_string}</script>`

  const link = page_html.querySelector('head > link[href*="index"]')

  const { rawAttrs } = link
  const updated_raw_attrs = rawAttrs.replace(
    '/page-data\\index\\page-data.json',
    `/page-data${parsed_page_rel_path}page-data.json`
  )

  link.rawAttrs = updated_raw_attrs

  const script_loader = page_html.querySelector('body #gatsby-script-loader')
  await setScriptLoaderRawText(script_loader, url)
  await writeFile(dest_file_path, page_html.toString())

  const page_data_text = `{"componentChunkName":"component---src-pages-index-js","path":"${url}","result":{"pageContext":{}},"staticQueryHashes":[]}`
  await writeFile(dest_page_data_file, page_data_text)

  console.log('created page --> ', url)
}

async function createSiteMap(pages) {
  const { app_slash } = getEnvData()
  const { site_url, INIT_CWD } = processEnv()
  const parsed_siteurl = site_url.replace(/\/$/, '')

  const { create } = require('xmlbuilder2')

  const root = create({
    version: '1.0',
    encoding: 'UTF-8',
    standalone: 'yes',
  }).ele('urlset', {
    xmlns: 'http://www.sitemaps.org/schemas/sitemap/0.9',
    'xmlns:xhtml': 'http://www.w3.org/1999/xhtml',
  })

  for (let i = 0; i !== pages.length; i += 1) {
    const page = pages[i]
    const { url } = page
    const parsed_url = url ? JSON.parse(url) : {}
    const lang_list = Object.keys(parsed_url)

    for (let j = 0; j !== lang_list.length; j += 1) {
      const lang = lang_list[j]

      if (!parsed_url[lang] || parsed_url[lang] === null) continue

      const lang_url = parsed_siteurl + parsed_url[lang]

      const element_url = root
        .ele('url')
        .ele('loc')
        .txt(lang_url)
        .up()
        .ele('lastmod')
        .txt(new Date().toISOString())
        .up()

      if (lang.length > 1) {
        for (let x = 0; x !== lang.length; x += 1) {
          const x_lang = lang_list[x]
          if (!parsed_url[x_lang] || parsed_url[x_lang] === null) continue
          const x_url = parsed_siteurl + parsed_url[x_lang]

          element_url
            .ele('xhtml:link')
            .ele('rel')
            .txt('alternate')
            .up()
            .ele('hreflang')
            .txt(x_lang)
            .up()
            .ele('href')
            .txt(x_url)
            .up()
            .up()
        }
      }
    }
  }

  root.up()
  const xml = root.end({ prettyPrint: true })

  const public_path = `${INIT_CWD + app_slash}public`
  const sitemap_path = `${public_path + app_slash}sitemap.xml`

  await writeFile(sitemap_path, xml)

  console.log('sitemap.xml created successfully')

  const robots_txt = `
    User-agent: *
    Disallow: /admin
    Disallow: /my-account
    
    Sitemap: ${site_url}sitemap.xml
    `
  const robots_path = `${public_path + app_slash}robots.txt`

  writeFile(robots_path, robots_txt)
  console.log('robots.txt created successfully')
}

async function createHtAccess() {
  const { app_slash } = getEnvData()
  const { client, INIT_CWD } = processEnv()

  const client_path = `${
    INIT_CWD + app_slash
  }vitae_specializations${app_slash}src${app_slash}${client}`

  let redirect_string = ''
  const redirect_path = `${client_path + app_slash}redirect.json`
  const redirect_data = await readFile(redirect_path)

  if (redirect_data) {
    const redirect_array = []

    const redirect_obj = JSON.parse(redirect_data)
    const { redirect_list } = redirect_obj

    for (let i = 0; i !== redirect_list.length; i += 1) {
      const item = redirect_list[i]
      const { from, to } = item
      redirect_array.push(`Redirect 301 ${from} ${to}`)
    }

    redirect_string = redirect_array.join('\n')
  }

  const public_path = `${INIT_CWD + app_slash}public`
  const htaccess_path = `${public_path + app_slash}.htaccess`

  const htaccess_string = `

  ##### Force using https without www
  RewriteEngine On
  RewriteCond %{HTTPS} off
  RewriteRule ^(.*)$ https://%{HTTP_HOST}%{REQUEST_URI} [L,R=301]

  ##### Force using https and www
  ##### RewriteEngine On
  ##### RewriteCond %{HTTP_HOST} !=""
  ##### RewriteCond %{HTTP_HOST} !^www\. [NC]
  ##### RewriteCond %{HTTPS}s ^on(s)|
  ##### RewriteRule ^ http%1://www.%{HTTP_HOST}%{REQUEST_URI} [R=301,L]

  ##### Force url to end with slash
  RewriteEngine on
  RewriteCond %{REQUEST_FILENAME} !-f
  RewriteCond %{REQUEST_URI} !/$
  RewriteRule ^(.+)$ /$1/ [L,R=301]

  ##### Enable gzip compression for resources
  <ifModule mod_gzip.c>
      mod_gzip_on Yes
      mod_gzip_dechunk Yes
      mod_gzip_item_include file .(html?|txt|css|js|php)$
      mod_gzip_item_include handler ^cgi-script$
      mod_gzip_item_include mime ^text/.*
      mod_gzip_item_include mime ^application/x-javascript.*
      mod_gzip_item_exclude mime ^image/.*
      mod_gzip_item_exclude rspheader ^Content-Encoding:.*gzip.*
  </ifModule>
    
  ##### Cache by file Type
  <ifModule mod_expires.c>
    ExpiresActive On
    ExpiresDefault "access plus 1 seconds"

    ExpiresByType text/html "access plus 1 seconds"
    
    ExpiresByType image/x-icon "access plus 12 weeks"
    ExpiresByType image/gif "access plus 12 weeks"
    ExpiresByType image/jpeg "access plus 12 weeks"
    ExpiresByType image/jpg "access plus 12 weeks"
    ExpiresByType image/png "access plus 12 weeks"

    ExpiresByType text/css "access plus 1 days"
    ExpiresByType text/javascript "access plus 1 days"
    ExpiresByType application/x-javascript "access plus 1 days"
    ExpiresByType application/pdf "access plus 1 days"
  </ifModule>

  ${redirect_string}
    `

  writeFile(htaccess_path, htaccess_string)

  console.log('.htaccess created successfully')
}

async function fixHomePageRelPath() {
  const { homePageRelPath } = processEnv()
  if (!homePageRelPath) return

  const { app_slash } = getEnvData()

  const public_path = `${__dirname}${app_slash}..${app_slash}..${app_slash}public`
  const file_path = `${public_path}${app_slash}index.html`

  const page_data_path = `${public_path}${app_slash}page-data`
  const dest_page_data_file = `${page_data_path}/index/page-data.json`

  const data = await readFile(file_path)

  const page_html = parseHtml(data)

  const script_loader = page_html.querySelector('body #gatsby-script-loader')

  await setScriptLoaderRawText(script_loader, homePageRelPath)
  await writeFile(file_path, page_html.toString())

  const page_data_text = `{"componentChunkName":"component---src-pages-index-js","path":"${homePageRelPath}","result":{"pageContext":{}},"staticQueryHashes":[]}`
  await writeFile(dest_page_data_file, page_data_text)
}

async function createPages() {
  const { apiServerUrl } = processEnv()

  const response = await getApiData(`${apiServerUrl}seo-data`)
  const { pages } = response

  for (let i = 0; i !== pages.length; i += 1) {
    const page = pages[i]
    const { url, meta } = page
    const parsed_url = url ? JSON.parse(url) : {}
    const parsed_meta = meta ? JSON.parse(meta) : {}

    const lang_list = Object.keys(parsed_url)
    for (let j = 0; j !== lang_list.length; j += 1) {
      const k = lang_list[j]
      const u = parsed_url[k] || ''
      const m = parsed_meta[k] || ''
      if (u !== '') {
        createStaticPage(u, m)
      }
    }
  }

  //createSiteMap(pages)
  //createHtAccess()

  // this operation must always be done at the end because it changes the
  // url of the main index file which is used as a starting template for
  // all the other pages of the site
  fixHomePageRelPath()
}

createPages()
