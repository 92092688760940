/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

export class PrivacyAcceptanceComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const url = `/${locale}/privacy-policy`

    return (
      <>
        <span>{`${texts[locale].acceptance_terms_part_1} `}</span>
        <span>
          <a href={url} target="_blank" rel="noreferrer">
            {texts[locale].privacy_policy}
          </a>
        </span>
        <span>{` ${texts[locale].acceptance_terms_part_2}`}</span>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PrivacyAcceptanceComponent)
