/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'

import * as apiService from '../../../../../../../src/services/apiService'
import KitColoredBox from '../../../common_group/kitColoredBox'

import { mapStateToProps, cloneObj } from '../../../../../../../src/utils'
import { saveSessionToServer } from '../../../../../../../src/services/sessionHelper'
import { getProductIdBySlug, accessTheFastCheckout } from '../../../../utils'
import JujoButtonComponent from '../../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaeSportNeeded extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      already_purchased: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const parsed_url = 'my/service/check-if-purchased'
    const data = { service_slug: 'training_card' }
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsed_url}`,
      data
    )
    if (response.status === 200) {
      const { already_purchased } = response.data || {}
      this.setState({ loaded: true, already_purchased })
    }
  }

  handleAdd = async () => {
    const { session, updateSession, environment } = this.props
    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { cart } = session_data
    const { path } = environment
    cart.back_to_url = path
    const { products } = cart
    products[8].quantity = 1
    const returned_data = await saveSessionToServer(session_id, session_data)
    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  handleCheckOut = async () => {
    await this.handleAdd()
    accessTheFastCheckout()
  }

  removeFromCart = async () => {
    const { session, updateSession, environment } = this.props
    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { cart } = session_data
    const { path } = environment
    cart.back_to_url = path
    const { products } = cart
    products[8].quantity = 0
    const returned_data = await saveSessionToServer(session_id, session_data)
    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  render() {
    const { already_purchased, loaded } = this.state
    const { specialization, environment } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { session } = this.props
    const { session_data } = session
    const { product_data, cart } = session_data
    const { products } = cart

    const training_card_id = getProductIdBySlug(product_data, 'training_card')
    let show_button = 'add_to_cart'
    let description = texts[locale].my_sport_is_needed_description.replace(
      '[price]',
      product_data[training_card_id].final_price
    )

    const { quantity } = products[training_card_id]

    if (quantity > 0) {
      description = texts[locale].my_sport_is_needed_but_on_cart_description
      show_button = 'access_the_cart'
    }

    if (already_purchased === true) {
      description =
        texts[locale].my_sport_is_needed_but_already_purchased_description
      show_button = 'none'
    }

    if (loaded === false) {
      return <></>
    }

    return (
      <div className={classNames('w-100 d-flex flex-column')}>
        <KitColoredBox
          title={`${texts[locale].training_card} VitaeDNA`}
          customClass="bg-sport"
        >
          <div
            className={classNames('d-flex flex-column mb-4')}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          <div
            className={classNames(
              'p-3 w-100 d-flex align-items-center justify-content-center'
            )}
          >
            {show_button === 'add_to_cart' && (
              <JujoButtonComponent
                bstyle={3}
                bwidth="290px"
                blabel={texts[locale].add_to_cart}
                bicon=""
                bFontClass="fw-bold"
                bdata=""
                handleClick={this.handleCheckOut}
              />
            )}
            {show_button === 'access_the_cart' && (
              <div
                className={classNames(
                  'd-flex align-items-center justify-content-evenly'
                )}
              >
                <div className={classNames('me-3')}>
                  <JujoButtonComponent
                    bstyle={4}
                    bwidth="190px"
                    blabel={`X ${texts[locale].remove_from_cart}`}
                    bicon=""
                    bFontClass="fw-bold fst-uppercase"
                    bclasses="fc-1 border-color-1"
                    bdata=""
                    handleClick={() => this.removeFromCart()}
                  />
                </div>
                <div className={classNames('me-3')}>
                  <JujoButtonComponent
                    bstyle={3}
                    bwidth="190px"
                    blabel={texts[locale].access_the_cart}
                    bicon="cart-icon-2"
                    bFontClass="fw-bold"
                    bdata=""
                    handleClick={() => accessTheFastCheckout()}
                  />
                </div>
              </div>
            )}
          </div>
        </KitColoredBox>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeSportNeeded)
