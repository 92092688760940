import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeNoPathologiesCheckComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const {
      specialization,
      environment,
      handleValueChanged,
      dataSource,
      field,
    } = this.props

    const { name, description } = field
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const checked = dataSource[name]

    return (
      <div className={classNames('fs-8 fst-italic d-flex align-items-center')}>
        <div
          className={classNames('border', checked ? 'check-icon' : '')}
          style={{
            height: '15px',
            width: '15px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
          label={name}
          role="button"
          tabIndex={0}
          onClick={() => {
            handleValueChanged(name, !checked)
          }}
          onKeyPress={() => {
            handleValueChanged(name, !checked)
          }}
        />
        <div className={classNames('ms-1')}>
          {texts[locale][description] || description}
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeNoPathologiesCheckComponent)
