/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../../src/utils'

const classNames = require('classnames')

export class VitaePrivacyAcceptanceComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const {
      specialization,
      environment,
      field,
      handleValueChanged,
      dataSource,
    } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { name } = field

    const privacy_policy_url = `${process.env.site_url + locale}/${
      texts[locale].privacy_policy_url
    }`

    return (
      <div className={classNames('fs-8 fc-4 fst-italic')}>
        <input
          type="checkbox"
          className={classNames('me-1')}
          defaultChecked={dataSource[name]}
          style={{ display: 'inline-block', width: 'auto' }}
          onChange={e => {
            const { checked } = e.target
            handleValueChanged(name, checked)
          }}
        />
        <span>{`${texts[locale].acceptance_privacy_part_1} `}</span>
        <span>
          <a href={privacy_policy_url} target="_blank" rel="noreferrer">
            {texts[locale].acceptance_privacy_part_2}
          </a>
        </span>
        <span>{` ${texts[locale].acceptance_privacy_part_3}`}</span>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaePrivacyAcceptanceComponent)
