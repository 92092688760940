import uuid from 'react-uuid'
import {
  createMSBDef,
  getEmptyMessageBox,
} from '../components/message_box/helper'
import conditionCheckTypesMap from '../enums/conditionCheckTypesMap'
import contextTypesMap from '../enums/contextTypesMap'
import sourcesMap from '../enums/sourcesMap'
import * as apiService from '../services/apiService'
import { lsGet } from '../services/localStorage'
// eslint-disable-next-line import/no-cycle
import { resetSession } from '../services/sessionHelper'

/**
 * It takes a string and a parameter name, and returns the value of the parameter
 * @param customization_string - This is the string that is passed to the script.
 * @param param - The parameter you want to get the value of.
 * @returns The value of the param in the customization_string
 */
export function getCustomizationParam(customization_string, param) {
  const v = customization_string.split(' ')
  for (let i = 0; i < v.length; i += 1) {
    const item = v[i]
    if (item.startsWith(param)) {
      return v[i]
    }
  }
  return ''
}

export function parseBoolean(value, def_value) {
  if (typeof value === 'string') {
    const parsed_value = JSON.parse(value.toLowerCase() || def_value);
    return parsed_value;
  } else if (def_value !== undefined) {
    return JSON.parse(def_value);
  } else {
    throw new Error('Value and default value are not strings');
  }
  
  // const parsed_value = JSON.parse((value && value.toLowerCase()) || def_value)
  // return parsed_value
}

export function cloneObj(obj) {
  const { parse, stringify } = require('flatted/cjs')

  const stringified = stringify(obj || {})
  const parsed = parse(stringified)
  return parsed
}

export function ensureVariableIsLoaded(sender, value) {
  function waitForVariable(resolve, reject) {
    const steps = value.split('/')

    let prop_value = sender[steps[0]]
    if (prop_value) {
      for (let i = 1; i !== steps.length; i += 1) {
        const curr_step = steps[i]
        prop_value = prop_value[curr_step]
        if (!prop_value) {
          break
        }
      }
    }

    if (prop_value) resolve()
    else setTimeout(waitForVariable.bind(this, resolve, reject), 30)
  }

  return new Promise(waitForVariable)
}

export async function processLogout(sender) {
  const { props } = sender
  const { resetAuthentication, updateSession, session } = props

  const session_copy = cloneObj(session)
  const { session_id } = session_copy

  const session_data = await resetSession(session_id)
  session_copy.session_data = session_data

  await updateSession(session_copy)
  await resetAuthentication()

  if (typeof window !== 'undefined') {
    const current_zone = window.location.pathname.split('/')[1]
    const redirect_after_logout =
      current_zone === 'my-account' || current_zone === 'admin'
        ? `/${current_zone}/`
        : null

    if (redirect_after_logout) {
      window.location.href = redirect_after_logout
    }
  }
}

//  ██████╗ ██████╗ ██████╗ ███████╗     ██████╗ ██████╗ ███╗   ██╗███████╗██╗ ██████╗
// ██╔════╝██╔═══██╗██╔══██╗██╔════╝    ██╔════╝██╔═══██╗████╗  ██║██╔════╝██║██╔════╝
// ██║     ██║   ██║██████╔╝█████╗      ██║     ██║   ██║██╔██╗ ██║█████╗  ██║██║  ███╗
// ██║     ██║   ██║██╔══██╗██╔══╝      ██║     ██║   ██║██║╚██╗██║██╔══╝  ██║██║   ██║
// ╚██████╗╚██████╔╝██║  ██║███████╗    ╚██████╗╚██████╔╝██║ ╚████║██║     ██║╚██████╔╝
//  ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝     ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝ ╚═════╝

/**
 * It returns an object with all the core entities
 * @returns An object with all the core entities.
 */
export function coreEntitesConfig() {
  const jujo_block = require('../core_config/entities/jujo_block.json')
  const jujo_content_type = require('../core_config/entities/jujo_content_type.json')
  const jujo_content_category = require('../core_config/entities/jujo_content_category.json')
  const jujo_content = require('../core_config/entities/jujo_content.json')
  const jujo_element = require('../core_config/entities/jujo_element.json')
  const jujo_font = require('../core_config/entities/jujo_font.json')
  const jujo_font_variant = require('../core_config/entities/jujo_font_variant.json')
  const jujo_layout = require('../core_config/entities/jujo_layout.json')
  const jujo_media = require('../core_config/entities/jujo_media.json')
  const jujo_note = require('../core_config/entities/jujo_note.json')
  const jujo_page = require('../core_config/entities/jujo_page.json')
  const jujo_translation = require('../core_config/entities/jujo_translation.json')

  const core_entities = {
    jujo_block,
    jujo_content_type,
    jujo_content_category,
    jujo_content,
    jujo_element,
    jujo_font,
    jujo_font_variant,
    jujo_layout,
    jujo_media,
    jujo_note,
    jujo_page,
    jujo_translation,
  }

  return core_entities
}

/**
 * It returns an object with two properties, core_admin and reset_password. The core_admin property is
 * an object with a jujo_core property, which is an object with a bunch of properties, each of which is
 * an object with a bunch of properties. The reset_password property is an object with a bunch of
 * properties
 * @returns An object with the core admin pages and the reset password page.
 */
export function corePagesConfig() {
  const jujo_core_block = require('../core_config/pages/admin/jujo_core_block.json')
  const jujo_core_content_category = require('../core_config/pages/admin/jujo_core_content_category.json')
  const jujo_core_content_type = require('../core_config/pages/admin/jujo_core_content_type.json')
  const jujo_core_content = require('../core_config/pages/admin/jujo_core_content.json')
  const jujo_core_element = require('../core_config/pages/admin/jujo_core_element.json')
  const jujo_core_font = require('../core_config/pages/admin/jujo_core_font.json')
  const jujo_core_layout = require('../core_config/pages/admin/jujo_core_layout.json')
  const jujo_core_media = require('../core_config/pages/admin/jujo_core_media.json')
  const jujo_core_note = require('../core_config/pages/admin/jujo_core_note.json')
  const jujo_core_page = require('../core_config/pages/admin/jujo_core_page.json')
  const jujo_core_translation = require('../core_config/pages/admin/jujo_core_translation.json')

  const reset_password = require('../core_config/pages/reset_password.json')

  const core_admin = {
    jujo_core: {
      blocks: jujo_core_block,
      content_categories: jujo_core_content_category,
      content_types: jujo_core_content_type,
      contents: jujo_core_content,
      elements: jujo_core_element,
      fonts: jujo_core_font,
      layouts: jujo_core_layout,
      media: jujo_core_media,
      notes: jujo_core_note,
      pages: jujo_core_page,
      translations: jujo_core_translation,
    },
  }

  return { core_admin, reset_password }
}

//  ██████╗ ██████╗ ███╗   ██╗████████╗███████╗██╗  ██╗████████╗
// ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔════╝╚██╗██╔╝╚══██╔══╝
// ██║     ██║   ██║██╔██╗ ██║   ██║   █████╗   ╚███╔╝    ██║
// ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══╝   ██╔██╗    ██║
// ╚██████╗╚██████╔╝██║ ╚████║   ██║   ███████╗██╔╝ ██╗   ██║
//  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚══════╝╚═╝  ╚═╝   ╚═╝

/**
 * It returns the context of the current page
 * @returns The context of the current page.
 */
export function envContext() {
  const environment = lsGet('environment')
  const { path } = environment
  const path_segments = path.split('/')

  const first_segment = path_segments[1]
  let context = contextTypesMap.frontend
  switch (first_segment) {
    case 'admin':
      context = contextTypesMap.admin
      break
    case 'my-account':
      context = contextTypesMap.my_account
      break
    default:
      context = contextTypesMap.my_account
  }

  return context
}

//  ██████╗ ██████╗ ███╗   ██╗██████╗ ██╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
// ██╔════╝██╔═══██╗████╗  ██║██╔══██╗██║╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
// ██║     ██║   ██║██╔██╗ ██║██║  ██║██║   ██║   ██║██║   ██║██╔██╗ ██║███████╗
// ██║     ██║   ██║██║╚██╗██║██║  ██║██║   ██║   ██║██║   ██║██║╚██╗██║╚════██║
// ╚██████╗╚██████╔╝██║ ╚████║██████╔╝██║   ██║   ██║╚██████╔╝██║ ╚████║███████║
//  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚═════╝ ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

/**
 * It checks if a condition is met
 * @param sender - the component that is calling the function
 * @param conditions - an array of conditions to check
 * @returns A function that takes in two parameters, sender and conditions.
 */
export function checkGenericCondition(sender, conditions) {
  const { props } = sender
  const { authentication } = props
  const { user } = authentication
  let errors = false
  for (let j = 0; j !== conditions.length; j += 1) {
    const cond = conditions[j]
    const { source, relatedField, checkType, check } = cond

    let relFieldValue = null
    if (source === sourcesMap.user) {
      relFieldValue = user[relatedField] || ''
    }

    if (checkType === conditionCheckTypesMap.contains) {
      errors = !check.includes(relFieldValue)
    } else if (checkType === conditionCheckTypesMap.equals_to) {
      if (Array.isArray(check)) {
        let meet = false
        for (let x = 0; x !== check.length; x += 1) {
          const currentCheck = check[x]
          if (currentCheck === relFieldValue) {
            meet = true
            break
          }
        }
        errors = !meet
      } else {
        const relFieldValueString =
          relFieldValue !== null && relFieldValue !== undefined
            ? relFieldValue.toString()
            : ''

        errors = relFieldValueString !== check.toString()
      }
    } else if (checkType === conditionCheckTypesMap.greater_than) {
      if (relFieldValue === undefined) {
        errors = true
      } else {
        errors = parseInt(relFieldValue, 10) <= parseInt(check, 10)
      }
    } else if (checkType === conditionCheckTypesMap.not_equals_to) {
      const relFieldValueString = relFieldValue ? relFieldValue.toString() : ''
      errors = relFieldValueString === check.toString()
    } else if (checkType === conditionCheckTypesMap.lower_than_or_equal_to) {
      if (relFieldValue === undefined) {
        errors = true
      } else {
        errors = parseInt(relFieldValue, 10) > parseInt(check, 10)
      }
    }

    if (errors === true) {
      break
    }
  }

  return !errors
}

// ██████╗ ███████╗██████╗ ██╗   ██╗██╗  ██╗
// ██╔══██╗██╔════╝██╔══██╗██║   ██║╚██╗██╔╝
// ██████╔╝█████╗  ██║  ██║██║   ██║ ╚███╔╝
// ██╔══██╗██╔══╝  ██║  ██║██║   ██║ ██╔██╗
// ██║  ██║███████╗██████╔╝╚██████╔╝██╔╝ ██╗
// ╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝ ╚═╝  ╚═╝

/**
 * It takes the state of the Redux store as an argument and returns an object that maps the state to
 * the props of the component
 * @param state - The state of the Redux store.
 * @returns The state of the application.
 */
export function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    cart: state.cart,
    dynamicForm: state.dynamicForm,
    environment: state.environment,
    genericData: state.genericData,
    genericDataBox2: state.genericDataBox2,
    genericDataBox3: state.genericDataBox3,
    infoBox: state.infoBox,
    injectedComponents: state.injectedComponents,
    session: state.session,
    specialization: state.specialization,
  }
}

// ██████╗ ██╗███╗   ██╗██████╗ ███████╗██████╗
// ██╔══██╗██║████╗  ██║██╔══██╗██╔════╝██╔══██╗
// ██████╔╝██║██╔██╗ ██║██║  ██║█████╗  ██████╔╝
// ██╔══██╗██║██║╚██╗██║██║  ██║██╔══╝  ██╔══██╗
// ██████╔╝██║██║ ╚████║██████╔╝███████╗██║  ██║
// ╚═════╝ ╚═╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚═╝  ╚═╝

/**
 * Binds an element to a component.
 * @param sender - The component that is calling the function.
 * @param name - The name of the property to bind to the element.
 * @param element - The element to bind to the component.
 */
export function bindElementToComponent(sender, name, element) {
  // eslint-disable-next-line no-param-reassign
  sender[name] = element
}

/**
 * It returns the value of the property of the sender object whose name is passed as the second
 * argument
 * @param sender - The object that is sending the message.
 * @param name - The name of the element to retrieve.
 * @returns The value of the property name on the object sender.
 */
export function retrieveBindedElement(sender, name) {
  return sender[name]
}

// ██╗███╗   ██╗     ██╗███████╗ ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
// ██║████╗  ██║     ██║██╔════╝██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
// ██║██╔██╗ ██║     ██║█████╗  ██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
// ██║██║╚██╗██║██   ██║██╔══╝  ██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
// ██║██║ ╚████║╚█████╔╝███████╗╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
// ╚═╝╚═╝  ╚═══╝ ╚════╝ ╚══════╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

/**
 * It takes a component definition and adds it to the list of injected components
 * @param sender - The component that is calling the injectComponent function.
 * @param identifier - The identifier of the component you want to inject.
 * @param definition - The definition of the component.
 */
export function injectComponent(sender, identifier, definition) {
  const { props } = sender
  const { injectedComponents, updateInjectedComponents } = props
  const updated_injected_components = cloneObj(injectedComponents)
  const { list } = updated_injected_components
  list[identifier] = definition

  updateInjectedComponents(updated_injected_components)
}

/**
 * It removes the component from the list of injected components
 * @param sender - The component that is calling the function.
 * @param identifier - This is the unique identifier for the component.
 */
export function retrieveComponent(sender, identifier) {
  const { props } = sender
  const { injectedComponents, updateInjectedComponents } = props
  const updated_injected_components = { ...injectedComponents }
  const { list } = updated_injected_components
  delete list[identifier]

  updateInjectedComponents(updated_injected_components)
}

// ████████╗██████╗  █████╗ ███╗   ██╗███████╗██╗      █████╗ ████████╗██╗ ██████╗ ███╗   ██╗███████╗
// ╚══██╔══╝██╔══██╗██╔══██╗████╗  ██║██╔════╝██║     ██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
//    ██║   ██████╔╝███████║██╔██╗ ██║███████╗██║     ███████║   ██║   ██║██║   ██║██╔██╗ ██║███████╗
//    ██║   ██╔══██╗██╔══██║██║╚██╗██║╚════██║██║     ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║╚════██║
//    ██║   ██║  ██║██║  ██║██║ ╚████║███████║███████╗██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║███████║
//    ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

/**
 * It takes a sender object and a value, and returns the translation of the value in the locale of the
 * sender
 * @param sender - The component that is calling the function.
 * @param value - The value to translate.
 * @returns The value of the key in the locale object.
 */
export function translate(value) {
  const environment = lsGet('environment')
  const session = lsGet('session')

  const { locale } = environment

  const { session_data } = session
  const { jujo_translations } = session_data

  const translated_value = jujo_translations[locale][value] || value

  return translated_value
}

// ███████╗████████╗██████╗ ██╗███╗   ██╗ ██████╗ ███████╗
// ██╔════╝╚══██╔══╝██╔══██╗██║████╗  ██║██╔════╝ ██╔════╝
// ███████╗   ██║   ██████╔╝██║██╔██╗ ██║██║  ███╗███████╗
// ╚════██║   ██║   ██╔══██╗██║██║╚██╗██║██║   ██║╚════██║
// ███████║   ██║   ██║  ██║██║██║ ╚████║╚██████╔╝███████║
// ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝

/**
 * It takes a string, trims it, replaces spaces with underscores, and converts it to lowercase
 * @param s - The string to slugify
 * @returns the value of the variable 'slug'
 */
export function slugifyString(str) {
  const slug = str.trimStart().trimEnd().replace(/\s/g, '_').toLowerCase()
  return slug
}

/**
 * It takes a string, replaces all underscores with spaces, and returns the result.
 * @param str - The string to be deslugified.
 * @returns A function that takes a string as an argument and returns a string with all underscores
 * replaced with spaces.
 */
export function deslugifyString(str) {
  const deslugged = str.replaceAll('_', ' ')
  return deslugged
}

/**
 * It encodes a string for use in a URL
 * @param str - The string to encode.
 * @returns A function that takes a string as an argument and returns a string.
 */
export function jujoEncoder(str) {
  return encodeURIComponent(str).replace(
    /[!'()*]/g,
    c => `%${c.charCodeAt(0).toString(16)}`
  )
}

/**
 * It takes a string, decodes it, and returns the decoded string
 * @param str - The string to be decoded.
 * @returns The function jujoDecoder is being returned.
 */
export function jujoDecoder(str) {
  return decodeURIComponent(str)
}

/**
 * It replaces all HTML entities with their corresponding characters
 * @param str - The string to be decoded.
 * @returns A function that takes a string as an argument and returns a string with the HTML entities
 * replaced with the corresponding characters.
 */
export function jujoHtmlDecoder(str) {
  return str.replace(/&#([0-9]{1,3});/gi, (_match, numStr) => {
    const num = parseInt(numStr, 10)
    return String.fromCharCode(num)
  })
}

export function jujoAsciiDecode(ascii) {
  /* Creating an array of unique values that start with 'x' and are 3 characters long. */
  const values_to_replace = Array.from(
    new Set(ascii.split('_').filter(x => x.startsWith('x') && x.length === 3))
  )

  let decoded_string = ascii

  values_to_replace.forEach(element => {
    /* Creating a variable called encoded_char and assigning it the value of the string "_" plus the value
    of the variable element plus the string "_". */
    const encoded_char = `_${element}_`

    /* The above code is converting a hexadecimal value to a decimal value and then converting that decimal
    value to a character. */
    const int_value = parseInt(element.slice(1), 16)
    const decoded_char = String.fromCharCode(int_value)

    /* Replacing all instances of the encoded character with the decoded character. */
    const replace_regex = new RegExp(encoded_char, 'g')
    decoded_string = decoded_string.replace(replace_regex, decoded_char)
  })

  return decoded_string
}

/**
 * Convert a string to hexadecimal.
 * @param str - The string to be encoded.
 * @returns A hexadecimal string
 */
export function jujoHexEncode(str) {
  const hex = Buffer.from(str, 'utf8').toString('hex')
  return hex
}

/**
 * It takes a hex string and returns a utf8 string
 * @param hex - The hex string to decode.
 * @returns A string
 */
export function jujoHexDecode(hex) {
  if (typeof hex !== 'string') return ''
  const str = Buffer.from(hex, 'hex').toString('utf8')
  return str || hex
}

/**
 * It generates a random string of a given length
 * @param length - The length of the string you want to generate.
 * @returns A random string of the length specified.
 */
export function randomString(length) {
  const characters =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

  let key = ''
  for (let i = 0; i < length; i += 1) {
    const random_idx = Math.floor(Math.random() * (characters.length - 1))
    key += characters[random_idx]
  }

  return key
}

// ██╗  ██╗████████╗███╗   ███╗██╗         ██████╗  ██████╗ ███╗   ███╗
// ██║  ██║╚══██╔══╝████╗ ████║██║         ██╔══██╗██╔═══██╗████╗ ████║
// ███████║   ██║   ██╔████╔██║██║         ██║  ██║██║   ██║██╔████╔██║
// ██╔══██║   ██║   ██║╚██╔╝██║██║         ██║  ██║██║   ██║██║╚██╔╝██║
// ██║  ██║   ██║   ██║ ╚═╝ ██║███████╗    ██████╔╝╚██████╔╝██║ ╚═╝ ██║
// ╚═╝  ╚═╝   ╚═╝   ╚═╝     ╚═╝╚══════╝    ╚═════╝  ╚═════╝ ╚═╝     ╚═╝

/**
 * It locks the body scroll by setting the overflow to hidden
 */
export function lockBodyScroll() {
  if (typeof document !== 'undefined') {
    document.body.style.overflow = 'hidden'
  }
}

/**
 * If the document exists, set the overflow of the body to unset.
 */
export function unlockBodyScroll() {
  if (typeof document !== 'undefined') {
    document.body.style.overflow = 'unset'
  }
}

/**
 * It scrolls the page to the top after 100 milliseconds
 */
export async function scrollPageOnTop() {
  if (typeof window !== 'undefined') {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}

/**
 * It takes a string of CSS code, creates a style element, and appends it to the document
 * @param document - The document object of the page.
 * @param code - The CSS code to be embedded
 * @param target - The target element to append the style to. Defaults to `head`.
 * @param identifier - The id of the style tag.
 */
export function embedStyleCode(document, code, target, identifier) {
  const style = document.createElement('style')
  style.setAttribute('id', identifier || uuid())

  const styleBody = document.createTextNode(code)

  style.appendChild(styleBody)
  document[target || 'head'].appendChild(style)
}

/**
 * It removes a style element from the document
 * @param document - The document object.
 * @param identifier - The identifier of the style element.
 */
export function removeStyleCode(document, identifier) {
  const style = document.getElementById(identifier)
  style.remove()
}

/**
 * It takes a string of JavaScript code, creates a script element, adds the code to the script element,
 * and then adds the script element to the document
 * @param document - The document object of the page you want to embed the code into.
 * @param code - The code to embed
 * @param target - The target element to append the script to. Defaults to 'head'.
 */
export function embedJSCode(document, code, target) {
  const script = document.createElement('script')
  const scriptText = document.createTextNode(code)

  script.appendChild(scriptText)
  document[target || 'head'].appendChild(script)
}

/**
 * It creates a script tag, sets the src attribute to the url passed in, sets the async attribute to
 * true, sets the charset to UTF-8, and then appends the script tag to the head of the document
 * @param document - The document object of the page.
 * @param url - The URL of the script to embed.
 * @param target - The target element to append the script to. Defaults to 'head'.
 */
export function embedJSFile(document, url, target) {
  const script = document.createElement('script')

  script.src = url
  script.async = true
  script.charSet = 'UTF-8'
  document[target || 'head'].appendChild(script)
}

//  ██████╗ ██╗   ██╗███████╗██████╗ ██╗   ██╗███████╗████████╗██████╗ ██╗███╗   ██╗ ██████╗
// ██╔═══██╗██║   ██║██╔════╝██╔══██╗╚██╗ ██╔╝██╔════╝╚══██╔══╝██╔══██╗██║████╗  ██║██╔════╝
// ██║   ██║██║   ██║█████╗  ██████╔╝ ╚████╔╝ ███████╗   ██║   ██████╔╝██║██╔██╗ ██║██║  ███╗
// ██║▄▄ ██║██║   ██║██╔══╝  ██╔══██╗  ╚██╔╝  ╚════██║   ██║   ██╔══██╗██║██║╚██╗██║██║   ██║
// ╚██████╔╝╚██████╔╝███████╗██║  ██║   ██║   ███████║   ██║   ██║  ██║██║██║ ╚████║╚██████╔╝
//  ╚══▀▀═╝  ╚═════╝ ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝ ╚═════╝

/**
 * If the window object exists, then get the query string value from the window object, otherwise
 * return null.
 * @param name - The name of the query string parameter you want to get the value of.
 * @returns the value of the query string parameter.
 */
export function getQsValue(name) {
  if (typeof window !== 'undefined') {
    const parsed_qs = decodeURIComponent(window.location.search).replaceAll(
      '[and]',
      '&'
    )

    const match = RegExp(`[?&]${name}=([^&]*)`).exec(parsed_qs)
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
  }

  return null
}

/**
 * It returns the query string parameters from the current URL
 * @returns A function that returns the query string parameters from the current URL.
 */
export function getQueryStringParams() {
  let params = null
  if (typeof window !== 'undefined') {
    const url = new URL(window.location.href)
    params = new URLSearchParams(url.search)
  }
  return params
}

/**
 * It takes a URL, updates the query string parameters, and then updates the URL in the browser
 * @param params - The URLSearchParams object
 * @param key - The key of the query string parameter you want to update.
 * @param value - The value of the parameter you want to update.
 */
export function updateQueryStringParams(params, key, value) {
  if (typeof window !== 'undefined') {
    params.set(key, value)

    const newUrl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${params.toString()}`
    window.history.pushState({ path: newUrl }, '', newUrl)
  }
}

/**
 * It removes parameters from the query string of the current URL
 * @param params - An array of strings that represent the parameters to remove from the query string.
 */
export function removeParametersFromQueryString(params) {
  if (typeof window !== 'undefined') {
    const url = new URL(window.location.href)
    const current_params = new URLSearchParams(url.search)

    for (let i = 0; i !== params.length; i += 1) {
      const param = params[i]
      current_params.delete(param)
    }

    const string_current_params = current_params.toString()

    const new_querystring =
      string_current_params.length === 0 ? '' : `?${string_current_params}`

    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${new_querystring}`

    window.history.pushState({ path: newUrl }, '', newUrl)
  }
}

// ███████╗██╗██╗     ███████╗███████╗
// ██╔════╝██║██║     ██╔════╝██╔════╝
// █████╗  ██║██║     █████╗  ███████╗
// ██╔══╝  ██║██║     ██╔══╝  ╚════██║
// ██║     ██║███████╗███████╗███████║
// ╚═╝     ╚═╝╚══════╝╚══════╝╚══════╝

/**
 * It uploads a file to the server
 * @param field - the field object from the form
 * @param file - the file to be uploaded
 * @param fieldList - the list of fields that are being submitted
 * @returns The file id
 */
export async function uploadFileToServer(field, file, fieldList) {
  const { source, relatedField, defValue } = field.fileOwner
  let owner_id = defValue
  if (source === sourcesMap.dynamicField) {
    owner_id = fieldList[relatedField]
  } else if (source === sourcesMap.static) {
    owner_id = defValue
  }

  const formData = new FormData()
  formData.append('file', file)
  formData.append('entity', field.relatedEntity)
  formData.append('visibility', field.visibility)
  formData.append('owner_id', owner_id)

  const result = await apiService.httpPost(
    `${process.env.apiUrl}file/upload`,
    formData
  )

  return result
}

/**
 * It takes a file, reads it as a base64 string, and returns a promise that resolves to the base64
 * string
 * @param file - The file to be converted to base64
 * @returns A promise that resolves to the base64 encoding of the file.
 */
export async function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

// ██████╗ ██╗   ██╗████████╗███████╗███████╗
// ██╔══██╗╚██╗ ██╔╝╚══██╔══╝██╔════╝██╔════╝
// ██████╔╝ ╚████╔╝    ██║   █████╗  ███████╗
// ██╔══██╗  ╚██╔╝     ██║   ██╔══╝  ╚════██║
// ██████╔╝   ██║      ██║   ███████╗███████║
// ╚═════╝    ╚═╝      ╚═╝   ╚══════╝╚══════╝

/**
 * It converts a number of bytes into a human-readable string
 * @param bytes - The number of bytes to convert.
 * @returns A string with the size of the file in KB, MB, GB, or TB.
 */
export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return 'n/a'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) return `${bytes} ${sizes[i]})`
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
}

/**
 * It takes a string like "1MB" or "2kB" and returns the number of bytes
 * @param size - The size of the file in bytes.
 * @returns A function that takes a string and returns the number of bytes.
 */
export function bytesValue(size) {
  const MB = 1048576
  const kB = 1024

  let multiplier = 0
  if (size.endsWith('MB')) {
    multiplier = MB
  } else {
    multiplier = kB
  }
  const unit = parseInt(size.slice(0, -2), 10)
  const bytes = unit * multiplier
  return bytes
}

// ██████╗  █████╗ ████████╗███████╗
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
// ██║  ██║███████║   ██║   █████╗
// ██║  ██║██╔══██║   ██║   ██╔══╝
// ██████╔╝██║  ██║   ██║   ███████╗
// ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

/**
 * It takes a MySQL date string and returns a date in Italian format
 * @param date - The date to be formatted.
 * @returns the result of the function.
 */
export function mysqlDateToIta(date) {
  let t
  let result = null

  if (typeof date === 'string') {
    t = date.split(/[- :]/)

    // When t[3], t[4] and t[5] are missing they defaults to zero
    result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0)
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      // Day: '2-digit',
    }
    result = result.toLocaleDateString('it-IT', options)
  }

  return result
}

/**
 * "Given a string in the format YYYY-MM-DD, return true if the date is a weekend, false otherwise."
 *
 * The function is written in JavaScript, but it's not a JavaScript-specific function. It's a general
 * purpose function that could be written in any language
 * @param s - the date string in the format of yyyy-MM-dd
 * @returns A boolean value.
 */
export function dateIsWeekend(s) {
  const year = s.substring(0, 4)
  const month = s.substring(5, 7)
  const day = s.substring(8, 10)
  const myDate = new Date()
  myDate.setFullYear(year)
  myDate.setMonth(month - 1)
  myDate.setDate(day)
  return myDate.getDay() === 6 || myDate.getDay() === 0
}

/**
 * It takes a date string, and returns a formatted date string
 * @param date_string - the date string to format
 * @param date_only - boolean, if true, only the date will be returned
 * @param options - an object with the following properties:
 * @returns A function that takes in a date string, a boolean, and an options object.
 */
export function formatDate(date_string, date_only, options) {
  const default_options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  const opt = options || default_options
  const date = new Date(date_string)
  const locale = 'it-IT'

  const output = date_only
    ? date.toLocaleDateString(locale, opt)
    : date.toLocaleTimeString(locale, opt)

  return output
}

/**
 * It takes a MySQL timestamp, a locale and a boolean value and returns a formatted date string
 * @param locale - the locale to use, default is 'it'
 * @param timestamp - the timestamp to convert
 * @param addHours - if true, the function will return the date with the hours, minutes and seconds.
 * @returns A function that takes 3 parameters: locale, timestamp, and addHours.
 */
export function mysql_timestamp_to_date(locale, timestamp, addHours) {
  const loc = locale || 'it'
  const add_h = addHours || false
  const ts = new Date(timestamp)

  const params = {}
  params.year = 'numeric'
  params.month = 'short'
  params.day = '2-digit'

  if (add_h) {
    params.hour = '2-digit'
    params.minute = '2-digit'
    params.seconds = '2-digit'
  }

  return new Intl.DateTimeFormat([loc], params).format(ts)
}

// ██████╗ ███████╗███████╗ █████╗ ██╗   ██╗██╗  ████████╗    ██████╗ ███████╗██╗  ██╗ █████╗ ██╗   ██╗██╗ ██████╗ ██████╗ ███████╗
// ██╔══██╗██╔════╝██╔════╝██╔══██╗██║   ██║██║  ╚══██╔══╝    ██╔══██╗██╔════╝██║  ██║██╔══██╗██║   ██║██║██╔═══██╗██╔══██╗██╔════╝
// ██║  ██║█████╗  █████╗  ███████║██║   ██║██║     ██║       ██████╔╝█████╗  ███████║███████║██║   ██║██║██║   ██║██████╔╝███████╗
// ██║  ██║██╔══╝  ██╔══╝  ██╔══██║██║   ██║██║     ██║       ██╔══██╗██╔══╝  ██╔══██║██╔══██║╚██╗ ██╔╝██║██║   ██║██╔══██╗╚════██║
// ██████╔╝███████╗██║     ██║  ██║╚██████╔╝███████╗██║       ██████╔╝███████╗██║  ██║██║  ██║ ╚████╔╝ ██║╚██████╔╝██║  ██║███████║
// ╚═════╝ ╚══════╝╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚═╝       ╚═════╝ ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝  ╚═══╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

/**
 * It opens the file in a new tab if it's not an image, and if it is an image, it injects a component
 * that renders the image
 * @param sender - The sender of the message.
 * @param url - The url of the file to be opened.
 */
export async function defaultHandleOpenJujoFileFromServer(sender, url) {
  if (typeof window !== 'undefined') {
    const file_ext = url.split('?')[0].split('/').slice(-1).pop()
    const img_ext_list = ['jpg', 'jpeg', 'png', 'bmp', 'tiff']
    const render_as_image = img_ext_list.includes(file_ext)

    if (render_as_image) {
      const comp_id = uuid()

      const component_definition = {
        specialized: false,
        path: 'common/file_renderer',
        data: {
          url,
        },
      }

      injectComponent(sender, comp_id, component_definition)
    } else {
      window.open(url)
    }
  }
}

/**
 * It either executes the action immediately, or it injects a confirmation box component into the DOM
 * @param btnDef - The button definition object.
 * @param sender - the component that sent the event
 */
export async function defaultHandleGenericBtnClick(btnDef, sender) {
  const { specialized, actionPath, actionName, confirmation } = btnDef

  async function executeAction() {
    if (specialized !== undefined && specialized === false) {
      const core_actions = await require(`../${actionPath}`)
      core_actions[actionName](sender)
    } else {
      const specialized_actions =
        await require(`../../vitae_specializations/src/${process.env.client}/${actionPath}`)
      specialized_actions[actionName](sender)
    }
  }

  if (confirmation === undefined) {
    executeAction()
  } else {
    const { message } = confirmation

    const comp_id = uuid()
    const component_definition = {
      specialized: false,
      path: 'common/confirmation_box',
      data: {
        message,
        executeAction,
      },
    }

    injectComponent(sender, comp_id, component_definition)
  }
}

/**
 * It retrieves the message box component and then calls the `handleOnComplete` function if it exists
 * @param params - The parameters passed to the message box.
 */
export async function defaultSuccessCloseMessageBox(params) {
  const { sender, msbox_instance_id } = params
  const { props } = sender
  const { gridComponent, handleOnComplete } = props
  const { loadGrid } = gridComponent || {}

  await retrieveComponent(sender, msbox_instance_id)

  // manage editble component if exists
  if (handleOnComplete) {
    handleOnComplete()
  }

  // manage jujo grid if exists
  if (loadGrid) {
    loadGrid(false)
  }
}

/**
 * It retrieves the component instance with the given id
 * @param params - {
 */
export async function defaultErrorCloseMessageBox(params) {
  const { sender, msbox_instance_id } = params

  await retrieveComponent(sender, msbox_instance_id)
}

/**
 * It takes a response object,
 * and depending on the status code, it either shows a success message or an error
 * message
 * @param sender - the component that is sending the request
 * @param customizations - an object with the following properties:
 * @param response - the response object returned by the server
 */
export async function defaultManageResponse(sender, customizations, response) {
  const { props } = sender
  const { specialization, environment } = props

  const { translations } = specialization
  const { texts } = translations
  const { locale } = environment

  const { success_message, on_success_callback, on_error_callback } =
    customizations

  const messageBox = getEmptyMessageBox()
  if (response) {
    const { status, data } = response

    if (status === 200 || status === 201) {
      const { message } = data
      const callback_function =
        on_success_callback || defaultSuccessCloseMessageBox
      messageBox.icon = 'confirm-icon-color'
      messageBox.message =
        success_message ||
        message ||
        texts[locale].request_successfully_completed

      const continue_btn = createMSBDef(
        texts[locale].continue_browsing,
        callback_function,
        {
          sender,
          data: data.data || {},
          msbox_instance_id: messageBox.id,
        },
        true
      )
      messageBox.buttons.push(continue_btn)
    } else {
      const callback_function = on_error_callback || defaultErrorCloseMessageBox

      messageBox.icon = 'warning-icon-color'
      messageBox.title = texts[locale].warning

      let { message } = data

      if (data.fields) {
        message += '<div class="fs-7">'
        const kList = Object.keys(data.fields)
        for (let i = 0; i !== kList.length; i += 1) {
          const k = kList[i]
          const KMsgList = data.fields[k]
          const msg = KMsgList[0]
          message += `<div>${msg}</div>`
        }
        message += '</div>'
      }

      messageBox.message = message
      const ok_btn = createMSBDef(
        texts[locale].ok,
        callback_function,
        {
          sender,
          data: data.data || {},
          msbox_instance_id: messageBox.id,
        },
        true
      )
      messageBox.buttons.push(ok_btn)
    }
  }

  const component_definition = {
    specialized: false,
    path: 'message_box',
    data: {
      messageBox,
    },
  }

  injectComponent(sender, messageBox.id, component_definition)
}
