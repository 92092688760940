import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeCartContent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { customClass, session } = this.props
    const { session_data } = session || {}
    const { cart_content } = session_data || {}
    return (
      <div className={classNames(`w-100 d-flex flex-column ${customClass}`)}>
        {cart_content &&
          cart_content.map((block, block_index) => (
            <div
              key={block_index}
              className={classNames('border-top bg-3 p-3 ')}
            >
              {block.map((row, row_index) => (
                <div
                  key={row_index}
                  className={classNames(
                    'd-flex flex-row align-items-center justify-content-between'
                  )}
                >
                  <div className={classNames(row.class_label)}>{row.label}</div>
                  <div className={classNames(row.class_value)}>{row.value}</div>
                </div>
              ))}
            </div>
          ))}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeCartContent)
