import requestTypesMap from '../../../../../src/enums/requestTypesMap'
import { cloneObj } from '../../../../../src/utils'

// ██████╗  █████╗ ███╗   ██╗ ██████╗ ███████╗██████╗  ██████╗ ██╗   ██╗███████╗
// ██╔══██╗██╔══██╗████╗  ██║██╔════╝ ██╔════╝██╔══██╗██╔═══██╗██║   ██║██╔════╝
// ██║  ██║███████║██╔██╗ ██║██║  ███╗█████╗  ██████╔╝██║   ██║██║   ██║███████╗
// ██║  ██║██╔══██║██║╚██╗██║██║   ██║██╔══╝  ██╔══██╗██║   ██║██║   ██║╚════██║
// ██████╔╝██║  ██║██║ ╚████║╚██████╔╝███████╗██║  ██║╚██████╔╝╚██████╔╝███████║
// ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝
// using dirty global variables like this sucks!
// I am doing it this way because redux limits the exchange of
// functions between distinct objects and I am aware that sooner
// or later it will be eliminated, so for now I close my eyes and move on
// *** PLEASE DO NOT USE GLOBAL VARIABLES AS IT HAPPENS HERE !!! ***
let global_last_used_sender = null

export async function reloadGridOnManageOrdercompleted() {
  const { props } = global_last_used_sender
  const { gridComponent } = props
  const { loadGrid } = gridComponent

  loadGrid(true)
}

export async function manageOrderFulfillment(sender) {
  const { props } = sender
  const { row, dynamicForm, updateDynamicFormIntoRedux } = props

  // this is a despicable thing for jujo!
  // don't take this as an example!
  // this is crap!! DON'T DO IT AGAIN
  global_last_used_sender = sender

  const dForm = cloneObj(dynamicForm)
  dForm.entity = 'order_fulfillment'
  dForm.type = requestTypesMap.update
  dForm.source = row
  dForm.visible = true
  dForm.callback.onSave = {
    action: 'reloadGridOnManageOrdercompleted',
    params: {},
  }

  await updateDynamicFormIntoRedux(dForm)
}
