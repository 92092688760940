/* eslint-disable jsx-a11y/label-has-associated-control */
import HTMLReactParser from 'html-react-parser'
import React from 'react'
import { connect } from 'react-redux'
import apiCallMap from '../../../enums/apiCallMap'
import { httpGet } from '../../../services/apiService'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../services/servicesHelper'

import * as actionCreators from '../../../store/actions'
import { cloneObj, mapStateToProps } from '../../../utils'
import JujoLoading from '../../loading'
import NotificationComponent from '../notification'

const classNames = require('classnames')

export class ECRadioCheckFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      field_value: {},
      parsed_options: [],
      available_option_keys: [],
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { field, dataSource } = this.props
    const { name } = field

    const parsed_options = await this.initOptions()
    await this.setState({ parsed_options })

    const field_value = (dataSource && dataSource[name]) || {}
    const available_option_keys = this.getAvailableOptionKeys()
    this.setState({ initialized: true, field_value, available_option_keys })
  }

  initOptions = async () => {
    const { field, environment, authentication, dataSource } = this.props
    const { options, apis } = field

    if (options) {
      return options
    }

    const { getData } = apis
    const {
      apiCall,
      relatedEntity,
      requestType,
      defaultFilters,
      placeholderMapping,
    } = getData

    const requestData = baseRequestObject(
      apiCallMap[apiCall],
      relatedEntity,
      requestType,
      environment,
      authentication
    )

    requestData.dynamicFields = dataSource
    requestData.defaultFilters = defaultFilters
    requestData.placeholderMapping = placeholderMapping
    const parsedEp = parseEndpoint(requestData)

    const result = await httpGet(`${process.env.apiUrl}${parsedEp}`)

    if (result) {
      const { status, data } = result
      if (status === 200) {
        return data.data
      }
    }

    return []
  }

  getAvailableOptionKeys = () => {
    const { parsed_options } = this.state

    const available_option_keys = []
    for (let i = 0; i !== parsed_options.length; i += 1) {
      const opt = parsed_options[i]
      const { key } = opt

      available_option_keys.push(key.toString())
    }

    return available_option_keys
  }

  processOnChange = async (key, value) => {
    const { field, handleValueChanged } = this.props
    const { name, type } = field

    const { field_value } = this.state

    const current_field_value = cloneObj(field_value)

    if (type === 'radio') {
      const key_list = Object.keys(current_field_value)
      for (let i = 0; i !== key_list.length; i += 1) {
        const curr_key = key_list[i]
        current_field_value[curr_key] = false
      }
    }
    current_field_value[key] = value

    await this.setState({ field_value: current_field_value })
    handleValueChanged(name, current_field_value)
  }

  getCheckedValue = opt_key => {
    const { field, dataSource } = this.props
    const { name } = field

    const field_instance = (dataSource && dataSource[name]) || {}

    return field_instance[opt_key] || false
  }

  notifyRemovedOptions = () => {
    const { available_option_keys } = this.state
    const { environment, specialization, field, dataSource } = this.props

    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations

    const { name } = field
    const field_instance = (dataSource && dataSource[name]) || {}

    let removed_option_found = false
    const k_list = Object.keys(field_instance)
    for (let i = 0; i !== k_list.length; i += 1) {
      const k = k_list[i].toString()
      if (field_instance[k] === true) {
        if (available_option_keys.indexOf(k) < 0) {
          removed_option_found = true
          break
        }
      }
    }

    const html = []
    if (removed_option_found) {
      html.push(
        <NotificationComponent
          key="removed_option_notification"
          body={texts[locale].removed_option_warning_message}
        />
      )
    }
    return html
  }

  render() {
    const { initialized, parsed_options } = this.state
    const { field } = this.props

    const { name, type, direction } = field
    // const { name, type, direction, hidden_input, options } = field
    const horizontal = !!(direction && direction === 'horizontal')

    const container_class = []
    if (horizontal) {
      container_class.push('row g-0')
    } else {
      container_class.push('list-group', 'mx-0')
      if (type === 'radio') {
        container_class.push('d-grid')
      }
    }

    return (
      <>
        {!initialized && <JujoLoading />}
        {initialized && (
          <>
            <div
              className={classNames(container_class.join(' '))}
              style={{ gap: type === 'radio' ? '0.5rem' : '' }}
            >
              {this.notifyRemovedOptions()}
              {parsed_options.map(opt => {
                const { key } = opt
                const is_checked = this.getCheckedValue(key)

                if (type === 'checkbox') {
                  return (
                    <label
                      key={key}
                      className={classNames('list-group-item d-flex gap-2')}
                    >
                      <input
                        className={classNames(
                          'form-check-input flex-shrink-0',
                          is_checked ? 'bg-1 border-color-1' : ''
                        )}
                        type={type}
                        name={name}
                        id={key}
                        onChange={async e => {
                          const { checked } = e.target
                          await this.processOnChange(key, checked)
                        }}
                        checked={is_checked}
                      />
                      <span>{HTMLReactParser(opt.label, {})}</span>
                    </label>
                  )
                }
                return (
                  <div key={key} className={classNames('col')}>
                    <input
                      className={classNames(
                        'list-group-item-check position-absolute d-none'
                      )}
                      type={type}
                      name="listGroupCheckableRadios"
                      id={key}
                      checked={is_checked}
                      onChange={async e => {
                        const { checked } = e.target
                        await this.processOnChange(key, checked)
                      }}
                    />
                    <label
                      className={classNames(
                        'p-3 border rounded-5 w-100',
                        is_checked ? 'bg-1 border-color-1 fc-white' : ''
                      )}
                      htmlFor={key}
                    >
                      {HTMLReactParser(opt.label, {})}
                    </label>
                  </div>
                  // <div
                  //   key={key}
                  //   className={classNames(
                  //     'd-flex',
                  //     horizontal
                  //       ? 'col-sm align-items-start justify-content-center bg-light-gray p-2 mx-2 mb-2'
                  //       : 'align-items-center',
                  //     is_checked && hidden_input && hidden_input === true
                  //       ? 'bshadow-c1-2px'
                  //       : ''
                  //   )}
                  // >
                  //   <input
                  //     className={classNames(
                  //       'me-2',
                  //       hidden_input && hidden_input === true ? 'd-none' : ''
                  //     )}
                  //     style={{ width: 15 }}
                  //     checked={is_checked}
                  //     type={type}
                  //     name={name}
                  //     id={key}
                  //     onChange={async e => {
                  //       const { checked } = e.target
                  //       await this.processOnChange(key, checked)
                  //     }}
                  //   />
                  //   <label htmlFor={key}>{HTMLReactParser(opt.label, {})}</label>
                  // </div>
                )
              })}
            </div>
          </>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ECRadioCheckFieldComponent)
