/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'

import VitaeWorkflowIndicatorComponent from '../workflow_indicator'
import workflowStepsMap from '../enums/workflow_swab_status_steps_map'
import KitColoredBox from '../kitColoredBox'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class MyResultsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { specialization, environment, authentication } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { user } = authentication || {}
    const { name: userName } = user || ''

    const { genericData } = this.props
    const { myResults } = genericData || false
    const { swab } = myResults || false
    const status_show_workflow_indicator = !!(
      swab && swab.status_show_workflow_indicator
    )
    const { product_title_translated: productTitle } = swab || 'Kit'
    const { product_cover_picture: productCoverPicture } = swab || ''
    const {
      product_attributes: productAttributes,
      owner_id,
      store_id,
    } = swab || {}
    let kitColor = productAttributes.filter(item => item.key === 'color')
    kitColor = kitColor.length > 0 ? kitColor[0].value : ''

    const swab_used_date =
      owner_id === store_id
        ? swab.used_at_formatted_day
        : swab.paid_at_formatted_day

    return (
      <div className={classNames('w-100 d-flex flex-column')}>
        <KitColoredBox
          title={`${texts[locale].my_test_vitaedna} ${productTitle}`}
          customStyle={{ backgroundColor: kitColor }}
        >
          {/* Contenitore a colonna Info Kit + Stato + Tracing */}
          <div className={classNames('d-flex flex-column')}>
            {/* Info kit */}
            <div
              className={classNames(
                'd-flex flex-row align-items-center justify-content-start'
              )}
            >
              {/* Immagine Kit */}
              <div>
                <img
                  src={productCoverPicture}
                  alt={texts[locale].my_test_vitaedna}
                  style={{
                    width: '130px',
                  }}
                />
              </div>

              {/* Info kit */}
              <div className={classNames('d-flex flex-column ms-4')}>
                <div className={classNames('fw-bold')}>{userName}</div>
                <div className={classNames('d-flex flex-row')}>
                  <div>{`${texts[locale].purchase_date}: `}</div>
                  <div className={classNames('fw-bold ms-2')}>
                    {swab_used_date}
                  </div>
                </div>
                <div className={classNames('d-flex flex-row')}>
                  <div>{`${texts[locale].swab_code}: `}</div>
                  <div className={classNames('fw-bold ms-2')}>
                    {swab.swab_id_formatted}
                  </div>
                </div>
              </div>
            </div>

            {/* Stato */}
            <div
              className={classNames('p-3 d-flex flex-column my-4')}
              style={{
                border: `2px solid ${kitColor}`,
                borderRadius: '10px',
              }}
            >
              <div className={classNames('d-flex flex-row')}>
                <div>{`${texts[locale].status}: `}</div>
                <div className={classNames('fw-bold ms-2')}>
                  {swab.status_name_translated}
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: swab.status_description_translated,
                }}
                className={classNames('fs-7 fw-bold')}
              />
            </div>

            {/* Tracing */}
            {status_show_workflow_indicator && (
              <div className={classNames('mb-3')}>
                <VitaeWorkflowIndicatorComponent
                  iconPrefix="swab-status"
                  navigateBackEnabled={false}
                  workflowStepsMap={workflowStepsMap}
                  currentStepSlug={swab.status}
                />
              </div>
            )}
          </div>
        </KitColoredBox>
        {swab.product_id === 2 && texts[locale].my_diet_informative !== '' && (
          <div
            className={classNames('mt-3 bg-diet-light')}
            style={{
              border: `2px solid ${kitColor}`,
              borderRadius: '10px',
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: texts[locale].my_diet_informative,
              }}
              className={classNames('fs-7 p-3 fs-8')}
            />
          </div>
        )}
        {swab.product_id === 3 && texts[locale].my_sport_informative !== '' && (
          <div
            className={classNames('mt-3 bg-sport-light')}
            style={{
              border: `2px solid ${kitColor}`,
              borderRadius: '10px',
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: texts[locale].my_sport_informative,
              }}
              className={classNames('fs-7 fst-italic p-3 fs-8')}
            />
          </div>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MyResultsContainer)
