/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeCookiebotComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      const cookie_declaration = document.createElement('script')
      // cookie_declaration.id = 'CookieDeclaration'
      cookie_declaration.src =
        'https://consent.cookiebot.com/uc.js?cbid=e1580f26-4242-4dc9-9ced-1af959160e19'

      document.head.appendChild(cookie_declaration)
    }
  }

  handleManageCookieClicked = () => {
    // eslint-disable-next-line no-undef
    Cookiebot.renew()
  }

  render() {
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div
        className={classNames('bg-1 g-column-1-1')}
        style={{ width: '100%' }}
        role="button"
        tabIndex={0}
        onClick={() => {
          this.handleManageCookieClicked()
        }}
        onKeyPress={() => {
          this.handleManageCookieClicked()
        }}
      >
        {texts[locale].manage_cookies}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeCookiebotComponent)
