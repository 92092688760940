/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../../../../../src/utils'

import FieldShell from '../../common_group/field_shell'
import ECSelectFieldComponent from '../../../../../../src/components/common/fields/selectField'
import ECDateFieldComponent from '../../../../../../src/components/common/fields/dateField'

import * as actionCreators from '../../../../../../src/store/actions'

import * as apiService from '../../../../../../src/services/apiService'

import { showMessageBox } from '../../common_group/message_box'
import JujoLoading from '../../../../../../src/components/loading'
import { JujoButtonComponent } from '../../../../../../src/components/jujo_button/index'

const classNames = require('classnames')

export class BusinessIntelligenceComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      month: false,
      period: 'single_month',
      date_in: '',
      date_out: '',
      loading: false,
      week: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  handleMonthChanged = (name, value) => {
    this.setState({ month: value })
  }

  handleWeekChanged = (name, value) => {
    console.log(name, value)
    const v = value.split(' - ')
    this.setState({ week: value, date_in: v[0], date_out: v[1] })
  }

  handlePeriodChanged = (name, value) => {
    this.setState({ period: value })
  }

  handleDateRangeChanged = (name, value) => {
    this.setState({ [name]: value })
  }

  setPeriodOptions = () => {
    const options = []
    options.push({
      value: 'single_month',
      label: 'Singolo mese',
    })
    options.push({
      value: 'date_range',
      label: 'Range di date',
    })
    options.push({
      value: 'single_week',
      label: 'Singola settimana',
    })
    return options
  }

  setMonthOptions = () => {
    const options = []
    const now = new Date()
    let year = now.getFullYear()
    let month = now.getMonth() + 2
    for (let i = 0; i <= 23; i += 1) {
      month -= 1
      if (month < 1) {
        month = 12
        year -= 1
      }

      const months = [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ]

      const month_name = months[month - 1]

      options.push({
        value: `${year}-${month.toString().padStart(2, '0')}`,
        label: `${year} ${month_name}`,
      })
    }
    return options
  }

  setWeekOptions = () => {
    const options = []

    for (let i = 1; i <= 20; i += 1) {
      let curr = new Date() // get current date
      const first = curr.getDate() - curr.getDay() + -1 * 7 * (i - 1) + 1 // First day is the day of the month - the day of the week
      const last = first + 6 // last day is the first day + 6

      let endDate = new Date(curr.setDate(last))
      let y = endDate.getFullYear()
      let m = (endDate.getMonth() + 1).toString().padStart(2, '0')
      let d = endDate.getDate().toString().padStart(2, '0')
      const endDateValue = `${y}-${m}-${d}`
      endDate = `${d}/${m}/${y}`

      curr = new Date() // get current date
      let startDate = new Date(curr.setDate(first))
      y = startDate.getFullYear()
      m = (startDate.getMonth() + 1).toString().padStart(2, '0')
      d = startDate.getDate().toString().padStart(2, '0')
      const startDateValue = `${y}-${m}-${d}`
      startDate = `${d}/${m}/${y}`

      options.push({
        value: `${startDateValue} - ${endDateValue}`,
        label: `${startDate} - ${endDate}`,
      })
    }
    return options
  }

  handleStats = async () => {
    await this.setState({ loading: true })
    const { month, period, date_in, date_out } = this.state
    const parsedEp = 'action/post/payment/bi-stats'
    const data = {
      month,
      period,
      date_in,
      date_out,
    }
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      data
    )
    await this.setState({ loading: false })
    showMessageBox(this, response, 'error')
  }

  render() {
    const { month, week, period, date_in, date_out, loading } = this.state
    const monthField = {
      name: 'months',
      options: this.setMonthOptions(),
    }
    const weekField = {
      name: 'weeks',
      options: this.setWeekOptions(),
    }
    const periodField = {
      name: 'Periodo',
      options: this.setPeriodOptions(),
    }
    const dateInField = {
      name: 'date_in',
      type: 'date',
      value: '',
    }
    const dateOutField = {
      name: 'date_out',
      type: 'date',
      value: '',
    }

    return (
      <div className={classNames('')}>
        <div
          className={classNames(
            'border rounded-1 p-5 border-color-1 d-flex flex-column align-items-center'
          )}
        >
          <div className={classNames('w-100 text-center fc-1 fs-3')}>
            Business Intelligence
          </div>
          <div className={classNames('w-100 text-center mt-2 mb-5 fc-5 fs-7')}>
            Seleziona il periodo su cui fare l&apos;indagine, quindi clicca sul
            pulsante [ Genera Statistiche ]
          </div>
          {loading && (
            <div className={classNames('py-5 my-5')}>
              <JujoLoading />
            </div>
          )}
          {!loading && (
            <>
              <div className={classNames('w-100 w-md-25')}>
                <FieldShell fieldName="period" withBorder>
                  <ECSelectFieldComponent
                    field={periodField}
                    handleValueChanged={this.handlePeriodChanged}
                    initialValue={period}
                  />
                </FieldShell>
              </div>
              {period === 'single_month' && (
                <div className={classNames('w-100 w-md-25 my-3')}>
                  <FieldShell fieldName="month" withBorder>
                    <ECSelectFieldComponent
                      field={monthField}
                      handleValueChanged={this.handleMonthChanged}
                      initialValue={month}
                    />
                  </FieldShell>
                </div>
              )}
              {period === 'date_range' && (
                <div
                  className={classNames(
                    'w-100 w-md-25 my-3 d-flex justify-content-between'
                  )}
                >
                  <div>
                    <FieldShell fieldName="date_in" withBorder>
                      <ECDateFieldComponent
                        field={dateInField}
                        handleValueChanged={null}
                        handleKeyPressed={this.handleDateRangeChanged}
                        initialValue={date_in}
                      />
                    </FieldShell>
                  </div>
                  <div>
                    <FieldShell fieldName="date_out" withBorder>
                      <ECDateFieldComponent
                        field={dateOutField}
                        handleValueChanged={null}
                        handleKeyPressed={this.handleDateRangeChanged}
                        initialValue={date_out}
                      />
                    </FieldShell>
                  </div>
                </div>
              )}
              {period === 'single_week' && (
                <div className={classNames('w-100 w-md-25 my-3')}>
                  <FieldShell fieldName="week" withBorder>
                    <ECSelectFieldComponent
                      field={weekField}
                      handleValueChanged={this.handleWeekChanged}
                      initialValue={week}
                    />
                  </FieldShell>
                </div>
              )}
              {((period === 'single_month' && month) ||
                (period === 'date_range' && date_in && date_out) ||
                (period === 'single_week' && date_in && date_out)) && (
                <div className={classNames('my-5')}>
                  <JujoButtonComponent
                    bstyle={3}
                    bwidth="200px"
                    blabel="Genera Statistiche"
                    handleClick={() => {
                      this.handleStats()
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(BusinessIntelligenceComponent)
