import React from 'react'
import { connect } from 'react-redux'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import * as apiService from '../../../../../../src/services/apiService'
import * as actionCreators from '../../../../../../src/store/actions'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import { cloneObj, mapStateToProps } from '../../../../../../src/utils'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'
import addressSelectionTypesMap from '../enums/address_selection_types_map'
import JujoLoading from '../../../../../../src/components/loading'

const classNames = require('classnames')

export class VitaeCompactAddressSelectorComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { initialized: false, addresses: [] }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const reqObj = this.composeRequest()
    const { parsedEp } = reqObj

    const response = await apiService.httpGet(
      `${process.env.apiUrl}${parsedEp}`
    )

    const { status } = response
    let addresses = []
    if (status === 200 || status === 201) {
      addresses = response.data.data
    }

    this.setState({
      initialized: true,
      addresses,
    })
  }

  composeRequest = () => {
    const { environment, authentication } = this.props

    const requestData = baseRequestObject(
      apiCallMap.getMy,
      'address',
      apiRequestTypesMap.get,
      environment,
      authentication
    )
    const parsedEp = parseEndpoint(requestData)

    return {
      parsedEp,
      data: [],
    }
  }

  handleAddressClick = async id => {
    const { session, updateSession } = this.props
    const session_copy = cloneObj(session)

    const { session_id, session_data } = session_copy
    const { cart } = session_data
    const { user_data } = cart

    user_data.shipping.existing.id = id
    user_data.shipping.address_type = addressSelectionTypesMap.existintg
    user_data.billing.address_type = addressSelectionTypesMap.same_as_shipping

    const returned_data = await saveSessionToServer(session_id, session_data)

    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  render() {
    const { initialized, addresses } = this.state
    const { specialization, environment, session } = this.props
    const { session_data } = session
    const { cart } = session_data
    const { user_data } = cart
    const { shipping } = user_data

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div className={classNames('')}>
        {initialized === false && <JujoLoading />}
        {initialized === true && (
          <div className={classNames('mt-2')}>
            <div
              className={classNames(
                'fw-bold border-bottom border-2 border-color-black pt-3 pb-2 fs-7'
              )}
            >
              {texts[locale].where_do_we_ship}
            </div>
            {addresses.map(address => {
              const { id, full_address } = address
              const selected = id === shipping.existing.id
              return (
                <div
                  key={id}
                  className={classNames('d-flex align-items-center my-2 fs-8')}
                  role="button"
                  tabIndex={0}
                  onClick={async () => {
                    await this.handleAddressClick(id)
                  }}
                  onKeyPress={async () => {
                    await this.handleAddressClick(id)
                  }}
                >
                  <div
                    className={classNames(
                      'd-flex justify-content-center align-items-center bg-white border rounded-percentage-100 me-2'
                    )}
                    style={{ width: '14px', height: '14px' }}
                  >
                    {selected && (
                      <div
                        className={classNames('bg-1 rounded-percentage-100')}
                        style={{ width: '10px', height: '10px' }}
                      />
                    )}
                  </div>
                  <div>{full_address}</div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeCompactAddressSelectorComponent)
