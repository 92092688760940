/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'
import MyDrawSinglFoodSupplement from './my_draw_single_food_supplement'

const classNames = require('classnames')

export class MyDrawFoodSupplements extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const {
      food_supplement_suggested,
      specialization,
      environment,
      genericData,
    } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { myResults } = genericData
    const { swab } = myResults
    const { product_id } = swab || false
    const results_font_color = product_id === 4 ? 'fc-11' : 'fc-1'
    return (
      <div
        className={classNames(
          'w-100 mt-4 mb-3 d-flex align_items-center flex-row flex-wrap row'
        )}
      >
        <div className={classNames('fs-7 fw-bold', results_font_color)}>
          {texts[locale]?.integration_suggested.toUpperCase()}
        </div>
        {food_supplement_suggested &&
          food_supplement_suggested.length > 0 &&
          food_supplement_suggested.map((item, index) => (
            <MyDrawSinglFoodSupplement key={index} item={item} />
          ))}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MyDrawFoodSupplements)
