/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'

export class VideoIframe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const { url } = this.props
    return (
      <>
        <div
          style={{
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            paddingTop: '50%',
          }}
        >
          <iframe
            src={url}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              bottom: '0px',
              right: '0px',
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = null

export default connect(mapStateToProps, actionCreators)(VideoIframe)
