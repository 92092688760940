/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import JujoEditableComponent from '../../../../../../src/components/editable_component'

import JujoLoading from '../../../../../../src/components/loading'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'
import * as actionCreators from '../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../src/utils'
import runTestWorkflowStepsMap from '../../common_group/enums/run_test_workflow_steps_map'
import JujoButtonComponent from '../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaeSensitiveDataComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      skip: true,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { session } = this.props
    const { session_data } = session
    const { run_test } = session_data
    const { skip_private_data } = run_test

    this.setState({ initialized: true, skip: skip_private_data })
  }

  handleSkipStepValueChanged = async skip => {
    this.setState({ initialized: false })
    const { session, updateSession } = this.props

    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { run_test } = session_data

    run_test.skip_private_data = skip

    const returned_data = await saveSessionToServer(session_id, session_data)

    session_copy.session_data = returned_data
    await updateSession(session_copy)

    this.setState({ initialized: true, skip })
  }

  handleGoToNextStep = async () => {
    const { session, updateSession } = this.props

    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { run_test } = session_data

    run_test.workflow_step = runTestWorkflowStepsMap.payment

    const returned_data = await saveSessionToServer(session_id, session_data)

    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  render() {
    const { initialized, skip } = this.state
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <>
        {!initialized && <JujoLoading />}
        {initialized && (
          <div className={classNames('')}>
            <div
              className={classNames('d-flex align-items-center my-3')}
              role="button"
              tabIndex={0}
              onClick={async () => {
                await this.handleSkipStepValueChanged(true)
              }}
              onKeyPress={async () => {
                await this.handleSkipStepValueChanged(true)
              }}
            >
              <div
                className={classNames(
                  'd-flex justify-content-center align-items-center bg-white border rounded-percentage-100 me-2'
                )}
                style={{ width: '16px', height: '16px' }}
              >
                {skip && (
                  <div
                    className={classNames('bg-1 rounded-percentage-100')}
                    style={{ width: '10px', height: '10px' }}
                  />
                )}
              </div>
              <div>{texts[locale].skip_this_step}</div>
            </div>
            <div
              className={classNames('d-flex align-items-center my-3')}
              role="button"
              tabIndex={0}
              onClick={async () => {
                await this.handleSkipStepValueChanged(false)
              }}
              onKeyPress={async () => {
                await this.handleSkipStepValueChanged(false)
              }}
            >
              <div
                className={classNames(
                  'd-flex justify-content-center align-items-center bg-white border rounded-percentage-100 me-2'
                )}
                style={{ width: '16px', height: '16px' }}
              >
                {!skip && (
                  <div
                    className={classNames('bg-1 rounded-percentage-100')}
                    style={{ width: '10px', height: '10px' }}
                  />
                )}
              </div>
              <div>{texts[locale].fill_customer_history_now}</div>
            </div>
            {skip && (
              <div className={classNames('d-flex justify-content-center mt-4')}>
                <JujoButtonComponent
                  bstyle={3}
                  bwidth="200px"
                  blabel={texts[locale].continue}
                  handleClick={async () => {
                    await this.handleGoToNextStep()
                  }}
                />
              </div>
            )}
            {!skip && (
              <JujoEditableComponent
                c_def={{
                  comp: 'editable_component',
                  entity: {
                    source: 'static',
                    defValue: 'customer_private_data',
                  },
                  renderer: {
                    viewPath: 'clinical_profile_group/private_data',
                  },
                }}
              />
            )}
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeSensitiveDataComponent)
