import React from 'react'
import { connect } from 'react-redux'
import apiCallMap from '../../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../../src/enums/apiRequestTypesMap'
import * as apiService from '../../../../../../../src/services/apiService'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../../src/services/servicesHelper'
import * as actionCreators from '../../../../../../../src/store/actions'
import addressTypesMap from '../../enums/address_types_map'
import workflowStepsMap from '../../enums/workflow_steps_map'
import {
  getAddressObject,
  getSelectedExistingAddressId,
  updateExistingAddressId,
} from '../helper'
import {
  cloneObj,
  mapStateToProps,
  scrollPageOnTop,
} from '../../../../../../../src/utils'
import { saveSessionToServer } from '../../../../../../../src/services/sessionHelper'

import { getSessionClientData } from '../../order_workflow/helper'
import JujoButtonComponent from '../../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaeExistingAddressSelectorComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { initialized: false, filtered_address_list: [] }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { session } = this.props
    const client_data = getSessionClientData(session)
    const { order_workflow } = client_data
    const { current_step } = order_workflow

    const reqObj = this.composeRequest()
    const { parsedEp } = reqObj

    const response = await apiService.httpGet(
      `${process.env.apiUrl}${parsedEp}`
    )

    const filtered_address_list = []
    const { status } = response
    if (status === 200 || status === 201) {
      const all_addresses = response.data.data

      const filter_at =
        current_step === workflowStepsMap.shipment
          ? addressTypesMap.shipping
          : addressTypesMap.billing

      for (let i = 0; i !== all_addresses.length; i += 1) {
        const adr = all_addresses[i]
        const { address_type } = adr
        if (address_type === filter_at) filtered_address_list.push(adr)
      }
    }

    this.setState({
      initialized: true,
      filtered_address_list,
    })
  }

  composeRequest = () => {
    const { environment, authentication } = this.props

    const requestData = baseRequestObject(
      apiCallMap.getMy,
      'address',
      apiRequestTypesMap.get,
      environment,
      authentication
    )
    const parsedEp = parseEndpoint(requestData)

    return {
      parsedEp,
      data: [],
    }
  }

  handleAddressSelected = async e => {
    const { target } = e
    const { value } = target
    await updateExistingAddressId(this, value)
  }

  handleSaveSelectedAddressAndContinueClicked = async () => {
    const selected_address_id = await getSelectedExistingAddressId(this)
    if (parseInt(selected_address_id, 10) === 0) return

    const { session, updateSession } = this.props

    const updated_session = cloneObj(session)

    const { session_id, session_data } = updated_session

    const client_data = getSessionClientData(updated_session)
    const { order_workflow } = client_data
    const { current_step } = order_workflow

    if (current_step === workflowStepsMap.shipment) {
      client_data.order_workflow.current_step = workflowStepsMap.billing
    }
    if (current_step === workflowStepsMap.billing) {
      client_data.order_workflow.current_step = workflowStepsMap.payment
    }

    const returned_data = await saveSessionToServer(session_id, session_data)

    updated_session.session_data = returned_data
    await updateSession(updated_session)
    scrollPageOnTop()
  }

  render() {
    const { initialized, filtered_address_list } = this.state
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const current_address = getAddressObject(this)
    const { existing } = current_address

    return (
      <div className={classNames('')}>
        {initialized === false && (
          <div
            className={classNames(
              'd-flex justify-content-center align-items-center'
            )}
            style={{ height: '300px' }}
          >
            {texts[locale].loading}
            <div
              className={classNames('loading-icon theme-svg ms-1')}
              style={{
                backgroundPosition: 'center',
                backgroundSize: '30px 30px',
                backgroundRepeat: 'no-repeat',
                height: '30px',
                width: '30px',
              }}
            />
          </div>
        )}
        {initialized === true && (
          <>
            <div className={classNames('bg-white border rounded p-2')}>
              <select
                defaultValue={existing.id}
                onChange={async e => {
                  await this.handleAddressSelected(e)
                }}
              >
                <option key="unselected_address" value={0}>
                  {texts[locale].select_address}
                </option>
                {filtered_address_list.map(a => {
                  const { id, full_address } = a
                  return (
                    <option key={id} value={id}>
                      {full_address}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className={classNames('d-flex justify-content-center my-4')}>
              <JujoButtonComponent
                bstyle={3}
                bwidth="200px"
                blabel={texts[locale].continue_with_purchase}
                handleClick={() => {
                  this.handleSaveSelectedAddressAndContinueClicked()
                }}
              />
            </div>
          </>
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeExistingAddressSelectorComponent)
