/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import * as apiService from '../../../../../../src/services/apiService'
import LabelAndText from '../../common_group/label_and_text'
import CourierTracking from '../../common_group/courier_tracking'
import JujoLoading from '../../../../../../src/components/loading'

import { mapStateToProps } from '../../../../../../src/utils'
import JujoButtonComponent from '../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaeOrderStatusComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const url = 'my/order?&sort=-id&locale=it&page=1&per_page=100'
    const response = await apiService.httpGet(`${process.env.apiUrl}${url}`)
    if (response.status === 200) {
      this.setState({ data: response.data.data })
    }
  }

  handleMyButtonClick = order => {
    // eslint-disable-next-line default-case
    switch (order.my_button_action) {
      case 'track_package':
        window.open(order.shipping.truck_url)
        break
    }
  }

  render() {
    const { data } = this.state
    const { specialization, environment } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    return (
      <div className={classNames('pt-4')}>
        <div
          className={classNames(
            'd-flex flex-column border-bottom border-2 pt-2 mb-1 fw-bold'
          )}
        >
          {texts[locale].my_orders_status}
        </div>
        {!data && <JujoLoading />}
        {data.length === 0 && (
          <div className={classNames('fst-italic py-3')}>
            {texts[locale].my_no_orders}
          </div>
        )}
        {data && (
          <div className={classNames('row g-2')}>
            {data.map(order => (
              <div key={order.id} className={classNames('col-md-6')}>
                <div className={classNames('p-3 bg-3 h-100')}>
                  <div
                    className={classNames(
                      'w-100 mb-3 d-flex justify-content-between align-items-center'
                    )}
                  >
                    <div className={classNames('fs-5 fc-1 fw-bold')}>
                      Ordine # {order.id}
                    </div>
                    <div
                      className={classNames('fs-7 fw-bold text-uppercase')}
                      style={{ color: order.status_color }}
                    >
                      {order.status_translated}
                    </div>
                  </div>
                  <LabelAndText
                    label={`${texts[locale].purchase_date}: `}
                    texts={[order.created_at_formatted_day]}
                  />
                  <LabelAndText
                    label={`${texts[locale].my_order_contents}: `}
                    texts={order.details}
                  />
                  <LabelAndText
                    label={`${texts[locale].my_total_price}: `}
                    texts={[`${order.total} €`]}
                  />
                  {order.show_tracking && <CourierTracking order={order} />}
                  {order.message_translated !== '' && (
                    <div
                      className={classNames(
                        'fs-8 fc-1 lh-1 d-flex align-items-end my-2'
                      )}
                      dangerouslySetInnerHTML={{
                        __html: order.message_translated,
                      }}
                    />
                  )}
                  {order.payment_pending === '!' && (
                    <>
                      <div
                        className={classNames(
                          'fs-8 fc-8 lh-1 d-flex align-items-end my-2'
                        )}
                        dangerouslySetInnerHTML={{
                          __html: `${texts[locale].order_waiting_payment}:`,
                        }}
                      />
                      <div
                        className={classNames(
                          'fs-8 fc-8 lh-2 d-flex flex-column my-2'
                        )}
                      >
                        <div>beneficiario: {process.env.companyName}</div>
                        <div>iban: {process.env.iban}</div>
                        <div>importo: € {order.total}</div>
                      </div>
                    </>
                  )}
                  {order.my_button_action !== '' && (
                    <div
                      className={classNames(
                        'd-flex align-items-center justify-content-center w-100 pt-3 '
                      )}
                    >
                      {order &&
                        order.shipping &&
                        order.shipping.truck_url &&
                        order.shipping.truck_url !== '' && (
                          <JujoButtonComponent
                            bstyle={3}
                            bwidth="160px"
                            blabel={order.my_button_label}
                            bdata={order}
                            handleClick={this.handleMyButtonClick}
                          />
                        )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}
export default connect(
  mapStateToProps,
  actionCreators
)(VitaeOrderStatusComponent)
