import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../../../../../../src/utils'
import * as actionCreators from '../../../../../../../src/store/actions'

export class VitaeStoreReportLineComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { record, specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const {
      source,
      swab_id_formatted,
      total_products,
      total,
      total_without_shipping_cost,
      order_shipping_cost,
    } = record
    let extra_data = source === 'kit' ? swab_id_formatted : ''
    extra_data = source === 'restocking' ? `${total_products} Kit` : extra_data

    const price = order_shipping_cost
      ? `${total_without_shipping_cost} € + ${texts[locale].shipping_costs_abbreviation} ${order_shipping_cost} €`
      : `${total} €`

    return (
      <div
        className={classNames(
          'bg-3 my-1 px-3 py-4 fs-7 ffamily-secondary d-flex flex-column flex-md-row justify-content-between align-items-center'
        )}
      >
        <div
          className={classNames('col-12 col-md-7 text-center text-md-start')}
        >
          <span className={classNames('me-3')}>
            {record.created_at_formatted_day}
          </span>
          <span className={classNames('ms-3')}>{record.source_translated}</span>
          <span className={classNames('ms-3 fw-bold')}>{extra_data}</span>
        </div>
        <div
          className={classNames(
            'col-12 col-md-5 d-flex d-flex justify-content-center justify-content-md-end mt-3 mt-md-0'
          )}
        >
          <span className={classNames('ms-3 fc-8')}>
            {record.payment_pending === '!' ? 'Atteso bonifico' : ''}
          </span>
          <span className={classNames('ms-3')}>{price}</span>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeStoreReportLineComponent)
