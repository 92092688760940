/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'

import VitaeMyResultsTitleAndDownloadButton from '../../_parts/my_results_title_and_download_button'

const classNames = require('classnames')

export class VitaeDrawSport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { specialization, environment, authentication, genericDataBox3 } =
      this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { swab } = genericDataBox3 || false
    const { training_card } = genericDataBox3 || false
    const { id: swab_id } = swab || {}
    const { updated_at_formatted_day: result_date } = training_card || {}
    const { access_token: tk } = authentication || ''
    const downloadUrl = `${process.env.apiServerUrl}pdf/vitae/sport?locale=${locale}&tk=${tk}`
    const informative = texts[locale].my_results_sport_informative
    return (
      <div className={classNames('w-100 d-flex flex-column')}>
        <VitaeMyResultsTitleAndDownloadButton
          title_black_part={texts[locale].vitae}
          title_colored_part={texts[locale].training_card}
          title_class="fc-7"
          downloadUrl={downloadUrl}
          result_date={result_date}
          swab_id={swab_id}
        />
        <div
          className={classNames('d-flex justify-content-start mt-3 pb-4 fs-9')}
        >
          <div
            className={classNames('fs-7')}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: informative,
            }}
          />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeDrawSport)
