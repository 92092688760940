import React from 'react'
import { connect } from 'react-redux'
import VitaeProductsSelectorComponent from '../products_selector'
import VitaeOrderWorkflowIndicatorComponent from '../workflow_indicator'
import VitaeOrderSummaryComponent from '../order_summary'
import VitaeUserRegistrationComponent from '../user_registration'
import VitaeAddressSelectorComponent from '../address_selector'
import VitaePaymentMethodSelectorComponent from '../../common_group/payment_method_selector'
import * as actionCreators from '../../../../../../src/store/actions'
import workflowStepsMap from '../enums/workflow_steps_map'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'
import {
  cloneObj,
  mapStateToProps,
  scrollPageOnTop,
} from '../../../../../../src/utils'
import { getSessionClientData } from './helper'

const classNames = require('classnames')

export class VitaeOrderWorkflowComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
    }
  }

  async componentDidMount() {
    await this.doWorkflowStepCheck()
    await this.setState({ initialized: true })
  }

  componentDidUpdate = async prevProps => {
    const { authentication } = this.props
    const { authentication: prevAuth } = prevProps

    if (
      Object.keys(authentication.user).length !==
      Object.keys(prevAuth.user).length
    ) {
      await this.doWorkflowStepCheck()
    }

    setTimeout(() => {
      this.appendDataLayer()
    }, 100)
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  doWorkflowStepCheck = async () => {
    const { session, updateSession, authentication } = this.props
    const { user } = authentication

    const session_copy = cloneObj(session)

    const { session_id, session_data } = session_copy
    const { cart } = session_data
    const { user_data } = cart

    const client_data = getSessionClientData(session_copy)
    const { order_workflow } = client_data

    if (!order_workflow) {
      client_data.order_workflow = {
        current_step: workflowStepsMap.shopping_cart,
      }
    }

    const curr_step = order_workflow.current_step
    const logged_user = Object.keys(user).length > 0
    const new_user_validated = Object.keys(user_data.user.new).length > 0
    const registration_enabled = !logged_user

    // case: step registration and registration is not enabled -> go to next step
    if (curr_step === workflowStepsMap.registration && !registration_enabled) {
      client_data.order_workflow.current_step = workflowStepsMap.shipment
    }

    // case: step greather than registration and user not logged
    // and new user data empty -> go back to registration
    if (
      curr_step > workflowStepsMap.registration &&
      !logged_user &&
      !new_user_validated
    ) {
      client_data.order_workflow.current_step = workflowStepsMap.registration
    }

    // always update registation enabled info
    client_data.order_workflow.registration_enabled = registration_enabled
    const returned_data = await saveSessionToServer(session_id, session_data)

    session_copy.session_data = returned_data
    await updateSession(session_copy)
    scrollPageOnTop()
  }

  appendDataLayer = () => {
    const { session } = this.props
    const { session_data } = session
    const { data_layer } = session_data
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      if (data_layer) {
        for (let i = 0; i !== data_layer.length; i += 1) {
          const dl = data_layer[i]
          window.dataLayer.push(dl)
        }
      }
    }
  }

  render() {
    const { initialized } = this.state
    const { specialization, environment, authentication, session } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const client_data = getSessionClientData(session)
    const { order_workflow } = client_data
    const { current_step } = order_workflow

    const { user } = authentication
    const { role } = user || {}

    const user_can_buy = role === undefined || role === 'customer'

    return (
      <div className={classNames('container d-flex flex-column mx-auto py-5')}>
        {initialized === false && (
          <div
            className={classNames(
              'd-flex justify-content-center align-items-center'
            )}
            style={{ height: '100%' }}
          >
            {texts[locale].loading}
            <div
              className={classNames('loading-icon theme-svg ms-1')}
              style={{
                backgroundPosition: 'center',
                backgroundSize: '30px 30px',
                backgroundRepeat: 'no-repeat',
                height: '30px',
                width: '30px',
              }}
            />
          </div>
        )}
        {initialized === true && !user_can_buy && (
          <div
            className={classNames(
              'd-flex flex-column justify-content-center align-items-center my-5'
            )}
            style={{ height: '100%' }}
          >
            <div className={classNames(' fs-4 fc-8 fw-bold text-center')}>
              {texts[locale].cart_bad_role}
            </div>
            <div className={classNames(' fs-6 my-3')}>
              <a
                href={`${process.env.site_url}my-account`}
              >{`${process.env.site_url}my-account`}</a>
            </div>
          </div>
        )}
        {initialized === true && user_can_buy && (
          <div className={classNames('row gy-4 flex-md-row')}>
            <div className={classNames('col-md-7')}>
              {current_step === workflowStepsMap.shopping_cart && (
                <VitaeProductsSelectorComponent />
              )}
              {current_step === workflowStepsMap.registration && (
                <VitaeUserRegistrationComponent />
              )}
              {current_step === workflowStepsMap.shipment && (
                <VitaeAddressSelectorComponent />
              )}
              {current_step === workflowStepsMap.billing && (
                <VitaeAddressSelectorComponent />
              )}
              {current_step === workflowStepsMap.payment && (
                <VitaePaymentMethodSelectorComponent />
              )}
            </div>
            <div className={classNames('col-md-5')}>
              <div className={classNames('mb-3')}>
                <VitaeOrderWorkflowIndicatorComponent />
              </div>
              <div className={classNames('')}>
                <VitaeOrderSummaryComponent />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeOrderWorkflowComponent)
