import React from 'react'
import { connect } from 'react-redux'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import * as actionCreators from '../../../../../../src/store/actions'
import {
  cloneObj,
  getQsValue,
  mapStateToProps,
} from '../../../../../../src/utils'
import paymentsTypesMap from '../enums/payments_types_map'
import * as apiService from '../../../../../../src/services/apiService'
import { updateCartAfterOrderCompleted } from './helper'
import workingContextTypesMap from '../enums/working_context_types_map'
import JujoLoading from '../../../../../../src/components/loading'

const classNames = require('classnames')

export class VitaeThankYouComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      order_data: {},
      error: {},
      working_context: ''
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { environment, authentication } = this.props
    let error = {}
    const session_id = getQsValue('sessionId')
    if (session_id) {
      const session = await this.getSessionForThankyou(session_id)
      const { working_context, confirmation_action } = session
      const { entity, action_name } = confirmation_action
      const { selected_payment_method } = session[working_context]

      const data_to_send = {
        session_id,
        selected_payment_method,
        paypal: {},
      }
      if (selected_payment_method === paymentsTypesMap.paypal) {
        const paymentId = getQsValue('paymentId')
        const token = getQsValue('token')
        const PayerID = getQsValue('PayerID')

        data_to_send.paypal.payment_id = paymentId
        data_to_send.paypal.token = token
        data_to_send.paypal.payer_id = PayerID
      }

      const requestData = baseRequestObject(
        apiCallMap.publicServerAction,
        entity,
        apiRequestTypesMap.post,
        environment,
        authentication
      )
      requestData.placeholderMapping.push({
        pSource: 'static',
        pKey: '{action}',
        pValue: action_name,
        pDefValue: action_name,
      })
      const parsedEp = parseEndpoint(requestData)
      const response = await apiService.httpPost(
        `${process.env.apiUrl}${parsedEp}`,
        data_to_send
      )

      const { status } = response

      let order_data = {}
      if (status === 200 || status === 201) {
        order_data = response.data

        await this.setState({ order_data })
        await this.appendDataLayer()

        const { updated_session } = response.data
        await updateCartAfterOrderCompleted(
          this,
          updated_session[working_context]
        )
      } else {
        error = response.data
      }
      this.setState({ working_context })
    }

    this.setState({ initialized: true, error })
  }

  getSessionForThankyou = async session_id => {
    const data = {
      session_id,
    }
    const url = `${process.env.apiUrl}public/action/post/session/get-session-for-thankyou`
    const response = await apiService.httpPost(url, data)
    console.log('response', response)
    return response.data.session
  }

  handleContinueBrowsingClicked = () => {
    const { order_data, error } = this.state
    if (typeof window !== 'undefined') {
      const url =
        Object.keys(order_data).length > 0
          ? order_data.continue_navigation_url
          : error.continue_navigation_url
      window.location.href = url
    }
  }

  appendDataLayer = async () => {
    const { order_data } = this.state
    const { session } = this.props
    const { session_data } = session
    const { data_layer } = session_data

    if (typeof window !== 'undefined' && data_layer) {
      window.dataLayer = window.dataLayer || []
      const data_layer_copy = cloneObj(data_layer || [])

      for (let i = 0; i !== data_layer_copy.length; i += 1) {
        const item = data_layer_copy[i]
        const { event } = item
        if (event === 'purchase') {
          if (Object.keys(order_data).length > 0) {
            const { order_id } = order_data || 0
            item.ecommerce.purchase.actionField.id = order_id
          }
        }

        window.dataLayer.push(item)
      }
    }
  }

  render() {
    const { initialized, order_data, error, working_context } = this.state
    const { specialization, environment, authentication } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { user } = authentication
    const { role } = user

    return (
      <>
        {!initialized && (
          <div
            className={classNames(
              'py-5 d-flex flex-column align-items-center justify-content-center'
            )}
          >
            Attendere qualche istante la conclusione dell'ordine
            <JujoLoading />
          </div>
        )}
        {initialized &&
          working_context === workingContextTypesMap.cart &&
          Object.keys(order_data).length > 0 && (
            <div
              className={classNames('pb-5')}
              style={{
                backgroundImage: 'url(/vitae/thank-you/bg.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '80vh',
              }}
            >
              <div
                className={classNames(
                  'fs-1 fc-1 d-flex justify-content-center py-4 ffamily-secondary'
                )}
              >
                {`${texts[locale].thank_you}!`}
              </div>
              <div
                className={classNames(
                  'd-flex flex-column justify-content-center bg-white mx-2 mx-md-5 px-2 px-md-5 py-4 mb-5 text-center rounded-5 shadow-sm'
                )}
              >
                <div className={classNames('fw-bold fs-6 fs-md-4 mb-2')}>
                  {texts[locale].order_placed}
                </div>
                <div className={classNames('fs-6 fs-md-5')}>
                  <span>{texts[locale].order_confirmation_line_1}</span>
                  <span className={classNames('ms-1 fw-bold fc-1')}>
                    {order_data.order_id}
                  </span>
                </div>
                <div className={classNames('fs-6 fs-md-5')}>
                  <span>{texts[locale].order_confirmation_line_2}</span>
                  <span
                    className={classNames('ms-1 fw-bold fc-1')}
                    style={{ wordBreak: 'break-all' }}
                  >
                    {order_data.order_email}
                  </span>
                </div>
                {order_data.payment_method === paymentsTypesMap.paypal && (
                  <div className={classNames('fs-6 fs-md-4 fw-bold')}>
                    {texts[locale].order_confirmation_payment_confirmed}
                  </div>
                )}
                {order_data.payment_method ===
                  paymentsTypesMap.bank_transfer && (
                  <div className={classNames('fs-6 fs-md-4 fw-bold')}>
                    {texts[locale].order_confirmation_payment_pending}
                  </div>
                )}
                <div className={classNames('fs-6 fs-md-5')}>
                  <span>
                    {texts[locale].order_confirmation_payment_method_chosen}
                  </span>
                  <span
                    className={classNames('ms-1 fw-bold fc-1')}
                    style={{ wordBreak: 'break-all' }}
                  >
                    {texts[locale][order_data.payment_method] ||
                      order_data.payment_method}
                  </span>
                </div>
              </div>
              {(role === undefined || role === 'customer') &&
                order_data.order_has_kits && (
                  <div
                    className={classNames(
                      'd-flex flex-column flex-md-row fc-white mx-auto  rounded-5 p-2 p-md-3 justify-content-center align-items-center'
                    )}
                    style={{
                      backgroundColor: '#2cd17f',
                      width: 'min(70%, 800px)',
                    }}
                  >
                    <div
                      className={classNames(
                        'smile-icon white-svg me-md-2 my-2'
                      )}
                      style={{
                        width: '40px',
                        height: '40px',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                    <div
                      className={classNames('d-flex flex-column text-center')}
                    >
                      <div>{texts[locale].order_confirmation_note_line_1}</div>
                      <div>{texts[locale].order_confirmation_note_line_2}</div>
                    </div>
                  </div>
                )}

              <div className={classNames('mt-5 d-flex justify-content-center')}>
                <div
                  className={classNames(
                    'bg-1 bg1-gradient-270deg fc-white p-2 text-center rounded-50 ms-4'
                  )}
                  style={{ width: '200px' }}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.handleContinueBrowsingClicked()
                  }}
                  onKeyPress={() => {
                    this.handleContinueBrowsingClicked()
                  }}
                >
                  {texts[locale].continue_browsing}
                </div>
              </div>
            </div>
          )}
        {initialized &&
          working_context === workingContextTypesMap.run_test &&
          Object.keys(order_data).length > 0 && (
            <div
              className={classNames('pb-5')}
              style={{
                backgroundImage: 'url(/vitae/thank-you/bg.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '80vh',
              }}
            >
              <div
                className={classNames(
                  'fs-1 fc-1 d-flex justify-content-center py-4 ffamily-secondary'
                )}
              >
                {`${texts[locale].test_completed_successfully}!`}
              </div>
              <div
                className={classNames('mx-auto text-center my-3 fs-5')}
                style={{ width: 'min(500px, 90%)' }}
              >
                <div>{texts[locale].test_completed_successfully_info_1}</div>
                <div className={classNames('fw-bold my-3')}>
                  {texts[locale].test_completed_successfully_info_2}
                </div>
                <div
                  className={classNames(
                    'border border-color-1 border-4 rounded-20 p-2 fs-6 text-start'
                  )}
                  dangerouslySetInnerHTML={{
                    __html: texts[locale].test_completed_successfully_info_3,
                  }}
                />
              </div>
              <div className={classNames('d-flex justify-content-center')}>
                <div
                  className={classNames(
                    'bg-1 bg1-gradient-270deg fc-white p-2 text-center rounded-50 ms-4'
                  )}
                  style={{ width: '200px' }}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.handleContinueBrowsingClicked()
                  }}
                  onKeyPress={() => {
                    this.handleContinueBrowsingClicked()
                  }}
                >
                  {texts[locale].continue_browsing}
                </div>
              </div>
            </div>
          )}
        {initialized &&
          working_context === workingContextTypesMap.courier_service && (
            <div
              className={classNames(
                'pb-5 d-flex align-items-center justify-content-center'
              )}
              style={{
                backgroundImage: 'url(/vitae/thank-you/bg.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '80vh',
              }}
            >
              <div>
                <div
                  className={classNames(
                    'fs-1 fc-1 d-flex justify-content-center py-4 ffamily-secondary'
                  )}
                >
                  {`${texts[locale].shipping_done_successfully_title}!`}
                </div>
                <div
                  className={classNames('mx-auto text-center my-3 fs-5')}
                  style={{ width: 'min(500px, 90%)' }}
                >
                  <div
                    className={classNames('my-3')}
                    dangerouslySetInnerHTML={{
                      __html: texts[locale].shipping_done_successfully_text,
                    }}
                  />
                </div>
                <div className={classNames('d-flex justify-content-center')}>
                  <div
                    className={classNames(
                      'bg-1 bg1-gradient-270deg fc-white p-2 text-center rounded-50 ms-4'
                    )}
                    style={{ width: '200px' }}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      this.handleContinueBrowsingClicked()
                    }}
                    onKeyPress={() => {
                      this.handleContinueBrowsingClicked()
                    }}
                  >
                    {texts[locale].continue_browsing}
                  </div>
                </div>
              </div>
            </div>
          )}
        {initialized && working_context === workingContextTypesMap.diets && (
          <div
            className={classNames(
              'pb-5 d-flex align-items-center justify-content-center'
            )}
            style={{
              backgroundImage: 'url(/vitae/thank-you/bg.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              minHeight: '80vh',
            }}
          >
            <div>
              <div
                className={classNames(
                  'fs-1 fc-1 d-flex justify-content-center py-4 ffamily-secondary'
                )}
              >
                {`${texts[locale].diet_purchased_successfully_title}!`}
              </div>
              <div
                className={classNames('mx-auto text-center my-3 fs-5')}
                style={{ width: 'min(500px, 90%)' }}
              >
                <div
                  className={classNames('my-3')}
                  dangerouslySetInnerHTML={{
                    __html: texts[locale].my_diet_informative,
                  }}
                />
              </div>
              <div className={classNames('d-flex justify-content-center')}>
                <div
                  className={classNames(
                    'bg-1 bg1-gradient-270deg fc-white p-2 text-center rounded-50 ms-4'
                  )}
                  style={{ width: '200px' }}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.handleContinueBrowsingClicked()
                  }}
                  onKeyPress={() => {
                    this.handleContinueBrowsingClicked()
                  }}
                >
                  {texts[locale].continue_browsing}
                </div>
              </div>
            </div>
          </div>
        )}
        {initialized &&
          working_context === workingContextTypesMap.training_cards && (
            <div
              className={classNames(
                'pb-5 d-flex align-items-center justify-content-center'
              )}
              style={{
                backgroundImage: 'url(/vitae/thank-you/bg.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '80vh',
              }}
            >
              <div>
                <div
                  className={classNames(
                    'fs-1 fc-1 d-flex justify-content-center py-4 ffamily-secondary'
                  )}
                >
                  {`${texts[locale].training_purchased_successfully_title}!`}
                </div>
                <div
                  className={classNames('mx-auto text-center my-3 fs-5')}
                  style={{ width: 'min(500px, 90%)' }}
                >
                  <div
                    className={classNames('my-3')}
                    dangerouslySetInnerHTML={{
                      __html: texts[locale].my_sport_informative,
                    }}
                  />
                </div>
                <div className={classNames('d-flex justify-content-center')}>
                  <div
                    className={classNames(
                      'bg-1 bg1-gradient-270deg fc-white p-2 text-center rounded-50 ms-4'
                    )}
                    style={{ width: '200px' }}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      this.handleContinueBrowsingClicked()
                    }}
                    onKeyPress={() => {
                      this.handleContinueBrowsingClicked()
                    }}
                  >
                    {texts[locale].continue_browsing}
                  </div>
                </div>
              </div>
            </div>
          )}
        {initialized && Object.keys(error).length > 0 && (
          <div
            className={classNames(
              'd-flex flex-column justify-content-center align-items-center p-2 p-md-5 m-2 m-md-5 shadow-sm'
            )}
          >
            <div className={classNames('mb-4')}>{error.message}</div>
            <div className={classNames('d-flex justify-content-center')}>
              <div
                className={classNames(
                  'bg-1 bg1-gradient-270deg fc-white p-2 text-center rounded-50 ms-4'
                )}
                style={{ width: '200px' }}
                role="button"
                tabIndex={0}
                onClick={() => {
                  this.handleContinueBrowsingClicked()
                }}
                onKeyPress={() => {
                  this.handleContinueBrowsingClicked()
                }}
              >
                {texts[locale].continue_browsing}
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeThankYouComponent)
