import React from 'react'
import { connect } from 'react-redux'
import { JujoButtonComponent } from '../../../../../../../src/components/jujo_button'
import * as actionCreators from '../../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../../src/utils'
import VitaeProductDescriptionComponent from './description'
import discountTypesMap from '../../../../../../../src/enums/discountTypesMap'
import { saveSessionToServer } from '../../../../../../../src/services/sessionHelper'

const classNames = require('classnames')

export class VitaeProductsPreviewComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleBuyNowclicked = async data => {
    const { session, updateSession, environment } = this.props
    const { locale } = environment
    const updated_session = cloneObj(session)
    const { session_id, session_data } = updated_session

    const { cart, discount_data } = session_data
    const { products, discount } = cart
    const { id } = data
    const cart_prod = products[id]
    const { quantity } = cart_prod

    discount.code = discount_data.code
    //products[id].quantity = quantity + 1

    const returned_data = await saveSessionToServer(session_id, session_data)

    updated_session.session_data = returned_data
    await updateSession(updated_session)

    if (typeof window !== 'undefined') {
      window.location.href = locale === 'it' ? '/it/acquista/' : 'en/buy/'
    }
  }

  handleFindOutMoreclicked = product_url => {
    window.location.href = product_url
  }

  renderProductServiceIcons = (prod_slug, services) => {
    const { environment, specialization } = this.props
    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations

    const html = []

    for (let i = 0; i !== services.length; i += 1) {
      const serv = services[i]
      const { slug, color, theme_svg } = serv
      html.push(
        <div
          key={`${prod_slug}_${slug}`}
          style={{ width: '85px' }}
          className={classNames(
            'd-flex flex-column justify-content-start align-items-center text-center'
          )}
        >
          <div
            className={classNames(`background-image ${slug}_icon ${theme_svg}`)}
            style={{ height: '50px', width: '50px' }}
          />
          <div
            className={classNames('fs-8 fw-bold mt-1')}
            style={{ color, lineHeight: '14px', height: '28px' }}
          >
            {texts[locale][slug] || slug}
          </div>
        </div>
      )
    }
    return html
  }

  calcSplittedPrice = price => {
    const { session } = this.props
    const { session_data } = session
    const { discount_data } = session_data
    const { discount_type, discount_value } = discount_data
    const calculaterdPrice =
      discount_type === discountTypesMap.percentage
        ? price - (price / 100) * discount_value
        : price - discount_value

    const splitted = calculaterdPrice.toString().split('.')
    return splitted
  }

  renderPrice = product_id => {
    const { environment, specialization } = this.props
    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations

    const { session } = this.props
    const { session_data } = session
    const { product_data, discount_data } = session_data
    const { discount_value } = discount_data
    const { price } = product_data[product_id]

    let splitted_price = this.calcSplittedPrice(price)
    let show_discount = discount_value > 0
    if (splitted_price[0] === 'NaN') {
      splitted_price = price.toString().split('.')
      show_discount = false
    }
    splitted_price[1] = splitted_price[1] ? splitted_price[1] : 0
    splitted_price[1] = `${splitted_price[1]}00`.substring(0, 2)
    const html = []
    html.push(
      <div
        key={`product_${product_id}`}
        className={classNames(
          'd-flex flex-row align-items-center justify-content-center mt-3'
        )}
      >
        <div
          className={classNames(
            'd-flex justify-content-between align-items-center rounded rounded-4 py-2 px-3 bg-12 ffamily-numbers'
          )}
          style={show_discount ? { width: '270px' } : {}}
        >
          <div className={classNames('fw-800 fs-1')}>
            <span>€</span>
            <span>{splitted_price[0]}</span>
            <span>,</span>
            <span className={classNames('fs-6')}>{splitted_price[1]}</span>
          </div>
          {show_discount && (
            <div className={classNames('bg-black fc-white p-2 rounded-5')}>
              <div className={classNames('fs-9 fst-italic text-center')}>
                {texts[locale].instead_of}
              </div>
              <div className={classNames('fs-5')}>
                <span>€</span>
                <span
                  className={classNames('fw-bold ms-1')}
                  style={{ textDecoration: 'line-through' }}
                >
                  {price}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    )
    return html
  }

  renderProduct = product => {
    const { environment, specialization, data } = this.props
    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations

    const {
      id,
      slug,
      title,
      cover_picture,
      attributes,
      included_services,
      description,
    } = product

    const { show_prices } = data || {}

    const kit_color = attributes.filter(attr => attr.key === 'color')[0].value
    let title_slug = title[locale].toLowerCase()
    title_slug = title_slug.replace('kit', '').trim()
    const product_url = `/${locale}/${title_slug}/`

    const html = []
    html.push(
      <div
        key={`${slug}_content`}
        className={classNames(
          'd-flex flex-column align-items-center mx-auto position-relative bg-white shadow shadow rounded rounded-3'
        )}
        style={{ width: 'min(380px, 100%)' }}
      >
        <a href={product_url} target="_self">
          <div
            className={classNames(
              'background-image background-image position-absolute  top-0 start-50 translate-middle'
            )}
            style={{
              backgroundImage: `url(${cover_picture})`,
              width: '250px',
              height: '150px',
            }}
          />
        </a>
        <div className={classNames('pt-5 mt-4')}>
          <a
            href={product_url}
            target="_self"
            className={classNames('text-decoration-none fc-black')}
          >
            <div className={classNames('mt-2 text-center')}>
              <span>VitæDNA</span>
              <span
                className={classNames('fst-uppercase ms-1 fw-bold')}
                style={{ color: kit_color }}
              >
                {title[locale]}
              </span>
            </div>
          </a>
          {show_prices && this.renderPrice(id)}
          <div className={classNames('d-flex justify-content-center my-3')}>
            {this.renderProductServiceIcons(slug, included_services)}
          </div>
          <div className={classNames('')}>
            <VitaeProductDescriptionComponent
              slug={slug}
              desc={description}
              color={kit_color}
            />
          </div>
          <div
            className={classNames(
              'my-4 d-flex justify-content-center align-items-center'
            )}
          >
            <div key={`${slug}_buy`}>
              <JujoButtonComponent
                bstyle={0}
                bclasses={`fc-white ${slug}-bg-gradient`}
                bwidth="125px"
                bheight="37px"
                blabel={texts[locale].find_out_more}
                bicon=""
                bFontClass="fs-7 fw-bold"
                bdata={product}
                handleClick={() => this.handleFindOutMoreclicked(product_url)}
              />
            </div>
            <div key={`${slug}_more_info`}>
              <JujoButtonComponent
                bstyle={0}
                bclasses={`fc-white ${slug}-bg-gradient`}
                bwidth="125px"
                bheight="37px"
                blabel={texts[locale].buy_now}
                bicon=""
                bFontClass="fs-7 fw-bold"
                bdata={product}
                handleClick={this.handleBuyNowclicked}
              />
            </div>
          </div>
        </div>
      </div>
    )
    return html
  }

  renderProducts = () => {
    const { session } = this.props
    const { session_data } = session
    const { browser_data, product_data } = session_data
    const { products } = browser_data || {}

    const html = []
    const product_data_key = Object.keys(product_data)
    for (let i = 0; i !== products.length; i += 1) {
      if (i + 1 <= product_data_key.length) {
        const prod = products.find(
            item => parseInt(item.id, 10) === parseInt(product_data_key[i], 10)
        )
        const { slug } = prod
        html.push(
          <div
            key={slug}
            className={classNames('col-12 col-md-6 p-0 p-md-2 my-5')}
          >
            {this.renderProduct(prod)}
          </div>
        )
      }
    }

    return html
  }

  render() {
    return (
      <div
        className={classNames('mx-auto row')}
        style={{ width: 'min(100%, 850px)' }}
      >
        {this.renderProducts()}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeProductsPreviewComponent)
