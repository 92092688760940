/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'
import {
  mapStateToProps,
  cloneObj,
  injectComponent,
} from '../../../../../../../src/utils'
import * as apiService from '../../../../../../../src/services/apiService'

import ECQuantitySelectorFieldComponent from '../../../../../../../src/components/common/fields/quantitySelectorField'
import { saveSessionToServer } from '../../../../../../../src/services/sessionHelper'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../../src/services/servicesHelper'
import apiCallMap from '../../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../../src/enums/apiRequestTypesMap'

const classNames = require('classnames')

export class MyDrawSinglFoodSupplement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      quantity: 0,
      max_quantity: 0,
      pID: 0,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { item, session } = this.props

    const { session_data } = session
    const { cart } = session_data || {}
    const { products } = cart || {}

    const { id } = item || {}
    const { quantity, available_quantity } = products[id]
    this.setState({ pID: id, quantity, max_quantity: available_quantity })
  }

  handleQuantityValueChanged = (fieldName, value) => {
    this.setState({ quantity: value }, () => {
      this.handleAdd()
    })
  }

  handleAdd = async () => {
    const { pID, quantity } = this.state
    const { session, updateSession } = this.props
    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { cart } = session_data
    const { products } = cart
    products[pID].quantity = Math.max(quantity, 0)
    const returned_data = await saveSessionToServer(session_id, session_data)
    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  handleProductInfoClicked = async pId => {
    const parsedEp = this.composeRequest()

    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      { pId }
    )

    if (response.status === 200 || response.status === 201) {
      const { data } = response

      const product_info = data.length > 0 ? data[0] : {}

      const comp_id = `product_infobox_${pId}`
      const component_definition = {
        specialized: true,
        path: 'my_result_group/dna/_parts/product_infobox',
        data: {
          sender: this,
          product_info,
        },
      }

      injectComponent(this, comp_id, component_definition)
    }
  }

  composeRequest = () => {
    const { environment, authentication } = this.props

    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'product',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'product-data',
      pDefValue: 'product-data',
    })

    const parsedEp = parseEndpoint(requestData)

    return parsedEp
  }

  render() {
    const { environment, item } = this.props
    const { quantity, max_quantity } = this.state
    const { locale } = environment
    const {
      id,
      cover_picture,
      title,
      subtitle,
      price,
      list_price,
      discount_to_show,
      list_price_discount,
    } = item || {}
    const title_translated = title[locale]
    const subtitle_translated = subtitle[locale]

    const field = {
      name: id,
      max_quantity,
    }

    return (
      <div
        className={classNames(
          'd-flex flex-column align-items-center justify-content-start mb-2 col-12 col-sm-6 col-md-4'
        )}
      >
        <div
          className={classNames(
            'd-flex flex-column align-items-center justify-content-center'
          )}
          role="button"
          tabIndex={0}
          onClick={async () => {
            await this.handleProductInfoClicked(id)
          }}
          onKeyPress={async () => {
            await this.handleProductInfoClicked(id)
          }}
        >
          <div
            style={{
              backgroundImage: `url(${cover_picture})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '120px',
              height: '120px',
            }}
          />
          <div className={classNames('fs-7 fw-bold')}>{title_translated}</div>
        </div>
        <div
          className={classNames('fs-9 fc-4 my-2 text-center lh-sm px-4')}
          style={{ whiteSpace: 'pre-wrap' }}
          dangerouslySetInnerHTML={{
            __html: subtitle_translated,
          }}
        />
        {list_price_discount > 0 && (
          <div className={classNames('fs-8 fc-5')}>
            <span>
              <span className={classNames('me-1')}>
                {` -${discount_to_show}%`}
              </span>
              <del>{`${list_price}`}</del>
            </span>
          </div>
        )}
        <div className={classNames('fs-7 fw-bold mb-1')}>{price}</div>
        <div className={classNames('mb-3 mt-1')}>
          {max_quantity === 0 && (
            <div className={classNames('fc-4')}>SOLD OUT</div>
          )}
          {max_quantity > 0 && (
            <ECQuantitySelectorFieldComponent
              field={field}
              initialValue={quantity}
              handleValueChanged={this.handleQuantityValueChanged}
            />
          )}
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(MyDrawSinglFoodSupplement)
