const admin_customer = require('./json/admin_customer.json')
const admin_key_account = require('./json/admin_key_account.json')
const admin_laboratory = require('./json/admin_laboratory.json')
const admin_promoter = require('./json/admin_promoter.json')
const admin_specialist = require('./json/admin_specialist.json')
const admin_store = require('./json/admin_store.json')
const address = require('./json/address.json')
const click_and_conversion = require('./json/click_and_conversion.json')
const customer = require('./json/customer.json')
const customer_private_data = require('./json/customer_private_data.json')
const document = require('./json/document.json')
const food_supplement = require('./json/food_supplement.json')
const service = require('./json/service.json')
const specialist = require('./json/specialist.json')
const disease = require('./json/disease.json')
const discount = require('./json/discount.json')
const fee = require('./json/fee.json')
const food = require('./json/food.json')
const holistic = require('./json/holistic.json')
const interpretation = require('./json/interpretation.json')
const key_account = require('./json/key_account.json')
const laboratory = require('./json/laboratory.json')
const laboratory_plates = require('./json/laboratory_plates.json')
const landing = require('./json/landing.json')
const mandatory_profile_props = require('./json/mandatory_profile_props.json')
const order = require('./json/order.json')
const order_detail = require('./json/order_detail.json')
const order_fulfillment = require('./json/order_fulfillment.json')
const payment = require('./json/payment.json')
const pdf_document = require('./json/pdf_document.json')
const pdf_page = require('./json/pdf_page.json')
const plate = require('./json/plate.json')
const polymorphism = require('./json/polymorphism.json')
const price_list = require('./json/price_list.json')
const product = require('./json/product.json')
const promoter = require('./json/promoter.json')
const promoter_report = require('./json/promoter_report.json')
const promoter_store = require('./json/promoter_store.json')
const pyramid = require('./json/pyramid.json')
const shipping = require('./json/shipping.json')
const store = require('./json/store.json')
const swab = require('./json/swab.json')
const key_account_swab = require('./json/key_account_swab.json')
const warehouse = require('./json/warehouse.json')
const jujo_block = require('./json/jujo_block.json')
const jujo_content_type = require('./json/jujo_content_type.json')
const jujo_content_category = require('./json/jujo_content_category.json')
const jujo_content = require('./json/jujo_content.json')
const jujo_element = require('./json/jujo_element.json')
const jujo_layout = require('./json/jujo_layout.json')
const jujo_media = require('./json/jujo_media.json')
const jujo_note = require('./json/jujo_note.json')
const jujo_page = require('./json/jujo_page.json')

export {
  admin_customer,
  admin_key_account,
  admin_laboratory,
  admin_promoter,
  admin_specialist,
  admin_store,
  address,
  click_and_conversion,
  customer,
  customer_private_data,
  document,
  service,
  specialist,
  disease,
  discount,
  fee,
  food,
  food_supplement,
  holistic,
  interpretation,
  key_account,
  laboratory,
  laboratory_plates,
  landing,
  mandatory_profile_props,
  order,
  order_detail,
  order_fulfillment,
  payment,
  pdf_document,
  pdf_page,
  plate,
  polymorphism,
  price_list,
  product,
  promoter,
  promoter_report,
  promoter_store,
  pyramid,
  shipping,
  store,
  swab,
  key_account_swab,
  warehouse,
  jujo_block,
  jujo_content_type,
  jujo_content_category,
  jujo_content,
  jujo_element,
  jujo_layout,
  jujo_media,
  jujo_note,
  jujo_page,
}
