/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'

const classNames = require('classnames')

export class VitaePaymentStepOrderSummaryComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { specialization, environment, session } = this.props

    const { session_data } = session
    const { product_data, run_test } = session_data

    const pId = Object.keys(product_data)[0]
    const selected_product = product_data[pId]

    const { title_translated } = selected_product
    const { swab_id_formatted, totals } = run_test
    const {
      discount_amount,
      total_products_taxable,
      total_products_taxable_discounted,
      total_vat_amount,
      total_vat,
      total_price,
    } = totals

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div className={classNames('mt-2')}>
        <div
          className={classNames(
            'fw-bold border-bottom border-2 border-color-black pt-3 pb-2'
          )}
        >
          {texts[locale].order_summary}
        </div>
        <div className={classNames('border-bottom border-2')}>
          <div
            className={classNames(
              'd-flex flex-column flex-md-row align-items-center justify-content-md-between fw-bold my-2'
            )}
          >
            <div>{texts[locale].kit}</div>
            <div>{`VitæDNA ${title_translated}`}</div>
          </div>
          <div
            className={classNames(
              'd-flex flex-column flex-md-row align-items-center justify-content-md-between fw-bold my-2'
            )}
          >
            <div>{texts[locale].code}</div>
            <div>{swab_id_formatted}</div>
          </div>
        </div>
        <div className={classNames('border-bottom border-2')}>
          <div
            className={classNames(
              'd-flex flex-column flex-md-row align-items-center justify-content-md-between my-2'
            )}
          >
            <div>{texts[locale].price}</div>
            <div
              style={{
                textDecoration: discount_amount > 0 ? 'line-through' : 'none',
              }}
            >
              {`€ ${total_products_taxable}`}
            </div>
          </div>
          {discount_amount > 0 && (
            <div
              className={classNames(
                'd-flex flex-column flex-md-row align-items-center justify-content-md-between fw-bold my-2'
              )}
            >
              <div>{texts[locale].discounted_price}</div>
              <div>{`€ ${total_products_taxable_discounted}`}</div>
            </div>
          )}
          <div
            className={classNames(
              'd-flex flex-column flex-md-row align-items-center justify-content-md-between my-2'
            )}
          >
            <div>{`${texts[locale].vat} ${total_vat_amount}%`}</div>
            <div>{`€ ${total_vat}`}</div>
          </div>
        </div>
        <div
          className={classNames(
            'd-flex flex-column flex-md-row align-items-center justify-content-md-between my-2 fw-bold fs-5'
          )}
        >
          <div>{texts[locale].total}</div>
          <div>{`€ ${total_price}`}</div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaePaymentStepOrderSummaryComponent)
