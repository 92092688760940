import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps, translate } from '../../../utils'
import {
  getGridDefinition,
  showJujoGridColumnByShowCondition,
} from '../helper/gridFunctions'
import { getStoredGridProps } from '../helper/lsInteractionFunctions'
import { sortByColumn } from '../helper/sortFilterFunctions'

const classNames = require('classnames')

export class GridHeadComponent extends React.Component {
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  componentDidUpdate = async () => {}

  handleCheckboxSelected = e => {
    const { selectAllRows, unselectAllRows } = this.props

    const { target } = e
    const { checked } = target
    if (checked === true) selectAllRows()
    else unselectAllRows()
  }

  renderSelectFeature = () => {
    const gridDefinition = getGridDefinition(this.props)
    const { selectableRows } = gridDefinition

    const html = []

    if (selectableRows && selectableRows.enabled === true) {
      html.push(
        <div
          key="selectable_head_column"
          className={classNames(
            'd-none d-md-flex align-items-center justify-content-between'
          )}
          style={{ height: '100%' }}
        >
          <input
            type="checkbox"
            onClick={e => {
              this.handleCheckboxSelected(e)
            }}
          />
        </div>
      )
    }

    return html
  }

  render() {
    const { loadGrid, data } = this.props

    const gridDefinition = getGridDefinition(this.props)
    const gridProps = getStoredGridProps(this.props)

    const sortList = gridProps.sort ? gridProps.sort.split(',') : []
    const { columns, expandableRowContent } = gridDefinition

    return (
      <>
        {this.renderSelectFeature()}
        {expandableRowContent && (
          <div
            key="expandable_btn_space"
            className={classNames(
              'd-none d-md-flex align-items-center justify-content-between'
            )}
            style={{ height: '100%' }}
          />
        )}
        {columns &&
          columns.map(col => {
            const { name, alias, sortable, show_conditions } = col
            const renderName = alias || name

            let sortedClass = ''
            let sortOrder = -1
            if (sortList.includes(name)) {
              sortedClass = 'sorted-asc'
              sortOrder = sortList.indexOf(name)
            } else if (sortList.includes(`-${name}`)) {
              sortedClass = 'sorted-desc'
              sortOrder = sortList.indexOf(`-${name}`)
            }

            const show_column = showJujoGridColumnByShowCondition(
              data.data,
              show_conditions
            )

            if (!show_column) {
              return <div key={renderName} className={classNames('d-none')} />
            }

            return (
              <div
                key={renderName}
                className={classNames(
                  'p-2 d-flex align-items-center align-self-center justify-content-between'
                )}
                role="button"
                tabIndex={0}
                onClick={async () => {
                  if (sortable !== false) {
                    await sortByColumn(name, this.props)
                    loadGrid()
                  }
                }}
                onKeyPress={async () => {
                  if (sortable !== false) {
                    await sortByColumn(name, this.props)
                    loadGrid()
                  }
                }}
              >
                <div className={classNames('text-break')}>
                  {translate(renderName)}
                </div>
                {sortedClass && (
                  <div className={classNames('d-flex')}>
                    <div
                      className={classNames(sortedClass)}
                      style={{
                        width: '13px',
                        height: '13px',
                        backgroundPosition: 'center',
                        backgroundSize: '13px',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                    <div className={classNames('fs-8 ms-1')}>
                      {`(${parseInt(sortOrder, 10) + 1})`}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(GridHeadComponent)
