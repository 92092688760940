import React from 'react'
import { connect } from 'react-redux'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'
import * as actionCreators from '../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaePaymentMethodSelectorComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handlePaymentMethodSelected = async pKey => {
    const { session, updateSession } = this.props
    const session_copy = cloneObj(session)

    const { session_id, session_data } = session_copy
    const { working_context } = session_data
    session_data[working_context].selected_payment_method = pKey

    const returned_data = await saveSessionToServer(session_id, session_data)

    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  renderPaymentMethods = () => {
    const { specialization, environment, session } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { session_data } = session
    const { payments, working_context } = session_data
    const { selected_payment_method, totals } = session_data[working_context]
    const { total_price_formatted } = totals
    const payment_keys = Object.keys(payments)

    const html = []

    for (let i = 0; i !== payment_keys.length; i += 1) {
      const pKey = payment_keys[i]
      const pMethod = payments[pKey]

      const selected = selected_payment_method === pKey
      html.push(
        <div key={pKey} className={classNames('my-3')}>
          <div
            className={classNames(
              'd-flex align-items-center justify-content-between'
            )}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handlePaymentMethodSelected(pKey)
            }}
            onKeyPress={() => {
              this.handlePaymentMethodSelected(pKey)
            }}
          >
            <div className={classNames('d-flex align-items-center')}>
              <div
                className={classNames(
                  'd-flex justify-content-center align-items-center bg-white border rounded-percentage-100 me-2'
                )}
                style={{ width: '16px', height: '16px' }}
              >
                {selected && (
                  <div
                    className={classNames('bg-1 rounded-percentage-100')}
                    style={{ width: '10px', height: '10px' }}
                  />
                )}
              </div>
              <div>{texts[locale][pKey] || pKey}</div>
            </div>
            <div>
              <div
                className={classNames(pKey.replace('_', '-'))}
                style={{
                  width: '80px',
                  height: '18px',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                }}
              />
            </div>
          </div>
          {pKey === 'paypal' && (
            <div
              className={classNames('mx-auto ms-md-4 mt-2 fs-7')}
              style={{ width: 'min(90%, 350px)' }}
            >
              <div className={classNames('fst-italic')}>
                {texts[locale].paypal_description}
              </div>
            </div>
          )}
          {pKey === 'bank_transfer' && (
            <div
              className={classNames('mx-auto ms-md-4 mt-2 fs-7')}
              style={{ width: 'min(90%, 350px)' }}
            >
              <div className={classNames('fst-italic')}>
                {texts[locale].bank_transfer_description}
              </div>

              {selected && (
                <div className={classNames('mt-2 border p-2')}>
                  <div>{texts[locale].coordinates}</div>
                  <div>
                    <strong className={classNames('me-1')}>
                      {texts[locale].payment_amount}
                    </strong>
                    <span>{`€ ${total_price_formatted}`}</span>
                  </div>
                  <div>
                    <strong className={classNames('me-1')}>
                      {texts[locale].iban}
                    </strong>
                    <span>{pMethod.iban}</span>
                  </div>
                  <div>
                    <strong className={classNames('me-1')}>
                      {texts[locale].beneficiary}
                    </strong>
                    <span>{pMethod.beneficiary}</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )
    }

    return html
  }

  render() {
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div className={classNames('')}>
        <div
          className={classNames(
            'border-bottom border-2 border-color-black pb-3'
          )}
        >
          <div className={classNames('fw-bold')}>
            {texts[locale].payment_methods}
          </div>
          <div>{texts[locale].payment_method_you_prefer}</div>
        </div>
        {this.renderPaymentMethods()}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaePaymentMethodSelectorComponent)
