import React from 'react'
import { connect } from 'react-redux'

import classNames from 'classnames'
import * as actionCreators from '../../../../../src/store/actions'

import VitaeMyAccountHead from '../common/header'
import VitaeFooter from '../common/footer'
import VitaeAdminSidebar from './sidebar'
import { JujoLeraLayout } from '../../../../../src/components/page_layout/layouts/lera'
import PageComponent from '../../../../../src/components/page_layout/layouts/_parts/page'
import { mapStateToProps } from '../../../../../src/utils'

import './styles.scss'

export class VitaeAdminLayout extends JujoLeraLayout {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderHead = () => <VitaeMyAccountHead />

  renderSideBar = () => <VitaeAdminSidebar />

  renderPageBody = () => {
    const html = []
    html.push(
      <div
        key="body"
        className={classNames('p-3 p-md-5 me-md-5')}
        style={{ marginTop: '56px' }}
      >
        <PageComponent />
      </div>
    )
    return html
  }

  renderFooter = () => <VitaeFooter />

  gridTemplate = () => 'g-template-col-md-190px-auto'
}

export default connect(mapStateToProps, actionCreators)(VitaeAdminLayout)
