import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import * as apiService from '../../../../../../src/services/apiService'
import { cloneObj } from '../../../../../../src/utils'
import runTestWorkflowStepsMap from '../../common_group/enums/run_test_workflow_steps_map'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'
import { showMessageBox } from '../../common_group/message_box'

export async function updateUserPrivateDataInSessionAndGoToPayment(
  sender,
  data
) {
  const { session, updateSession } = sender.props

  const session_copy = cloneObj(session)
  const { session_id, session_data } = session_copy
  const { run_test } = session_data
  const { user_data } = run_test

  user_data.private_data = data
  run_test.workflow_step = runTestWorkflowStepsMap.payment

  const returned_data = await saveSessionToServer(session_id, session_data)

  session_copy.session_data = returned_data
  await updateSession(session_copy)
}

export async function validateUserPrivateDataAndChangeWorkflowStep(sender) {
  const { localDataSource } = sender.state
  const { environment, authentication } = sender.props
  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'customer-private-data',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'validate-card',
    pDefValue: 'validate-card',
  })

  const parsedEp = parseEndpoint(requestData)
  const response = await apiService.httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    localDataSource
  )

  const { status } = response
  if (status === 200 || status === 201) {
    await updateUserPrivateDataInSessionAndGoToPayment(sender, localDataSource)
  } else {
    showMessageBox(sender, response, 'error')
  }
}
