import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'
import { GenericFilter } from './genericFilter'

const classNames = require('classnames')

export class DateFilter extends GenericFilter {
  composeValue = e => {
    const container = e.target.closest('.date-field')
    const inputList = container.querySelectorAll('input[type=date]')
    const updatedFieldArrayValue = []

    for (let i = 0; i !== 2; i += 1) {
      const dateValue = inputList[i].value
      if (dateValue) {
        updatedFieldArrayValue.push(dateValue)
      }
    }

    this.updateFilter(updatedFieldArrayValue.join(','))
  }

  renderFilter = () => {
    const { filter } = this.props
    const { filterValue } = this.state

    const parsedFilterValue = filterValue ? filterValue.split(',') : []

    const html = []
    html.push(
      <div key={`filter_${filter.label}`} className={classNames('date-field')}>
        <input
          type="date"
          className={classNames('form-control rounded-4 shadow-sm border-0')}
          defaultValue={parsedFilterValue[0] || ''}
          onBlur={e => {
            this.composeValue(e)
          }}
        />
        <input
          type="date"
          className={classNames(
            'form-control rounded-4 shadow-sm border-0 mt-2'
          )}
          defaultValue={parsedFilterValue[1] || ''}
          onBlur={e => {
            this.composeValue(e)
          }}
        />
      </div>
    )
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(DateFilter)
