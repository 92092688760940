import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../src/store/actions'
import PageComponent from '../../../../../src/components/page_layout/layouts/_parts/page'

import VitaeMyAccountHead from '../common/header'
import VitaeFooter from '../common/footer'
import './styles.scss'

import { JujoLeraLayout } from '../../../../../src/components/page_layout/layouts/lera'
import { mapStateToProps } from '../../../../../src/utils'

const classNames = require('classnames')

export class VitaeBaseLayout extends JujoLeraLayout {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderHead = () => <VitaeMyAccountHead />

  renderSideBar = () => []

  renderPageBody = () => {
    const html = []
    html.push(
      <div
        key="body"
        className={classNames('me-md-5')}
        style={{ marginTop: '56px', width: '100vw' }}
      >
        <PageComponent />
      </div>
    )
    return html
  }

  renderFooter = () => <VitaeFooter />
}

export default connect(mapStateToProps, actionCreators)(VitaeBaseLayout)
