import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import apiCallMap from '../../../enums/apiCallMap'
import * as apiService from '../../../services/apiService'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../services/servicesHelper'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

export class ECMultiPickerButtonsFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      data: [],
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const data = await this.initData()
    this.setState({ data, initialized: true })
  }

  initData = async () => {
    const { field, environment, authentication } = this.props
    const { apis } = field
    const { getData } = apis

    const {
      apiCall,
      relatedEntity,
      requestType,
      defaultFilters,
      placeholderMapping,
    } = getData
    const requestData = baseRequestObject(
      apiCallMap[apiCall],
      relatedEntity,
      requestType,
      environment,
      authentication
    )

    requestData.defaultFilters = defaultFilters
    requestData.placeholderMapping = placeholderMapping
    const parsedEp = parseEndpoint(requestData)
    const result = await apiService.httpGet(`${process.env.apiUrl}${parsedEp}`)

    if (result) {
      const { status, data } = result
      if (status === 200) {
        return data.data
      }
    }

    return []
  }

  handleElementClicked = value => {
    const { dataSource, field, handleValueChanged } = this.props
    const { name } = field

    const currValue = dataSource[name]
    const selectedList = currValue ? currValue.split(',') : []

    const valueIndex = selectedList.indexOf(value.toString())
    if (valueIndex >= 0) selectedList.splice(valueIndex, 1)
    else selectedList.push(value.toString())

    const updatedValue = selectedList.join(',')
    handleValueChanged(name, updatedValue)
  }

  render() {
    const { initialized, data } = this.state
    const { specialization, environment, dataSource, field } = this.props
    const { name, valueField, renderField, customizations } = field

    const { elementWidth } = customizations || {}

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const selectedValues = dataSource[name] ? dataSource[name].split(',') : []
    return (
      <>
        {!initialized && (
          <div
            className={classNames(
              'd-flex justify-content-center align-items-center'
            )}
            style={{ height: '100%' }}
          >
            {texts[locale].loading}
            <div
              className={classNames('loading-icon theme-svg ms-1')}
              style={{
                backgroundPosition: 'center',
                backgroundSize: '30px 30px',
                backgroundRepeat: 'no-repeat',
                height: '30px',
                width: '30px',
              }}
            />
          </div>
        )}
        {initialized && (
          <div
            className={classNames('d-flex flex-wrap justify-content-between')}
          >
            {data.map(item => {
              const value = item[valueField]
              const label = item[renderField]

              const selected = selectedValues.indexOf(value.toString()) >= 0

              return (
                <div
                  key={value}
                  className={classNames(
                    'd-flex flex-fill align-items-center justify-content-center mx-2 ffamily-secondary fs-8 p-2 rounded-4 text-break text-center',
                    selected ? 'bg-1 fc-white' : 'bshadow-c1 bg-white fc-1'
                  )}
                  style={{ width: elementWidth || '' }}
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleElementClicked(value)}
                  onKeyPress={() => this.handleElementClicked(value)}
                >
                  {label}
                </div>
              )
            })}
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ECMultiPickerButtonsFieldComponent)
