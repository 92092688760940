import classNames from 'classnames'
import JoditEditor from 'jodit-react'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { jujoHexDecode, jujoHexEncode, mapStateToProps } from '../../../utils'
import JujoLoading from '../../loading'

export class ECTextAreaFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      field_value: {},
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    const { initialized } = this.state
    const { field } = this.props
    const { value } = field

    const current_field_value = JSON.stringify(value)
    const prev_field_value = JSON.stringify(prevProps.field.value)

    let update = false
    if (current_field_value !== prev_field_value) {
      update = true
    }
    if (initialized !== prevState.initialized) {
      update = true
    }

    return update
  }

  componentDidMount = async () => {
    const { field, dataSource } = this.props
    const { name } = field
    const field_value = (dataSource && dataSource[name]) || ''

    this.setState({ initialized: true, field_value })
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  processOnBlur = () => {
    const { field_value } = this.state
    const { field, handleValueChanged } = this.props
    const { name } = field

    handleValueChanged(name, field_value)
  }

  render() {
    const { initialized, field_value } = this.state

    const { field, initialValue, handleValueChanged, readonly } = this.props
    const { name, placeholder, richTextMode, defaultMode } = field

    const useHexEncoding = field.useHexEncoding || false

    const parsed_initial_value = useHexEncoding
      ? jujoHexDecode(initialValue)
      : initialValue

    return (
      <>
        {!initialized && <JujoLoading />}
        {initialized && (richTextMode === undefined || richTextMode === false) && (
          <textarea
            name={name}
            placeholder={placeholder || ''}
            rows={3}
            defaultValue={parsed_initial_value}
            onBlur={e => {
              const { value } = e.target
              const parsed_value = useHexEncoding ? jujoHexEncode(value) : value
              handleValueChanged(name, parsed_value)
            }}
          />
        )}
        {initialized && richTextMode && richTextMode === true && (
          <div
            key={`richtext_${name}`}
            onBlur={() => {
              this.processOnBlur()
            }}
            className={classNames('bg-white')}
          >
            <JoditEditor
              value={field_value}
              config={{
                // zIndex: 0,
                // defaultMode: defaultMode || 1, //  MODE_WYSIWYG = 1 MODE_SOURCE = 2 MODE_SPLIT = 3,
                // readonly,
                // enableDragAndDropFileToEditor: true,
                // saveModeInCookie: false,
                // spellcheck: true,
                // editorCssClass: false,
                // triggerChangeEvent: true,
                // direction: 'ltr',
                // language: 'en',
                // debugLanguage: false,
                // i18n: 'en',
                // tabIndex: -1,
                // toolbar: true,
                // enter: 'div',
                // useSplitMode: true,
                // colorPickerDefaultTab: 'background',
                // imageDefaultWidth: 100,
                // events: {},
                // textIcons: false,
                // uploader: {
                //   insertImageAsBase64URI: true,
                // },
                // placeholder: '',
                // showXPathInStatusbar: true,
                // buttons: ['bold'],

                zIndex: 0,
                enter: 'div',
                useSplitMode: true,
                defaultMode: defaultMode || 1, //  MODE_WYSIWYG = 1 MODE_SOURCE = 2 MODE_SPLIT = 3,
                readonly, // all options from https://xdsoft.net/jodit/doc/
              }}
              tabIndex={0}
              onChange={content => {
                this.setState({ field_value: content })
              }}
            />
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ECTextAreaFieldComponent)
