/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeSecurePaymentsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const payments = [
      'payment-visa',
      'payment-mastercard',
      'payment-maestro',
      'payment-sepa',
      'payment-paypal',
      'payment-amex',
    ]

    return (
      <div>
        <div className={classNames('d-flex justify-content-center')}>
          <div
            className={classNames('lock-icon')}
            style={{
              width: '15px',
              height: '15px',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <div className={classNames('ms-1 fw-bold fs-8')}>
            {texts[locale].secure_shopping}
          </div>
        </div>
        <div className={classNames('d-flex justify-content-between pt-4 pb-2')}>
          {payments.map(icon => (
            <div
              key={icon}
              className={classNames(icon, 'mx-1')}
              style={{
                height: '45px',
                width: '45px',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}
            />
          ))}
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeSecurePaymentsComponent)
