import React from 'react'
import { connect } from 'react-redux'
import JujoLeraMenu from '../../../../../../src/components/page_layout/layouts/lera/_parts/menu'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeAdminSidebar extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    return (
      <div
        className={classNames('bg-light-gray d-flex flex-column p-3')}
        style={{ marginTop: '56px' }}
      >
        <JujoLeraMenu />
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeAdminSidebar)
