/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps, cloneObj } from '../../../../../../../src/utils'

import { accessTheFastCheckout } from '../../../../utils'
import { saveSessionToServer } from '../../../../../../../src/services/sessionHelper'
import JujoButtonComponent from '../../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class MyCallToActionServices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleAdd = async sId => {
    const { session, updateSession, environment } = this.props
    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { cart } = session_data
    const { path } = environment
    cart.back_to_url = path
    const { products } = cart
    products[sId].quantity = 1
    const returned_data = await saveSessionToServer(session_id, session_data)
    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  handleCheckOut = async sId => {
    await this.handleAdd(sId)
    accessTheFastCheckout()
  }

  renderService = sId => {
    const { session } = this.props
    const { session_data } = session
    const { product_data } = session_data
    const service_def = product_data ? product_data[sId] : {}
    const { cover_picture, slug: product_slug } = service_def || {}

    const { environment, specialization } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const product_color = sId === 7 ? 6 : 7

    const html = []
    html.push(
      <div
        key={uuid()}
        className={classNames(
          `border border-color-${product_color} rounded-20 p-4 w-100 m-2`
        )}
      >
        <div className={classNames('row')}>
          <div className={classNames('col-3 col-md-2')}>
            <div
              style={{
                backgroundImage: `url(${cover_picture})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '80px',
                height: '80px',
              }}
            />
          </div>
          <div className={classNames('col-9 col-md-10')}>
            <div className={classNames('d-flex flex-column')}>
              <div
                className={classNames(`fc-${product_color} fw-bold fs-7 mb-2`)}
              >
                {texts[locale][`call_to_action_title_${product_slug}`]}
              </div>
              <div className={classNames('fs-8 fc-5')}>
                <div
                  className={classNames('d-flex flex-row align-items-center')}
                >
                  <div>
                    {
                      texts[locale][
                        `call_to_action_description_${product_slug}`
                      ]
                    }
                  </div>
                  <div className={classNames('fc-4')}>
                    <JujoButtonComponent
                      bstyle={3}
                      bwidth="190px"
                      blabel={texts[locale].add_to_cart}
                      bicon="cart-icon-2"
                      bFontClass="fw-bold"
                      bdata=""
                      handleClick={() => this.handleCheckOut(sId)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
    return html
  }

  render() {
    const { genericData } = this.props
    const { myResults } = genericData || {}
    const { swab } = myResults || {}
    const { diet_status, training_card_status } = swab

    return (
      <div
        className={classNames(
          'd-flex flex-column align-items-center justify-content-between mt-4'
        )}
      >
        <>
          {diet_status === 'none' && this.renderService(7)}
          {training_card_status === 'none' && this.renderService(8)}
        </>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MyCallToActionServices)
