/* eslint-disable no-undef */
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeCookieBotComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { environment, specialization } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    return (
      <div
        className={classNames('fc-white')}
        role="button"
        tabIndex={0}
        onClick={() => {
          Cookiebot.renew()
        }}
        onKeyPress={() => {
          Cookiebot.renew()
        }}
      >
        {texts[locale].manage_cookies}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeCookieBotComponent)
