import workflowStepsMap from '../../enums/workflow_steps_map'

export function getSessionClientData(session) {
  const { session_data } = session
  const { cart } = session_data

  const client_data = cart ? cart.client_data : {}

  if (Object.keys(client_data).length === 0) {
    client_data.order_workflow = {
      current_step: workflowStepsMap.shopping_cart,
      registration_enabled: true,
    }
  }

  return client_data
}
