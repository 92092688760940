import React from 'react'
import { connect } from 'react-redux'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps, cloneObj } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeCartIcon extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  goToCart = async () => {
    const { session, updateSession, environment } = this.props
    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { cart } = session_data
    const { path } = environment
    cart.back_to_url = path
    const returned_data = await saveSessionToServer(session_id, session_data)
    session_copy.session_data = returned_data
    await updateSession(session_copy)

    if (typeof window !== 'undefined') {
      if (window.location.pathname.split('/')[1] === 'my-account') {
        window.location.href = '/my-account/fast-checkout/'
      } else {
        window.location.href = '/cart/'
      }
    }
  }

  render() {
    const { session } = this.props
    const { session_data } = session
    const { cart } = session_data || {}
    const { totals } = cart || {}
    const { total_quantity } = totals || 0
    return (
      <div className={classNames('d-flex ms-4')}>
        <div
          className={classNames('cart-icon-2 theme-svg')}
          style={{
            height: '25px',
            width: '25px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }}
          role="button"
          label="cart"
          tabIndex={0}
          onClick={() => {
            if (total_quantity > 0) this.goToCart()
          }}
          onKeyPress={() => {
            if (total_quantity > 0) this.goToCart()
          }}
        />
        {total_quantity > 0 && (
          <div
            className={classNames(
              'd-flex bg-1 fc-white rounded-percentage-100 fs-8 justify-content-center align-items-center ms-1'
            )}
            style={{ width: '20px', height: '20px' }}
          >
            {total_quantity}
          </div>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeCartIcon)
