/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import { cloneObj, mapStateToProps } from '../../../../../../src/utils'
import * as actionCreators from '../../../../../../src/store/actions'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import * as apiService from '../../../../../../src/services/apiService'

import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'
import runTestWorkflowStepsMap from '../../common_group/enums/run_test_workflow_steps_map'
import { showMessageBox } from '../../common_group/message_box'
import JujoLoading from '../../../../../../src/components/loading'
import JujoButtonComponent from '../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaeSwabUserDataComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      fields: {
        swab_id: {
          type: 'text',
          alias: 'swab_code',
          value: '',
        },
        first_name: {
          type: 'text',
          value: '',
        },
        last_name: {
          type: 'text',
          value: '',
        },
        email: {
          type: 'email',
          value: '',
        },
        email_confirmation: {
          type: 'email_confirmation',
          value: '',
        },
        privacy_acceptance: {
          type: 'boolean',
          value: false,
        },
        terms_conditions_acceptance: {
          type: 'boolean',
          value: false,
        },
      },
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { fields } = this.state
    const { session } = this.props
    const { session_data } = session
    const { run_test } = session_data
    const { user_data, swab_id } = run_test

    const { profile } = user_data
    const cloned_fields = cloneObj(fields)

    if (Object.keys(profile).length > 0) {
      cloned_fields.swab_id.value = swab_id
      cloned_fields.first_name.value = profile.first_name
      cloned_fields.last_name.value = profile.last_name
      cloned_fields.email.value = profile.email
      cloned_fields.privacy_acceptance.value = profile.privacy_acceptance
      cloned_fields.terms_conditions_acceptance.value =
        profile.terms_conditions_acceptance
    }

    this.setState({ initialized: true, fields: cloned_fields })
  }

  handleValueChanged = (k, v) => {
    const { fields } = this.state
    const cloned_fields = cloneObj(fields)

    cloned_fields[k].value = v
    this.setState({ fields: cloned_fields })
  }

  renderFields = () => {
    const { specialization, environment } = this.props
    const { fields } = this.state

    const keys = Object.keys(fields)

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const terms_conditions_url = `${process.env.site_url + locale}/${
      texts[locale].terms_conditions_url
    }`
    const privacy_policy_url = `${process.env.site_url + locale}/${
      texts[locale].privacy_policy_url
    }`

    const html = []
    for (let i = 0; i !== keys.length; i += 1) {
      const k = keys[i]
      const f = fields[k]
      const { type, alias, value } = f
      if (type !== 'boolean') {
        html.push(
          <div key={k} className={classNames('my-3 col-md-6')}>
            <div className={classNames('ffamily-secondary fs-9 fc-gray')}>
              {alias ? texts[locale][alias] || alias : texts[locale][k] || k}
            </div>
            <div
              className={classNames(
                'p-1 border bg-white border-color-4 rounded'
              )}
            >
              <input
                type={type}
                defaultValue={value}
                onChange={e => this.handleValueChanged(k, e.target.value)}
              />
            </div>
          </div>
        )
      }
      if (i === 0) {
        html.push(<div key="empty" className={classNames('my-3 col-md-6')} />)
      }
    }

    html.push(
      <div key="privacy-terms-conditions">
        <div className={classNames('fs-8 fc-4 fst-italic mt-4')}>
          <input
            type="checkbox"
            className={classNames('me-1')}
            defaultChecked={fields.privacy_acceptance.value}
            style={{ display: 'inline-block', width: 'auto' }}
            onChange={e => {
              const { checked } = e.target
              this.handleValueChanged('privacy_acceptance', checked)
            }}
          />
          <span>{`${texts[locale].acceptance_privacy_part_1} `}</span>
          <span>
            <a href={privacy_policy_url} target="_blank" rel="noreferrer">
              {texts[locale].acceptance_privacy_part_2}
            </a>
          </span>
          <span>{` ${texts[locale].acceptance_privacy_part_3}`}</span>
        </div>
        <div className={classNames('fs-8 fc-4 fst-italic mt-4')}>
          <input
            type="checkbox"
            className={classNames('me-1')}
            defaultChecked={fields.terms_conditions_acceptance.value}
            style={{ display: 'inline-block', width: 'auto' }}
            onChange={e => {
              const { checked } = e.target
              this.handleValueChanged('terms_conditions_acceptance', checked)
            }}
          />
          <span>{`${texts[locale].acceptance_terms_part_1} `}</span>
          <span>
            <a href={terms_conditions_url} target="_blank" rel="noreferrer">
              {texts[locale].acceptance_terms_part_2}
            </a>
          </span>
          <span>{` ${texts[locale].acceptance_terms_part_3}`}</span>
        </div>
      </div>
    )

    return html
  }

  updateSessionAndContinue = async () => {
    const { session, updateSession } = this.props
    const { fields } = this.state

    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { run_test } = session_data
    const { user_data } = run_test
    const { profile } = user_data

    run_test.swab_id = fields.swab_id.value
    run_test.workflow_step = runTestWorkflowStepsMap.sensitive_data_compilation
    profile.first_name = fields.first_name.value
    profile.last_name = fields.last_name.value
    profile.email = fields.email.value
    profile.privacy_acceptance = fields.privacy_acceptance.value
    profile.terms_conditions_acceptance =
      fields.terms_conditions_acceptance.value

    const returned_data = await saveSessionToServer(session_id, session_data)

    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  confirmAndContinueClicked = async () => {
    const { fields } = this.state
    const { environment, authentication } = this.props

    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'swab',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'validate-assigned-swab',
      pDefValue: 'validate-assigned-swab',
    })

    const parsedEp = parseEndpoint(requestData)
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      {
        swab_id: fields.swab_id.value,
        first_name: fields.first_name.value,
        last_name: fields.last_name.value,
        email: fields.email.value,
        email_confirmation: fields.email_confirmation.value,
        privacy_acceptance: fields.privacy_acceptance.value,
        terms_conditions_acceptance: fields.terms_conditions_acceptance.value,
      }
    )

    const { status } = response
    if (status === 200 || status === 201) {
      await this.updateSessionAndContinue()
    } else {
      showMessageBox(this, response, 'error')
    }
  }

  render() {
    const { initialized } = this.state
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <>
        {initialized === false && <JujoLoading />}
        {initialized === true && (
          <div>
            <div className={classNames('row align-items-center gy-3')}>
              <div
                className={classNames('col-12 col-md-6')}
                style={{
                  backgroundImage: `url(/${process.env.client}/run-test/kit.png`,
                  height: '150px',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              />
              <div className={classNames('col-12 col-md-6')}>
                <div className={classNames('fw-bold')}>
                  {texts[locale].run_test_step_1_title}
                </div>
                <div
                  className={classNames('fst-italic fs-7')}
                  dangerouslySetInnerHTML={{
                    __html: texts[locale].run_test_step_1_description,
                  }}
                />
              </div>
            </div>
            <div className={classNames('row')}>{this.renderFields()}</div>
            <div className={classNames('d-flex justify-content-center my-4')}>
              <JujoButtonComponent
                bstyle={3}
                bwidth="200px"
                blabel={texts[locale].confirm_and_continue}
                handleClick={async () => {
                  await this.confirmAndContinueClicked()
                }}
              />
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeSwabUserDataComponent)
