/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'

import { cloneObj, mapStateToProps } from '../../../../../../src/utils'
import * as apiService from '../../../../../../src/services/apiService'
import MySwabTracing from '../../common_group/my_swab_tracing'
import JujoLoading from '../../../../../../src/components/loading'

const classNames = require('classnames')

export class VitaeProcessInProgress extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dataLoaded: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    await this.updateData()
  }

  updateData = async () => {
    await this.createMyResultsOnRedux()
    await this.getCollectionFromServerAndUpdateOnRedux('my/swab', 'swab', '', 0)
    this.setState({ dataLoaded: true })
  }

  createMyResultsOnRedux = async () => {
    const { genericData, updateGenericData } = this.props
    const updatedGenericData = cloneObj(genericData)
    updatedGenericData.myResults = {}
    updatedGenericData.myResults.menuItemSelected = 0
    await updateGenericData(updatedGenericData)
  }

  getCollectionFromServerAndUpdateOnRedux = async (
    url,
    name,
    params,
    index
  ) => {
    let { genericData } = this.props
    const { updateGenericData, environment } = this.props
    const { locale } = environment
    const parsed_url = `${url}?locale=${locale}&page=1&per_page=9999${params}`
    const response = await apiService.httpGet(
      `${process.env.apiUrl}${parsed_url}`
    )
    if (response.status === 200) {
      let data = response.data.data || false
      data =
        index !== undefined && index >= 0 && data.length > 0
          ? data[index]
          : data
      genericData = {
        ...genericData,
        myResults: { ...genericData.myResults, [name]: data },
      }
      await updateGenericData(genericData)
      return data
    }
    return false
  }

  render() {
    const { dataLoaded } = this.state
    const { genericData } = this.props
    const { myResults } = genericData || {}
    const { swab } = myResults || {}
    const status_show_results = !!(swab && swab.status_show_results)
    return (
      <div className={classNames('pt-4')}>
        <div className={classNames('pt-4')}>
          {!dataLoaded && <JujoLoading />}
          {dataLoaded && !swab && <div>Tampone non rilevato...</div>}
          {dataLoaded && swab && !status_show_results && <MySwabTracing />}
          {dataLoaded && swab && status_show_results && (
            <div>Tampone analizzato...</div>
          )}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeProcessInProgress)
