export function getCurrentPageDefinition(props) {
  const { environment, specialization, session } = props

  const { session_data } = session
  const { page_visibility } = session_data

  const pathSections = environment.path.split('/').filter(section => section)
  const { languages } = process.env
  const lang_list = languages.split(',')

  if (pathSections.length === 0) {
    // If path Sections is emply, the urs is root so go to home
    pathSections.push('home')
  }

  // Se hai una sola sezione e questa sezione è uguale a
  // una delle lingua del sito allora sei sulla home
  if (pathSections.length === 1 && lang_list.indexOf(pathSections[0]) >= 0) {
    pathSections.push('home')
  }

  const { pages } = specialization.config

  //let recursiveSection = pages[pathSections[0].replace(/-/g, '_')]
  let recursiveSection = pages[pathSections[1]?.replace(/-/g, '_')]
  if (recursiveSection) {
    //for (let i = 1; i < pathSections.length; i += 1) {
    for (let i = 2; i < pathSections.length; i += 1) {
      const section = pathSections[i].replace(/-/g, '_')
      recursiveSection = recursiveSection[section]
      if (recursiveSection === undefined) break
    }

    if (recursiveSection) {
      if (recursiveSection.layout) {
        return recursiveSection
      }
      if (recursiveSection.index !== undefined) {
        return recursiveSection.index
      }
    }
  }

  // Nel caso in cui la pagina non è hardcodata in gatsby gli viene associato un layout di default
  if (typeof window !== 'undefined') {
    const dynamic_layouts = require(`../../../../vitae_specializations/src/${process.env.client}/dynamic_layouts.json`)

    const { pathname } = window.location

    let dynamic_template = {
      layout: 'blank_page',
      specialized: false,
      visibility: page_visibility,
      components: {
        pageRendererComponent: {
          comp: 'jujo_page_renderer',
        },
      },
    }

    const keys = Object.keys(dynamic_layouts)
    for (let i = 0; i !== keys.length; i += 1) {
      const k = keys[i]
      if (pathname.startsWith(k)) {
        dynamic_template = dynamic_layouts[k]
        break
      }
    }

    return dynamic_template
  }

  return false
}

export function handleChangePageClicked(path, props) {
  const { environment, updateEnvironment } = props
  const newEnvironment = { ...environment }

  newEnvironment.path = path
  updateEnvironment(newEnvironment)

  if (typeof window !== 'undefined') {
    window.history.pushState({}, null, path)
  }
}
