/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'
import VideoIframe from './video_iframe'

const classNames = require('classnames')

export class VitaeVideoAccademyVideo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const { environment, data } = this.props
    const { locale } = environment
    return (
      <div className={classNames('row m-0 pt-4 d-flex flex-column')}>
        <VideoIframe url={data.video_link} />
        <div className={classNames('p-3 d-flex flex-column bg-3')}>
          <div className={classNames('fs-8 fw-bold mb-2 lh-sm')}>
            {data.title[locale]}
          </div>
          <div className={classNames('fs-9 fst-italic lh-sm')}>
            {data.description[locale]}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeVideoAccademyVideo)
