import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import * as apiService from '../../../../../../src/services/apiService'
import { mapStateToProps } from '../../../../../../src/utils'
import JujoLoading from '../../../../../../src/components/loading'

const classNames = require('classnames')

export class VitaePromoterReports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      data: [],
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const url = 'action/get/swab/promoter-reports'
    const response = await apiService.httpGet(`${process.env.apiUrl}${url}`)
    if (response.status === 200) {
      this.setState({ data: response.data.swabs, initialized: true })
    }
  }

  render() {
    const { data, initialized } = this.state
    const { environment, specialization } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    return (
      <div className={classNames('row')}>
        {!initialized && <JujoLoading />}
        {initialized && data && data.length === 0 && (
          <div className={classNames('pt-4 fst-italic fw-bold fc-1')}>
            {texts[locale].no_reports_yet}
          </div>
        )}
        {initialized && data && data.length === 0 && (
          <div className={classNames('my-3')}>
            {texts[locale].no_statements_to_view}
          </div>
        )}
        {data.map(record => (
          <div
            key={record.id}
            className={classNames(
              'bg-3 my-1 px-3 py-3 fs-7 ffamily-secondary d-flex flex-column justify-content-between align-items-center'
            )}
          >
            <div className={classNames('my-1 row w-100')}>
              <div className={classNames('col-4 fw-bold px-3')}>
                {record.swab_id_formatted}
              </div>
              <div className={classNames('col-4')}>
                {record.product_title_formatted.replace('Kit', '')}
              </div>
              <div className={classNames('col-4')}>
                {record.used_at_formatted_day}
              </div>
            </div>
            <div className={classNames('my-1 row w-100')}>
              {record.level === 1 && record.lev_1_id > 0 && (
                <div
                  className={classNames(
                    'col-12 p-0 col-md-4 px-3',
                    record.level === 1 ? 'fw-bold' : ''
                  )}
                >
                  {`${
                    record.level === 1
                      ? texts[locale].profit
                      : record.lev_1_name
                  }: ${record.lev_1_profit}`}{' '}
                  €
                </div>
              )}
              {record.level === 1 && record.lev_1_id === 0 && (
                <div className={classNames('col-12 p-0 col-md-4')} />
              )}
              {record.level <= 2 && record.lev_2_id > 0 && (
                <div
                  className={classNames(
                    'col-12 p-0 col-md-4 px-3',
                    record.level === 2 ? 'fw-bold' : ''
                  )}
                >
                  {`${
                    record.level === 2
                      ? texts[locale].profit
                      : record.lev_2_name
                  }: ${record.lev_2_profit}`}{' '}
                  €
                </div>
              )}
              {record.level <= 2 && record.lev_2_id === 0 && (
                <div className={classNames('col-12 p-0 col-md-4')} />
              )}
              {record.level <= 3 && record.lev_3_id > 0 && (
                <div
                  className={classNames(
                    'col-12 p-0 col-md-4 px-3',
                    record.level === 3 ? 'fw-bold' : ''
                  )}
                >
                  {`${
                    record.level === 3
                      ? texts[locale].profit
                      : record.lev_3_name
                  }: ${record.lev_3_profit}`}{' '}
                  €
                </div>
              )}
              {record.level <= 3 && record.lev_3_id === 0 && (
                <div className={classNames('col-12 p-0 col-md-4')} />
              )}
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaePromoterReports)
