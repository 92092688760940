import apiCallMap from '../../../../../src/enums/apiCallMap'
import * as apiService from '../../../../../src/services/apiService'
import apiRequestTypesMap from '../../../../../src/enums/apiRequestTypesMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../src/services/servicesHelper'
import {
  createMSBDef,
  getEmptyMessageBox,
} from '../../../../../src/components/message_box/helper'
import {
  injectComponent,
  retrieveComponent,
  translate,
} from '../../../../../src/utils'

export function composeAdminActionRequest(sender, entity, action_name) {
  const { props } = sender
  const { environment, authentication } = props

  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    entity,
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: action_name,
    pDefValue: action_name,
  })

  const parsedEp = parseEndpoint(requestData)

  return parsedEp
}

export async function performRequest(endpoint, data) {
  const response = await apiService.httpPost(
    `${process.env.apiUrl}${endpoint}`,
    data
  )

  return response
}

async function closeMessageBox(params) {
  const { sender, msbox_instance_id } = params
  retrieveComponent(sender, msbox_instance_id)
}
async function closeAllAndReloadGridIfExists(params) {
  const { sender, msbox_instance_id, comp_id } = params
  const { props } = sender
  const { gridComponent } = props
  const { loadGrid } = gridComponent || {}

  await retrieveComponent(sender, msbox_instance_id)
  if (comp_id !== 0) {
    await retrieveComponent(sender, comp_id)
  }
  if (loadGrid) {
    loadGrid(true)
  }
}

export function manageResponse(sender, response) {
  const messageBox = getEmptyMessageBox()

  if (response) {
    const { status, data, comp_id } = response
    const { message } = data

    let icon = 'warning-icon-color'
    let callback_function = closeMessageBox

    if (status === 200 || status === 201) {
      icon = 'confirm-icon-color'
      callback_function = closeAllAndReloadGridIfExists
    }

    messageBox.icon = icon
    messageBox.message = message

    const continue_btn = createMSBDef(
      translate('continue_browsing'),
      callback_function,
      {
        sender,
        msbox_instance_id: messageBox.id,
        comp_id: comp_id || 0,
      },
      true
    )
    messageBox.buttons.push(continue_btn)
  }

  const component_definition = {
    specialized: false,
    path: 'message_box',
    data: {
      messageBox,
    },
  }

  injectComponent(sender, messageBox.id, component_definition)
}
