/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import * as actionCreators from '../../../../../../src/store/actions'
import paymentsTypesMap from '../../checkout_group/enums/payments_types_map'
import VitaePaymentMethodSelectorComponent from '../../common_group/payment_method_selector'
import VitaePaymentStepOrderSummaryComponent from './_parts/order_summary'
import JujoLoading from '../../../../../../src/components/loading'

import * as apiService from '../../../../../../src/services/apiService'
import { mapStateToProps } from '../../../../../../src/utils'
import { showMessageBox } from '../../common_group/message_box'
import JujoButtonComponent from '../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaePaymentStepComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pay_button_clicked: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handlePayButtonClicked = async () => {
    this.setState({ pay_button_clicked: true })

    const { session, environment, authentication } = this.props
    const { session_data, session_id } = session
    const { run_test } = session_data
    const { selected_payment_method } = run_test

    if (selected_payment_method === paymentsTypesMap.paypal) {
      const requestData = baseRequestObject(
        apiCallMap.publicServerAction,
        paymentsTypesMap.paypal,
        apiRequestTypesMap.post,
        environment,
        authentication
      )

      requestData.placeholderMapping.push({
        pSource: 'static',
        pKey: '{action}',
        pValue: 'generate-payment-link',
        pDefValue: 'generate-payment-link',
      })

      const parsedEp = parseEndpoint(requestData)
      const response = await apiService.httpPost(
        `${process.env.apiUrl}${parsedEp}`,
        { session_id }
      )

      const { status } = response
      if (status === 200 || status === 201) {
        const { data } = response
        const { url } = data
        if (typeof window !== 'undefined') {
          window.location.href = url
        }
      }
    }
    if (selected_payment_method === paymentsTypesMap.bank_transfer) {
      if (typeof window !== 'undefined') {
        window.location.href = `/thank-you/?sessionId=${session_id}`
      }
    }
  }

  handleSaveButtonClicked = async () => {
    const { session } = this.props
    const { session_id } = session
    const parsedEp = 'my/swab/use'
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      { session_id }
    )

    const { status } = response
    if (status === 200 || status === 201) {
      if (typeof window !== 'undefined') {
        window.location.href = '/my-account'
      }
    } else {
      showMessageBox(this, response, 'error')
    }
  }

  render() {
    const { pay_button_clicked } = this.state
    const { specialization, environment, session } = this.props

    const { session_data } = session
    const { run_test, product_data, payments } = session_data
    const { selected_payment_method, skip_payment } = run_test

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    if (product_data === undefined || payments === undefined) {
      return <JujoLoading />
    }

    return (
      <>
        {!skip_payment && (
          <div
            className={classNames('mx-auto')}
            style={{ maxWidth: 'min(400px, 90%)' }}
          >
            <VitaePaymentStepOrderSummaryComponent />
            <div className={classNames('bg-white mt-4 p-3')}>
              <VitaePaymentMethodSelectorComponent />
              <div className={classNames('d-flex justify-content-center py-4')}>
                {pay_button_clicked === false && (
                  <JujoButtonComponent
                    bstyle={3}
                    bwidth="210px"
                    blabel={texts[locale].pay.toUpperCase()}
                    bDisabled={
                      selected_payment_method === null ||
                      selected_payment_method === ''
                    }
                    handleClick={this.handlePayButtonClicked}
                  />
                )}
                {pay_button_clicked === true && <JujoLoading />}
              </div>
            </div>
          </div>
        )}
        {skip_payment && (
          <>
            <div className={classNames('d-flex justify-content-center py-4')}>
              {texts[locale].swab_already_paid}
            </div>
            <div className={classNames('d-flex justify-content-center py-4')}>
              <JujoButtonComponent
                bstyle={3}
                bwidth="210px"
                blabel={texts[locale].save.toUpperCase()}
                handleClick={this.handleSaveButtonClicked}
              />
            </div>
          </>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaePaymentStepComponent)
