import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'
import VitaeProductDescriptionComponent from '../home_group/products/description'

const classNames = require('classnames')

export class VitaeProductExpandableDescription extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { data, session, environment, specialization } = this.props
    const { session_data } = session
    const { browser_data } = session_data
    const { products } = browser_data || {}
    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations
    const { slug } = data || {}
    const product = products.filter(item => item.slug === slug)[0]
    const { description, attributes } = product
    const item_color = attributes.filter(item => item.key === 'color')[0]
    const { value: kit_color } = item_color || {}

    let label = ''
    if (slug === 'kit_health') {
      label = texts[locale].vitaedna_health_kit_genetic_test
    }
    if (slug === 'kit_diet') {
      label = texts[locale].genetic_based_personalized_diet
    }
    if (slug === 'kit_sport') {
      label = texts[locale].training_program_on_your_dna
    }

    return (
      <div className={classNames('d-block z-index-2')}>
        <VitaeProductDescriptionComponent
          slug={slug}
          desc={description}
          color={kit_color}
          label={label}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeProductExpandableDescription)
