import { saveSessionToServer } from '../../../../../../../src/services/sessionHelper'
import { cloneObj } from '../../../../../../../src/utils'

export async function updateCartAfterOrderCompleted(sender, updated_context) {
  const { props } = sender
  const { session, updateSession } = props
  const session_copy = cloneObj(session)

  const { session_id, session_data } = session_copy
  const { working_context } = session_data
  session_data[working_context] = updated_context
  const returned_data = await saveSessionToServer(session_id, session_data)

  session_copy.session_data = returned_data
  await updateSession(session_copy)
}
