/* eslint-disable import/no-cycle */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'

import GridHeadComponent from './head'
import GridActionsComponent from './gridActions'
import GridPaginationComponent from './pagination'
import GridRowsComponent from './rows'
import AdvancedSearchPanelComponent from './advancedSearchPanel'

import { clearFilters } from './helper/sortFilterFunctions'
import {
  calculateColumnTemplate,
  calculateGridContainerwidth,
  getAdvancedSearch,
  getGridData,
  getGridDefinition,
} from './helper/gridFunctions'
import { getStoredGridProps } from './helper/lsInteractionFunctions'
import lsGridPropsMap from '../../enums/lsGridPropsMap'
import sourcesMap from '../../enums/sourcesMap'
import { cloneObj, mapStateToProps, translate } from '../../utils'
import JujoLoading from '../loading'

const classNames = require('classnames')

export class JujoGridComponent extends React.Component {
  constructor(props) {
    super(props)
    this.gridRef = React.createRef()
    this.searchRef = React.createRef()

    this.state = {
      initialized: false,
      loading_data: false,
      fullWidth: false,
      gridDataObj: {},
      ctxVarsDependencyList: [],
      advancedSearchDefinition: {},
      advancedSearchVisible: false,
      externalComponents: {},
      selectedRows: {
        idList: [],
      },
    }
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  componentDidMount = async () => {
    await this.loadAdvancedSearch()
    await this.checkContextVariablesDependency()
    await this.loadGrid(true)
    await this.loadExternalComponents()

    this.setState({ initialized: true })
  }

  componentDidUpdate = async prevProps => {
    const { ctxVarsDependencyList } = this.state
    const { environment, entity, dynamicForm } = this.props
    const { path } = environment

    if (
      prevProps.environment.locale !== environment.locale ||
      prevProps.entity !== entity
    ) {
      await this.loadAdvancedSearch()
      await this.checkContextVariablesDependency()
      await this.loadGrid(true)
    }

    if (
      dynamicForm &&
      prevProps.dynamicForm &&
      prevProps.dynamicForm.visible === true &&
      dynamicForm.visible === false &&
      prevProps.dynamicForm.entity === entity
    ) {
      await this.loadGrid(false)
    }

    if (
      ctxVarsDependencyList.length > 0 &&
      JSON.stringify(prevProps.environment.contextVariables[path]) !==
        JSON.stringify(environment.contextVariables[path])
    ) {
      await this.loadAdvancedSearch()
      await this.loadGrid(true)
    }
  }

  checkContextVariablesDependency = async () => {
    const gridDefinition = getGridDefinition(this.props)
    const { apis } = gridDefinition
    const { getData } = apis
    const { defaultFilters } = getData

    const ctxVarsDependencyList = []

    if (defaultFilters) {
      for (let i = 0; i !== defaultFilters.length; i += 1) {
        const filter = defaultFilters[i]
        const { fSource, fValue } = filter
        if (fSource === sourcesMap.contextVariable) {
          fValue.match(/{([^}]+)}/g).map(ctxV => {
            const parsedVar = ctxV.replace(/{|}/g, '')
            ctxVarsDependencyList.push(parsedVar)
            return true
          })
        }
      }
    }

    this.setState({ ctxVarsDependencyList })
  }

  loadExternalComponents = async () => {
    const gridDefinition = getGridDefinition(this.props)
    const { components } = gridDefinition
    if (!components) return

    const externalComponents = {}

    for (let i = 0; i !== components.length; i += 1) {
      const comp_def = components[i]
      const { specialized, position, compPath, compName } = comp_def
      if (specialized) {
        const DynamicComponent =
          require(`../../../vitae_specializations/src/${process.env.client}/${compPath}/${compName}`).default

        if (!externalComponents[position]) externalComponents[position] = []
        externalComponents[position].push(DynamicComponent)
      }
    }

    this.setState({ externalComponents })
  }

  loadGrid = async (changeInitializedStatus = false) => {
    if (changeInitializedStatus === true) this.setState({ initialized: false })

    this.setState({ loading_data: true })
    const gridDataObj = await getGridData(this.props)
    this.setState({ gridDataObj, loading_data: false })

    if (changeInitializedStatus === true) this.setState({ initialized: true })
  }

  loadAdvancedSearch = async () => {
    const advancedSearchDefinition = await getAdvancedSearch(this.props)
    this.setState({ advancedSearchDefinition })
  }

  handleSearch = async searchValue => {
    const { entity, updateGridIntoStorageAndRedux } = this.props

    const props = [
      {
        rowId: null,
        key: lsGridPropsMap.page,
        value: 1,
      },
      {
        rowId: null,
        key: lsGridPropsMap.searchValue,
        value: searchValue,
      },
    ]

    await updateGridIntoStorageAndRedux(entity, props)
    return true
  }

  handleCloseAdvancedSearch = () => {
    this.setState({ advancedSearchVisible: false })
  }

  updatedSelectedRows = selected_rows => {
    this.setState({ selectedRows: selected_rows })
  }

  loopIDsInPageAndUpdateSelectedRows = insert => {
    const gridDefinition = getGridDefinition(this.props)
    const { identifier } = gridDefinition

    const { selectedRows, gridDataObj } = this.state
    const updated_selected_rows = cloneObj(selectedRows)

    const { idList } = updated_selected_rows

    const { data } = gridDataObj
    for (let i = 0; i !== data.data.length; i += 1) {
      const id = data.data[i][identifier]
      const id_idx = idList.indexOf(id)

      if (insert === true && id_idx < 0) idList.push(id)
      if (insert === false && id_idx >= 0) idList.splice(id_idx, 1)
    }

    this.setState({ selectedRows: updated_selected_rows })
  }

  selectAllRows = () => {
    this.loopIDsInPageAndUpdateSelectedRows(true)
  }

  unselectAllRows = () => {
    this.loopIDsInPageAndUpdateSelectedRows(false)
  }

  renderExternalComponents = position => {
    const { externalComponents, gridDataObj } = this.state
    const component_for_position = externalComponents[position]
    const { data } = gridDataObj

    const html = []

    if (!component_for_position) return html

    for (let i = 0; i !== component_for_position.length; i += 1) {
      const DynamicComponent = component_for_position[i]
      html.push(
        <div key={`external_component_${i}`}>
          <DynamicComponent data={data} />
        </div>
      )
    }

    return html
  }

  render() {
    const {
      initialized,
      loading_data,
      fullWidth,
      gridDataObj,
      advancedSearchDefinition,
      advancedSearchVisible,
      selectedRows,
    } = this.state
    const {
      entity,
      specialization,
      environment,
      parentData,
      editableComponentProps,
    } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { data, status, message } = gridDataObj

    const gridDefinition = getGridDefinition(this.props)
    const { title, actions } = gridDefinition

    const gridProps = getStoredGridProps(this.props)
    const { searchValue, advancedSearchList } = gridProps

    const fullWidthStyle = {
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      left: 0,
      top: 0,
      zIndex: 2,
      background: '#00000080',
      padding: '1rem',
      overflowY: 'scroll',
    }

    const grid_calculated_width = calculateGridContainerwidth(
      gridDefinition,
      data
    )
    const grid_width = `max(${grid_calculated_width.toString()}px, 100%)`

    return (
      <div key={entity} style={fullWidth ? fullWidthStyle : {}}>
        <div className={classNames('position-relative')}>
          {initialized === true && (
            <>
              {loading_data && (
                <div
                  className={classNames(
                    'position-absolute h-100 w-100 zindex-1 d-flex align-items-center'
                  )}
                  style={{
                    background: '#ffffff87',
                  }}
                >
                  <div
                    className={classNames(
                      'd-flex border border-color-1 border-2 rounded-5 p-2 w-100 w-md-50 mx-auto bg-white shadow-sm'
                    )}
                  >
                    <JujoLoading />
                  </div>
                </div>
              )}
              {(status === 200 || status === 201) && (
                <div
                  className={classNames(
                    'container-fluid bg-white mb-2',
                    fullWidth ? 'p-4' : 'p-0'
                  )}
                  ref={this.gridRef}
                >
                  <div
                    className={classNames(
                      'd-flex flex-column flex-md-row align-items-center justify-content-between py-2'
                    )}
                  >
                    <div>
                      <div className={classNames('fs-6 ffamily-secondary')}>
                        {translate(title)}
                      </div>
                      {this.renderExternalComponents('after_title')}
                    </div>
                    <div
                      className={classNames(
                        'mt-2 mt-md-0 d-flex align-items-center'
                      )}
                    >
                      <div
                        className={classNames('d-flex border rounded-5 p-1')}
                      >
                        <input
                          key={title}
                          className={classNames('border-end fs-7')}
                          type="text"
                          placeholder={texts[locale].search}
                          defaultValue={searchValue}
                          ref={this.searchRef}
                          onKeyDown={async e => {
                            if (e.key === 'Enter') {
                              await this.handleSearch(e.target.value)
                              this.loadGrid()
                            }
                          }}
                        />
                        <div
                          className={classNames(
                            'btn search-icon theme-svg ms-1 ps-3'
                          )}
                          style={{
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: '25px',
                            height: '25px',
                            backgroundSize: '20px',
                          }}
                          label="clear grid"
                          role="button"
                          tabIndex={0}
                          onClick={async () => {
                            await this.handleSearch(
                              this.searchRef.current.value
                            )
                            this.loadGrid()
                          }}
                          onKeyPress={async () => {
                            await this.handleSearch(
                              this.searchRef.current.value
                            )
                            this.loadGrid()
                          }}
                        />
                      </div>
                      {Object.keys(advancedSearchDefinition).length > 0 && (
                        <div
                          className={classNames(
                            'btn ms-1 theme-svg',
                            advancedSearchList &&
                              Object.keys(advancedSearchList).length > 0
                              ? 'filter-icon-full'
                              : 'filter-icon-empty'
                          )}
                          style={{
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: '25px',
                            height: '25px',
                            backgroundSize: '20px 20px',
                          }}
                          label="clear grid"
                          role="button"
                          tabIndex={0}
                          onClick={async () => {
                            this.setState({ advancedSearchVisible: true })
                          }}
                          onKeyPress={async () => {
                            this.setState({ advancedSearchVisible: true })
                          }}
                        />
                      )}
                      <div
                        className={classNames('btn reset-icon theme-svg ms-1')}
                        style={{
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          width: '25px',
                          height: '25px',
                          backgroundSize: '25px',
                        }}
                        label="clear grid"
                        role="button"
                        tabIndex={0}
                        onClick={async () => {
                          await clearFilters(this.props)
                          await this.loadGrid(true)
                          this.searchRef.current.value = ''
                        }}
                        onKeyPress={async () => {
                          await clearFilters(this.props)
                          await this.loadGrid(true)
                          this.searchRef.current.value = ''
                        }}
                      />

                      <div
                        className={classNames(
                          'btn theme-svg ms-1',
                          fullWidth ? 'shrink-icon' : 'widen-icon'
                        )}
                        style={{
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          width: '25px',
                          height: '25px',
                          backgroundSize: '25px',
                        }}
                        label="widen grid"
                        role="button"
                        tabIndex={0}
                        onClick={async () => {
                          this.setState({ fullWidth: !fullWidth })
                        }}
                        onKeyPress={async () => {
                          this.setState({ fullWidth: !fullWidth })
                        }}
                      />
                    </div>
                  </div>
                  {actions && (
                    <GridActionsComponent
                      gridComponent={this}
                      entity={entity}
                      parentData={parentData}
                      editableComponentProps={editableComponentProps || {}}
                    />
                  )}
                  <div className={classNames('overflow-md-auto')}>
                    <div
                      className={classNames('max-width-md-unset')}
                      style={{
                        width: grid_width,
                        maxWidth: '100%',
                      }}
                    >
                      <div
                        className={classNames(
                          'd-none d-md-grid bg-1 fs-7 fc-white'
                        )}
                        style={{
                          gridTemplateRows: '1fr',
                          gridAutoFlow: 'row',
                          gap: '0px 0px',
                          gridTemplateColumns:
                            calculateColumnTemplate(gridDefinition),
                        }}
                      >
                        <GridHeadComponent
                          data={data}
                          entity={entity}
                          loadGrid={this.loadGrid}
                          selectAllRows={this.selectAllRows}
                          unselectAllRows={this.unselectAllRows}
                        />
                      </div>
                      {(data.data === undefined || data.data.length === 0) && (
                        <div
                          className={classNames(
                            'd-flex justify-content-center p-5'
                          )}
                          style={{
                            maxWidth: 'calc(100vw - 3rem)',
                            minHeight: '300px',
                          }}
                        >
                          {texts[locale].no_data_to_render}
                        </div>
                      )}
                      {data.data !== undefined && (
                        <GridRowsComponent
                          gridComponent={this}
                          entity={entity}
                          data={data}
                          selectedRows={selectedRows}
                          updatedSelectedRows={this.updatedSelectedRows}
                          gridRef={this.gridRef}
                          editableComponentProps={editableComponentProps || {}}
                        />
                      )}
                    </div>
                  </div>
                  <GridPaginationComponent
                    entity={entity}
                    data={data}
                    loadGrid={this.loadGrid}
                  />
                </div>
              )}
              {status !== 200 && status !== 201 && message !== '' && (
                <div
                  className={classNames(
                    'd-flex shadow-sm fw-bold fs-condensed p-3 align-items-center'
                  )}
                >
                  <div
                    className={classNames('error-alert-icon red-svg me-2')}
                    style={{
                      height: '30px',
                      width: '30px',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                    }}
                  />
                  {message}
                </div>
              )}
              {Object.keys(advancedSearchDefinition).length > 0 && (
                <div
                  className={classNames(
                    'position-absolute shadow-lg bg-white top-0'
                  )}
                  style={{
                    height: '100%',
                    transition: 'all ease-in-out 0.4s',
                    right: advancedSearchVisible ? '0%' : '-100%',
                    opacity: advancedSearchVisible ? '1' : '0',
                    backgroundColor: 'rgba(255, 255, 255, 0.6)',
                    backdropFilter: 'blur(10px)',
                    width: 'min(100%, 400px)',
                    borderRadius: '10px 0px 0px 10px',
                    overflow: 'hidden',
                  }}
                >
                  <AdvancedSearchPanelComponent
                    entity={entity}
                    loadGrid={this.loadGrid}
                    handleCloseAdvancedSearch={this.handleCloseAdvancedSearch}
                    advancedSearchDefinition={advancedSearchDefinition}
                  />
                </div>
              )}
            </>
          )}
          {initialized === false && <JujoLoading />}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoGridComponent)
