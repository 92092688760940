import http from './httpService'

import lsEntryMap from '../enums/lsEntryMap'
import { lsGet } from './localStorage'

export function httpHeaders(withToken, additionalHeaders) {
  const headersObj = { ...additionalHeaders }
  if (withToken === true) {
    const authentication = lsGet(lsEntryMap.authentication)
    const { access_token } = authentication || {}

    headersObj.headers = { Authorization: `Bearer ${access_token || {}}` }
  }

  if (!headersObj.headers) {
    headersObj.headers = {}
  }
  
  // headersObj.headers['Client-Request-Uri'] =
  //   typeof window !== 'undefined'
  //     ? window.location.pathname + window.location.search
  //     : ''

  if (typeof window !== 'undefined') {
    // Ottieni il percorso completo
    let fullPath = window.location.pathname + window.location.search;

    // Verifica se "/test/" fa parte dell'URL
    let testIndex = fullPath.indexOf('/private/');
    let desiredPath = fullPath; // default to fullPath se "/test/" non è presente

    if (testIndex !== -1) {
      // "/test/" è nell'URL, quindi estrai tutto ciò che viene dopo
      desiredPath = fullPath.slice(testIndex + '/private/'.length);
    }

    if (!desiredPath.startsWith('/')) {
      desiredPath = '/' + desiredPath;
    }
    // Impostare l'header con il percorso desiderato
    headersObj.headers['Client-Request-Uri'] = desiredPath;
  } else {
    headersObj.headers['Client-Request-Uri'] = '';
  }
  return headersObj
}

export function localizePath(path) {
  const environment = lsGet(lsEntryMap.environment)
  const { locale } = environment

  const alreadyHasQuerystring = path.indexOf('?') >= 0
  const alreadyHasLocale =
    path.indexOf('&locale=') >= 0 || path.indexOf('?locale=') >= 0

  let lQS = ''
  if (!alreadyHasLocale) {
    lQS = alreadyHasQuerystring ? `&locale=${locale}` : `?locale=${locale}`
  }

  return path + lQS
}

export async function httpPost(
  path,
  data,
  withToken = true,
  additionalHeaders = {}
) {
  try {
    const result = await http.post(
      localizePath(path),
      data,
      httpHeaders(withToken, additionalHeaders)
    )
    return result
  } catch (e) {
    return e
  }
}

export async function httpUpdate(
  path,
  item,
  withToken = true,
  additionalHeaders = {}
) {
  try {
    const result = await http.put(
      localizePath(path),
      item,
      httpHeaders(withToken, additionalHeaders)
    )
    return result
  } catch (e) {
    return e
  }
}

export async function httpGet(path, withToken = true, additionalHeaders = {}) {
  try {
    const result = await http.get(
      localizePath(path),
      httpHeaders(withToken, additionalHeaders)
    )
    return result
  } catch (e) {
    return e
  }
}

export async function httpDelete(
  path,
  withToken = true,
  additionalHeaders = {}
) {
  try {
    const result = await http.delete(
      localizePath(path),
      httpHeaders(withToken, additionalHeaders)
    )
    return result
  } catch (e) {
    return e
  }
}

export async function getFileToDownload(apiUrl) {
  const additionalHeaders = {}
  additionalHeaders.responseType = 'blob'
  const result = await http.get(apiUrl, httpHeaders(true, additionalHeaders))
  return result
}
