import workflowAccessPointMap from '../../enums/workflow_access_point_map'
import workflowStepsMap from '../../enums/workflow_steps_map'
import { getSessionClientData } from '../../order_workflow/helper'

export function getNextWorkflowStep(sender) {
  const { authentication, environment, session } = sender.props
  const { user } = authentication
  const { path } = environment

  const client_data = getSessionClientData(session)
  const { order_workflow } = client_data
  const { current_step } = order_workflow

  // Initialize to first step
  let next_step = workflowStepsMap.shopping_cart

  if (current_step === workflowStepsMap.shopping_cart) {
    if (
      path.startsWith(workflowAccessPointMap.it_acquista) ||
      path.startsWith(workflowAccessPointMap.en_buy)
    ) {
      next_step =
        Object.keys(user).length > 0
          ? workflowStepsMap.shipment
          : workflowStepsMap.registration
    }
    if (path.startsWith(workflowAccessPointMap.my_account_orders_new)) {
      next_step = workflowStepsMap.payment
    }
  }

  return next_step
}
