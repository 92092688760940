import React from 'react'
import { connect } from 'react-redux'
import Parser from 'html-react-parser'
import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'
import './styles.scss'

const classNames = require('classnames')

export class VitaeHomeSliderComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderSentences = () => {
    const { environment, specialization } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const html = []
    /*
    const { data } = this.props
    const { sentences } = data

    for (let i = 0; i !== sentences.length; i += 1) {
      html.push(
        <div
          key={`sentence_${i + 1}`}
          className={classNames(
            `w-450px position-absolute opacity-0 sentence-${i + 1}`
          )}
          style={{
            animation: `slider-slide-sentece ${
              4 * sentences.length
            }s  infinite`,
            animationDelay: `${4 * i}s`,
          }}
        >
          {sentences[i]}
        </div>
      )
    }
    */

    html.push(
      <div
        key="sentence_1"
        className={classNames(`w-450px position-absolute sentence-1 lh-sm`)}
      >
        {Parser(texts[locale].born_to_improve_voice_your_dna)}
      </div>
    )

    return html
  }

  renderImages = () => {
    const { data } = this.props
    const { images } = data

    const html = []

    for (let i = 0; i !== images.length; i += 1) {
      html.push(
        <div
          key={`image_${i + 1}`}
          className={classNames(
            'background-image opacity-0 rounded-percentage-100 border border-2 border-white h-250px w-250px position-absolute top-0 top-md-50 translate-md-60-y mt-4 mt-md-0'
          )}
          style={{
            backgroundImage: `url(${images[i]})`,
            animation: `slider-fade-image ${4 * images.length}s  infinite`,
            animationDelay: `${4 * i}s`,
          }}
        />
      )
    }

    return html
  }

  render() {
    return (
      <div
        className={classNames(
          'd-flex flex-column justify-content-center align-items-center bg-1-opacity-10 h-auto h-md-150px'
        )}
      >
        <div
          className={classNames(
            'fs-8 fc-1 text-center w-md-750px zindex-1 container position-relative d-flex flex-column align-items-center align-items-md-start'
          )}
        >
          {this.renderImages()}
          <div
            className={classNames(
              'd-flex align-items-center fc-black fs-2 ms-md-250px position-relative w-450px h-80px mt-270px mt-md-0 overflow-hidden'
            )}
          >
            {this.renderSentences()}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeHomeSliderComponent)
