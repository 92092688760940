/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../../../../src/utils'

import * as actionCreators from '../../../../../src/store/actions'

import PaymentsComponent from './payments'
import BusinessIntelligenceComponent from './business_intelligence'

export class VitaePaymentGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const { environment } = this.props
    const { tabs } = environment
    const { definition } = tabs
    const { tabEntity } = definition || {}

    if (tabEntity === 'business_intelligence') {
      return <BusinessIntelligenceComponent />
    }
    return <PaymentsComponent />
  }
}

export default connect(mapStateToProps, actionCreators)(VitaePaymentGroup)
