/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeSelectedProductsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const { session } = this.props

    const { session_data } = session
    const { cart, product_data } = session_data
    const { products } = cart
    return (
      <div
        className={classNames('py-3 px-2 border-bottom border-color-4 fs-8')}
      >
        {Object.keys(products).map(pID => {
          const prod = products[pID]
          const { quantity, total_gross_price } = prod

          const pDef = product_data[pID]
          const { title_translated, category } = pDef

          if (quantity > 0) {
            return (
              <div key={pID} className={classNames('row mb-2')}>
                <div className={classNames('col-md-6')}>
                  {category === 'kit' && (
                    <span className={classNames('me-1')}>VitæDNA</span>
                  )}
                  <span className={classNames('fw-bold fst-uppercase')}>
                    {title_translated}
                  </span>
                </div>
                <div className={classNames('col-md-3 fw-bold text-md-end')}>
                  <span>{quantity}</span>
                </div>
                <div className={classNames('col-md-3 fw-bold text-md-end')}>
                  {`€ ${total_gross_price}`}
                </div>
              </div>
            )
          }
        })}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeSelectedProductsComponent)
