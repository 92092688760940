import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'
import { JujoButtonComponent } from '../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class VitaeDynamicButton extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  goTo = data => {
    const { url } = data
    if (typeof window !== 'undefined') {
      window.open(url, '_blank')
    }
  }

  render() {
    const { environment, specialization, data } = this.props

    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations

    const { bclasses, bstyle, blabel, bwidth, bheight, url } = data
    return (
      <div className={classNames('')}>
        <JujoButtonComponent
          bstyle={bstyle}
          bclasses={bclasses}
          bwidth={bwidth}
          blabel={texts[locale][blabel] || blabel}
          bheight={bheight}
          bdata={{ url }}
          handleClick={this.goTo}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeDynamicButton)
