import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaePersonalDataActionComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { enterEditMode, data } = this.props
    const dataToEdit = { ...data[0] }

    return (
      <div
        className={classNames('edit-icon-2')}
        style={{
          height: '18px',
          width: '18px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
        }}
        role="button"
        tabIndex={0}
        label="edit button"
        onClick={() => {
          enterEditMode(dataToEdit)
        }}
        onKeyPress={() => {
          enterEditMode(dataToEdit)
        }}
      />
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaePersonalDataActionComponent)
