import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'
import VitaeAssociableComponent from './_parts/associable_component'

const classNames = require('classnames')

export class VitaeAssociateProductsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderProductsToAssign = () => {
    const { session } = this.props
    const { session_data } = session
    const { products_to_associate } = session_data

    const html = []
    if (products_to_associate && products_to_associate.length > 0) {
      for (let i = 0; i !== products_to_associate.length; i += 1) {
        const product = products_to_associate[i]

        html.push(
          <VitaeAssociableComponent key={product.id} product={product} />
        )
      }
    }

    return html
  }

  render() {
    const { specialization, environment, session } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { session_data } = session
    const { products_to_associate } = session_data

    return (
      <div className={classNames('')}>
        <div
          className={classNames(
            'd-flex flex-column border-bottom border-2 pt-2 mb-1 fw-bold'
          )}
        >
          {texts[locale].n_products_to_assign.replace(
            '[n]',
            products_to_associate ? products_to_associate.length : 0
          )}
        </div>
        <div className={classNames('d-flex flex-column')}>
          {this.renderProductsToAssign()}
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeAssociateProductsComponent)
