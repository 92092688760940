/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'

import MyResultsTabMenu from './my_results_tab_menu'
import MyResults from './my_results'
import MyCallToCationServices from './my_call_to_action_services'
import VitaeMyResultsTitleAndDownloadButton from '../../_parts/my_results_title_and_download_button'
import JujoLoading from '../../../../../../../src/components/loading'
import { mapStateToProps } from '../../../../../../../src/utils'

const classNames = require('classnames')

export class MyResultsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  /* handleMyButtonClick = async () => {
    const { environment, authentication } = this.props
    const { access_token: tk } = authentication || ''
    const { locale } = environment
    const url = `${process.env.apiServerUrl}pdf/vitae/health?locale=${locale}&tk=${tk}`
    window.open(url) 
  } */

  render() {
    const { specialization, environment, authentication } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { genericData } = this.props
    const { myResults } = genericData || false
    const { swab } = myResults || false

    const { access_token: tk } = authentication || ''
    const downloadUrl = `${process.env.apiServerUrl}pdf/vitae/health?locale=${locale}&tk=${tk}`
    const { analysed_at_formatted_day: result_date, id: swab_id } = swab || {}

    const old_pdf_heath_exists =
      swab.old_pdf_health !== null && swab.old_pdf_health !== ''

    let informative = texts[locale].my_result_informative_1
    informative += !old_pdf_heath_exists
      ? texts[locale].my_result_informative_2
      : ''

    const { product_id } = swab || false
    const results_font_color = product_id === 4 ? 'fc-11' : 'fc-1'
    const title_colored_part =
      product_id === 4 ? 'Fitness' : texts[locale].health
    return (
      <div className={classNames('pt-4')}>
        {/*  Titolo */}
        {!swab && <JujoLoading />}
        {swab && (
          <>
            <VitaeMyResultsTitleAndDownloadButton
              title_black_part={texts[locale].my_health_result_test}
              title_colored_part={title_colored_part}
              title_class={results_font_color}
              downloadUrl={downloadUrl}
              result_date={result_date}
              swab_id={swab_id}
            />
            <div
              className={classNames(
                'd-flex justify-content-start mt-3 pb-4 fs-9',
                !old_pdf_heath_exists
                  ? 'border-bottom border-2 border-dark'
                  : ''
              )}
            >
              <div
                className={classNames('fs-7')}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: informative,
                }}
              />
            </div>
            {!old_pdf_heath_exists && (
              <>
                <MyResultsTabMenu />
                <MyResults />
              </>
            )}
            <MyCallToCationServices />
          </>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MyResultsContainer)
