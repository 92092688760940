import React from 'react'
import { connect } from 'react-redux'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'

import * as actionCreators from '../../../../../../src/store/actions'
import {
  cloneObj,
  ensureVariableIsLoaded,
  mapStateToProps,
} from '../../../../../../src/utils'
import workflowStepsMap from '../../checkout_group/enums/workflow_steps_map'
import VitaeOrderSummaryComponent from '../../checkout_group/order_summary'
import VitaeProductsSelectorComponent from '../../checkout_group/products_selector'
import JujoLoading from '../../../../../../src/components/loading'
import { getSessionClientData } from '../../checkout_group/order_workflow/helper'

const classNames = require('classnames')

export class VitaeKitOrderComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { initialized: false }
  }

  async componentDidMount() {
    await ensureVariableIsLoaded(this, 'props/session/session_data/cart')
    await this.doWorkflowStepCheck()
    await this.setState({ initialized: true })
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  doWorkflowStepCheck = async () => {
    const { session, updateSession } = this.props

    const session_copy = cloneObj(session)

    const { session_id, session_data } = session_copy

    const client_data = getSessionClientData(session_copy)
    const { order_workflow } = client_data

    if (!order_workflow || order_workflow === undefined) {
      client_data.order_workflow = {
        current_step: workflowStepsMap.shopping_cart,
      }
    }

    const returned_data = await saveSessionToServer(session_id, session_data)

    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  render() {
    const { initialized } = this.state
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    return (
      <div className={classNames('pt-2')}>
        {initialized === false && <JujoLoading />}
        {initialized === true && (
          <>
            <div
              className={classNames(
                'd-flex flex-column border-bottom border-2 pt-2 mb-1 fw-bold'
              )}
            >
              {texts[locale].my_orders_new}
            </div>
            <div className={classNames('row mt-3')}>
              <div className={classNames('col-12 col-md-6')}>
                <VitaeProductsSelectorComponent />
              </div>
              <div className={classNames('col-12 col-md-6')}>
                <VitaeOrderSummaryComponent />
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeKitOrderComponent)
