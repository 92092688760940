import React from 'react'
import { connect } from 'react-redux'
import Parser from 'html-react-parser'
import * as actionCreators from '../../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeProductDescriptionComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleDescBtnClicked = () => {
    const { expanded } = this.state
    this.setState({ expanded: !expanded })
  }

  renderProductDescription = () => {
    const { expanded } = this.state
    const { environment, specialization, slug, desc, color, label } = this.props
    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations

    const html = []

    const label_to_show =
      label || `${texts[locale].what_you_get} ${texts[locale][slug]}`

    html.push(
      <div
        key={`${slug}_description`}
        className={classNames(
          'mx-auto rounded-5 position-relative px-0',
          expanded ? 'rounded-md-top-5' : ''
        )}
        style={{
          borderColor: color,
          borderWidth: 1,
          borderStyle: 'solid',
          width: 'min(85vw, 280px)',
          fontSize: '11px',
        }}
      >
        <div
          className={classNames(
            'd-flex justify-content-between align-items-center'
          )}
        >
          <div className={classNames('fw-bold ms-2')} style={{ color }}>
            {label_to_show}
          </div>
          <div
            className={classNames(
              expanded ? `${slug}-bg-opacity-15` : '',
              expanded ? 'rounded-top-end-5' : 'rounded-end-5'
            )}
          >
            <div
              className={classNames(
                'background-image',
                `${slug}-svg-color`,
                expanded ? 'collapse-icon-2' : 'expand_submenu_icon'
              )}
              style={{
                width: '33px',
                height: '33px',
                backgroundSize: expanded ? '70%' : '70%',
              }}
              label="expand_collapse"
              role="button"
              tabIndex={0}
              onClick={() => {
                this.handleDescBtnClicked()
              }}
              onKeyPress={() => {
                this.handleDescBtnClicked()
              }}
            />
          </div>
        </div>
        {expanded && (
          <div
            className={classNames(
              'position-md-absolute zindex-1 bg-white p-2 border-top rounded-bottom-5 fs-8 shadow-md-sm',
              `${slug}-border-color`
            )}
          >
            {Parser(desc[locale], {})}
          </div>
        )}
      </div>
    )

    return html
  }

  render() {
    return (
      <div
        className={classNames('mx-auto row')}
        style={{ width: 'min(100%, 850px)' }}
      >
        {this.renderProductDescription()}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeProductDescriptionComponent)
