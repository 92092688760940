import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeLangSwitcherComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  updatePageUrl = locale => {
    const { session } = this.props
    const { session_data } = session
    const { jujo_page } = session_data
    const { lang_urls } = jujo_page

    const new_path = lang_urls[locale]
    if (typeof window !== 'undefined') {
      window.history.pushState({}, null, new_path)
    }
  }

  handleChangeLanguageClicked = () => {
    const { updateLocaleIntoStorageAndRedux } = this.props
    const { environment } = this.props
    const { locale } = environment

    const new_locale = locale === 'it' ? 'en' : 'it'

    this.updatePageUrl(new_locale)
    updateLocaleIntoStorageAndRedux(new_locale)
  }

  render() {
    const { environment } = this.props
    const { locale } = environment

    return (
      <div
        className={classNames(`lang-${locale}-icon background-image mx-auto`)}
        style={{ width: '30px', height: '30px' }}
        role="button"
        tabIndex={0}
        label="lang-swithcer"
        onClick={() => {
          this.handleChangeLanguageClicked()
        }}
        onKeyPress={() => {
          this.handleChangeLanguageClicked()
        }}
      />
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeLangSwitcherComponent)
