/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'

import TitleLineAndText from '../../../common_group/title_line_and_text'
import MyDrawInterpretations from './my_draw_interpretations'
import MyMicronutrients from './my_micronutrients'
import MyFoods from './my_foods'
import JujoLoading from '../../../../../../../src/components/loading'
import { mapStateToProps } from '../../../../../../../src/utils'

const classNames = require('classnames')

export class MyResults extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      resultCategory: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    await this.updateData()
  }

  componentDidUpdate = async prevProps => {
    await this.updateData(prevProps)
  }

  updateData = async prevProps => {
    const { genericData } = this.props
    const { resultCategory } = this.state
    if (
      genericData &&
      genericData.myResults &&
      genericData.myResults.resultCategoryTabs
    ) {
      if (
        !resultCategory ||
        (prevProps &&
          prevProps.genericData &&
          prevProps.genericData.myResults &&
          prevProps.genericData.myResults.menuItemSelected !==
            genericData.myResults.menuItemSelected)
      ) {
        const updatedResultCategory =
          genericData.myResults.resultCategoryTabs[
            genericData.myResults.menuItemSelected
          ]
        this.setState({ resultCategory: updatedResultCategory })
      }
    }
  }

  handleGuideButtonClick = () => {
    window.open('https://private.vitaedna.com/pdf/Guida%20vitaeDNA.pdf')
  }

  render() {
    const { resultCategory } = this.state
    const { layout } = resultCategory || ''
    const { specialization, environment, genericData } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { myResults } = genericData
    const { swab } = myResults
    const { product_id } = swab || false
    const results_font_color = product_id === 4 ? 'fc-11' : 'fc-1'

    return (
      <>
        {!resultCategory && <JujoLoading />}
        {resultCategory && (
          <div className={classNames('border border-color-1 rounded-20 p-4')}>
            {/* Title */}
            <div className={classNames('fs-5 fw-bold', results_font_color)}>
              {resultCategory.title_translated}
            </div>

            {/* Category title and text  */}
            {resultCategory.description_translated &&
              resultCategory.description_translated !== '' && (
                <div className={classNames('fs-6')}>
                  <TitleLineAndText
                    title={`${texts[locale].description}:`}
                    text={resultCategory.description_translated}
                  />
                </div>
              )}

            {/* Interpretazioni */}
            {layout === 'holistic' &&
              resultCategory &&
              resultCategory.holistics &&
              resultCategory.holistics
                .split(',')
                .map(holisticId => (
                  <MyDrawInterpretations
                    key={holisticId}
                    holisticId={holisticId}
                  />
                ))}

            {/* Micronutrienti */}
            {layout === 'micronutrients' && <MyMicronutrients />}

            {/* Cibi */}
            {layout === 'foods' && <MyFoods />}

            {/* Wiki */}
            {/* !!! RICORDA DI RIABILITARE ANCHE IL JUJO BUTTON IN CIMA ALLA PAGINA !!! */}
            {/* 
            <div className={classNames('d-flex flex-column py-4')}>
              <TitleLineAndText
                title={`${texts[locale].my_reading_of_the_results}:`}
                text={texts[locale].my_reading_of_the_results_description}
              />
              <div
                className={classNames(
                  'd-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center text-center text-md-start fw-bold fs-7 mt-2 mt-md-0'
                )}
              >
                <div>{texts[locale].my_scientific_guide_download}</div>
                <div>
                  <JujoButtonComponent
                    bstyle={3}
                    bwidth="230px"
                    blabel={texts[locale].my_download_guide_pdf}
                    bicon="download-icon"
                    bFontClass="fw-bold"
                    bdata=""
                    handleClick={this.handleGuideButtonClick}
                  />
                </div>
              </div>
            </div>
            */}
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MyResults)
