import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import requestTypesMap from '../../../../enums/requestTypesMap'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'
import { GenericField } from './genericField'

export class DateField extends GenericField {
  processOnChange = value => {
    this.setState({ fieldValue: value })
  }

  processOnBlur = value => {
    this.saveOnRedux(value)
  }

  renderField = () => {
    const { fieldValue } = this.state
    const { field, dynamicForm } = this.props
    const { name, disabled, renderDateType } = field
    const { type } = dynamicForm

    let dateValue = ''
    if (
      fieldValue &&
      fieldValue !== '0000-00-00 00:00:00' &&
      fieldValue !== '0000-00-00' &&
      fieldValue.length >= 10
    ) {
      dateValue = new Date(fieldValue.substr(0, 10))
        .toISOString()
        .slice(0, renderDateType && renderDateType !== 'date' ? 7 : 10)
    } else {
      dateValue = new Date()
        .toISOString()
        .slice(0, renderDateType && renderDateType !== 'date' ? 7 : 10)

      this.processOnChange(dateValue)
      this.processOnBlur(dateValue)
    }

    const html = []
    html.push(
      <input
        key={uuid()}
        type={renderDateType || 'date'}
        placeholder="yyyy-mm-dd"
        name={name}
        defaultValue={dateValue}
        disabled={disabled || type === requestTypesMap.view}
        onChange={e => {
          const { value } = e.target
          this.processOnChange(value)
        }}
        onBlur={e => {
          const { value } = e.target
          this.processOnBlur(value)
        }}
      />
    )

    return html
  }
}

export default connect(mapStateToProps, actionCreators)(DateField)
