/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/no-danger */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

export class ECComponentFieldComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { field, handleValueChanged, dataSource } = this.props
    const { component, specialized } = field

    let DynamicComponent = null
    if (specialized) {
      DynamicComponent =
        require(`../../../../vitae_specializations/src/${process.env.client}/components/${component}`).default
    } else {
      DynamicComponent = require(`../../${component}`).default
    }

    return (
      <div>
        <DynamicComponent
          field={field}
          dataSource={dataSource}
          handleValueChanged={handleValueChanged}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ECComponentFieldComponent)
