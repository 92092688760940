/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import TitleLineAndText from '../../common_group/title_line_and_text'
import Gallery from '../_parts/gallery'
import VideoIframe from '../_parts/video_iframe'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeVideoAccademyPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const { specialization, environment, session } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { session_data } = session
    const { video_accademy } = session_data || null
    if (!video_accademy) {
      return <div className={classNames('pt-4 d-flex flex-column')} />
    }
    const { gallery, selected_video } = video_accademy

    const { title, description, video_link } = gallery[selected_video]
    return (
      <div className={classNames('pt-4 d-flex flex-column')}>
        <TitleLineAndText title={texts[locale][title]} text="" />
        <div
          className={classNames(
            'row m-0 w-100 d-flex flex-row justify-content-between'
          )}
        >
          <div className={classNames('col-12 col-lg-7 p-0')}>
            <VideoIframe url={video_link} />
          </div>
          <div
            className={classNames(
              'col-12 col-lg-5  p-3 d-flex flex-column justify-content-center bg-3'
            )}
          >
            <div className={classNames('fs-6 fw-bold mb-2 lh-sm')}>
              {title[locale]}
            </div>
            <div className={classNames('fs-7 fst-italic lh-sm')}>
              {description[locale]}
            </div>
          </div>
          <div className={classNames('p-0')}>
            <Gallery />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeVideoAccademyPage)
