import React from 'react'
import { connect } from 'react-redux'
import Parser from 'html-react-parser'
import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleExpandCollapse = () => {
    const { expanded } = this.state
    const expanded_updated = !expanded
    this.setState({ expanded: expanded_updated })
  }

  render() {
    const { expanded } = this.state
    const { obj } = this.props
    const { question, answer, bg_color, font_color } = obj || {}
    const bg_color_to_use = bg_color || 'bg-health'
    const font_color_to_use = font_color || 'fc-5'

    const button_svg = expanded ? 'collapse_icon' : 'expand_icon'

    return (
      <div>
        <div className={classNames('row mb-1 mb-md-2 mt-4 mt-md-0')}>
          <div
            className={classNames(
              'col-10 fw-600 fs-6 fs-md-5 text-start',
              font_color_to_use
            )}
          >
            {Parser(question)}
          </div>
          <div className={classNames('col-2')}>
            <div
              className={classNames(
                'd-flex align-itams-center justify-content-center fs-6 fs-md-5 fc-5 float-start float-md-end',
                bg_color_to_use
              )}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '5px',
                color: '#fff',
                cursor: 'pointer',
                margin: '0 auto',
              }}
              role="button"
              tabIndex={0}
              onClick={async () => {
                this.handleExpandCollapse()
              }}
              onKeyPress={async () => {
                this.handleExpandCollapse()
              }}
            >
              <div
                className={classNames(
                  'background-image theme-svg-3 fs-7 fs-md-6',
                  button_svg
                )}
                style={{ width: '15px', height: '25px' }}
              />
            </div>
          </div>
        </div>
        {expanded && (
          <div className={classNames('mb-4 fs-8 fs-md-7 text-start')}>
            {Parser(answer)}
          </div>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeQuestion)
