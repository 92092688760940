import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

import LabelAndText from '../label_and_text'

const classNames = require('classnames')

export class CourierTracking extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleClick = () => {}

  render() {
    const { specialization, environment, order } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    return (
      <div
        className={classNames(
          'position-relative row m-0 mt-2 p-2 border border-color-4 border-1 rounded-2'
        )}
      >
        <div
          className={classNames('position-absolute truck-icon theme-svg-4')}
          style={{
            width: '35px',
            height: '35px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            right: '10px',
            top: '4px',
          }}
        />
        <LabelAndText
          label={`${texts[locale].my_shipped_with}: `}
          texts={[order.shipping.shipping_name]}
          size="short"
        />
        <LabelAndText
          label={`${texts[locale].my_tracking_number}: `}
          texts={[order.shipping.waybill]}
          size="short"
        />
        <LabelAndText
          label={`${texts[locale].status}: `}
          texts={[order.shipping.status_name_translated]}
          size="short"
        />
        {order.shipping.last_tracking_data !== '' && (
          <LabelAndText
            label={`${texts[locale].my_shipment_date}: `}
            texts={[order.shipping.last_tracking_data]}
            size="short"
          />
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(CourierTracking)
