/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'

import * as apiService from '../../../../../../src/services/apiService'
import VitaeSportNeeded from './_parts/my_sport_needed'
import VitaeSportNotYetAvailable from './_parts/my_sport_not_yet_available'
import VitaeDietWaitPayment from './_parts/my_sport_wait_payment'
import VitaeDrawSport from './_parts/my_draw_sport'
import VitaeDietWaitClinicalProfile from './_parts/my_sport_waiting_clinical_profile'
import JujoLoading from '../../../../../../src/components/loading'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeResultSport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dataLoaded: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    await this.updateData()
  }

  updateData = async () => {
    await this.getCollectionFromServerAndUpdateOnRedux('my/swab', 'swab', '', 0)
    await this.getCollectionFromServerAndUpdateOnRedux(
      'my/service',
      'training_card',
      'service_slug[like]=training_card',
      0
    )
    this.setState({ dataLoaded: true })
  }

  getCollectionFromServerAndUpdateOnRedux = async (
    url,
    name,
    params,
    index
  ) => {
    let { genericDataBox3 } = this.props
    const { updateGenericDataBox3, environment } = this.props
    const { locale } = environment
    const parsed_url = `${url}?locale=${locale}&page=1&per_page=9999&${params}`
    const response = await apiService.httpGet(
      `${process.env.apiUrl}${parsed_url}`
    )
    if (response.status === 200) {
      let data = response.data.data || false
      data =
        index !== undefined && index >= 0 && data.length > 0
          ? data[index]
          : false
      genericDataBox3 = {
        ...genericDataBox3,
        [name]: data,
      }
      await updateGenericDataBox3(genericDataBox3)
      return data
    }
    return false
  }

  render() {
    const { dataLoaded } = this.state
    const { genericDataBox3 } = this.props
    const { training_card, swab } = genericDataBox3
    const { training_card_status } = swab || 'none'

    const waitingTrainingCard =
      training_card &&
      (training_card.status === 'waiting_health' ||
        training_card.status === 'waiting_specialist')
    const waitingPayment =
      training_card && training_card.status === 'waiting_payment'
    const waitingClinicalProfile =
      training_card && training_card.status === 'waiting_clinical_profile'
    if (!training_card && training_card_status !== 'none') {
      return <div className={classNames('pt-4')} />
    }
    return (
      <div className={classNames('pt-4')}>
        {!dataLoaded && <JujoLoading />}
        {dataLoaded && !training_card && <VitaeSportNeeded />}
        {dataLoaded && training_card && waitingTrainingCard && (
          <VitaeSportNotYetAvailable />
        )}
        {dataLoaded && training_card && waitingPayment && (
          <VitaeDietWaitPayment />
        )}
        {dataLoaded && training_card && waitingClinicalProfile && (
          <VitaeDietWaitClinicalProfile />
        )}
        {dataLoaded &&
          training_card &&
          !waitingTrainingCard &&
          !waitingPayment &&
          !waitingClinicalProfile && <VitaeDrawSport />}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeResultSport)
