/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import { saveSessionToServer } from '../../../../../../../../src/services/sessionHelper'

import * as actionCreators from '../../../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeDiscountCodeComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      edit_mode: false,
      discount_code: '',
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  handleApplyDiscount = async () => {
    const { discount_code } = this.state
    const { session, updateSession } = this.props

    // it works better than { ... session } because it create a new object
    const updated_session = cloneObj(session)

    const { session_id, session_data } = updated_session

    const { cart } = session_data
    const { discount } = cart
    discount.code = discount_code

    const returned_data = await saveSessionToServer(session_id, session_data)

    updated_session.session_data = returned_data
    await updateSession(updated_session)
  }

  render() {
    const { edit_mode, discount_code } = this.state
    const { session, specialization, environment } = this.props

    const { session_data } = session
    const { cart } = session_data
    const { discount } = cart
    const { code } = discount

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div className={classNames('px-2')}>
        {code === '' && edit_mode === false && (
          <div
            className={classNames('fc-4 fs-7')}
            style={{ textDecoration: 'underline' }}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.setState({ edit_mode: true })
            }}
            onKeyPress={() => {
              this.setState({ edit_mode: true })
            }}
          >
            {texts[locale].apply_discount_code}
          </div>
        )}
        {edit_mode === true && (
          <div
            className={classNames(
              'd-flex align-items-center bg-white rounded border p-2'
            )}
          >
            <input
              type="text"
              placeholder={texts[locale].enter_discount_code}
              defaultValue={code}
              onChange={e => {
                this.setState({ discount_code: e.target.value })
              }}
            />
            {discount_code.length === 0 && (
              <div
                className={classNames('fw-bold fs-7')}
                style={{ textDecoration: 'underline' }}
                role="button"
                tabIndex={0}
                onClick={() => {
                  this.setState({ edit_mode: false })
                }}
                onKeyPress={() => {
                  this.setState({ edit_mode: false })
                }}
              >
                {texts[locale].cancel}
              </div>
            )}
            {discount_code.length > 0 && (
              <div
                className={classNames('fc-1 fw-bold fs-7')}
                style={{ textDecoration: 'underline' }}
                role="button"
                tabIndex={0}
                onClick={async () => {
                  await this.handleApplyDiscount()
                  this.setState({ edit_mode: false })
                }}
                onKeyPress={async () => {
                  await this.handleApplyDiscount()
                  this.setState({ edit_mode: false })
                }}
              >
                {texts[locale].apply}
              </div>
            )}
          </div>
        )}
        {code !== '' && edit_mode === false && (
          <div
            className={classNames('d-flex justify-content-between')}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.setState({ edit_mode: true })
            }}
            onKeyPress={() => {
              this.setState({ edit_mode: true })
            }}
          >
            <div>
              <span>{texts[locale].discount_code}</span>
              <span className={classNames('ms-1 fw-bold')}>{code}</span>
            </div>
            <div
              className={classNames('confirm-icon-color')}
              style={{
                height: '20px',
                width: '20px',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeDiscountCodeComponent)
