import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'

import VitaeStoreReportLineComponent from './_parts/report_line'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeStoreReports extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { data, entityDefinition, environment, specialization } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div className={classNames('row')}>
        {data && data.length === 0 && (
          <div className={classNames('my-3')}>
            {texts[locale].no_statements_to_view}
          </div>
        )}
        {data.map(record => (
          <VitaeStoreReportLineComponent
            key={record.id}
            entityDefinition={entityDefinition}
            record={record}
          />
        ))}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeStoreReports)
