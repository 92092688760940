/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'

import * as apiService from '../../../../../../src/services/apiService'
import VitaeDrawDiet from './_parts/my_draw_diet'
import VitaeDietNeeded from './_parts/my_diet_needed'
import VitaeDietWaitPayment from './_parts/my_diet_waiting_payment'
import VitaeDietWaitClinicalProfile from './_parts/my_diet_waiting_clinical_profile'
import VitaeDietNotYetAvailable from './_parts/my_diet_not_yet_available'
import JujoLoading from '../../../../../../src/components/loading'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeResultDiet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dataLoaded: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    await this.updateData()
  }

  updateData = async () => {
    await this.getCollectionFromServerAndUpdateOnRedux('my/swab', 'swab', '', 0)
    await this.getCollectionFromServerAndUpdateOnRedux(
      'my/service',
      'diet',
      'service_slug[like]=diet',
      0
    )
    this.setState({ dataLoaded: true })
  }

  getCollectionFromServerAndUpdateOnRedux = async (
    url,
    name,
    params,
    index
  ) => {
    let { genericDataBox2 } = this.props
    const { updateGenericDataBox2, environment } = this.props
    const { locale } = environment
    const parsed_url = `${url}?locale=${locale}&page=1&per_page=9999&${params}`
    const response = await apiService.httpGet(
      `${process.env.apiUrl}${parsed_url}`
    )
    if (response.status === 200) {
      let data = response.data.data || false
      data =
        index !== undefined && index >= 0 && data.length > 0
          ? data[index]
          : false
      genericDataBox2 = {
        ...genericDataBox2,
        [name]: data,
      }
      await updateGenericDataBox2(genericDataBox2)
      return data
    }
    return false
  }

  render() {
    const { dataLoaded } = this.state
    const { genericDataBox2 } = this.props
    const { diet } = genericDataBox2
    const waitingDiet =
      diet &&
      (diet.status === 'waiting_health' || diet.status === 'waiting_specialist')
    const waitingPayment = diet && diet.status === 'waiting_payment'
    const waitingClinicalProfile =
      diet && diet.status === 'waiting_clinical_profile'
    return (
      <div className={classNames('pt-4')}>
        {!dataLoaded && <JujoLoading />}
        {dataLoaded && !diet && <VitaeDietNeeded />}
        {dataLoaded && diet && waitingDiet && <VitaeDietNotYetAvailable />}
        {dataLoaded && diet && waitingPayment && <VitaeDietWaitPayment />}
        {dataLoaded && diet && waitingClinicalProfile && (
          <VitaeDietWaitClinicalProfile />
        )}
        {dataLoaded &&
          diet &&
          !waitingDiet &&
          !waitingPayment &&
          !waitingClinicalProfile && <VitaeDrawDiet />}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeResultDiet)
