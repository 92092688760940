import React from 'react'
import { connect } from 'react-redux'
import VitaeQuantitySelectorComponent from '../quantity_selector'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeProductsSelectorComponent extends React.Component {
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderProducts = () => {
    const { session, environment, specialization } = this.props
    const { session_data } = session
    const { product_data, cart } = session_data

    const { products } = cart

    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations

    const pIDList = Object.keys(product_data || {})

    const render_categories = ['kit']

    const html = []
    for (let i = 0; i !== pIDList.length; i += 1) {
      const pID = pIDList[i]
      const prod = product_data[pID]
      const { category } = prod

      if (render_categories.indexOf(category) >= 0) {
        const cart_product_instance = products[pID]
        const { quantity, max_quantity } = cart_product_instance

        const {
          title_translated,
          subtitle_translated,
          final_price,
          // attributes,
        } = prod

        const { cover_picture } = prod
        html.push(
          <div
            key={pID}
            className={classNames(
              'd-flex flex-column flex-md-row justify-content-between align-items-center py-4 border-bottom fc-5',
              i === 0 ? '' : ' border-top'
            )}
          >
            <div
              style={{
                backgroundImage: `url(${cover_picture})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '210px',
                height: '140px',
              }}
            />
            <div
              className={classNames(
                'd-flex flex-fill flex-column align-self-center align-items-start ffamily-secondary mx-3'
              )}
              style={{ width: 'min(70%, 250px)' }}
            >
              <div className={classNames('fs-6')}>
                <span>VitæDNA</span>
                <span className={classNames('fw-bold ms-1 fst-uppercase')}>
                  {title_translated}
                </span>
              </div>
              <div className={classNames('fs-7 fst-italic')}>
                {subtitle_translated}
              </div>
              <div
                className={classNames(
                  'd-flex align-items-center justify-content-between fs-7 my-2 me-1'
                )}
                style={{ width: '100%' }}
              >
                <div>{texts[locale].quantity}</div>

                <VitaeQuantitySelectorComponent
                  quantity={quantity}
                  max_quantity={max_quantity}
                  pID={pID}
                />
              </div>
              <div
                className={classNames(
                  'd-flex align-items-center justify-content-between fs-7'
                )}
                style={{ width: '100%' }}
              >
                <div>{texts[locale].price}</div>
                <div className={classNames('fw-bold fs-6 fc-black')}>
                  {`€ ${final_price}`}
                </div>
              </div>
            </div>
          </div>
        )
      }
    }
    return html
  }

  render() {
    return <div className={classNames('')}>{this.renderProducts()}</div>
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeProductsSelectorComponent)
