import {
  createMSBDef,
  getEmptyMessageBox,
} from '../../../../../../src/components/message_box/helper'
import { injectComponent, retrieveComponent } from '../../../../../../src/utils'

async function closeMessageBox(params) {
  const { sender, msbox_instance_id } = params
  retrieveComponent(sender, msbox_instance_id)
}

export function showMessageBox(sender, response, type) {
  const { specialization, environment } = sender.props
  const { data } = response

  const { translations } = specialization
  const { texts } = translations
  const { locale } = environment

  const messageBox = getEmptyMessageBox()

  messageBox.icon =
    type === 'error' ? 'warning-icon-color' : 'confirm-icon-color'
  messageBox.title =
    type === 'error' ? texts[locale].warning : texts[locale].success

  let { message } = data

  if (data.fields) {
    message += '<div class="fs-7">'
    const kList = Object.keys(data.fields)
    for (let i = 0; i !== kList.length; i += 1) {
      const k = kList[i]
      const KMsgList = data.fields[k]
      const msg = KMsgList[0]
      message += `<div>${msg}</div>`
    }
    message += '</div>'
  }

  messageBox.message = message
  const ok_btn = createMSBDef(
    texts[locale].ok,
    closeMessageBox,
    { sender, msbox_instance_id: messageBox.id },
    true
  )
  messageBox.buttons.push(ok_btn)

  const component_definition = {
    specialized: false,
    path: 'message_box',
    data: {
      messageBox,
    },
  }

  injectComponent(sender, messageBox.id, component_definition)
}
