import React from 'react'
import { connect } from 'react-redux'
import {
  getCurrentPageDefinition,
  handleChangePageClicked,
} from '../../../../helper/pageFunctions'
import conditionCheckTypesMap from '../../../../../../enums/conditionCheckTypesMap'
import sourcesMap from '../../../../../../enums/sourcesMap'

import * as actionCreators from '../../../../../../store/actions'
import { mapStateToProps, translate } from '../../../../../../utils'

const classNames = require('classnames')

export class JujoLeraMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      menu: [],
      mobile_opened: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => { }
  }

  componentDidMount = async () => {
    const { specialization, session } = this.props

    if (process.env.workWithSession) {
      const { session_data } = session
      const { menu } = session_data
      this.setState({ initialized: true, menu })
    } else {
      const { config } = specialization
      const { menu } = config

      const pageDef = getCurrentPageDefinition(this.props)
      const menuItems = menu[pageDef.menu]
      const filteredMenu = this.filterMenuItems(menuItems)

      this.setState({ initialized: true, menu: filteredMenu })
    }
  }

  componentDidUpdate = async prevProps => {
    const { session } = this.props
    const { session_data } = session
    const { menu } = session_data

    if (
      JSON.stringify(menu) !==
      JSON.stringify(prevProps.session.session_data.menu) &&
      process.env.workWithSession
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ menu })
    }
  }

  /**
    @deprecated Session replacement: all functions that currently works with json config will be gradually moved to session
  */
  filterMenuItems = menuItems => {
    const filteredMenuItems = []
    for (let i = 0; i !== menuItems.length; i += 1) {
      const mItem = menuItems[i]

      const { conditions, sub_menu } = mItem
      const verified = this.verifyMenuConditions(conditions)

      if (verified) {
        if (sub_menu) {
          const filteredSubMenu = this.filterMenuItems(sub_menu)
          mItem.sub_menu = filteredSubMenu
        }

        filteredMenuItems.push(mItem)
      }
    }
    return filteredMenuItems
  }

  verifyMenuConditions = conditions => {
    const { authentication, session } = this.props
    const { user } = authentication
    const { session_data } = session
    if (!conditions) return true


    let errors = false
    for (let j = 0; j !== conditions.length; j += 1) {
      console.log(`Checking condition:`, condition);
      const condition = conditions[j]
      const { source, relatedField, checkType, check } = condition

      let relFieldValue = null
      if (source === sourcesMap.user) {
        relFieldValue = user[relatedField]
      }
      if (source === sourcesMap.session) {
        const steps = relatedField.split('/')
        relFieldValue = { ...session_data }
        for (let i = 0; i !== steps.length; i += 1) {
          const next_step = steps[i]
          relFieldValue = relFieldValue[next_step]
        }
      }

      console.log(`Related field value:`, relFieldValue);
      if (checkType === conditionCheckTypesMap.equals_to) {
        if (Array.isArray(check)) {
          let meet = false
          for (let x = 0; x !== check.length; x += 1) {
            const currentCheck = check[x]
            if (currentCheck === relFieldValue) {
              meet = true
              break
            }
          }

          errors = !meet
        } else {
          errors = check !== relFieldValue
        }
      } else if (checkType === conditionCheckTypesMap.greater_than) {
        errors = parseInt(relFieldValue, 10) <= parseInt(check, 10)
      }

      if (errors === true) {
        break
      }
    }

    return !errors
  }

  handleMenuItemClicked = itm => {
    const { url, sub_menu } = itm

    const sessionWWW = localStorage.getItem('vitae_www.vitaedna.com_session');
    const session = localStorage.getItem('vitae_vitaedna.com_session');

    const authWWW = localStorage.getItem('vitae_www.vitaedna.com_authentication');
    const auth = localStorage.getItem('vitae_vitaedna.com_authentication');

    if (url === '/admin/orders/') {
      window.location.href = `${process.env.site_url}new-area/orders-admin/list/?token=${JSON.parse(authWWW ? authWWW : auth).access_token}
      &sessionId=${JSON.parse(sessionWWW ? sessionWWW : session).session_id}&role=admin`
      return
    }

    // if (!sub_menu) {
    //   handleChangePageClicked(url, this.props)
    // } else {
    //   handleChangePageClicked(sub_menu[0].url, this.props)
    // }
    if (!sub_menu) {
      handleChangePageClicked(`/private${url}`, this.props)
    } else {
      handleChangePageClicked(`/private${sub_menu[0].url}`, this.props)
    }
  }

  openCloseMobileMenu = () => {
    const { mobile_opened } = this.state
    this.setState({ mobile_opened: !mobile_opened })
  }

  renderMenuItems = (menuLevel, deep) => {
    const { environment } = this.props
    const { path } = environment

    const html = []
    for (let i = 0; i !== menuLevel.length; i += 1) {
      const itm = menuLevel[i]
      const { static_name, title, url, sub_menu, badge_amount } = itm
      const pathDeep = path.split('/').filter(x => x).length
      const urlDeep = url.split('/').filter(x => x).length
      const currentPage = url.replace(/\/$/, '') === path.replace("/private", "").replace(/\/$/, '')
      let expandedNode = false

      if (
        pathDeep > urlDeep &&
        path.indexOf("/private" + url) != -1 &&
        sub_menu !== undefined
      ) {
        for (let j = 0; j !== sub_menu.length; j += 1) {
          const sub_itm = sub_menu[j]
          const currentSubPage =
            sub_itm.url.replace(/\/$/, '') === path.replace("/private", "").replace(/\/$/, '')
          if (currentSubPage) {
            expandedNode = true
            break
          }
        }
      }

      html.push(
        <div
          key={static_name}
          className={classNames(
            deep === 0 ? 'animated-menu-icon' : '',
            currentPage ? 'fc-1 selected' : '',
            expandedNode ? 'fc-1 selected pb-2' : ''
          )}
        >
          <div
            className={classNames(
              deep === 0 ? 'fs-6 ps-2 py-2' : '',
              deep === 1 ? 'fs-7 ps-3 py-1' : '',
              expandedNode
                ? 'fw-bold d-flex justify-content-between align-items-center'
                : '',
              currentPage ? 'fw-bold' : ''
            )}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleMenuItemClicked(itm)
            }}
            onKeyPress={() => {
              this.handleMenuItemClicked(itm)
            }}
          >
            <div className={classNames('d-flex flex-row align-items-center')}>
              <div>{translate(title)}</div>
              <div
                className={classNames('ms-2 badge rounded-pill bg-danger')}
                style={{
                  display:
                    !badge_amount || badge_amount === 0 ? 'none' : 'block',
                }}
              >
                {badge_amount}
              </div>
            </div>
            {expandedNode && (
              <div
                className={classNames('expand-icon theme-svg')}
                style={{
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  width: '18px',
                  height: '18px',
                }}
              />
            )}
          </div>
          {expandedNode && (
            <div>{this.renderMenuItems(sub_menu, deep + 1)}</div>
          )}
        </div>
      )
    }

    return html
  }

  render() {
    const { initialized, menu, mobile_opened } = this.state

    return (
      <div className={classNames('')}>
        {initialized === true && menu && menu.length > 0 && (
          <>
            <div
              className={classNames(
                'd-flex d-md-none mx-auto',
                mobile_opened ? 'close-icon' : 'burger-menu-icon'
              )}
              style={{
                width: '25px',
                height: '25px',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              }}
              role="button"
              tabIndex={0}
              label="burger_menu"
              onClick={() => {
                this.openCloseMobileMenu()
              }}
              onKeyPress={() => {
                this.openCloseMobileMenu()
              }}
            />
            <div
              className={classNames(
                'd-md-flex flex-column my-4 mx-auto',
                mobile_opened ? 'd-flex' : 'd-none'
              )}
              style={{ maxWidth: '300px' }}
            >
              {this.renderMenuItems(menu, 0)}
            </div>
          </>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoLeraMenu)
