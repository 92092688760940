import React from 'react'
import { connect } from 'react-redux'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'
import * as actionCreators from '../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeWorkflowIndicatorComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  goToStep = async idx => {
    const {
      workflowStepsMap,
      navigateBackEnabled,
      stepsTrackerPath,
      currentStepSlug,
      session,
      updateSession,
    } = this.props

    const currentStep = workflowStepsMap[currentStepSlug]
    if (navigateBackEnabled && currentStep > idx) {
      const session_copy = cloneObj(session)
      const { session_id, session_data } = session_copy

      const paths = stepsTrackerPath.split('/')

      let step_value = session_data
      for (let i = 0; i !== paths.length - 1; i += 1) {
        const sub_path = paths[i]
        step_value = step_value[sub_path]
      }

      const key_to_change = paths[paths.length - 1]
      step_value[key_to_change] = idx

      const returned_data = await saveSessionToServer(session_id, session_data)

      session_copy.session_data = returned_data
      await updateSession(session_copy)
    }
  }

  render() {
    const {
      specialization,
      environment,
      workflowStepsMap,
      currentStepSlug,
      iconPrefix,
    } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const currentStep = workflowStepsMap[currentStepSlug]

    const steps_count = Object.keys(workflowStepsMap).length
    const margins = 100 / (steps_count * 2)
    const multiplier_factor = 100 / steps_count

    return (
      <div className={classNames('d-flex ffamily-secondary fs-8 ')}>
        <div className={classNames('row m-0 w-100 position-relative')}>
          {Object.keys(workflowStepsMap).map((name, idx) => (
            <div
              key={idx}
              className={classNames('col-12 col-md my-2 my-md-0')}
              role="button"
              tabIndex={0}
              onClick={() => {
                this.goToStep(idx)
              }}
              onKeyPress={() => {
                this.goToStep(idx)
              }}
            >
              {/* Icon */}
              <div
                className={classNames('d-flex flex-column align-items-center')}
              >
                <div
                  className={classNames(
                    `${iconPrefix}-${name}-${
                      idx <= currentStep ? 'enabled' : 'disabled'
                    }-icon mb-2`
                  )}
                  style={{
                    width: '35px',
                    height: '35px',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              </div>
              {/* Circles */}
              <div
                className={classNames('d-flex flex-column align-items-center')}
              >
                {idx === currentStep && (
                  <div
                    className={classNames(
                      'border border-2 rounded-percentage-100 border-color-1 d-flex justify-content-center align-items-center mx-auto bg-3 zindex-1'
                    )}
                    style={{ width: '23px', height: '23px' }}
                  />
                )}
                {idx > currentStep && (
                  <div
                    className={classNames(
                      'border border-2 rounded-percentage-100 border-color-4 d-flex justify-content-center align-items-center mx-auto bg-3 zindex-1'
                    )}
                    style={{ width: '23px', height: '23px' }}
                  />
                )}
                {idx < currentStep && (
                  <div
                    className={classNames('confirm-icon-color zindex-1')}
                    style={{
                      width: '23px',
                      height: '23px',
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                )}
              </div>
              {/* Text */}
              <div
                className={classNames('d-flex flex-column align-items-center')}
              >
                <div
                  className={classNames(
                    'd-flex justify-content-center text-center mt-1 fs-9',
                    idx <= currentStep ? 'fc-1 fw-bold' : 'fc-4'
                  )}
                  style={{
                    opacity: idx <= currentStep ? 1 : 0.8,
                  }}
                >
                  {texts[locale][name] || name}
                </div>
              </div>
            </div>
          ))}

          <div
            className={classNames('position-absolute bg-4 d-none d-md-block')}
            style={{
              height: '2px',
              width: `calc(100% - ${margins * 2}%)`,
              left: `${margins}%`,
              top: '54px',
            }}
          />
          {currentStep > 0 && (
            <div
              className={classNames('position-absolute bg-1 d-none d-md-block')}
              style={{
                height: '2px',
                width: `calc(${currentStep * multiplier_factor}%)`,
                top: '54px',
                left: `${margins}%`,
              }}
            />
          )}
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeWorkflowIndicatorComponent)
