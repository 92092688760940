/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeMyAddressEditMessageComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div className={classNames('fs-8 fc-4 fst-italic')}>
        <span>{`${texts[locale].my_address_privacy_policy_part_1} `}</span>
        <span>
          <a href="/">{texts[locale].privacy_policy}</a>
        </span>
        <span>{` ${texts[locale].my_address_privacy_policy_part_2}`}</span>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(VitaeMyAddressEditMessageComponent)
