import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class VitaeProductFeature extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { data } = this.props
    const { description, svg, bg } = data || {}

    return (
      <div
        className={classNames(
          'py-4 py-md-0 d-flex flex-column align-items-center justify-content-center h-250px',
          bg
        )}
      >
        <div
          className={classNames(svg, 'theme-svg-3 background-image my-3')}
          style={{
            width: '70px',
            height: '70px',
          }}
        />
        <div
          className={classNames('fs-6 fc-white text-center my-3 p-2')}
          style={{ maxWidth: '300px' }}
        >
          {description}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(VitaeProductFeature)
