/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import Parser from 'html-react-parser'
import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'

const classNames = require('classnames')

export class MyFitnessInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { genericData, specialization, environment } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { myResults } = genericData || {}
    const { swab } = myResults || {}
    const { product_id } = swab || {}

    if (product_id !== 4) {
      return <></>
    }

    return (
      <div className={classNames('fs-7 fc-1 fst-italic')}>
        {Parser(texts[locale].my_results_dna_description_only_fitness)}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MyFitnessInfo)
